import React from 'react';
import {Dimensions, StyleSheet,View,} from 'react-native';
import {IconButton, useTheme} from 'react-native-paper';
import BudgetList from '../components/BudgetList';

interface Props {
    navigation: any
}

export default function BudgetScreen({navigation}:Props){ 
    const {colors} = useTheme();
    const styles = Styles(colors);

    React.useLayoutEffect(() => {
        navigation.setOptions({
          headerRight: () => (
            <View style={{alignItems:'center',backgroundColor:colors.primary,borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
              <IconButton color={colors.accent} icon={'plus'} onPress={()=>navigation.navigate('Add Budget')} size={18} />
            </View>
          ),
        });
    }, [navigation]);

    return(
        <View style={styles.container}>
            <BudgetList />
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
        })
    );
}