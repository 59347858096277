import React from 'react';
import { View } from 'react-native';
import { Badge, List, Text, useTheme } from 'react-native-paper';
import { currencyFormatter, dateFormatter } from '../constants/utils';
import { BillProps } from '../hooks/useBills';
import DeleteBillAlert from './DeleteBillAlert';

export interface BillListProps {
    bill: BillProps;
    DeleteBill: (id:string)=>Promise<any>
    onPress: () => void;
}
export function BillsList({ bill, DeleteBill, onPress }: BillListProps) {
    const { colors } = useTheme();
    const [visible, setVisible] = React.useState(false);
    const hideDialog = () => setVisible(false);
    const showDialog = () => setVisible(true);

    return (
        <>
        <List.Item
            description={bill.due>0&&'Due: '+dateFormatter(bill.due,'fullString')}
            descriptionStyle={{ color: colors.onSurface }}
            onPress={onPress}
            onLongPress={()=>showDialog()}
            right={(props) => (
                <View {...props}>
                    {(!bill.complete) && <Badge style={{color:colors.error}} />}
                    <Text style={{ fontWeight: 'bold', }}>
                        {currencyFormatter(Number(bill.amount))}
                    </Text>
                </View>
            )}
            style={{ backgroundColor: colors.background, borderColor: '#F0F0F0', borderRadius: 15, borderWidth: 1, marginBottom: 20 }}
            title={bill.name}
            titleStyle={{ color: colors.onSurface }} 
        />
        <DeleteBillAlert 
            DeleteBill={()=>{
                DeleteBill(bill.id)
                    .then(()=>{
                        hideDialog();
                    })
                    .catch((error:Error)=>{
                        alert(error.message);
                    })
                }}
                hideDialog={hideDialog}
                visible={visible} 
            />
        </>    
    );
}
