import React from 'react';
import {Alert, ScrollView, StyleSheet,View,} from 'react-native';
import {List, useTheme, Divider, Switch} from 'react-native-paper';
import { AppContext } from '../context';
import { useAuthentication } from '../hooks/useAuthentication';
import { useUser } from '../hooks/useUser';
import { FirebaseAuth } from '../constants/Firebase';
import { useBiometrics } from '../hooks/useBiometrics';

export default function SettingsScreen({navigation}:any){ 
    const {state} = React.useContext(AppContext);
    const {toggleBiometricUse} = useBiometrics();
    const {biometric,user} = state;
    const {colors} = useTheme();
    const styles = Styles(colors);

    const {Logout} = useAuthentication({});
    const {DeleteUser} = useUser();

    const ListItems = [
        {
            key:'Authentication',
            title:'Authentication',
            type:'header'
        },
        {
            key:'biometiric',
            title:(biometric=='enabled'?'Disable':'Enable')+' Biometric Unlock',
            onPress:toggleBiometricUse,
            left:(props:any)=><List.Icon {...props} icon={'lock-open-outline'} />,
            right:(props:any)=><><Switch {...props} value={(biometric=='enabled')} onValueChange={toggleBiometricUse} /></>,
            noShow: (biometric=='unknown'|| biometric=='unavailable')? true : false
        },
        {
            key:'account',
            title:'Account',
            type:'header'
        },
        {
            key:'resetPass',
            title:'Reset Password',
            onPress:async ()=>{ 
                Alert.alert("Reset my Password","Are you sure you want to reset your password?",[
                    {text: 'Cancel',style:'cancel'},
                    {
                        text:'reset',
                        onPress: ()=>{
                            FirebaseAuth.sendPasswordResetEmail(FirebaseAuth.getAuth(),user.email)
                            .then(()=>{
                                alert('Password reset email sent successfully');
                            })
                            .catch((error)=>{
                            })
                            .finally(()=>{
                            });
                        }
                    }
                ]);
            },
            left:(props:any)=><List.Icon {...props} icon={'lock-reset'} />
        },
        {
            key:'delete',
            title:'Delete My Account', 
            color:colors.error,
            onPress:async ()=>{ 
                Alert.alert("Delete My Account","Are you sure you want to delete your entire account?\n This action is permanent and cannot be reversed.",[
                    {text: 'Cancel',style:'cancel'},
                    {
                        text:'delete',
                        onPress: ()=>{DeleteUser(user.id,'DELETE',Logout)}
                    }
                ]);
            },
            left:(props:any)=><List.Icon {...props} color={colors.error} icon={'delete'} />
        },
    ];

    return(
        <View style={styles.container}>
            <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
                <List.Section>
                {
                    ListItems.map((item)=>{
                        if(item.noShow) return <View/>
                        if(item.type && item.type=='header'){
                            return(
                                <React.Fragment key={item.key}>
                                    {/*@ts-ignore*/}
                                    <List.Subheader>{item.title}</List.Subheader>
                                    <Divider/>
                                </React.Fragment>
                            );
                        }
                        return(
                            <React.Fragment key={item.key}>
                            {/*@ts-ignore*/}
                            <List.Item 
                                left={item.left}
                                onPress={item.onPress}
                                right={(props:any)=>item?.right?item.right(props):<List.Icon {...props} icon={'chevron-right'} />}
                                title={item.title}
                                titleStyle={{color:item.color??colors.primary}}
                            />
                            <Divider />
                            </React.Fragment>
                        );
                    })
                }
                </List.Section>
            </ScrollView>
        </View>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            container: {
                backgroundColor: colors.background,
                flex: 1,
                padding:10
            },
            profileSummary: {
                flexDirection: 'row',
                height: 100,
                marginBottom: 5,
                padding: 20,
            },
        })
    );
}