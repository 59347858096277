import React from 'react';
import { Firestore,Functions } from '../constants/Firebase';
import Strings from '../constants/Strings';
import { AppContext } from '../context';
const {useEffect,useState}=React;

interface UserProps{
    email:string
    emailVerified?:boolean,
    id:string
    isAdmin: boolean
    name:string
}
interface AllUsersProps {
    error:boolean
    errorMessage:string
    loading:boolean
    users: UserProps[]
}
const InitialState = {
    error:false,
    errorMessage:'',
    loading:false,
    users:[]
}
type PropType = {get?:boolean,getUser?:string} | null;
export const useAllUsers = (Props:PropType)=>{
    const [state,setState] = useState<AllUsersProps>(InitialState);
    const [user,setUser] = useState<UserProps|null>(null);
    const {state: AppState} = React.useContext(AppContext);
    const {id} = AppState.user;
    const Ref = Firestore.collection(Firestore.getFirestore(),'Users');
    const getUserInfoFunction = Functions.httpsCallable(Functions.getFunctions(),'getUserv2');

    type OptionProp = 'CLEAR' | 'DELETE' | 'REFRESH'
    const DeleteUser = async (uid:string,option:OptionProp)=>{
        setState({...state,loading:true,error:false});
        return fetch(Strings.DELETE_USER,{
            method: 'POST',
            body: JSON.stringify({uid,me:id,option}),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res:any)=>{
            if(res.status && res.status == 200){
                setState({
                    ...state,
                    error:false,
                    errorMessage:'',
                    loading:false
                });
                GetAllUsers();
                return true;
            }
            else{
                setState({
                    ...state,
                    error:true,
                    errorMessage:res.statusText,
                    loading:false
                });
                return false;
            }
        })
        .catch((error)=>{
            console.log('error: ', error);
            setState({
                ...state,
                error:true,
                errorMessage:error,
                loading:false
            });
            return false;
        })
    }

    const GetAllUsers = ()=>{
        setState({...state,loading:true});
        const docRef = Firestore.query(Ref,Firestore.orderBy('name','asc'))
        Firestore.getDocs(docRef)
        .then((snapshot)=>{
            const users: UserProps[] = [];
            snapshot.forEach((doc)=>{
                const data = (doc.data() as UserProps);
                if(data.name !== 'Tester Man') users.push(data);
            });
            setState({
                ...state,
                loading:false,
                users
            });
            return users;
        })
        .catch((error)=>{
            setError(error);
        })
    }

    const GetUser = async (id:string)=>{
        setState({...InitialState,loading:true});
        const docRef = Firestore.doc(Ref,id);
        return Firestore.getDoc(docRef)
        .then((snapshot)=>{
            //setUser( (snapshot.data() as UserProps) );
            return {userData:(snapshot.data() as UserProps),userId:id};
        })
        .then(async ({userData,userId})=>{
            const userInfo = await GetUserInfo(userId);
            setUser({...userData,...userInfo})
        })
        .catch((error)=>{ setError(error) })
        .finally(()=>{
            setState({...InitialState,loading:false})
        })
    }

    const GetUserInfo = async (id:string)=>{
        return getUserInfoFunction({userId:id})
        .then((res)=>{
            const userInfo:any = res.data;
            return userInfo;
        })
        .catch((error)=>{})
    }

    const MakeUserAdmin = (uid:string)=>{
        setState({...state,loading:true,error:false});
        return fetch(Strings.MAKE_ADMIN,{
            method: 'POST',
            body: JSON.stringify({uid,me:id}),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res:any)=>{
            if(res.status && res.status == 200){
                setState({
                    ...state,
                    error:false,
                    errorMessage:'',
                    loading:false
                });
                GetAllUsers();
                return true;
            }
            else{
                setState({
                    ...state,
                    error:true,
                    errorMessage:res.statusText,
                    loading:false
                });
                return false;
            }
        })
        .catch((error)=>{
            console.log('error: ', error);
            setState({
                ...state,
                error:true,
                errorMessage:error,
                loading:false
            });
            return false;
        })
    }

    const setError = (error: Error)=>{
        console.log('error: ', error);
            setState({
                ...state,
                error:true,
                errorMessage:error.message
            })
    }
 
    useEffect(()=>{
        if(!Props || Props?.get)GetAllUsers();
        if(Props?.getUser) {
            GetUser(Props?.getUser??'');
        }
    },
    []);
    return {...state,user,DeleteUser,GetAllUsers,GetUser,MakeUserAdmin};
}