import React from 'react';
import {Dimensions, StyleSheet,Text,View,} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {Button, List, useTheme} from 'react-native-paper';
import { AppContext } from '../../context';
import { useUser } from '../../hooks/useUser';

const Width = Dimensions.get('window').width;

interface props {
    navigation: any
}

const ListItems = [
    {key:0,title:'Debt Elimination Plans'},
    {key:1,title:'Know your debt free date'},
    // {key:2,title:'Smart savings created with spare change'},
    {key:3,title:'Improved Credit Score'},
    {key:4,title:'Debt paid in half the time'},
    {key:5,title:'Gain Financial Literacy'},
    {key:6,title:'Realtime Account Sync'},
];

export default function FinalScreen(props:props){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {state} = React.useContext(AppContext);
    const {user} = state;
    const {UpdateUserData} = useUser();
    const [loading,setLoading] = React.useState(false);

    const handleSubmit = ()=>{
        setLoading(true);
        user.onBoardComplete = true;
        UpdateUserData(user).catch(()=>{setLoading(false)})
    }
    
    return(
        <>
        <KeyboardAwareScrollView
            contentContainerStyle={styles.container}
            extraScrollHeight={160} 
            keyboardShouldPersistTaps={'always'}
        >
            <View style={styles.welcomeTextContainer}>
                <Text style={styles.welcomeTextHeader}>All Set!</Text>
                <Text style={styles.WelcomeTextSubHeader}>
                    You're well on your way to creating a Legacy with these money saving services.
                </Text>
            </View>
            <View>
                {   
                    ListItems.map((item)=>(
                        //@ts-ignore
                        <List.Item 
                            key={item.key}
                            left={(props)=><List.Icon {...props} color={colors.primary} icon={'check-bold'} />}
                            title={item.title}
                            titleNumberOfLines={2}
                            titleStyle={{fontSize:Width>1000?40:15}}
                        />
                    ))
                }
            </View>
            <Button
                style={styles.button}
                labelStyle={{fontSize:Width>1000?30:15}}
                onPress={async ()=>{ handleSubmit(); }}
                color={colors.primary}
                mode={'contained'}
                uppercase={true}
                loading={loading}
                disabled={loading}
            >
                Done
            </Button>
        </KeyboardAwareScrollView>
        </>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            button: {
                borderRadius: 10,
                height: Width>1000?75:55,
                justifyContent: 'space-around',
                marginTop: '4%',
            },
            container: {
                alignContent: 'center',
                alignSelf: 'center',
                flexGrow: 1,
                marginTop: '10%',
                maxWidth: 700,
                paddingLeft: Width>1000?20:'10%',
                paddingRight: Width>1000?20:'10%',
                width: '100%',
            },
            welcomeTextContainer: {
                marginBottom: '5%'
            },
            welcomeTextHeader: {
                color: colors.primary,
                fontSize: Width>1000?70:30,
                fontWeight: 'bold',
                marginTop: 20,
                textAlign: 'center'
            },
            WelcomeTextSubHeader: {
                color: colors.primary,
                fontSize: Width>1000?40:20,
                paddingTop:10,
                textAlign:'center'
            }
        })
    );
}