import React from 'react';
import dayjs from 'dayjs';
import { Firebase, Firestore, Functions } from '../constants/Firebase';
import { dateFormatter } from '../constants/utils';
import { AppContext } from '../context';
import { AccountProps } from '../constants/Types';
import { BillProps } from './useBills';
import Strings from '../constants/Strings';
import { useCategories } from './useCategories';
const {useEffect,useState}=React;

const LoadMoreLimit = Strings.LIST_LIMITS;
export interface TransactionProps {
    id: string
    name: string
    date: any
    logo: string
    amount: string
    account?: AccountProps | false
    type: 'expense' | 'income'
    bill?:BillProps
    billId?:string
    category?: string[]
    logo_url?:string,
    icon?:string
}
interface useTransactionsProps {
    dateDisplay: string
    selection: string | number
    loading: boolean
    transaction: TransactionProps
    transactions: TransactionProps[]
    error: boolean
    errorMessage: string
}
const InitialTransaction: TransactionProps = {
    id: '0',
    name: '',
    date: Date.now(),
    logo: '',
    amount: '0',
    account: false,
    type: 'expense',
}
const InitialState = {
    dateDisplay: ' ',
    selection: 'monthly',
    loading: false,
    transaction: InitialTransaction,
    transactions: [],
    error: false,
    errorMessage: ''
}
export const useTransactions = ({account=false,get=true,limit=3,selection='monthly',type='',uid=null})=>{
    const {categories} = useCategories();
    const AddTransactionFunction = Functions.httpsCallable(Functions.getFunctions(),'addTransactionv2');
    const DeleteTransactionFunction = Functions.httpsCallable(Functions.getFunctions(),'deleteTransactionv2');
    const [state,setState] = useState<useTransactionsProps>(InitialState);
    const { state: AppState, dispatch } = React.useContext(AppContext);
    const UID = uid ?? AppState.user.id;
    const Ref = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/Transactions');
    const InvesRef = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/Investments');

    async function AddBillToTransaction(transactionId:string,bill:BillProps){
        setState({...state,loading:true});
        const docRef = Firestore.doc(Ref,transactionId);
        Firestore.updateDoc(docRef,{bill:bill,billId:bill.id})
        //.then(()=>{setState({...state,loading:false});})
        .catch(()=>{setState({...state,loading:false,error:true,errorMessage:Strings.TRANSACTION_NOT_FOUND});});
    }

    async function AddTransaction(transaction:TransactionProps,transId:string|null=null){
        const {name} = transaction;
        transaction.logo = 'https://ui-avatars.com/api/?name='+name+'&background=004481&color=fff';

        const ID = transId ?? await Firestore.addDoc(Ref,{}).then((res)=>{return res.id}).catch((error)=>{return transaction.date})
        transaction.id = ID;

        try{
            const res:any = await AddTransactionFunction({transactionData:transaction})//Ref.doc(ID).set(transaction).then(()=>{return true});
            if(!res.data.success) throw new Error(res.data.message);
            return res.data.success;
        }
        catch(error){
            throw error;
        }
    }

    const dateDisplayFunction = (selection='monthly')=>{
        const date = new Date();
        const year = date.getFullYear();
        const month = dayjs().format('MMMM');

        switch(selection){
            case 'weekly':{
                const firstDay = new Date( date.setDate( (date.getDate()-date.getDay() ) ) ).getTime();
                const lastDay = new Date( date.setDate( (date.getDate()-date.getDay()+6 ) ) ).getTime();
                return dateFormatter(firstDay) + ' - ' + dateFormatter(lastDay);
            }
            case 'monthly':{
                return month+', '+year;
            }
            case 'yearly':{
                return year.toString();
            }
            default:{return month+', '+year;}
        }
    }

    async function DeleteTransaction(transaction:TransactionProps){
        const transactionId = transaction.id;
        setState({
            ...state,
            loading:true
        })
        return DeleteTransactionFunction({transactionData:transaction})
            .then((res)=>{
                setState({...state,loading:false})
                return res.data
            })//Ref.doc(transactionId).delete();
    }

    async function GetBillTransactions(billId:string,limit=LoadMoreLimit){
        let query;
        if(limit > 0){
            query = Firestore.query(Ref, Firestore.limit(limit), Firestore.orderBy('date','desc'), Firestore.where('billId','==',billId));
        }
        else{
            query = Firestore.query(Ref, Firestore.orderBy('date','desc'), Firestore.where('billId','==',billId));
        }
        
        Firestore.onSnapshot(query,(snapshot)=>{
            if(snapshot.empty) {
                setState({
                    ...state,
                    transactions: []
                })
                return [];
            }

            setState({...state,loading:true});
            const trans: TransactionProps[] = [];
            
            snapshot.forEach((snap)=>{
                const data = (snap.data() as TransactionProps);
                trans.push(data);
            });
            setState({
                ...state,
                loading:false,
                dateDisplay: dateDisplayFunction(selection.toString()),
                selection,
                transactions: trans
            })
            return trans;
        },
        (error)=>{
            setState({...state,loading:false,error:true,errorMessage:error.message});
            return [];
        })
    }

    async function GetTransactions(limit=-1,selection:string|number='monthly',category:string|null=null){
        let collection = type.toLowerCase() == 'investment'? InvesRef : Ref;

        let queryParams:any[] = refWhereClause(selection);
        queryParams.push( Firestore.orderBy('date','desc') );
        if( limit > 0 ) queryParams.push( Firestore.limit(limit) );
        if(category){ queryParams.push( Firestore.where('category', 'array-contains', category) );}

        let query = Firestore.query(collection,...queryParams);

        return Firestore.getDocs(query)
        .then((snapshot)=>{
            const trans: TransactionProps[] = [];
            snapshot.forEach((snap)=>{
                const data = (snap.data() as TransactionProps);
                trans.push(data);
            });
            setState({
                ...state,
                dateDisplay: dateDisplayFunction(selection.toString()),
                selection,
                transactions: trans
            })
            return(trans);
        })
        .catch((error)=>{
            setState({...state,error:true,errorMessage:error.message});
            return([]);
        })
        
        // Firestore.onSnapshot(query,(snapshot)=>{
        //     if(snapshot.empty) {
        //         setState({
        //             ...state,
        //             transactions: []
        //         })
        //     }
        //     //if(AppState.user.getTrans) return(state.transactions);

        //     const trans: TransactionProps[] = [];
            
        //     snapshot.forEach((snap)=>{
        //         const data = (snap.data() as TransactionProps);
        //         trans.push(data);
        //     });
        //     setState({
        //         ...state,
        //         dateDisplay: dateDisplayFunction(selection.toString()),
        //         selection,
        //         transactions: trans
        //     })
        //     return(trans);
        // },
        // (error)=>{
        //     setState({...state,error:true,errorMessage:error.message});
        //     return([]);
        // })
    }
    
    async function GetSingleTransaction(transactionId:string,type=''){
        let collection = type.toLowerCase() == 'investment'? InvesRef : Ref;
        setState({...state,loading:true});
        const docRef = Firestore.doc(collection,transactionId);
        return Firestore.onSnapshot(docRef,(snapshot)=>{
            if(!snapshot.exists){
                setState({...state,loading:true,error:true,errorMessage:Strings.TRANSACTION_NOT_FOUND,transaction:InitialTransaction});
                return;
            }
            const transaction = (snapshot.data() as TransactionProps);
            setState({...state,loading:false,transaction});
        },
        (error)=>{
            setState({...state,loading:false,error:true,errorMessage:error.message,transaction:InitialTransaction});
        });
    }

    async function LoadMoreTransactions(after:number,selection:string|number='monthly',type=null){
        let collection = type == 'Investment'? InvesRef : Ref;

        let queryParams:any[] = refWhereClause(selection);
        queryParams.push( Firestore.orderBy('date','desc') );
        queryParams.push( Firestore.limit(LoadMoreLimit) );
        queryParams.push( Firestore.startAfter(after) );
        
        let query = Firestore.query(collection,...queryParams);
        
        setState({...state,loading:true});
        const trans: TransactionProps[] = state.transactions;

        Firestore.getDocs(query)
        .then((snapshot)=>{
            snapshot.forEach((snap)=>{
                const data = (snap.data() as TransactionProps);
                trans.push(data);
            });
            setState({
                ...state,
                loading:false,
                dateDisplay: dateDisplayFunction(selection.toString()),
                selection,
                transactions: trans
            })
            return trans;
        })
        .catch((error)=>{
            setState({...state,loading:false,error:true,errorMessage:error.message});
            return state.transactions;
        });
    }

    function refWhereClause(selection:string|number='monthly'){
        let res:any[] = [];

        if(account){
            res.push(Firestore.where('account_id','==',selection));
            return res;
        }

        //const accountIds = AppState.accountIds;
        //if(accountIds && accountIds.length > 0) 
            //res.push( Firestore.where('account.item_id', 'in', accountIds) );

        const date = new Date();
        //@ts-ignore
        if(!isNaN(selection)){
            const firstDay = selection;
            const lastDay = dayjs(selection).add(1, 'month').valueOf();
            res.push( Firestore.where('date','>=',firstDay) );
            res.push( Firestore.where('date','<=',lastDay) );
        }
        else{
            switch(selection){
                case 'weekly':{
                    const firstDay = new Date( date.setDate( (date.getDate()-date.getDay() ) ) ).getTime();
                    const lastDay = new Date( date.setDate( (date.getDate()-date.getDay()+6 ) ) ).getTime();
                    res.push( Firestore.where('date','>=',firstDay) );
                    res.push( Firestore.where('date','<=',lastDay) );
                    break;
                }
                case 'monthly':{
                    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
                    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
                    res.push( Firestore.where('date','>=',firstDay) );
                    res.push( Firestore.where('date','<=',lastDay) );
                    break;
                }
                case 'yearly':{
                    const firstDay = new Date(date.getFullYear(),0,1).getTime();
                    const lastDay = new Date(date.getFullYear(),11,31,23,59,59).getTime();
                    res.push( Firestore.where('date','>=',firstDay) );
                    res.push( Firestore.where('date','<=',lastDay) );
                    break;
                }
                default:{break;}
            }
        }
        return res;
    }

    async function RemoveBillFromTransaction(transactionId:string){
        setState({...state,loading:true});
        const docRef = Firestore.doc(Ref,transactionId);
        return Firestore.setDoc(docRef,{
            bill:Firestore.deleteField(),
            billId:Firestore.deleteField()
        },{merge:true})
        //.then(()=>{setState({...state,loading:false});})
        .catch(()=>{setState({...state,loading:false,error:true,errorMessage:Strings.TRANSACTION_NOT_FOUND});});
    }

    async function UpdateTransaction(trans:TransactionProps){
        setState({...state,loading:true})
        return DeleteTransaction(trans)
        .then((res:any)=>{
            if(!res.success) return false;
            return AddTransaction(trans);
        })
        .then(()=>{
            return true;
        })
        .catch((error)=>{
            setState({
                ...state,
                loading:false,
                error:true,
                errorMessage:error.message
            });
            return false;
        })
    }

    async function UpdateTransactionCategory(trans:TransactionProps){
        setState({...state,loading:true});
        const cat = trans.category?trans.category[0]:'';
        const cats = categories.find(o => o.value === cat);
        if(cats){
            trans.icon = cats.icon;
        }

        const ref = Firestore.doc(Ref,trans.id);

        await Firestore.updateDoc(ref,{...trans});

        setState({...state,loading:false});
        return {success:false}
    }
 
    useEffect(()=>{
        if(!get) return;

        GetTransactions(limit,selection);
    },
    []);
    
    return {
        ...state,
        AddBillToTransaction,
        AddTransaction,
        DeleteTransaction,
        GetBillTransactions,
        GetTransactions,
        GetSingleTransaction,
        LoadMoreTransactions,
        RemoveBillFromTransaction,
        UpdateTransaction,
        UpdateTransactionCategory
    };
}