// @ts-nocheck 
import React from 'react';

interface props {
  appstoreUrl:string,
  headingTitle:string,
  headingSubTitle:string,
  playstoreUrl:string
}
export function Banner({appstoreUrl,headingTitle,headingSubTitle,playstoreUrl}:props) {
  return (
    <div className="hero-section" id="home">
    <div className="container hero-grid">
      <div className="hero-left">
        <h1>{headingTitle}</h1>
        <div className='subtitleWrapper'>
          <p className="md-text">{headingSubTitle}</p>
        </div>
        <div className="cta">
          <a href={playstoreUrl} target="_blank" rel="noopener noreferrer" className="btn">
            <div className="icon"><ion-icon className="icon" name="logo-android" /></div>
            <div>
              <p><strong>Get it on the</strong></p>
              <p className="md-text">Google Play</p>
            </div>
          </a>
          <a href={appstoreUrl} target="_blank" rel="noopener noreferrer" className="btn">
            <div className="icon"><ion-icon className="icon" name="logo-apple" /></div>
            <div>
              <p><strong>Available on the</strong></p>
              <p className="md-text">Apple Store</p>
            </div>
          </a>
          <img className="qrcode-img" src="img/legacyDownloadCode.png" />
        </div>
      </div>
      <div className="hero-right">
        <img className="section-img" src="img/screen 1.png" />
      </div>
    </div>
  </div>
  );
}
