// @ts-nocheck 
import React from 'react';

export function Features({
  feat1Des,feat2Des,feat3Des,feat4Des,feat5Des,feat6Des,featSubtitle,
  feat1Title,feat2Title,feat3Title,feat4Title,feat5Title,feat6Title
}:any) {
  return (
    <div className="app-features-section" id="app-features">
    <div className="container">
      <h4>App Features</h4>
      <p className="contained-text text">{featSubtitle}</p>
      <div className="app-features-grid">
        <div className="order-0">
          <img className="features-img" src="img/screen 3.png" />
        </div>
        <div className="app-feature-textbox order-1">
          <div className="icon"><ion-icon className="icon" name="card-outline" /></div>
          <div className="feature-text">
            <h3>{feat1Title}</h3>
            <p className="text">{feat1Des}</p>
          </div>
        </div>
        <div className="app-feature-textbox order-2">
          <div className="icon"><ion-icon className="icon" name="images-outline" /></div>
          <div className="feature-text">
            <h3>{feat2Title}</h3>
            <p className="text">{feat2Des}</p>
          </div>
        </div>
        <div className="app-feature-textbox order-3">
          <div className="icon"><ion-icon className="icon" name="aperture-outline" /></div>
          <div className="feature-text">
            <h3>{feat3Title}</h3>
            <p className="text">{feat3Des}</p>
          </div>
        </div>
        <div className="app-feature-textbox order-4">
          <div className="icon"><ion-icon className="icon" name="infinite-outline" /></div>
          <div className="feature-text">
            <h3>{feat4Title}</h3>
            <p className="text">{feat4Des}</p>
          </div>
        </div>
        <div className="app-feature-textbox order-5">
          <div className="icon"><ion-icon className="icon" name="qr-code-outline" /></div>
          <div className="feature-text">
            <h3>{feat5Title}</h3>
            <p className="text">{feat5Des}</p>
          </div>
        </div>
        <div className="app-feature-textbox order-6">
          <div className="icon"><ion-icon className="icon" name="construct-outline" /></div>
          <div className="feature-text">
            <h3>{feat6Title}</h3>
            <p className="text">{feat6Des}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
