import React from 'react';
import dayjs from 'dayjs';
import { Firestore } from '../constants/Firebase';
import { AppContext } from '../context';
import Please from '../constants/Please';
import { currencyFormatter } from '../constants/utils';
import { useTheme } from 'react-native-paper';
const {useEffect,useState}=React;
 
const InitialState = {
    currentSpending: 0,
    error:false,
    errorMessage:'',
    labels: [],
    loading:false,
    spendingDifference: {text:['','',''],color:'#fff',icon:''},
    spendFreeDays:0,
    totals:{
        yearMonth:'',
        income:[{data:[0],totalIncome: 0,},{data:[0],totalIncome: 0,month: '',}],
        incomeY: [],
        expense:[{data:[0],data2:[0],totalExpense: 0,xAxis:[0],label:['1']},{data:[0],data2:[0],totalExpense: 0,xAxis:[0],label:['1'],month: '',}],
        expenseXY:[[],[]],
        expenseY: [],
        highestExpense: 0,
        highestIncome: 0,
        lowestExpense: 0,
        lowestIncome: 0
    },
}
export const useDailyTotals = (uid=null,totals=false)=>{
    const {colors} = useTheme();
    const [state,setState] = useState(InitialState);
    const { state: AppState } = React.useContext(AppContext);
    const UID = uid??AppState.user.id;
    const DailyTotalsRef = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/DailyCashTotals');

    const GetSummary=()=>{
        let spendFreeDays = 0;
        const prev = dayjs().subtract(1,'month').format('YYYYMM');
        const cur = dayjs().format('YYYYMM');
        const curMonth = dayjs().format('MMM');
        const prevMonth = dayjs().subtract(1,'month').format('MMM');
        const today = Number( dayjs().format('DD') );
        let daysInMonth = dayjs().subtract(1,'month').daysInMonth();//Number( new Date(Number(prev.substring(4)), Number(prev.substring(4,6)), 0).getDate() );
        //daysInMonth = daysInMonth > today? daysInMonth : today;
        //console.log('daysInMonth: ', daysInMonth);
        const query = Firestore.query(DailyTotalsRef,Firestore.orderBy('id','asc'),Firestore.where('id','>=',prev));
        return Firestore.getDocs(query)
                .then((snapshot) => {
                    if(AppState.user.getTrans) return;
                    setState({...state,loading:true});
                    if( snapshot.empty ){ setState({...state,loading:false}); return; }
                    const size = snapshot.size;
                    const randomColors = Please.make_scheme({h: 208,s: .4,v: 1},{scheme_type: 'analogous',format: 'hex'});
        
                    let count = 0;
        
                    const totals:any = {
                        expense:[],
                        expenseXY:[],
                        expenseY: [],
                        income:[],
                        incomeY: [],
                        totalExpesnse: 0,
                        totalIncome: 0,
                        highestExpense: 0,
                        highestIncome: 0,
                        lowestExpense: 0,
                        lowestIncome: 0,
                        month: '',
                        today:[{x:0,y:0}]
                    };
                    let incomeY: number[] = [];
                    let expenseXY:{x:any,y:any}[] = [];
                    let expenseY: number[] = [];
                    let labels:string[] = [];
                    let highestExpense = 0;
                    let highestIncome = 0;
                    let lowestExpense = 0;
                    let lowestIncome = 0;
                    snapshot.forEach((doc)=>{
                        const data = doc.data();
                        const month = dayjs(data.id).format('MMM');
                        const res:any = {yearMonth:data.id,income:[],incomeY:[],expense:[],expenseY:[],};
                        let income = [];
                        let expense = [];
                        let totalIncome = 0;
                        let totalExpense = 0;
                        
                        const xAxis = [];
                        const label = [];
                        for(let i=1;i<=daysInMonth;i++){
                            const day = (i>=10? i : '0'+i).toString();
                            labels.push( day );
                            xAxis.push( day );
                            label.push( month+' '+day );
                            const date = data[Number(day)];

                            let incomeAmount = Number(date?.income??0);
                            let expenseAmount = Number(date?.expense??0);

                            if(month == curMonth && Number(day) < today){
                                const yesterday = today-1;
                                const curHour = new Date().getHours();
                                if( !expenseAmount ){
                                    if( Number(day) == yesterday && ( curHour < 14 ) ) {}
                                    else spendFreeDays++;
                                }
                                // else{
                                //     spendFreeDays = 0;
                                // }
                            }
        
                            totalIncome += incomeAmount;
                            totalExpense += expenseAmount;
        
                            if(month === prevMonth && Number(day) == today){
                                totals.today = [{x:Number(day),y:totalExpense}]
                            }
                            
                            if(date && date.expense) {
                                const expense = totalExpense;//date.expense;
                                expenseXY.push({x:Number(day),y:expense});
                                if(highestExpense < date.expense) highestExpense = expense;
                                if(lowestExpense > data.expense) lowestExpense = expense;
                            }
                            else {
                                if(curMonth==month && today >= Number(day))
                                    expenseXY.push({x:Number(day),y:totalExpense});
                                else if(curMonth!=month)
                                    expenseXY.push({x:Number(day),y:totalExpense});
                            }
                            if(date && date.income){
                                if(highestIncome < date.income) highestIncome = date.income;
                                if(lowestIncome > data.income) lowestIncome = data.income;
                            }
        
                            if(!date) {
                                income.push( 0 );
                                expense.push( 0 );
                                incomeY.push( 0 );
                                expenseY.push( 0 );
                            }
                            else{
                                income.push( Number(date?.income??0) );
                                expense.push( Number(date?.expense??0) );
                                incomeY.push( Number(date?.income??0) );
                                expenseY.push( Number(date?.expense??0) );
                            }
                        }
                        res.income = income;
                        res.expense = expense;
                        
                        totals.expense.push( {data:res.expense,data2:res.expense,xAxis,label,svg:{stroke:randomColors[count]},yearMonth:res.yearMonth,totalExpense,month: month,} );
                        totals.expenseXY.push(expenseXY);
                        expenseXY = [];
                        totals.income.push( {data:res.income,xAxis,svg:{stroke:randomColors[count]},yearMonth:res.yearMonth,totalIncome} );
                        totals.yearMonth = res.yearMonth;
                        count++;
                    });
        
                    totals.highestExpense = highestExpense;
                    totals.highestIncome = highestIncome;
                    totals.lowestExpense = lowestExpense;
                    totals.lowestIncome = lowestIncome;
                    incomeY = incomeY.sort(numberSort);
                    totals.incomeY = [...new Set(incomeY)];
                    expenseY = [...new Set(expenseY)];
                    totals.expenseY = expenseY.sort(numberSort);
                    //console.log('expenseY: ', expenseY);
                    labels = labels.sort();
                    labels = [...new Set(labels)];
        
                    const totals2 = matchLabelsToAxis(totals,labels);
                    
                    //console.log('expenseXY: ', totals2.expenseXY[1].length);
                    //console.log('totals2: ', totals2.expense[1]);
                    //console.log('labels: ', labels.length);
                    //@ts-ignore
                    setState({...state,loading:false,totals:totals2,labels, spendFreeDays});
                })
                .catch((error)=>{
                    setState({
                        ...InitialState,
                        loading:false,
                        error:true,
                        errorMessage:error.message
                    });
                });
        // return Firestore.onSnapshot(query,(snapshot) => {
        //     if(AppState.user.getTrans) return;
        //     setState({...state,loading:true});
        //     if( snapshot.empty ){ setState({...state,loading:false}); return; }
        //     const size = snapshot.size;
        //     const randomColors = Please.make_scheme({h: 208,s: .4,v: 1},{scheme_type: 'analogous',format: 'hex'});

        //     let count = 0;

        //     const totals:any = {
        //         expense:[],
        //         expenseXY:[],
        //         expenseY: [],
        //         income:[],
        //         incomeY: [],
        //         totalExpesnse: 0,
        //         totalIncome: 0,
        //         highestExpense: 0,
        //         highestIncome: 0,
        //         lowestExpense: 0,
        //         lowestIncome: 0,
        //         month: '',
        //         today:[{x:0,y:0}]
        //     };
        //     let incomeY: number[] = [];
        //     let expenseXY:{x:any,y:any}[] = [];
        //     let expenseY: number[] = [];
        //     let labels:string[] = [];
        //     let highestExpense = 0;
        //     let highestIncome = 0;
        //     let lowestExpense = 0;
        //     let lowestIncome = 0;
        //     snapshot.forEach((doc)=>{
        //         const data = doc.data();
        //         const month = dayjs(data.id).format('MMM');
        //         const res:any = {yearMonth:data.id,income:[],incomeY:[],expense:[],expenseY:[],};
        //         let income = [];
        //         let expense = [];
        //         let totalIncome = 0;
        //         let totalExpense = 0;
                
        //         const xAxis = [];
        //         const label = [];
        //         for(let i=1;i<=daysInMonth;i++){
        //             const day = (i>=10? i : '0'+i).toString();
        //             labels.push( day );
        //             xAxis.push( day );
        //             label.push( month+' '+day );
        //             const date = data[Number(day)];

        //             totalIncome += Number(date?.income??0);
        //             totalExpense += Number(date?.expense??0);

        //             if(month === prevMonth && Number(day) == today){
        //                 totals.today = [{x:Number(day),y:totalExpense}]
        //             }
                    
        //             if(date && date.expense) {
        //                 const expense = totalExpense;//date.expense;
        //                 expenseXY.push({x:Number(day),y:expense});
        //                 if(highestExpense < date.expense) highestExpense = expense;
        //                 if(lowestExpense > data.expense) lowestExpense = expense;
        //             }
        //             else {
        //                 if(curMonth==month && today >= Number(day))
        //                     expenseXY.push({x:Number(day),y:totalExpense});
        //                 else if(curMonth!=month)
        //                     expenseXY.push({x:Number(day),y:totalExpense});
        //             }
        //             if(date && date.income){
        //                 if(highestIncome < date.income) highestIncome = date.income;
        //                 if(lowestIncome > data.income) lowestIncome = data.income;
        //             }

        //             if(!date) {
        //                 income.push( 0 );
        //                 expense.push( 0 );
        //                 incomeY.push( 0 );
        //                 expenseY.push( 0 );
        //             }
        //             else{
        //                 income.push( Number(date?.income??0) );
        //                 expense.push( Number(date?.expense??0) );
        //                 incomeY.push( Number(date?.income??0) );
        //                 expenseY.push( Number(date?.expense??0) );
        //             }
        //         }
        //         res.income = income;
        //         res.expense = expense;
                
        //         totals.expense.push( {data:res.expense,data2:res.expense,xAxis,label,svg:{stroke:randomColors[count]},yearMonth:res.yearMonth,totalExpense,month: month,} );
        //         totals.expenseXY.push(expenseXY);
        //         expenseXY = [];
        //         totals.income.push( {data:res.income,xAxis,svg:{stroke:randomColors[count]},yearMonth:res.yearMonth,totalIncome} );
        //         totals.yearMonth = res.yearMonth;
        //         count++;
        //     });

        //     totals.highestExpense = highestExpense;
        //     totals.highestIncome = highestIncome;
        //     totals.lowestExpense = lowestExpense;
        //     totals.lowestIncome = lowestIncome;
        //     incomeY = incomeY.sort(numberSort);
        //     totals.incomeY = [...new Set(incomeY)];
        //     expenseY = [...new Set(expenseY)];
        //     totals.expenseY = expenseY.sort(numberSort);
        //     //console.log('expenseY: ', expenseY);
        //     labels = labels.sort();
        //     labels = [...new Set(labels)];

        //     const totals2 = matchLabelsToAxis(totals,labels);
            
        //     //console.log('expenseXY: ', totals2.expenseXY[1].length);
        //     //console.log('totals2: ', totals2.expense[1]);
        //     //console.log('labels: ', labels.length);
        //     //@ts-ignore
        //     setState({...state,loading:false,totals:totals2,labels});
        // },
        // (error)=>{
        //     setState({
        //         ...InitialState,
        //         loading:false,
        //         error:true,
        //         errorMessage:error.message
        //     });
        // });
    }

    function matchLabelsToAxis(totals:any,labels:string[]){
        //console.log('totals: ', totals.expense[1]);
        for(let i=0; i<totals.expense.length;i++){
            const expense = totals.expense[i];
            for(let j=0; j<expense.xAxis.length;j++){
                expense.xAxis[j] = labels.findIndex((element)=>element===expense.xAxis[j]);
            }
            if( labels.length > expense.xAxis.length  ){
                const diff = labels.length - expense.xAxis.length;
                for(let k=0; k<diff;k++) {
                    expense.xAxis.push(0);
                    totals.expense[i].data.push(0);
                }
            }
            totals.expense[i] = expense;
        }
        // for(let i=0; i<totals.income.length;i++){
        //     const income = totals.income[i];
        //     for(let j=0; j<income.xAxis.length;j++){
        //         income.xAxis[j] = labels.findIndex((element)=>element==income.xAxis[j]);
        //     }
        //     totals.income[i] = income;
        // }
        return totals;
    }

    function chunkArray(arr:any[],n:number){
        var chunkLength = Math.max(arr.length/n ,1);
        var chunks = [];
        for (var i = 0; i < n; i++) {
            if(chunkLength*(i+1)<=arr.length)chunks.push(arr.slice(chunkLength*i, chunkLength*(i+1)));
        }
        return chunks; 
    }
    function numberSort(a:number, b:number) {
        return a - b;
    };

    //TODO: move this function to cloud functions
    const GetDailyTotals= async()=>{
        try{
            setState({...state,loading:true,error:false});
            const prev = dayjs().subtract(1,'month').format('YYYYMM');
            let prevMonth = {label:dayjs(prev.toString(),"YYYYMM").format('MMM'),value:0.00};
            let prevMonthLastDay = Number(dayjs().subtract(1,'month').endOf('month').format('D'));
            const cur = dayjs().format('YYYYMM');
            let curMonth = {label:dayjs(cur.toString(),"YYYYMM").format('MMM'),value:0.00};
            const today = Number(dayjs().format('D'));

            const query = Firestore.query(DailyTotalsRef,Firestore.orderBy('id','asc'),Firestore.where('id','>=',prev));

            return Firestore.getDocs(query)
            .then((snapshot)=>{
                let totalCurExpense = 0;
                let totalCurIncome = 0;

                let totalPrevExpense = 0;
                let totalPrevIncome = 0;

                snapshot.forEach((snap) => {
                    const totals = snap.data();
                    let totalIncome = 0;
                    let totalExpense = 0;
                    for (const key in totals) {
                        if (isNaN(Number(key))) continue;
                        const date = Number(key);
                        if (Number(date) > Number(today)) break;
                        const total = totals[key];
                        totalIncome = Number(totalIncome) + Number(total.income ?? 0);
                        totalExpense = Number(totalExpense) + Number(total.expense ?? 0);
                    }
                    switch (totals['id']) {
                        case cur: {
                            totalCurExpense = totalExpense;
                            totalCurIncome = totalIncome;
                            break;
                        }
                        case prev: {
                            totalPrevExpense = totalExpense;
                            totalPrevIncome = totalIncome;
                            break;
                        }
                    }
                });
                let spendDiff = {text:['','',''],color:colors.accent,icon:''};
                let day = today > prevMonthLastDay ? prevMonthLastDay : today;

                if (totalPrevExpense > totalCurExpense) {
                    spendDiff.text = [currencyFormatter(totalPrevExpense - totalCurExpense), ' LESS\n',' than last month']//+prevMonth.label//+' '+day;
                    spendDiff.icon = 'arrow-bottom-right';
                    spendDiff.color = colors.accent;
                }
                else if (totalPrevExpense < totalCurExpense) {
                    spendDiff.text = [currencyFormatter(totalCurExpense - totalPrevExpense), ' MORE\n',' than last month']//+prevMonth.label//+' '+prevMonthLastDay;
                    spendDiff.color = colors.primary;
                    spendDiff.icon = 'arrow-top-right';
                }
                else if (totalPrevExpense == totalCurExpense) {
                    spendDiff.text = [' same as ' + prevMonth.label,'',''];
                }
                setState({ ...state, loading: false, currentSpending: totalCurExpense, spendingDifference: spendDiff });
            })
            .catch((error)=>{
                setState({...state,loading:false,error:true,errorMessage:error.message});
            })

            // Firestore.onSnapshot(query,(snapshot)=>{
            //     let totalCurExpense = 0;
            //     let totalCurIncome = 0;

            //     let totalPrevExpense = 0;
            //     let totalPrevIncome = 0;

            //     snapshot.forEach((snap) => {
            //         const totals = snap.data();
            //         let totalIncome = 0;
            //         let totalExpense = 0;
            //         for (const key in totals) {
            //             if (isNaN(Number(key))) continue;
            //             const date = Number(key);
            //             if (Number(date) > Number(today)) break;
            //             const total = totals[key];
            //             totalIncome = Number(totalIncome) + Number(total.income ?? 0);
            //             totalExpense = Number(totalExpense) + Number(total.expense ?? 0);
            //         }
            //         switch (totals['id']) {
            //             case cur: {
            //                 totalCurExpense = totalExpense;
            //                 totalCurIncome = totalIncome;
            //                 break;
            //             }
            //             case prev: {
            //                 totalPrevExpense = totalExpense;
            //                 totalPrevIncome = totalIncome;
            //                 break;
            //             }
            //         }
            //     });
            //     let spendDiff = '';
            //     let day = today > prevMonthLastDay ? prevMonthLastDay : today;

            //     if (totalPrevExpense > totalCurExpense) {
            //         spendDiff = currencyFormatter(totalPrevExpense - totalCurExpense) + ' less than last month'//+prevMonth.label//+' '+day;
            //     }
            //     else if (totalPrevExpense < totalCurExpense) {
            //         spendDiff = currencyFormatter(totalCurExpense - totalPrevExpense) + ' more than last month'//+prevMonth.label//+' '+prevMonthLastDay;
            //     }
            //     else if (totalPrevExpense == totalCurExpense) {
            //         spendDiff = ' same as ' + prevMonth.label;
            //     }
            //     setState({ ...state, loading: false, currentSpending: totalCurExpense, spendingDifference: spendDiff });
            // });
        }
        catch(error:any){
            setState({...state,loading:false,error:true,errorMessage:error.message});
        }
    }
 
    useEffect(()=>{
        if(totals) {
            GetDailyTotals();
            return;
        }
        const unsubscribe = GetSummary();
        return ()=>{
            //unsubscribe();
        }
    },
    []);

    return {...state};
}