import React from 'react';
import { useDocs } from '../hooks/useDocs';
import { Loading } from '../components/LoadingModal';
import { Download } from './LandingPage.web/Download';

export default function DownloadScreen(){ 
    const {loading,landing} = useDocs();

    
    React.useEffect(() => {
        if(loading || !landing.appstoreUrl || !landing.playstoreUrl){ return }
        if( /Android/i.test(navigator.userAgent) ) {
            window.location.replace(landing.playstoreUrl);
            return;
        }
        else if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
            window.location.replace(landing.appstoreUrl);
        }
    }, [loading, landing]);

    if(loading)return(<Loading visible={true}/>)
    
    return(
        <Download 
        appstoreUrl={landing.appstoreUrl}
        downloadSubTitle={landing.downloadSubTitle}
        downloadTitle={landing.downloadTitle}
        playstoreUrl={landing.playstoreUrl}
        showCode={false}
      />
    )
}