import React from 'react';
import { StyleSheet, View, } from 'react-native';
import { IconButton, List } from 'react-native-paper';
import AccountLogo from './AccountLogo';
import { UserAccountProps } from '../hooks/usePlaidLinks';
import { AccountContext } from '../context/AccountContext';

export default function AccountsList({ colors, item, navigation }: { colors: any, item: UserAccountProps, navigation?: any }) {
    const {dispatch} = React.useContext(AccountContext);

    return (
        /**@ts-ignore */
        <List.Item
            title={item.name}
            titleStyle={{ color: colors.onSurface }}
            description={item.refresh ? 'Login Verification Required' : ''}
            descriptionStyle={{ color: item.refresh ? colors.error : colors.backdrop }}
            left={(props) =>
                <AccountLogo
                    compProps={props}
                    logo={item.logo}
                    name={item.name}
                    refresh={item.refresh}
                />
            }
            /**@ts-ignore */
            right={(props) => <IconButton {...props} icon={'chevron-right'} />}
            onPress={() => {
                if (!navigation) { return; }
                //if(item.refresh) navigation.navigate('plaidrefreshbank',{account:item});
                else {
                    dispatch({type:'setAccount',payload:item});
                    navigation.navigate('View Account', { account: item });
                }
            }}
            //onPress={()=>{navigation.navigate('View Account',{account:item});}}
            onLongPress={() => { if (!navigation) { return; } navigation.navigate('plaidrefreshbank', { account: item }); }}
        />
    )
}

const Styles = (colors: any) => {
    return (
        StyleSheet.create({})
    );
}