import React from 'react';
import { Firestore } from '../constants/Firebase';
import { AppContext } from '../context';
import { useAccounts } from './useAccounts';
import { useUser } from './useUser';
const {useEffect,useState}=React;

export interface bankType{
    accessToken:string
    id:string
    item_id:string
    name:string
}

const InitialState = {
    loading:false,
    error:false,
    errorMsg:''
};

export const useBanks = ()=>{
    const {state} = React.useContext(AppContext);
    const {GetAllAccounts,RemoveAccount} = useAccounts(false);
    const {UpdateUserData} = useUser();
    const [bankState,setBankState] = useState(InitialState);
    const [banks,setBanks] = useState<bankType[]>([]);

    const AccountTrackerRef = Firestore.collection(Firestore.getFirestore(),'PlaidAccountsTracker');

    async function DeleteAccountTracker(id:string){
        const doc = Firestore.doc(AccountTrackerRef,id);
        return Firestore.deleteDoc(doc);
    }

    async function DeleteBank(bank:bankType|undefined){
        if(!bank) return;
        setBankState({...InitialState,loading:true});
        return DeleteAccountTracker(bank.item_id)
        .then(()=>{
            const user = state.user;
            return UpdateUserData(user);
        })
        .then(async ()=>{
            const accounts = await GetAllAccounts();
            for(let i=0; i<accounts.length; i++) {
                const accountsOfType = accounts[i];
                for(let j=0; j<accountsOfType.accounts.length; j++){
                    const account = accountsOfType.accounts[j];
                    if(account.bankId === bank.id && account.item_id === bank.item_id){
                        await RemoveAccount(account);
                    }
                }
            }
        })
        .then(()=>{setBankState({...InitialState})})
        .catch((error)=>{
            setBankState({...InitialState,error:true,errorMsg:error.message})
        })
    }

    return {banks,...bankState,DeleteBank}
}