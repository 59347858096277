import React from 'react';
import { Firestore, Functions } from '../constants/Firebase';
import { AppContext } from '../context';
import Strings from '../constants/Strings';
const {useEffect,useState}=React;
 
export const useUser = ()=>{
    const { state: AooState, dispatch } = React.useContext(AppContext);
    const userData = AooState.user;
    const [state,setState] = useState({loading:false,error:false,errorMessage:''});
    const {id} = userData;
    const Ref = Firestore.doc(Firestore.getFirestore(),'Users/'+id);
    const DeleteAccountFunction = Functions.httpsCallable(Functions.getFunctions(),'deleteMyAccountv2');
    
    type OptionProp = 'CLEAR' | 'DELETE' | 'REFRESH'
    const DeleteUser = async (uid:string,option:OptionProp,logout:()=>{})=>{
        setState({...state,loading:true,error:false});
        try{
            const res:any = await DeleteAccountFunction({option:option})//Ref.doc(ID).set(transaction).then(()=>{return true});
            if(!res.data.success) throw new Error(res.data.message);
            
            setState({...state,loading:false,error:false});
            //alert('Your account is currently being deleted. This process can take a few minutes to finalize.')
            logout();
        }
        catch(error:any){
            setState({...state,loading:false,error:true,errorMessage:error.message});
        }
    }

    const UpdateUserData = async (data:any)=>{
        return Firestore.setDoc(Ref,{...data},{merge:true});
    }
 
    useEffect(()=>{
    },
    []);

    return {...state,DeleteUser,UpdateUserData};
}