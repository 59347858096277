import React from 'react';
import {Dimensions, StyleSheet,Text,View,} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {Button, Card, IconButton,useTheme} from 'react-native-paper';
import { Firestore } from '../../constants/Firebase';
import { AppContext } from '../../context';

const Width = Dimensions.get('window').width;

interface props {
    navigation: any
    next: ()=>void
    prev: ()=>void
}

const Goals = [
    {key:0,icon:'currency-usd-off',text:'Know my debt free date'},
    {key:1,icon:'cash-multiple',text:'Lower my Bills'},
    {key:2,icon:'chart-arc',text:'Track my Spending'},
    {key:3,icon:'cash-remove',text:'Create debt elimination plan'},
    {key:4,icon:'chart-line',text:'Grow my Savings'},
    {key:5,icon:'clock-check-outline',text:'Pay my bills on time'},
    {key:6,icon:'finance',text:'Improve my credit'},
    {key:7,icon:'trending-down',text:'Reduce my debt'},
];

export default function ChooseTopGoals(props:props){ 
    const {next,prev} = props;
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {state} = React.useContext(AppContext);
    const {user} = state;

    const [loading,setLoading] = React.useState(false);
    const [error,setError] = React.useState('');
    const [selectedGoals,setSelectedGoals] = React.useState<string[]>([]);

    const handleSubmit = ()=>{
        setLoading(true);
        setError('');
        const userId = user.id;
        const UserRef = Firestore.doc(Firestore.getFirestore(),'Users/'+userId);
        Firestore.setDoc(UserRef,{goals:selectedGoals},{merge:true})
        .then(()=>{
            next();
        })
        .catch((error)=>{
            setError(error.code);
        })
        .finally(()=>{
            setLoading(false);
        })
    }
    const selectGoal = async (item:any)=>{
        setLoading(true);
        const index = selectedGoals.indexOf(item.text);
        
        let newSelected = selectedGoals;

        if( index != -1 ){
            newSelected.splice(index, 1);
        }
        else{
            newSelected.push(item.text);
        }
        setSelectedGoals( newSelected );
        await new Promise(r => setTimeout(r, 1)); //TODO: temporary, hacky way to update screen
        setLoading(false);
    }
    
    return(
        <>
        <View style={{ alignItems: 'center',flexDirection: 'row',justifyContent: 'space-between',marginTop: 60,marginBottom:-20}}>
                {/*@ts-ignore*/}
                <IconButton
                    color={colors.primary}
                    icon={'arrow-left'}
                    onPress={()=>{prev();}}
                    size={35}
                    style={{backgroundColor: 'transparent'}}
                />
            </View>
        <KeyboardAwareScrollView
            contentContainerStyle={styles.container}
            extraScrollHeight={160} 
            keyboardShouldPersistTaps={'always'}
            showsVerticalScrollIndicator={false}
        >
            <View style={styles.welcomeTextContainer}>
                <Text style={styles.welcomeTextHeader}>Choose your top goals</Text>
            </View>
            <View style={{flexDirection:'row',flexWrap: 'wrap',justifyContent:'space-between'}}>
            {
                Goals.map((item)=>{
                    return (
                    <Card 
                        key={item.key}
                        style={{
                            backgroundColor:selectedGoals.includes(item.text)?'white':colors.background,//'white',
                            //height:Width>1000?200:120,
                            marginTop:10,
                            width:'48%'
                        }}
                        onPress={()=>{selectGoal(item)}}
                    >
                        {/*@ts-ignore*/}
                        <IconButton icon={item.icon} size={Width>1000?50:30} />
                        <View style={{flex:1,justifyContent:'flex-end',paddingBottom:20,paddingLeft:20,paddingRight:20}}>
                            <Text
                                style={{color:colors.primary,fontSize:Width>1000?30:15,flex: 1, flexWrap: 'wrap',}}
                                numberOfLines={10}
                            >
                                {item.text}
                            </Text>
                        </View>
                    </Card>
                )})
            }
            </View>
            <Text style={{color: colors.error,marginTop:'4%'}}>{error}</Text>
            <Button
                style={styles.button}
                labelStyle={{fontSize:Width>1000?30:15}}
                onPress={async ()=>{ handleSubmit(); }}
                color={colors.primary}
                mode={'contained'}
                uppercase={true}
                loading={loading}
                disabled={loading}
            >
                Continue
            </Button>
        </KeyboardAwareScrollView>
        </>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            button: {
                borderRadius: 10,
                height: Width>1000?70:55,
                justifyContent: 'space-around',
                marginTop: '4%',
            },
            container: {
                alignContent: 'center',
                alignSelf: 'center',
                flexGrow: 1,
                maxWidth: 700,
                paddingLeft: Width>1000?20:'10%',
                paddingRight: Width>1000?20:'10%',
                width: '100%',
            },
            welcomeTextContainer: {
                marginBottom: '5%'
            },
            welcomeTextHeader: {
                color: colors.primary,
                fontSize: Width>1000?70:30,
                fontWeight: 'bold',
                marginTop: 20,
                textAlign: 'center'
            },
            WelcomeTextSubHeader: {
                color: colors.placeholder,
                fontSize:15,
                paddingTop:0,
                textAlign:'center'
            },
        })
    );
}