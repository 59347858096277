import React from 'react';
import { FlatList, StyleSheet, useWindowDimensions, View, } from 'react-native';
import { Button, Card, DataTable, IconButton, Text, useTheme } from 'react-native-paper';
import { LoadingModal } from '../components/LoadingModal';
import Strings from '../constants/Strings';
import { AppContext } from '../context';
import { useAllUsers } from '../hooks/useUsers';
import WebDashboard from '../layouts/WebDashboard';
import Error403Screen from './Error403Screen';

export default function DashboardScreenWeb({ navigation }: any) {
    const { state } = React.useContext(AppContext);
    const { isAdmin } = state.user;
    if (!isAdmin) {
        return <Error403Screen />
    }

    const { colors } = useTheme();
    const { width } = useWindowDimensions();
    const styles = Styles(colors, width);
    const { errorMessage, loading, users, DeleteUser, MakeUserAdmin } = useAllUsers(null);

    return (
        <WebDashboard>
            <LoadingModal visible={loading} />
            <Card style={styles.userListContainer}>
                <Card.Title title={'All Users'} />
                <Card.Content>
                    <DataTable>
                        <DataTable.Header>
                            <DataTable.Title>User ID</DataTable.Title>
                            <DataTable.Title>User Name</DataTable.Title>
                            <DataTable.Title>User Email</DataTable.Title>
                            <DataTable.Title>Assign as Admin</DataTable.Title>
                            <DataTable.Title>Delete User Data Only</DataTable.Title>
                            <DataTable.Title>Delete User</DataTable.Title>
                        </DataTable.Header>

                        <FlatList
                            data={users}
                            keyExtractor={(item) => item.id}
                            renderItem={({ item }) => {
                                return (
                                    <DataTable.Row onPress={()=>{navigation.navigate('User',{id:item.id})}}>
                                        <DataTable.Cell>{item.id}</DataTable.Cell>
                                        <DataTable.Cell>{item.name}</DataTable.Cell>
                                        <DataTable.Cell>{item.email}</DataTable.Cell>
                                        <DataTable.Cell>
                                            <Button
                                                onPress={() => {
                                                    MakeUserAdmin(item.id)
                                                        .then((res: any) => {
                                                            console.log('res: ', res);
                                                            if (res) alert('Success.');
                                                            else { alert(errorMessage) }
                                                        })
                                                }}
                                                uppercase={false}
                                            >
                                                {item.isAdmin ? 'Remove Admin' : 'Assign Admin'}
                                            </Button>
                                        </DataTable.Cell>
                                        <DataTable.Cell>
                                            <Button
                                                onPress={() => {
                                                    DeleteUser(item.id, 'CLEAR')
                                                        .then((res: any) => {
                                                            console.log('res: ', res);
                                                            if (res) alert('User data was deleted successfully.');
                                                            else { alert(errorMessage) }
                                                        })
                                                }}
                                                uppercase={false}
                                            >
                                                {'Clear Data'}
                                            </Button>
                                        </DataTable.Cell>
                                        <DataTable.Cell>
                                            <IconButton
                                                color={colors.error}
                                                icon={'close-circle'}
                                                onPress={() => {
                                                    DeleteUser(item.id,'DELETE')
                                                        .then((res: any) => {
                                                            console.log('res: ', res);
                                                            if (res) alert('User was deleted successfully.');
                                                            else { alert(errorMessage) }
                                                        })
                                                }}
                                            />
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                )
                            }}
                        />
                    </DataTable>
                </Card.Content>
            </Card>
        </WebDashboard>
    );
}

const Styles = (colors: any, width: number) => {
    const maxWidth = width > Strings.MAX_SCREEN_WIDTH ? Strings.MAX_SCREEN_WIDTH : width;
    return (
        StyleSheet.create({
            btnContainer: {
                flexDirection: 'row',
            },
            container: {
                flex: 1,
                padding: 20,
            },
            userListContainer: {
                alignSelf: 'center',
                marginTop: 10,
                maxWidth,
                width: maxWidth * 0.75
            },
        })
    );
}