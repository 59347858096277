import React from 'react';
import { Firestore } from '../constants/Firebase';
import { AppContext } from '../context';
const {useEffect,useState}=React;
 export interface AccountsProps {
    accounts: AccountProps[],
    key: string,
    title: string,
    total: number,
}

export interface AccountProps {
    id: string,
    title: string,
    total: string,
    type: string,
    balances:{available:number,current:number},
    bankId: string,
    bankAccessToken: string,
    bankName: string,
    name: string,
    item_id: string,
    updated: number,
    logo?:string,
    refresh:boolean,
    official_name?:string,
    
}

export interface UserAccountProps{
    accessToken: string,
    accounts: AccountProps[],
    bankId: string,
    id: string,
    logo?:string,
    name: string,
    refresh: boolean
}

interface StateProps {
    loading:boolean,
    accounts:UserAccountProps[],
    netWorth: number,
    homeAccounts: AccountsProps[],
    error:boolean,
    errorMessage:string
}
type usePlaidLinksProps = 'default' | 'home' | null | undefined;
const InitialState = {
    loading:false,
    accounts:[],
    netWorth: 0,
    homeAccounts: [
        { key: 'cash', title: 'Cash', total: 0.00, accounts: [] },
        { key: 'credit', title: 'Credit', total: 0.00, accounts: [] },
        { key: 'loans', title: 'Loans', total: 0.00, accounts: [] },
        { key: 'investment', title: 'Investment', total: 0.00, accounts: [] },
        { key: 'properties', title: 'Real Estate', total: 0.00, accounts: []}
    ],
    error:false,
    errorMessage:''
};
export const usePlaidLinks = (get:usePlaidLinksProps,userId:string|null=null)=>{
    const [state,setState] = useState<StateProps>(InitialState);
    const { state: AppState } = React.useContext(AppContext);
    const { user } = AppState;
    const id = userId??user.id;

    const Ref = Firestore.collection(Firestore.getFirestore(),'Users/'+id+'/Accounts');

    const getAccounts = async ()=>{
        const query = Firestore.query(Ref,Firestore.orderBy('name'));
        return Firestore.onSnapshot(query,(snapshot)=>{
            setState({...InitialState,loading:true});

            const accounts: UserAccountProps[] = [];
            snapshot.forEach((snap)=>{
                const data = (snap.data() as UserAccountProps);
                accounts.push(data);
            });
            setState({...InitialState,accounts});
        },
        (error)=>{
            setState({...InitialState,error:true,errorMessage:error.message});
        });
    }

    const getAccountsHomeScreen = async ()=>{
        Firestore.onSnapshot(Ref,(snapshot)=>{
            if(snapshot.empty) {
                return;
            }
            setState({...InitialState,loading:true});
            const homeAccounts:AccountsProps[] = [];
            const cash:AccountsProps = {key:'cash',title:'Cash',total:0,accounts:[]};
            const credit:AccountsProps = {key:'credit',title:'Credit',total:0,accounts:[]};
            const loan:AccountsProps = {key:'loan',title:'Loans',total:0,accounts:[]};
            const investment:AccountsProps = {key:'investment',title:'Investment',total:0,accounts:[]};
            const properties:AccountsProps = {key:'properties',title:'Real Estate',total:0,accounts:[]};
            let totalIncome = 0;
            let totalExpense = 0;

        
            snapshot.forEach((snap)=>{
                const data = (snap.data() as UserAccountProps);
                for (const key in data.accounts) {
                    const account = data.accounts[key];
                    if( Number(account.total) === 0 ) continue;
                    //account.logo = data.logo;
                    account.refresh = data.refresh;
                    //account.total = GetBalance( account.balances, account.type).toString();
                    switch(account.type.toLowerCase()){
                        case 'cash':{
                            cash.accounts.push(account);
                            cash.total += Number(account.total);
                            totalIncome += Number(account.total);
                            break;
                        }
                        case 'credit':{
                            credit.accounts.push(account);
                            credit.total += Number(account.total);
                            totalExpense += Math.abs(Number(account.total));
                            break;
                        }
                        case 'loans':{
                            loan.accounts.push(account);
                            loan.total += Number(account.total);
                            totalExpense += Math.abs(Number(account.total));
                            break;
                        }
                        case 'investment':{
                            investment.accounts.push(account);
                            investment.total += Number(account.total);
                            totalIncome += Number(account.total);
                            break;
                        }
                        case 'properties':{
                            properties.accounts.push(account);
                            properties.total += Number(account.total);
                            totalIncome += Number(account.total);
                            break;
                        }
                    }
                }
            });
            homeAccounts.push(cash);
            homeAccounts.push(credit);
            homeAccounts.push(loan);
            homeAccounts.push(investment);
            homeAccounts.push(properties);
            const netWorth = totalIncome - totalExpense;
            setState({...InitialState,homeAccounts,netWorth});
        },
        (error)=>{
            setState({...InitialState,error:true,errorMessage:error.message});
        });
    }

    const deleteAccount = async (account:UserAccountProps)=>{
        alert('delete function unfinished');
    }
 
    useEffect(()=>{
        switch(get){
            case 'default':{
                getAccounts();
                break;
            }
            case 'home':{
                getAccountsHomeScreen();
                break;
            }
        }
    },
    []);

    return {...state,getAccounts,deleteAccount};
}