//TODO: refactor this page
import React from 'react';
import { Dimensions, FlatList, RefreshControl, ScrollView, StyleSheet,View,} from 'react-native';
import {Button, Card, IconButton, List, Text, useTheme} from 'react-native-paper';
import dayjs from 'dayjs';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { currencyFormatter, dateFormatter, getDateCodes, hexToRGB, rgb2hsv } from '../constants/utils';
import { useAccounts } from '../hooks/useAccounts';
import { useTransactions } from '../hooks/useTransactions';
import Please from '../constants/Please';
import TransactionCategoryModal from '../components/TransactionCategoryModal';
import RBSheet from 'react-native-raw-bottom-sheet';
import MonthlyLineGraph from '../components/MonthlyLineGraph';
import SpendingCategoriesList from '../components/SpendingCategoriesList';
import IncomeExpenseChart from '../components/IncomeExpenseChart';
import { useCategories } from '../hooks/useCategories';

export default function MonthlyScreen({navigation,route}:any){ 
    const isFocused = useIsFocused();
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {GetSingleSummary,UpdateCashTotals} = useAccounts(false);
    const { transactions, GetTransactions } = useTransactions({limit:-1});
    const DefaultCat = [{
        label:'',
        name:'',
        value: 0.1,
        color:colors.primary,
        legendFontColor: colors.primary,
        legendFontSize: 15,
        svg: {
            fill: colors.primary,
        },
    }];
    const {categories:AppCategories,loading} = useCategories();
    
    const RBRef = React.useRef<RBSheet | null>();
    const [data,setData] = React.useState<any>([]);
    const [curDate,setCurDate] = React.useState(dayjs().startOf('M'));
    const [categories, setCategories] = React.useState(DefaultCat);
    const [selectedCat,setSelectedCat] = React.useState<any>('other');
    const [first,setFirst] = React.useState(true);
    const [refreshing,setRefresing] = React.useState(false);

    //TODO: this conversion should probable be in the config and ran only once
    const rgb = hexToRGB(colors.primary,true);
    //@ts-ignore
    const hsv = rgb2hsv(rgb.r,rgb.g,rgb.b);
    const randomColors = Please.make_scheme(
        {
          h: hsv.h,
          s: hsv.s,
          v: hsv.v
        },
        {
          scheme_type: 'analogous',
          format: 'hex'
        }
    );
    
    const changeDate = (action:'prev'|'next')=>{
        let newDate;
        switch(action){
            case 'prev':{
                newDate = dayjs(curDate).subtract(1, 'month');
                break;
            }
            case 'next':{
                newDate = dayjs(curDate).add(1, 'month');
                break;
            }
        }
        if(newDate > dayjs()) return;
        const yearStart = dayjs(dayjs().year()+'-01-01');
        const monthsPrior = dayjs().subtract(4,'months');
        if( newDate < yearStart && newDate < monthsPrior ) return;
        setCurDate(newDate);
        setData([]);
        let dateCode = getDateCodes(newDate.toISOString(),'yearMonth');
        prepareSummary(dateCode);
    }

    const arrangeCategories = (transactions:any,curDate:number)=>{
        let categories:any = {};
        transactions.forEach((transaction:any) => {
            if(transaction.type === 'income') return;
            
            const category = transaction.category ? transaction.category[0] : 'Other';
            const amount = Math.abs(Number(transaction.amount));
            categories[ category ] =  categories[ category ]? Number(categories[ category ])+amount : amount;
            
        });
        categories = Object.keys(categories).sort().reduce((obj:any, key) =>{ obj[key] = categories[key]; return obj;},{});
        const cats:any = [];
        let i=0;
        for (const [key, value] of Object.entries(categories)) {
            let color = randomColors[i];
            color = color?.slice(0,7);
            const icon = AppCategories.find((cat)=>cat?.name == key)?.icon??'';
            i++;
            cats.push({
                label:key,
                name:key,
                value: value,
                color: color,
                legendFontColor: color,
                legendFontSize: 15,
                icon: icon,
                //arc: { outerRadius: (70 + Number(value)) + '%', padAngle: (selectedCat && selectedCat.label === key) ? 0.1 : 0 },
                onPress: ()=>{
                    navigation.navigate('Spending Category',
                        {
                            category:key,
                            color: color,
                            date: dayjs(curDate).valueOf(),
                            icon: icon
                        }
                    );
                    //setSelectedCat(key);
                    //RBRef.current?.open();
                },
                svg: {
                    fill: color,
                },
            },);
          }
        setCategories(cats);
    }

    React.useEffect(()=>{if(!loading)arrangeCategories(transactions,Number(curDate))},[loading,transactions]);
    React.useEffect(()=>{
        prepareSummary(undefined);
        UpdateCashTotals({uid:null})
        .catch()
        .then(()=>{setRefresing(false);});
    },[]);
    React.useEffect(()=>{
        if(!isFocused) return;
        let dateCode = getDateCodes(dayjs(curDate).toISOString(),'yearMonth');
        prepareSummary(dateCode);
    },[isFocused])

    const prepareSummary = async (dateCode:any)=>{
        GetSingleSummary(dateCode)
        .then((sum:any)=>{
            setData([
                {
                    label:'Income',
                    name:'Income',
                    value: sum.monthlyIncome,
                    color:colors.primary,
                    fillColor:colors.background,
                    legendFontColor: colors.primary,
                    legendFontSize: 15,
                    svg: {
                        fill: colors.primary,
                    },
                },
                {
                    label:'Expense',
                    name:'Expense',
                    value: sum.monthlyExpense,
                    color:colors.error,
                    fillColor: colors.background,
                    legendFontColor: colors.error,
                    legendFontSize: 15,
                    svg: {
                        fill: colors.error,
                    },
                },
            ]);
        });
        if(dateCode){
            const trans = await GetTransactions(-1,dayjs(dateCode+'0101').valueOf());
            arrangeCategories(trans,dateCode)
        }
        else{
            
        }
    }

    const refresh = ()=>{
        setRefresing(true)
        UpdateCashTotals({uid:null})
        .catch()
        .then(()=>{return prepareSummary(undefined)})
        .then(()=>{setRefresing(false);});
    }


    return(
        <View style={styles.container}>
            <ScrollView 
                contentContainerStyle={{flexGrow:1}} 
                refreshControl={
                    <RefreshControl
                      colors={[colors.primary]}
                      refreshing={refreshing}
                      onRefresh={()=>{ refresh() }}
                    />
                  }
                showsVerticalScrollIndicator={false} 
                stickyHeaderIndices={[1]}
            >
            <MonthlyLineGraph year={dayjs().format('YYYY')} />
            <View style={{...styles.pickerContainer,elevation: 1}}>
            <View style={styles.pickerContainer}>
                <Button mode={'outlined'} onPress={()=>{changeDate('prev')}}>Prev</Button>
                {/*@ts-ignore*/}
                <Text>{dayjs(curDate).format('MMMM, YYYY')}</Text>
                <Button mode={'outlined'} onPress={()=>{changeDate('next')}}>Next</Button>
            </View>
            </View>
            
                <Card style={styles.contentContainer}>
                    <Card.Title title={dayjs(curDate).format('MMMM')+' Cash Flow'} titleStyle={styles.cardTitle} />
                    <IncomeExpenseChart colors={colors} data={data} />
                </Card>

                <Card style={styles.contentContainer}>
                    <Card.Title title={dayjs(curDate).format('MMMM')+' Spending Breakdown'} titleStyle={styles.cardTitle} />
                    <View>
                        {/* <RenderPieChart categories={categories} /> */}
                        <SpendingCategoriesList categories={categories} />
                    </View>
                </Card>

            </ScrollView>
            <TransactionCategoryModal 
                category={selectedCat}
                date={dayjs(curDate).valueOf()}
                navigation={navigation}
                RbRef={RBRef}
            />
        </View>
    )
}


const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    
    return (
        StyleSheet.create({
            cardTitle:{
                fontSize: 18,
                textAlign:'center'
            },
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
            },
            contentContainer:{
                borderColor: '#F8F0E3',
                borderWidth: 1,
                minHeight: Height,
                marginBottom: 20,
                //padding:5
            },
            pickerContainer:{
                alignItems: 'center',
                alignSelf: 'center',
                backgroundColor: colors.background,
                flexDirection: "row",
                height: 80,
                justifyContent: "space-between",
                paddingHorizontal: 16,
                width: '100%'
            }
        })
    );
}