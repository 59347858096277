import React from 'react';
import { Dimensions, Platform, ScrollView, StyleSheet,View,} from 'react-native';
import {Button, Divider, List, Text, TextInput, useTheme} from 'react-native-paper';
import CurrencyInput from 'react-native-currency-input';
import {Formik} from 'formik';
import DateTimePicker from '@react-native-community/datetimepicker';
import { TransactionProps, useTransactions } from '../hooks/useTransactions';
import { addTransactionSchema } from '../constants/Schemas';
import { dateFormatter } from '../constants/utils';
import SelectBillModal from '../components/SelectBillModal';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BillProps } from '../hooks/useBills';
import { useCategories } from '../hooks/useCategories';
import CategoriesPicker from '../components/CategoriesPicker';

export default function AddTransactionsScreen({navigation,route}:any){ 
    const account = route?.params?.account ?? false;
    const InitialFormValues = {
        account: account,
        amount: 0,
        billId: false,
        bill: {name:''},
        category: 'Other',
        date: Date.now(),
        error: '',
        name: '',
        type: 'expense'
    }
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {AddTransaction}  = useTransactions({get:false});
    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const RBRef = React.useRef<RBSheet | null>();
    const CatRef = React.useRef<boolean>(false);
    
    return(
        <View style={styles.container}>
            <Formik
                initialValues={InitialFormValues}
                validationSchema={addTransactionSchema}
                onSubmit={(values,actions)=>{
                    const transaction: TransactionProps = (values as any);
                    transaction.amount = values.type === 'expense'? '-'+transaction.amount : transaction.amount;
                    transaction.category = [values.category];
                    AddTransaction(transaction)
                    .then(()=>{
                        //TODO: figure why tf this shit won't work
                        navigation.goBack();
                    })
                    .catch((error)=>{
                        actions.setFieldError('error',error.message);
                            actions.setSubmitting(false);
                    })
                    //navigation.goBack();
                }}
            >
            {({handleChange,handleSubmit,setFieldValue,isSubmitting,values,errors})=>{
                return(
                    <>
                    <ScrollView contentContainerStyle={{flexGrow:1}} showsVerticalScrollIndicator={false}>
                        <View style={{...styles.contentContainer,alignItems:'center'}}>
                            <CurrencyInput
                                autoFocus={true}
                                value={values.amount}
                                onChangeValue={(value)=>{setFieldValue('amount',value)}}
                                prefix={(values.type==='expense'?'-':'') + "$"}
                                delimiter=","
                                separator="."
                                precision={2}
                                onChangeText={(formattedValue) => {
                                }}
                                keyboardType={'decimal-pad'}
                                style={styles.currencyInput}
                            />
                        </View>
                        <View>
                            <List.Item 
                                description={
                                    <View style={{flexDirection:'row'}}>
                                        <Button 
                                            compact={true}
                                            mode={'contained'}
                                            style={{marginRight:20}}
                                            color={values.type==='expense'?colors.primary:colors.accent} 
                                            onPress={()=>{setFieldValue('type','expense')}}
                                            uppercase={false}
                                        >
                                            Expense
                                        </Button>
                                        <Button 
                                            compact={true}
                                            mode={'contained'}
                                            color={values.type==='income'?colors.primary:colors.accent} 
                                            onPress={()=>{setFieldValue('type','income')}}
                                            uppercase={false}
                                        >
                                            Income
                                        </Button>
                                    </View>
                                }
                                left={props => <List.Icon {...props} icon="calculator-variant" />}
                                title={'Expense Type'}
                            />
                            <Divider />
                            
                            <View style={{flexDirection:'row',marginBottom:-40}}>
                                <List.Icon icon={'file-document-outline'} />
                                <View style={{width:'100%'}}>
                                    <List.Subheader style={{marginBottom:-20}}>Merchant</List.Subheader>
                                    <TextInput
                                        mode={'flat'}
                                        style={styles.textInput}
                                        onChangeText={handleChange('name')}
                                        value={values.name}
                                        //label={'Merchant'}
                                        placeholder={'Add merchant name'}
                                        error={errors.name?true:false}
                                        underlineColor={'transparent'}
                                    />
                                    <Text style={styles.errorText}>{errors.error}</Text>
                                </View>    
                            </View>
                            <Divider />
                            
                            <List.Item 
                                description={dateFormatter(values.date,'fullString')}
                                left={props => <List.Icon {...props} icon="calendar" />}
                                onPress={()=>setShowDatePicker(true)}
                                title={'Date'}
                            />
                            <Divider />

                            <List.Item 
                                description={
                                    <CategoriesPicker 
                                        value={values.category} 
                                        onChange={(value)=>{
                                            setFieldValue('category',value);
                                            CatRef.current = false;
                                        }} 
                                    />
                                }
                                left={props => <List.Icon {...props} icon="shape" />}
                                title={'Category'}
                            />
                            <Divider />

                            <List.Item 
                                title={
                                    values.billId?
                                    values.bill?.name
                                    :
                                        'Mark as bill?'    
                                }
                                description={values.billId&&'Bill Name'}
                                left={props => <List.Icon {...props} icon="text-box-outline" />}
                                right={props => values.billId?<List.Icon {...props} icon="delete" />:<View/>}
                                onPress={()=>{
                                    if(!values.billId){
                                        RBRef.current?.open();
                                        return;
                                    }
                                    setFieldValue('billId',false);
                                    setFieldValue('bill',{name:''});
                                }}
                            />
                            <Divider />
                        </View>

                        {account &&<View style={{...styles.contentContainer,marginTop:20}}>
                            <View style={{flexDirection:'row',marginBottom:20}}>
                                <Text style={{fontSize:15}}>{'Adding transaction to: '}</Text>
                                <Text style={{fontSize:15,fontWeight:'bold'}}>{account.title}</Text>
                            </View>
                            <Divider/>
                        </View>}
                        <Button 
                            mode={'contained'}
                            color={colors.primary}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            uppercase={false}
                            onPress={()=>handleSubmit()}
                            style={{}}
                        >
                            Save Transaction
                        </Button>
                    </ScrollView>
                    {showDatePicker && (
                        <View>
                            {Platform.OS === 'ios' && (
                            <Button onPress={() => setShowDatePicker(false)}>Close</Button>
                            )}
                        <DateTimePicker
                        testID="dateTimePicker"
                        value={new Date(values.date)}
                        mode={'date'}
                        display={Platform.OS === 'ios'?"spinner":"default"}
                        onChange={(event:any, selectedDate:Date | undefined)=>{
                            const currentDate = selectedDate?.getTime() ?? Date.now();
                            setShowDatePicker(Platform.OS === 'ios');
                            setFieldValue('date',currentDate);
                        }}
                        />
                        </View>
                    )}
                    <SelectBillModal 
                        navigation={navigation}
                        onPress={(bill:BillProps)=>{
                            RBRef.current?.close();
                            setFieldValue('billId',bill.id);
                            setFieldValue('bill',bill);
                        }}
                        RBRef={RBRef}
                    />
                    </>
                )
            }}
            </Formik>
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                borderColor: 'black',
                height: Height/2,
                marginBottom: 20,
            },
            currencyInput:{
                fontSize: Height*0.2,
                height: '100%',
            },
            errorText: {
                color: colors.error,
                marginBottom: 40,
            },
            textInput: {
                backgroundColor: 'transparent',
                borderBottomColor: 'transparent',
                borderBottomWidth: 0,
                fontSize: 17,
                height: 50,
                width: '75%'
            },
        })
    );
}