import { Formik } from 'formik';
import React from 'react';
import {Dimensions, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View,} from 'react-native';
import {Button, Divider, List,TextInput,useTheme} from 'react-native-paper';
import { AccountProps } from '../constants/Types';
import { useAccounts } from '../hooks/useAccounts';
import CurrencyInput from 'react-native-currency-input';
import Picker from 'react-native-picker-select';
import Strings from '../constants/Strings';
import { PickerSelectStylesAvil } from '../constants/Theme';

const Width = Dimensions.get('window').width*0.9;

export default function AddProperties(Props:any){ 
    const Property = Props?.route?.params?.property??{};
    const InitialFormValues = {
        balance:Property?.balances?.current??0.00,
        connectedBank:Property?.bankName??'Real Estate',
        id:Property?.id??null,
        manuallyAdded: true,
        name:Property?.name??'',
        streetLine1:Property?.address?.streetLine1??'',
        streetLine2:Property?.address?.streetLine2??'',
        city:Property?.address?.city??'',
        state:Property?.address?.state??'',
        zip:Property?.address?.zip??'',
        type:'properties'
    }
    const {colors} = useTheme();
    const pickerStyles = PickerSelectStylesAvil(colors,Width*2);
    const styles = Styles(colors);
    const {accounts,error,errorMessage,loading,message,GetAllAccounts,AddAccountMannually} = useAccounts(false);

    return(
        <View style={styles.container}>
            <Formik
                initialValues={InitialFormValues}
                onSubmit={async(values,actions)=>{
                    const account = ({
                        address:{
                            streetLine1:values.streetLine1,
                            streetLine2:values.streetLine2,
                            city:values.city,
                            state:values.state,
                            zip:values.zip
                        },
                        balances:{available:values.balance,   
                        current:values.balance},
                        bankName:'Real Estate', 
                        id:values.id,
                        item_id:'realestate',
                        manuallyAdded:true,
                        name:values.name,
                        title:values.name,
                        total:(values.balance*100).toString(),
                        //next_payment_due_date:dateDue,
                        type: values.type
                    } )

                    //@ts-ignore
                    const res = await AddAccountMannually(account,false)
                    actions.setSubmitting(false);
                    if( res ) {
                        Props.navigation.goBack();
                    }
                }}
            >
            {
                ({handleChange,handleSubmit,setFieldValue,isSubmitting,values,errors})=>{
                return(
                    <>
                        <ScrollView contentContainerStyle={{flexGrow:1}} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>

                        <View style={{flexDirection:'row',}}>
                            <List.Icon icon={'card-text-outline'} />
                            <View style={{width:'100%'}}>
                                {/*@ts-ignore*/}
                                <List.Subheader style={{marginBottom:-20}}>Property Name</List.Subheader>
                                <TextInput
                                    mode={'flat'}
                                    style={styles.textInput}
                                    onChangeText={handleChange('name')}
                                    value={values.name}
                                    placeholder={'Property name'}
                                    error={errors.name ? true : false}
                                    underlineColor={'transparent'} 
                                />
                                <Text style={styles.errorText}>{errors.name?.toString()}</Text>
                            </View>    
                        </View>
                        <Divider/>

                        <View style={{flexDirection:'row',}}>
                            <List.Icon icon={'currency-usd'} />
                            <View style={{width:'100%'}}>
                                {/*@ts-ignore*/}
                                <List.Subheader style={{marginBottom:-20}}>Property Value</List.Subheader>
                                <View style={{marginLeft:15}}>
                                    <CurrencyInput
                                        style={{...styles.textInput,}}
                                        onChangeValue={(value)=>setFieldValue('balance',value)}
                                        value={values.balance}
                                        placeholder={'Property Value'}
                                        prefix={"$"}
                                        delimiter=","
                                        separator="."
                                        precision={2}
                                        keyboardType={'decimal-pad'}
                                    />
                                   <Text style={styles.errorText}>{errors.balance?.toString()}</Text>
                                </View>
                            </View>    
                        </View>
                        <Divider/>

                        <View style={{flexDirection:'row',}}>
                            <List.Icon icon={'home-map-marker'} />
                            <View style={{width:'100%'}}>
                                {/*@ts-ignore*/}
                                <List.Subheader style={{marginBottom:-20}}>Address Line 1</List.Subheader>
                                <TextInput
                                    mode={'flat'}
                                    style={styles.textInput}
                                    onChangeText={handleChange('streetLine1')}
                                    value={values.streetLine1}
                                    placeholder={'Address Line 1'}
                                    error={errors.streetLine1 ? true : false}
                                    underlineColor={'transparent'} 
                                />
                                <Text style={styles.errorText}>{errors.streetLine1?.toString()}</Text>
                            </View>    
                        </View>
                        <Divider/>

                        <View style={{flexDirection:'row',}}>
                            <List.Icon icon={'map-marker-outline'} />
                            <View style={{width:'100%'}}>
                                {/*@ts-ignore*/}
                                <List.Subheader style={{marginBottom:-20}}>Address Line 2</List.Subheader>
                                <TextInput
                                    mode={'flat'}
                                    style={styles.textInput}
                                    onChangeText={handleChange('streetLine2')}
                                    value={values.streetLine2}
                                    placeholder={'Address Line 2'}
                                    error={errors.streetLine2 ? true : false}
                                    underlineColor={'transparent'} 

                                />
                                <Text style={styles.errorText}>{errors.streetLine2?.toString()}</Text>
                            </View>    
                        </View>
                        <Divider/>

                        <View style={{flexDirection:'row',}}>
                            <List.Icon icon={'home-city-outline'} />
                            <View style={{width:'100%'}}>
                                {/*@ts-ignore*/}
                                <List.Subheader style={{marginBottom:-20}}>City</List.Subheader>
                                <TextInput
                                    mode={'flat'}
                                    style={styles.textInput}
                                    onChangeText={handleChange('city')}
                                    value={values.city}
                                    placeholder={'City'}
                                    error={errors.city ? true : false}
                                    underlineColor={'transparent'} 
                                />
                                <Text style={styles.errorText}>{errors.city?.toString()}</Text>
                            </View>    
                        </View>
                        <Divider/>

                        <View style={{flexDirection:'row',}}>
                            <List.Icon icon={'city-variant-outline'} />
                            <View style={{width:'100%'}}>
                                {/*@ts-ignore*/}
                                <List.Subheader style={{marginBottom:-20}}>State</List.Subheader>
                                <View style={{marginLeft: Platform.OS!='android'?15:0}}>
                                    <Picker 
                                        onValueChange={(value:string)=>{setFieldValue('state',value)}}
                                        items={Strings.LIST_OF_STATES}
                                        placeholder={{label:'State',value:null}}
                                        style={pickerStyles}
                                        value={values.state}
                                    />
                                </View>
                                {/* <TextInput
                                    mode={'flat'}
                                    style={styles.textInput}
                                    onChangeText={handleChange('state')}
                                    value={values.state}
                                    placeholder={'State'}
                                    error={errors.state ? true : false}
                                    underlineColor={'transparent'} 
                                    autoCompleteType={undefined}
                                /> */}
                                <Text style={styles.errorText}>{errors.state?.toString()}</Text>
                            </View>    
                        </View>
                        <Divider/>

                        <View style={{flexDirection:'row',}}>
                            <List.Icon icon={'zip-box-outline'} />
                            <View style={{width:'100%'}}>
                                {/*@ts-ignore*/}
                                <List.Subheader style={{marginBottom:-20}}>zip</List.Subheader>
                                <TextInput
                                    mode={'flat'}
                                    style={styles.textInput}
                                    onChangeText={handleChange('zip')}
                                    value={values.zip}
                                    placeholder={'zip'}
                                    error={errors.zip ? true : false}
                                    underlineColor={'transparent'} 
                                    keyboardType={'number-pad'}
                                />
                                <Text style={styles.errorText}>{errors.zip?.toString()}</Text>
                            </View>    
                        </View>
                        <Divider/>

                        <Text style={{...styles.errorText,color:error?colors.error:colors.primary}}>
                                {error?errorMessage:message}
                        </Text>
                        <TouchableOpacity>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'100%'}}>
                                    <Button
                                        style={styles.button}
                                        labelStyle={{fontSize:Width>500?25:15}}
                                        onPress={()=>handleSubmit()}
                                        color={colors.primary}
                                        mode={'contained'}
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        uppercase={true}
                                    >
                                        Submit
                                    </Button>
                                </View>
                            </View>
                        </TouchableOpacity>
                            
                        </ScrollView>
                    </>
                )}
            }
            </Formik>
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    return (
        StyleSheet.create({
            button: {
                alignSelf:'center',
                borderRadius: 10,
                height: Width>500?75:55,
                justifyContent: 'space-around',
                marginTop: '4%',
                width:'75%'
            },
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                justifyContent:'center',
                alignContent:'center',
                paddingLeft: 20,
                paddingRight: 20,
                //marginTop:20
            },
            contentContainer:{
                borderColor: 'black',
                height: Height/3,
            },
            errorText: {
                color: colors.error,
                width:'65%'
            },
            textInput: {
                backgroundColor: 'transparent',
                borderBottomColor: 'transparent',
                borderBottomWidth: 0,
                fontSize: 17,
                height: 50,
                width: '75%'
            },
        })
    );
}