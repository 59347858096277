import * as yup from 'yup';
import Strings from './Strings';
import { isBillPassedDue, isLiability } from './utils';

export const addAccountSchema = yup.object().shape({
  balance:yup.number().min(1.00).required('Required'),
  connectBank:yup.string(),
  interestRate:yup.number(),
  manuallyAdded:yup.boolean().required('Required'),
  name:yup.string().required('Required'),
  paymentAmount:yup.number().min(1.00),
  type:yup.string().required('Required'),
  next_payment_due_date:yup.string().when("type",{
    is: (type:string) => isLiability(type),
    then: (schema) => schema.test('pastDue','Bill is currently passed due. Please update due date.',(value)=>{return !isBillPassedDue(value??'')})
  }),
});
// then: yup.string().test('pastDue','Bill is currently passed due. Please update due date.',(value)=>{
//   return !isBillPassedDue(value??''),
  
// })

// is: (type:string) => isLiability(type),
    then: yup.string().test('pastDue','Bill is currently passed due. Please update due date.',(value)=>{
      return !isBillPassedDue(value??'')
    })

export const addBillSchema = yup.object().shape({//TODO:this
  amount: yup.number().min(0.01).required('Required'),
  name: yup.string().required('Required'),
});

export const addBudgetSchema = yup.object().shape({});

export const addTransactionSchema = yup.object().shape({
  amount: yup.number().required('Required'),
  name: yup.string().required('Required'),
});

const phoneRegExp = /^\s*(\+)(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const signUpSchema = yup.object().shape({
  email: yup.string().trim('white space').email(Strings.INVALID_EMAIL).required('Required'),
  name: yup.string().required('Please enter your name.'),
  phone: yup.string()
        // .min(10, 'Must be a 10 digit phone number')
        // .max(10, 'Must be a 10 digit phone number')
        .matches(phoneRegExp,Strings.INVALID_PHONE_NUMBER)
        .required('Required'),

  password: yup.string().required('Required').matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?.<>,&+%^\-()=/\\\[\]{}])[A-Za-z\d@$!%*#?.<>,&+%^\-()=/\\\[\]{}]{8,}$/,
      Strings.INVALID_PASSWORD
    ),
});

export const signUpWithoutPhoneSchema = yup.object().shape({
  email: yup.string().trim('white space').email(Strings.INVALID_EMAIL).required('Required'),
  name: yup.string().required('Please enter your name.'),
  password: yup.string().required('Required').matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?.<>,&+%^\-()=/\\\[\]{}])[A-Za-z\d@$!%*#?.<>,&+%^\-()=/\\\[\]{}]{8,}$/,
      Strings.INVALID_PASSWORD
    ),
});

export const signInSchema = yup.object().shape({
  email: yup.string().email(Strings.INVALID_EMAIL).required('Required'),
  password: yup.string().required('Required'),
});