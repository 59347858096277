import React from 'react';
import { Text, View, ImageBackground, Dimensions, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import HomeGraph from './HomeGraph';
import { currencyFormatter } from '../constants/utils';
import Strings from '../constants/Strings';
//@ts-ignore
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export const Width = Dimensions.get('window').width;

type HomSumProps = {
  currentSpending: number;
  netWorth: number;
  spendFree: number;
  spendingDifference: { color: string; icon: string; text: string[]; };
  setSpendFree: (n: number) => void;
};
export function HomeSummary({ currentSpending, netWorth, spendingDifference, spendFree, setSpendFree }: HomSumProps) {
  const { colors } = useTheme();
  const styles = Styles(colors);

  return (
    <>
      <View style={styles.fundsSummary}>
        <View style={{ paddingLeft: 0, flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <View style={{ width: Width / 3 }}>
            <Text style={[styles.labelText,]}>Spent this month:</Text>
            <Text style={[styles.totalBalance]}>{currencyFormatter(Math.abs(currentSpending))}</Text>
            <Text>
              <Text style={{...styles.spendDiff,color:spendingDifference.color}}>
                {spendingDifference.text[0]}    
              </Text>
              <Text style={{...styles.spendDiff,color:spendingDifference.color,fontWeight:'bold'}}>
                {spendingDifference.text[1]}    
              </Text>
              <Text style={{...styles.spendDiff,color:spendingDifference.color}}>
                {spendingDifference.text[2]}    
              </Text>
            </Text>
          </View>

          {spendFree > Strings.SPENDFREEDAYSLIMIT ?
            <View style={{ width: Width / 3 }}>
              <ImageBackground
                resizeMode="contain"
                source={Strings.SHIELD}
                style={{
                  marginTop: -5
                }}
              >
                <View
                  style={{
                    alignSelf: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: Width > 500 ? 20 : 15,
                  }}
                >
                  <Text style={{ color: colors.accent, fontSize: Width > 500 ? 35 : 20, fontWeight: 'bold', marginTop: -5, textAlign: 'center' }}>{spendFree}</Text>
                </View>
              </ImageBackground>
              <Text style={{ color: colors.accent, fontSize: Width > 500 ? 16 : 11, fontWeight: 'bold', textAlign: 'center' }}>{' spend free days🎉'}</Text>

              <Text style={{ color: colors.accent, fontSize: Width > 500 ? 20 : 15, fontWeight: 'bold', textAlign: 'center' }}>{dayjs().format('MMMM')}</Text>
            </View>
            :
            <View style={{ width: Width / 3, marginRight: 20 }}>
              <Text style={{ color: colors.accent, fontSize: Width > 500 ? 20 : 15, fontWeight: 'bold', marginTop: Width > 500 ? '7%' : 30, textAlign: 'center' }}>{dayjs().format('MMMM')}</Text>
            </View>}

          <View style={{ alignItems: 'flex-end', width: Width / 3 }}>
            <Text style={{ ...styles.labelText }}>Net Worth</Text>
            <Text style={styles.totalBalance}>{currencyFormatter(netWorth)}</Text>
          </View>
        </View>
        <View style={{ marginLeft: '0%', marginTop:5 }}><HomeGraph setSpendFree={setSpendFree} /></View>
      </View>

      <Text style={{ textAlign: 'center', color: colors.primary }}>swipe down to update financial data</Text>
    </>
  );
}

const Styles = (colors: any) => {
  const Maxwidth = 1000;
  const windowHeight = Dimensions.get('window').height;
  return StyleSheet.create({
    container: {
      backgroundColor: colors.background,
      flex: 1,
      //height: '100%',
    },
    button: {
      borderColor: 'black',
      borderWidth: 1,
      height: 50,
      margin: '5%',
      width: '40%',
    },
    buttonsContainer: {
      alignSelf: 'center',
      maxWidth: Maxwidth,
      padding: 20,
      width: '100%',
    },
    fundsSummary: {
      backgroundColor: colors.headerBackground,
      height: windowHeight * 0.512,
      paddingTop: 20,
      padding: 20,
      width: Width
    },
    labelText: {
      color: colors.accent,
      fontSize: Width > 500 ? 16 : 12,
    },
    spendDiff: {
      color: colors.accent,
      fontSize: Width > 500 ? 18 : 12,
      fontWeight: 'bold',
      //marginBottom: 10,
      //marginRight: 20
    },
    monthlyContainer: {
      bottom: 10,
      flexDirection: 'row',
    },
    totalBalance: {
      color: colors.accent,
      fontSize: Width > 500 ? 30 : 18,
      fontWeight: 'bold',
    },
    totalContainer: {
      justifyContent: 'center',
    },
    totalMonthlyBalance: {
      color: colors.accent,
      fontSize: 18,
      fontWeight: 'bold',
    },
    transactionsContainer: {
      alignSelf: 'center',
      marginTop: -20,
      maxWidth: Maxwidth,
      width: '90%',
    },
  });
} 