import React from 'react';
import {StyleSheet,Text,useWindowDimensions,View,} from 'react-native';
import {Button, Card, Divider, List,useTheme} from 'react-native-paper';
import { LoadingModal } from '../components/LoadingModal';
import { currencyFormatter, isBillPassedDue } from '../constants/utils';
import { useAccounts } from '../hooks/useAccounts';
import { AccountProps } from '../constants/Types';
import { AccountContext } from '../context/AccountContext';

export default function ViewAccountDataScreen({navigation,route}:any){ 
    const acc:AccountProps = route.params.account;
    const {state: accountState} = React.useContext(AccountContext);
    const account = accountState.account.accounts[acc.id];
    const {balances,bankName,title,total,type} = account;
    const {colors} = useTheme();
    const {height} = useWindowDimensions();
    const styles = Styles(colors,height);
    const {error,errorMessage,loading,RemoveAccount} = useAccounts(false);

    React.useLayoutEffect(() => {
        navigation.setOptions({
          headerTitle: bankName+ ' - '+title,
        });
    }, [navigation,title]);

    return(
        <View style={styles.container}>
            <LoadingModal visible={loading} />
            <Card style={styles.contentContainer}>
                <Card.Title 
                    title={title} 
                    titleStyle={styles.cardTitle} 
                />
                <Card.Content>
                    {/*@ts-ignore*/}
                    <List.Item 
                        description={'Bank'}
                        left={props => <List.Icon {...props} icon="bank-outline" />}
                        title={bankName??'-'}
                    />
                    <Divider />
                    {/*@ts-ignore*/}
                    <List.Item 
                        description={'Account name'}
                        left={props => <List.Icon {...props} icon="text-box-outline" />}
                        title={title??'-'}
                    />
                    <Divider />
                    {/*@ts-ignore*/}
                    <List.Item 
                        description={'Account category'}
                        left={props => <List.Icon {...props} icon="shape" />}
                        title={type??'-'}
                    />
                    <Divider />
                    {/*@ts-ignore*/}
                    <List.Item 
                        description={(props)=>(
                            <View {...props}>
                                <Text style={{color:(balances?.available ?? 0.00)>=0? colors.primary : colors.error}}>
                                    Avaliable - {currencyFormatter(balances?.available ?? 0.00,false)} 
                                </Text>
                                <Text style={{color:(balances?.current ?? 0.00)>=0? colors.primary : colors.error}}>Current - {currencyFormatter(balances?.current ?? 0.00,false)} </Text>
                            </View>
                        )}
                        left={props => <List.Icon {...props} icon="currency-usd" />}
                        title={'Funds'}
                    />
                    <Divider />
                    {/*@ts-ignore*/}
                    <List.Item
                        left={props => 
                            <List.Icon 
                                {...props} 
                                icon="note-edit-outline" 
                                color={isBillPassedDue(account?.next_payment_due_date ?? (Date.now).toString()) ? colors.error : colors.primary}
                            />
                        }
                        onPress={()=>{
                            navigation.navigate('Add Account',{
                                balance:account.balances.current,
                                connectBank:account.item_id,
                                id:account.id,
                                //@ts-ignore
                                interestRate:account.interestRate??null,
                                isEditing:true,
                                manuallyAdded:account.manuallyAdded??false,
                                name:account.name,
                                next_payment_due_date:account.next_payment_due_date,
                                //@ts-ignore
                                paymentAmount:account?.paymentAmount?account.paymentAmount/100:1.00,
                                type:account.type
                            })
                        }}
                        right={props => 
                            <List.Icon 
                                {...props} 
                                icon="chevron-right" 
                                color={isBillPassedDue(account?.next_payment_due_date ?? (Date.now).toString()) ? colors.error : colors.primary} 
                            />
                        }
                        title={'Edit Account'}
                        titleStyle={{color:isBillPassedDue(account?.next_payment_due_date ?? (Date.now).toString()) ? colors.error : colors.primary}}
                    />
                    <Divider />
                    {/*@ts-ignore*/}
                    <List.Item
                        left={props => <List.Icon {...props} icon="view-list-outline" />}
                        onPress={()=>{navigation.navigate('Account Transactions',{account})}}
                        right={props => <List.Icon {...props} icon="chevron-right" />}
                        title={'View Transactions'}
                    />
                </Card.Content>
            </Card>

            {/* <Text style={styles.errorText}>{errorMessage}</Text>
            <Button
                color={colors.error}
                onPress={()=>{RemoveAccount(account).then((res)=>{if(res)navigation.goBack()})}}
                uppercase={false}
            >
                Delete Account
            </Button> */}
        </View>
    )
}

const Styles = (colors:any,height:number)=>{
    const Height = height*0.3;
    return (
        StyleSheet.create({
            cardTitle:{
                fontSize: 18,
                textAlign:'center'
            },
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                borderColor: '#F8F0E3',
                borderWidth: 1,
                minHeight: Height,
                marginBottom: 20,
                padding:5
            },
            errorText:{
                color:colors.error,
                textAlign:'center',
            },
        })
    );
}