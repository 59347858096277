// @ts-nocheck 
import React from 'react';

export function About({about1Des,about2Des,about3Des,about1Title,about2Title,about3Title}:any) {
  return (
    <div className="features-section" id="about-the-app">
    <div className="container features-grid">
      <div className="features-left">
        <img className="section-img" src="img/screen 2.png" />
      </div>
      <div className="features-right">
        <div className="feature-textbox">
          <div className="icon"><ion-icon className="icon" name="color-palette-outline" /></div>
          <div className="feature-text">
            <h3>{about1Title}</h3>
            <p className="text">{about1Des}</p>
          </div>
        </div>
        <div className="feature-textbox">
          <div className="icon"><ion-icon className="icon" name="albums-outline" /></div>
          <div className="feature-text">
            <h3>{about2Title}</h3>
            <p className="text">{about2Des}</p>
          </div>
        </div>
        <div className="feature-textbox">
          <div className="icon"><ion-icon className="icon" name="cellular-outline" /></div>
          <div className="feature-text">
            <h3>{about3Title}</h3>
            <p className="text">{about3Des}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
