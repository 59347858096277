import { Formik } from 'formik';
import React from 'react';
import {StyleSheet,useWindowDimensions,View} from 'react-native';
import {Card, FAB, Text,TextInput,useTheme} from 'react-native-paper';
import { Loading, LoadingModal } from '../components/LoadingModal';
import Strings from '../constants/Strings';
import { useDocs } from '../hooks/useDocs';
import WebDashboard from '../layouts/WebDashboard';

const layout = [
    {label:'Heading',fields:['headingTitle','headingSubTitle']},
    {label:'About',fields:['about1Title','about1Des','about2Title','about2Des','about3Title','about3Des']},
    {label:'App Features',fields:['featSubtitle','feat1Title','feat1Des','feat2Title','feat2Des','feat3Title','feat3Des','feat4Title','feat4Des','feat5Title','feat5Des','feat6Title','feat6Des',]},
    {label:'Download',fields:['downloadTitle','downloadSubTitle']},
    {label:'App Stores',fields:['appstoreUrl','playstoreUrl']},
    {label:'Contact Us',fields:['contactEmail']},
    {label:'Social Media',fields:['facebook','instagram','twitter']}
];

export default function EditLandingScreen(){ 
    const {colors} = useTheme();
    const {width} = useWindowDimensions();
    const styles = Styles(colors,width);

    const {loading,landing,UpdateLanding} = useDocs();

    const InitialFormValues = {
        about1Des: landing.about1Des,
        about2Des: landing.about2Des,
        about3Des: landing.about3Des,
        about1Title: landing.about1Title,
        about2Title: landing.about2Title,
        about3Title: landing.about3Title,
        appstoreUrl: landing.appstoreUrl,
        downloadSubTitle: landing.downloadSubTitle,
        downloadTitle: landing.downloadTitle,
        facebook: landing.facebook,
        feat1Des: landing.feat1Des,
        feat2Des: landing.feat2Des,
        feat3Des: landing.feat3Des,
        feat4Des: landing.feat4Des,
        feat5Des: landing.feat5Des,
        feat6Des: landing.feat6Des,
        featSubtitle: landing.featSubtitle,
        feat1Title: landing.feat1Title,
        feat2Title: landing.feat2Title,
        feat3Title: landing.feat3Title,
        feat4Title: landing.feat4Title,
        feat5Title: landing.feat5Title,
        feat6Title: landing.feat6Title,
        instagram:landing.instagram,
        playstoreUrl: landing.playstoreUrl,
        headingTitle: landing.headingTitle,
        headingSubTitle: landing.headingSubTitle,
        twitter:landing.twitter
    }

    const [state,setState] = React.useState(InitialFormValues);

    React.useEffect(()=>{
        setState(landing);
    },[landing]);

    return(
        <WebDashboard>
            <Formik
                initialValues={state}
                //validationSchema={}
                enableReinitialize
                onSubmit={(values,actions)=>{
                    UpdateLanding(values)
                    .then(()=>{
                        actions.setSubmitting(false);
                        alert('Successfully saved.');
                    })
                    .catch((error)=>{
                        actions.setFieldError('email',error.message);
                        actions.setSubmitting(false);
                        alert('Filed to update info.');
                    })
                }}
            >
                {({handleChange,handleSubmit,isSubmitting,values,errors})=>{
                    return(
                    <View style={styles.formContainer}>
                        {
                            layout.map((lay)=>{
                                return(
                                    <Card key={lay.label} style={styles.card}>
                                        <Card.Title title={lay.label} />
                                        <Card.Content>{
                                            lay.fields.map((field)=>(
                                                <React.Fragment key={field}>
                                                <TextInput
                                                    mode={'flat'}
                                                    style={styles.textInput}
                                                    onChangeText={handleChange(field)}
                                                    //@ts-ignore
                                                    value={values[field]}
                                                    label={field}
                                                    placeholder={''}
                                                    //@ts-ignore
                                                    error={errors[field]?true:false}
                                                    returnKeyType={'next'}
                                                />
                                                {/* @ts-ignore */}
                                                <Text style={styles.errorText}>{errors[field]}</Text>
                                                </React.Fragment>
                                            ))
                                        }
                                        </Card.Content>
                                    </Card>
                                );
                            })
                        }
                        <FAB
                            style={styles.fab}
                            small
                            icon="content-save"
                            label={'Save'}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            onPress={() => handleSubmit()}
                        />
                        <LoadingModal visible={isSubmitting} />
                    </View>
                    )
                }}
            </Formik>
        </WebDashboard>
    );
}

const Styles = (colors:any,width:number)=>{
    const maxWidth = width>Strings.MAX_SCREEN_WIDTH?Strings.MAX_SCREEN_WIDTH:width;
    return (
        StyleSheet.create({
            card:{
                marginBottom: 20,
            },
            container:{
                alignItems: 'center',
                flex:1,
                justifyContent: 'center',
                padding:20,
            },
            errorText: {
                color: colors.error,
            },
            fab: {
                //@ts-ignore
                position: 'fixed',
                margin: 16,
                right: '3%',
                bottom: 20,
            },
            formContainer: {
                maxWidth,
                width: maxWidth*0.75
            },
            textInput: {
                fontSize: 17,
                marginBottom: 20,
            },
        })
    );
}