import React, { useEffect } from 'react';
import {Dimensions,ImageBackground,SafeAreaView,ScrollView,StyleSheet,Text,View,} from 'react-native';
import {Button,useTheme} from 'react-native-paper';
import AppIntroSlider from 'react-native-app-intro-slider';
import SignupComponent from '../components/Onboarding/Signup';
import HeardAboutUs from '../components/Onboarding/HeardAboutUs';
import ChooseTopGoals from '../components/Onboarding/ChooseTopGoals';
import ConnectAccount from '../components/Onboarding/ConnectAccount';
import FinalScreen from '../components/Onboarding/FinalScreen';
import { AppContext } from '../context';
import PaymentScren from '../components/Onboarding/PaymentScreen';

const Height = Dimensions.get('window').height;
const Width = Dimensions.get('window').width;

const slides = (navigation:any,sliderRef:any)=> [
    {
      key: 1,
      title: 'Welcome',
      text: ['Personalized debt elimination plans', 'Lower your bills', 'Improve your quality of life', 'Avoid debt settlement', 'Avoid debt consolidation', 'Avoid bankruptcy'],
      backgroundColor: 'white',
      image: require('../../assets/iconNoBackground.png'),
      resizeMode:'contain',
      height: 200,
      width: 200
    },
    {
        key: 2,
        title: 'Know where your money is going',
        text: ['Know how much you can afford to spend on bills','and where you can afford to save.'],
        backgroundColor: 'white',
        image: require('../../assets/onboarding/knowWhereMoney.png')
    },
    {
        key: 3,
        title: 'Put money back in your pocket',
        text: 'Pay off your debt in half the time and know your debt free date.',
        backgroundColor: 'white',
        image: require('../../assets/onboarding/putMoneyPocket.png')
    },
    // {
    //     key: 4,
    //     title: 'Reach your savings goals with ease',
    //     text: 'Create an emergency or vacation fund with spare change',
    //     backgroundColor: '#59b2ab',
    // },
    {
        key: 5,
        component: <SignupComponent navigation={navigation} next={()=>{sliderRef.current?.goToSlide(4, true)}} prev={()=>{sliderRef.current?.goToSlide(2, true)}}/>,
        title: 'Get Started',
        text: 'Description.\nSay something cool',
        backgroundColor: null,
    },
    {
        key: 6,
        component: <HeardAboutUs navigation={navigation} next={()=>{sliderRef.current?.goToSlide(5, true)}} prev={()=>{sliderRef.current?.goToSlide(3, true)}}/>,
        title: 'How did you first hear about us?',
        text: 'Description.\nSay something cool',
        backgroundColor: null,
    },
    {
        key: 7,
        component: <ChooseTopGoals navigation={navigation} next={()=>{sliderRef.current?.goToSlide(6, true)}} prev={()=>{sliderRef.current?.goToSlide(4, true)}}/>,
        title: 'Choose your top goals',
        text: 'Description.\nSay something cool',
        backgroundColor: null,
    },
    // {
    //     key: 8,
    //     component: <ConnectAccount navigation={navigation} next={()=>{sliderRef.current?.goToSlide(7, true)}} prev={()=>{sliderRef.current?.goToSlide(5, true)}}/>,
    //     title: 'Connect your accounts',
    //     text: '',
    //     backgroundColor: null,
    // },
    // {
    //     key: 9,
    //     component: ()=>{return(<PaymentScren navigation={navigation} next={()=>{sliderRef.current?.goToSlide(8, true)}} prev={()=>{sliderRef.current?.goToSlide(6, true)}}/>)},
    //     custom: true,
    //     title: "We're in this together",
    //     text: '',
    //     backgroundColor: '#dddee1',
    //     image: require('../../assets/onboarding/paymentscreen.png')
    // },
    {
        key: 10,
        component: <FinalScreen navigation={navigation}/>,
        title: 'Final Screen',
        text: '',
        backgroundColor: null,
    },
]

export default function Onboarding(props:any){ 
    const {navigation} = props;
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {state} = React.useContext(AppContext);
    const {user} = state;
    const sliderRef = React.useRef<any>();

    useEffect(()=>{
        if(user.id){
            sliderRef.current?.goToSlide(3, true)
        }
    },[sliderRef,user.id]);

    const onDone = ()=>{}

    const renderDoneButton = ()=>{}

    const renderItem = ({item}:any)=>{
        const Component = item.component;
        if(!item.custom && item.component){
            return (
                <View key={item.key} style={styles.slide}>
                    {Component}
                </View>
            )
        }
        const height = item.height?Height*0.4:item.custom?Height*0.35:Height*0.45;
        return(
            <View key={item.key} style={{...styles.slide,backgroundColor:item.backgroundColor??colors.background}}>
                <ImageBackground source={item.image} resizeMode={item.resizeMode??'cover'} style={{...styles.image,height:height,marginTop:item.resizeMode?25:0}}>
                    <Button 
                        labelStyle={{fontSize:Width>1000?40:20,}}
                        onPress={()=>navigation.navigate(user.id?'Settings':'Sign In')}
                        style={{alignSelf:'flex-end', justifyContent:'flex-end',marginTop:50,marginRight:20,width:Width>1000?150:100}}
                        uppercase={false}
                    >
                        { user.id? 'Profile' : 'Log in' }
                    </Button>
                </ImageBackground>

                <View style={{flex:1,height:200,paddingLeft:30,paddingRight:30}}>
                    <Text style={styles.title}>{item.title}</Text>
                    <ScrollView contentContainerStyle={{flexGrow:1}} showsVerticalScrollIndicator={false}>
                    {
                        item.custom?
                            Component()
                        :
                        <View style={{height:350}}>
                        {
                            Array.isArray(item.text)?
                                item.text.map((text:string,index:number)=>{
                                    return <Text key={index} style={styles.text}>{text}</Text>
                                })
                            :
                                <Text style={styles.text}>{item.text}</Text>
                        }
                        </View>
                    }
                    </ScrollView>
                </View>
            </View>
        );
    }

    const renderNextButton = (next:()=>void)=>{
        return(
            <View style={{alignSelf:'center'}}>
                <Button
                    contentStyle={{alignSelf:'center',flexDirection: 'row-reverse'}}
                    icon={'chevron-right'}
                    labelStyle={{fontSize:Width>1000?35:15}}
                    mode='contained'
                    onPress={next}
                    style={{borderRadius:8,height:Width>1000?100:50,justifyContent:'center',width:'45%'}}
                >
                    Get Started
                </Button>
            </View>
        );
    }

    const renderPagination = (activeIndex: number) => {
        const lastIndex = 3;
        const data = slides(navigation,null);
        return (
          <View style={styles.paginationContainer}>
            <SafeAreaView>
                {
                    activeIndex+1 <= lastIndex &&
                        //@ts-ignore
                        renderNextButton(() => sliderRef?.current?.goToSlide(activeIndex+1, true))
                }
            </SafeAreaView>
          </View>
        );
      };

    return(
        <View style={styles.container}>
            <AppIntroSlider  
                ref={(ref) => (sliderRef.current = ref)}
                data={slides(navigation,sliderRef)}
                onDone={onDone}
                //renderDoneButton={renderDoneButton}
                renderItem={renderItem}
                //renderNextButton={renderNextButton}
                renderPagination={renderPagination}
                scrollEnabled={false}
            />
        </View>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            buttonCircle:{
                alignItems: 'center',
                backgroundColor: colors.primary,
                borderRadius: 20,
                justifyContent: 'center',
                height:40,
                width:40,
            },
            container: {
                backgroundColor: colors.background,
                flexDirection: 'column',
                flex:1,
                height: '100%',
                marginTop: 0,
                //padding:30,
            },
            dot: {
                width: 8,
                height: 8,
                borderRadius: 5,
                marginHorizontal: 2,
                backgroundColor: colors.faddedPrimary,
            },
            dotCurrent:{
                width: 30,
                height: 8,
                borderRadius: 5,
                marginHorizontal: 4,
                backgroundColor: colors.primary,
            },
            image: {
                alignSelf: 'center',
                width: '100%',
                height: Height*0.45,
            },
            paginationContainer: {
                position: 'absolute',
                bottom: 10,
                left: 16,
                right: 16,
            },
            paginationDots: {
                height: 16,
                margin: 10,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            },
            slide: {
                flex: 1,
            },
            text: {
                color: colors.primary,
                fontSize:Width>1000?35:20,
                paddingLeft:Width>1000?50:0,
                paddingRight:Width>1000?50:0,
                paddingTop:20,
                textAlign:'center'
            },
            title: {
                fontSize: Width>1000?45:30,
                fontWeight: 'bold',
                marginTop: 20,
                textAlign: 'center'
            }
        })
    );
}