import React from 'react';
import {Dimensions, StyleSheet,useWindowDimensions,Text,View,} from 'react-native';
import {useTheme} from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Strings from '../constants/Strings';

const Width = Dimensions.get('window').width;

interface Props{
    invert?:boolean
    type: 'account' | 'bank' | 'bills' | 'budgets' | 'spendingGraph' | 'transactions'
}
export default function ListEmpty({invert=false,type}:Props){ 
    const {colors} = useTheme();
    const {height} = useWindowDimensions();
    const styles = Styles(colors,height);

    let Msg;
    switch(type) {
        case 'account':{
            Msg = 'No accounts have been added yet.'
            break;
        }
        case 'bank':{
            Msg = Strings.NO_BANKS;
            break;
        }
        case 'bills':{
            Msg = 'It appears you have not added any bills as yet.';
            break;
        }
        case 'budgets':{
            Msg = 'It appears you have not added any budgets as yet.';
            break;
        }
        case 'spendingGraph':{
            Msg = Strings.NO_SPENDING;
            break;
        }
        case 'transactions':{
            Msg = 'There are currently no transactions to display.';
            break;
        }
        default:{
            Msg = 'Nothing to see here.';
            break;
        }
    }
    
    return(
        <View style={styles.container}>
            <MaterialCommunityIcons 
                name={'information-outline'} 
                color={invert?colors.accent:colors.primary} 
                size={Width>1000?150:100} 
            />
            <Text 
                style={{
                    ...styles.text,
                    color:invert?colors.accent:colors.primary
                }}
            >
                {Msg}
            </Text>
        </View>
    )
}

const Styles = (colors:any,height:number)=>{
    return (
        StyleSheet.create({
            container: {
                alignItems: 'center',
                flex: 1,
                justifyContent:'center',
                padding:20,
                alignContent: 'center',
            },
            text:{
                fontSize: Width>1000?60:20,
                marginTop: 10,
                textAlign:'center',
            }
        })
    );
}