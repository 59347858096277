import { Formik } from 'formik';
import React from 'react';
import { Dimensions, Platform, ScrollView, StyleSheet,View,} from 'react-native';
import CurrencyInput from 'react-native-currency-input';
import {Button, Divider, List, Switch, Text, TextInput, useTheme} from 'react-native-paper';
import DateTimePicker from '@react-native-community/datetimepicker';
import { addBillSchema } from '../constants/Schemas';
import { dateFormatter } from '../constants/utils';
import { useBills } from '../hooks/useBills';

export default function BillsScreen({navigation}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {AddBill} = useBills({get:false});

    const [showDatePicker, setShowDatePicker] = React.useState(false);
    
    const InitialFormValues = {
        amount:0,
        autoPay: false,
        created:Date.now(),
        due:Date.now(),
        id:'',
        markPaid:true,
        name:'',
        overdue:false,
        owed:0,
        paid:0,
        repeats:'Monthly',
        uid:'',
        updated:Date.now()
    }
    
    return(
        <View style={styles.container}>
            <Formik
                initialValues={InitialFormValues}
                validationSchema={addBillSchema}
                onSubmit={(values,actions)=>{
                    if(!values.markPaid){
                        values.owed = values.amount;
                    }
                    AddBill(values)
                    .then(()=>{
                        navigation.goBack();
                    })
                    .catch(()=>{
                        actions.setFieldError('name','Unable to add bill at this time.')
                    })
                    .finally(()=>{
                        actions.setSubmitting(false);
                    })
                }}
            >
                {({handleChange,handleSubmit,setFieldValue,isSubmitting,values,errors})=>{
                    return(
                        <>
                        <ScrollView contentContainerStyle={{flexGrow:1}} showsVerticalScrollIndicator={false}>
                            <View style={{...styles.contentContainer,alignItems:'center'}}>
                                <CurrencyInput
                                    autoFocus={true}
                                    value={values.amount}
                                    onChangeValue={(value)=>{setFieldValue('amount',value)}}
                                    prefix={"$"}
                                    delimiter=","
                                    separator="."
                                    precision={2}
                                    onChangeText={(formattedValue) => {
                                    }}
                                    keyboardType={'decimal-pad'}
                                    style={styles.currencyInput}
                                />
                                <Text style={styles.errorText}>{errors.amount}</Text>
                            </View>

                            <View>
                                <View style={{flexDirection:'row',marginBottom:-40}}>
                                    <List.Icon icon={'file-document-outline'} />
                                    <View style={{width:'100%'}}>
                                        <List.Subheader style={{marginBottom:-20}}>Bill Name</List.Subheader>
                                        <TextInput
                                            mode={'flat'}
                                            style={styles.textInput}
                                            onChangeText={handleChange('name')}
                                            value={values.name}
                                            //label={'Merchant'}
                                            placeholder={'Add bill name'}
                                            error={errors.name?true:false}
                                            underlineColor={'transparent'}
                                        />
                                        <Text style={styles.errorText}>{errors.name}</Text>
                                    </View>    
                                </View>
                                <Divider />
                                <List.Item
                                    description={dateFormatter(values.due,'fullString')}
                                    left={props => <List.Icon {...props} icon="calendar" />}
                                    onPress={()=>setShowDatePicker(true)}
                                    title={'Due Date'}
                                />
                                <Divider />
                                <List.Item
                                    description={values.repeats}
                                    left={props => <List.Icon {...props} icon="sync" />}
                                    onPress={()=>{}}
                                    title={'Repeats'}
                                />
                                <Divider />
                                <List.Item
                                    description={
                                        values.markPaid?
                                            'This bill HAS been paid.'
                                        :
                                            'This bill HAS NOT been paid.'    
                                    }
                                    descriptionStyle={{fontSize:12}}
                                    left={props => <List.Icon {...props} icon="check" />}
                                    onPress={()=>{}}
                                    right={props => 
                                        <Switch 
                                            {...props} 
                                            color={colors.primary} 
                                            onValueChange={()=>{
                                                setFieldValue('markPaid',!values.markPaid);
                                            }} 
                                            value={values.markPaid} 
                                        />
                                    }
                                    title={'Mark as Paid?'}
                                />
                                <Divider />
                                <List.Item
                                    description={
                                        values.autoPay?
                                            'This bill WILL BE automatically marked as paid on due date.'
                                        :
                                            'This bill WILL NOT BE automatically marked as paid on due date.'    
                                    }
                                    descriptionStyle={{fontSize:12}}
                                    left={props => <List.Icon {...props} icon="cogs" />}
                                    onPress={()=>{}}
                                    right={props => <Switch {...props} color={colors.primary} onValueChange={()=>setFieldValue('autoPay',!values.autoPay)} value={values.autoPay} />}
                                    title={'Autopay on Due Date?'}
                                />
                            </View>

                            <Button 
                                mode={'contained'}
                                color={colors.primary}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                uppercase={false}
                                onPress={()=>handleSubmit()}
                                style={{bottom:0,position:'absolute',width:'100%'}}
                            >
                                Add Bill
                            </Button>

                        </ScrollView>
                        {showDatePicker && (
                        <View>
                            {Platform.OS === 'ios' && (
                            <Button onPress={() => setShowDatePicker(false)}>Close</Button>
                            )}
                        <DateTimePicker
                        testID="dateTimePicker"
                        value={new Date(values.due)}
                        mode={'date'}
                        display={Platform.OS === 'ios'?"spinner":"default"}
                        onChange={(event:any, selectedDate:Date | undefined)=>{
                            const currentDate = selectedDate?.getTime() ?? Date.now();
                            setShowDatePicker(Platform.OS === 'ios');
                            setFieldValue('due',currentDate);
                        }}
                        />
                        </View>
                    )}
                        </>
                    )
                }}
            </Formik>
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                borderColor: 'black',
                height: Height/2,
                marginBottom: 20,
            },
            currencyInput:{
                fontSize: Height*0.2,
                //height: '100%',
            },
            errorText: {
                color: colors.error,
                marginBottom: 40,
            },
            textInput: {
                backgroundColor: 'transparent',
                borderBottomColor: 'transparent',
                borderBottomWidth: 0,
                fontSize: 17,
                height: 50,
                width: '75%'
            },
        })
    );
}