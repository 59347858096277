import React from 'react';
const {useEffect,useRef,useState}=React;
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { Subscription } from 'expo-modules-core';
import Constants from 'expo-constants';

export interface androidConfigProps {
    name: string,
    config: {
        name: string,
        importance: 'DEFAULT' | 'LOW' | 'HIGH',
        vibrationPattern: number[],
        lightColor: string,
    }
}

export interface Props {
    androidConfig?:androidConfigProps[],
    register?: boolean //automatically register for push token
    shouldShowAlert?: boolean,
    shouldPlaySound?: boolean,
    shouldSetBadge?: boolean,
    //uploadToken: (token:string)=>Promise<void> //callback function used to upload token when generated
}
 
export const useGetPushTokens = ({
        androidConfig,
        register=true,
        shouldShowAlert = true,
        shouldPlaySound = false,
        shouldSetBadge = false,
    }
    :Props
)=>{
    const [expoPushToken, setExpoPushToken] = useState<string | null>();
    const [notification, setNotification] = useState<any>();
    const notificationListener = useRef<Subscription>();
    const responseListener = useRef<Subscription>();

    function configAndroidNotification(){
        if (Platform.OS === 'android') {
            if(!androidConfig){
                Notifications.setNotificationChannelAsync('default', {
                    name: 'default',
                    importance: Notifications.AndroidImportance.MAX,
                    vibrationPattern: [0, 250, 250, 250],
                    lightColor: '#FF231F7C',
                });
                return;
            }
            
            androidConfig.map(({name,config})=>{
                Notifications.setNotificationChannelAsync(name, {
                    name: config.name,
                    importance: Notifications.AndroidImportance[config.importance],
                    vibrationPattern: config.vibrationPattern,
                    lightColor: config.lightColor,
                });
            });
        }
    }

    function NotificationHandler(){
        Notifications.setNotificationHandler({
            handleNotification: async () => ({
              shouldShowAlert: shouldShowAlert,
              shouldPlaySound: shouldPlaySound,
              shouldSetBadge: shouldSetBadge,
            }),
          });
    }

    async function schedulePushNotification() {
        await Notifications.scheduleNotificationAsync({
          content: {
            title: "You've got mail! 📬",
            body: 'Here is the notification body',
            data: { data: 'goes here' },
          },
          trigger: { seconds: 2 },
        });
    }

    async function requestNotificatinPermissions(){
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                //alert('Notification permissions were not granted.');
                throw new Error('Notification permissions were not granted.');
                return;
            }
    }

    async function registerForPushNotificationsAsync() {
        let token;
        try{
            if(Platform.OS === 'web') return;
            await requestNotificatinPermissions();
            token = (await Notifications.getExpoPushTokenAsync({
                projectId: Constants.expoConfig?.extra?.eas.projectId,
            })).data;
            
            notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                setNotification(notification);
              });
          
            responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                Notifications.dismissAllNotificationsAsync();
              });
            configAndroidNotification();
        }
        catch(error:any){
            console.log('notificationError: ', error.message);
        }
        if(token){
            //uploadToken(token);
        }
        return token;
    }
 
    useEffect(()=>{
        NotificationHandler();

        if(register) registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
    },
    []);

    return {
        expoPushToken,
        notification,
        registerForPushNotificationsAsync
    }   
}