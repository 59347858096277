import * as React from 'react';
import { Dimensions, Image, StyleSheet, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Button, useTheme } from 'react-native-paper';
import { FirebaseAuth } from '../constants/Firebase';
import { AppContext } from '../context';
import Strings from '../constants/Strings';

const Width = Dimensions.get('window').width;


export default function UnverifiedScreen({navigation}:any) {
  const {colors} = useTheme();
  const {dispatch} = React.useContext(AppContext);
  
  const [loading,setLoading] = React.useState(false);
  const [error,setError] = React.useState('');

const resendVerification = ()=> {
    setLoading(true);
    const currentUser = FirebaseAuth.getAuth().currentUser;
    if(!currentUser) return;
    FirebaseAuth.sendEmailVerification(currentUser)
    .then(function() {
       // Email sent.
       alert('Verification email sent successfully');
       setLoading(false);
    }).catch(function(error) {
       // An error happened.
       setError(error.message);
       setLoading(false);
    });
}
 
  return (
    <KeyboardAwareScrollView contentContainerStyle={{}}>
      <View style={{alignItems: 'center',marginTop:'20%' }}>
        <Image source={Strings.LOGO_COLORED} style={styles.logo} />
      </View>
      <View style={{ alignItems: 'center',paddingLeft: Width>1000?20:'10%',paddingRight: Width>1000?20:'10%', }}>
        <Text style={{ ...styles.text, color: colors.placeholder }}>A verification email has been sent to your email address.</Text>
        <Text style={{ ...styles.text, color: colors.placeholder }}>Please verifiy your email to continue.</Text>

        <Text style={{ marginTop:'10%', color: colors.error }}>{error}</Text>
        <Button
          style={styles.button}
          labelStyle={{fontSize:Width>1000?30:15}}
          onPress={() => resendVerification()}
          mode='contained'
          disabled={loading}
          loading={loading}
          uppercase={false}
        >
          Resend Verification Email
        </Button>
        <Button
          style={{ borderRadius: 10 }}
          labelStyle={{fontSize:Width>1000?25:15}}
          onPress={()=>{
            FirebaseAuth.signOut(FirebaseAuth.getAuth()).then().catch();
          }}
          disabled={loading}
        >
          Go Back
        </Button>
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    margin: 25,
  },
  button: {
    borderRadius: 10,
    height: Width>1000?75:55,
    justifyContent: 'space-around',
    marginTop: '4%',
  },
  logo: {
    alignSelf:'center',
    height: Width>1000?350:200,
    marginBottom: '10%',
    resizeMode: 'contain',
    width: Width>1000?350:200,
  },
  signupContainer: {
    marginTop: '10%',
  },
  signupButton: {
    marginTop: '2%',
    height:45,
    justifyContent: 'space-around',
  },
  text: {
    fontSize: Width>1000?38:18,
    marginBottom: 15,
  },
});