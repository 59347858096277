//TODO: refactor page
import React from 'react';
import { Text, View, ScrollView, RefreshControl, TouchableNativeFeedback, Dimensions, StyleSheet } from 'react-native';
import { Badge, Button, Card, Divider, IconButton, List, Switch, ToggleButton, useTheme } from 'react-native-paper';
import { calculatePaymentPeriod, currencyFormatter, dateFormatter } from '../constants/utils';
import { useSpendingCalculator } from '../hooks/useSpendingCalculator';
import { Loading } from '../components/LoadingModal';
import { Slider } from '@miblanchard/react-native-slider';
import { Col, Row, Grid } from "react-native-easy-grid";
import RBSheet from "react-native-raw-bottom-sheet";
import { LiabilitiesProp } from '../constants/Types';
import UpdateLiabilityBottomSheet from '../components/UpdateLiabilityBottomSheet';
import ErrorScreen from '../components/ErrorScreen';
import ListEmpty from '../components/ListEmpty';
import dayjs from 'dayjs';
import { useCreditUtilization } from '../hooks/useCreditUtilization';
import CreditUtilizationGraph from '../components/CreditUtilizationGraph';
import { useUserStats } from '../hooks/useUserStats';

const HEIGHT = Dimensions.get('window').height;
const WIDTH = Dimensions.get('window').width;
const DefaultIncome = 200000;

const SPENDING_TOGGLE_BUTTONS = [
    { key: 50, label: '50%', value: '50%' },
    { key: 65, label: '65%', value: '65%' },
    { key: 75, label: '75%', value: '75%' },
];

export function Optimizer(props: any) {
    const userId = props?.route?.params?.uid??null;
    const { colors } = useTheme();
    const styles = Styles(colors);
    const RBRef = React.useRef<RBSheet | null>();
    const newLib = ( {
        account:{bankName:'new',name:'new',},
        amount_owed:0,
        interestRate:0,
        next_payment_due_date: dateFormatter(Date.now(),'dashFormattedString'),
        paymentAmount:0,
        wasGenerated:true,
        manuallyAdded:true,

    } as LiabilitiesProp);
    const [selectedLiab, setSelectedLiab] = React.useState<LiabilitiesProp | false>(false);
    const { errMessage, MaxIncome, MinIncome, liabilities, liabTotals, loadingPlan, modalIncome, months, payPlan, percentReductionTime, savingAmount, share, Step, totals, totalWithoutPlan, toggleVal, useBlizzard, calculateToCreditors, setModalIncome, setToggleVal, setUseBlizzard, updateIncome } = useSpendingCalculator('true',userId);
    const {totalCreditUtilization,getCreditUtilization} = useCreditUtilization();
    const {totalSavings,getTotalSavings} = useUserStats();

    React.useEffect(()=>{getTotalSavings()},[]);
    React.useEffect(() => {
        if ( isNaN(percentReductionTime) || percentReductionTime <= 0){
            props.navigation.setOptions({headerRight: () => ( <Text/>)});
            return;
        }
        props.navigation.setOptions({
            headerRight: () => {
                if ( isNaN(percentReductionTime) || percentReductionTime <= 0)
                {
                    return <View/>
                }
                return(
                <View style={{ alignItems: 'flex-end', marginRight: 15 }}>
                    <Text style={{ color: colors.primary, textAlign: 'right', fontSize: WIDTH > 500 ? 18 : 14, fontWeight: 'bold' }}>
                        {percentReductionTime + '% Less Time in Debt'}
                    </Text>
                    {savingAmount>0? 
                        <Text style={{color: colors.primary, textAlign: 'right', fontSize: WIDTH > 500 ? 18 : 14, fontWeight: 'bold'}}>
                            {'Cost Savings of '+currencyFormatter(savingAmount)}
                        </Text> 
                    : 
                        <Text/>
                    }
                </View>
            )},
            headerTitleAlign: 'left'
        });
    }, [percentReductionTime, savingAmount]);

    const debounceOnChange = React.useCallback(debounce(updateIncome, 600), [modalIncome]);

    function isBillPassedDue(dateDue:string){
        const diff = dayjs(dateDue).diff(dayjs(),'day');
        return (diff <= 0);
    }

    function debounce(func: any, wait: number) {
        let timeout: any;
        //@ts-ignore
        return function (...args) {
            //@ts-ignore
            const context = this;
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    function RenderPlan2({ item, index }: any) {
        return (
            <Row key={index}>
                <Col style={styles.col}>
                    <Text style={{ fontSize: 14 }}>{months[index]}</Text>
                </Col>
                {item.map((account: any, index: number) => RenderAccount2(account, index))}
            </Row>
        );
    }

    function RenderAccount2(acc: any, index: number) {
        if (acc.noShow == 'true' || acc.curPaid <= 0)
            return (
                <Col key={index} style={styles.col}><Text>{''}</Text></Col>
            );
        let percentage = 0;
        if (acc?.account?.balances?.current) {
            percentage = Number(Number(((100 * (acc.paid ?? 0)) / (acc.account?.balances?.current * 100))).toFixed(0));
        }
        const svgProgress = 100 - percentage;
        
        if (isNaN(percentage) || percentage <= 0) { percentage = 0; }
        let progressColor = getProgressColour(percentage);
        let fontColor = 'black';

        let width = 100; //percentage > 100? 100 : percentage;
        width = width <= 0 ? 100 : width;
        const widthPercent = width + '%';
        return (
            <Col key={index} style={{ ...styles.col }}>
                <View
                //@ts-ignore
                    style={{
                        height: '100%',
                        width: widthPercent,
                        backgroundColor: progressColor,
                        alignSelf: 'flex-start',
                        marginTop: -20
                    }} />
                <Text style={{
                    marginTop: -35,
                    zIndex: 10,
                    color: fontColor
                }}>
                    {currencyFormatter(acc.curPaid ?? 0)}
                </Text>
            </Col>
        );
    }
    function RenderTotals() {
        return (
            <Row>
                <Col style={styles.col}>
                    <Text style={{ fontSize: 14 }}>{'TOTALS'}</Text>
                </Col>
                {liabTotals.map((account: any, index: number) => (
                    <Col key={index} style={styles.col}>
                        <Text>{account?.amount > 0 ? currencyFormatter(account.amount ?? 0) : ''}</Text>
                    </Col>
                ))}
            </Row>
        );
    }

    function closeModal() {
        RBRef.current?.close();
        setSelectedLiab(false);
        calculateToCreditors(modalIncome ?? 0, (toggleVal), true);
        getCreditUtilization();
    }

    function onRefresh(){
        calculateToCreditors(modalIncome ?? DefaultIncome, toggleVal, true);
        getCreditUtilization();
    }

    return (
        <>
            <ScrollView
                refreshControl={<RefreshControl refreshing={false} onRefresh={() => {onRefresh()}} />}
                showsHorizontalScrollIndicator={false}
                stickyHeaderIndices={[2]}
                style={styles.container}
            >
                <View style={{
                    maxHeight: HEIGHT / 2.5,
                    margin: 0,
                }}>
                    <Card>
                        {/*@ts-ignore*/}
                        <List.Item
                            description={currencyFormatter(modalIncome ?? DefaultIncome)}
                            descriptionStyle={{ color: colors.primary, fontSize:25, fontWeight: 'bold' }}
                            title={"Monthly Income"}
                            //title={dayjs().subtract(1, 'month').format('MMMM') + "'s Income"}
                            titleStyle={{ fontSize: WIDTH>500?20: 13, marginTop: -17 }}
                            right={(props) => totalWithoutPlan ?
                                <View {...props} style={{ alignItems: 'flex-end', width: '50%' }}>
                                    <Text style={{color: colors.primary,fontSize:WIDTH>500?18: 12,}}>
                                        <Text style={{ fontWeight: 'bold' }}>{totalWithoutPlan}</Text>
                                        {' Payments in Total'}
                                    </Text>
                                    <Text style={{color:colors.primary, fontSize:WIDTH>500?18: 12,textAlign: 'right', overflow: 'hidden' }} numberOfLines={4}>
                                        {'Debt free in '}
                                        <Text style={{ color: colors.primary, fontWeight: 'bold' }}>{calculatePaymentPeriod(totalWithoutPlan)}</Text>
                                        {WIDTH > 500 ?
                                            <>
                                                <Text style={{ color: colors.primary, fontWeight: 'bold' }}>{'\nwithout '}</Text>
                                                <Text>Optimization</Text>
                                            </>
                                            :
                                            <>
                                                <Text style={{ color: colors.primary, fontWeight: 'bold' }}>{' without '}</Text>
                                                <Text>Optimization</Text>
                                            </>}
                                    </Text>
                                </View>
                                :
                                <View />} />

                        <View style={{ alignItems: 'center', alignContent: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            {/*@ts-ignore*/}
                            <IconButton
                                icon={'minus'}
                                onPress={() => {
                                    const newVal = Number(modalIncome) - (Step * 100);
                                    if (newVal > (MaxIncome * 100))
                                        return;
                                    if (newVal < (MinIncome * 100))
                                        return;
                                    setModalIncome(newVal);
                                    debounceOnChange(newVal / 100, toggleVal);
                                }} />
                            <Slider
                                containerStyle={{ width: '75%' }}
                                //disabled={loadingPlan}
                                maximumValue={MaxIncome}
                                minimumValue={MinIncome}
                                onSlidingComplete={(value) => {
                                    debounceOnChange(Number(value), toggleVal);
                                }}
                                onValueChange={(value) => {
                                    setModalIncome(Number(value) * 100);
                                }}
                                step={Step}
                                thumbTintColor={colors.primary}
                                value={modalIncome ? modalIncome / 100 : DefaultIncome / 100} />
                            {/*@ts-ignore*/}
                            <IconButton
                                icon={'plus'}
                                onPress={() => {
                                    const newVal = Number(modalIncome) + (Step * 100);
                                    if (newVal > (MaxIncome * 100))
                                        return;
                                    if (newVal < (MinIncome * 100))
                                        return;
                                    setModalIncome(newVal);
                                    debounceOnChange(newVal / 100, toggleVal);
                                }} />
                        </View>

                        <Card.Title
                            title={'Select Percentage:'}
                            titleStyle={{ color: colors.primary, fontSize: WIDTH>500?20: 13 }} />
                        <Card.Content style={{}}>
                            <View>
                                <ToggleButton.Row
                                    onValueChange={value => setToggleVal(value)}
                                    value={toggleVal??""}
                                    style={styles.toggleBtn}
                                >
                                    {SPENDING_TOGGLE_BUTTONS.map((item, index) => {
                                        return (
                                            <ToggleButton
                                                key={item.key}
                                                style={styles.toggleBtnBtn}
                                                value={item.value}
                                                icon={() => <View>
                                                    <Text style={styles.toggleBtnText}>{item.label}</Text>
                                                </View>}
                                                disabled={loadingPlan} />
                                        );
                                    })}
                                </ToggleButton.Row>
                            </View>
                        </Card.Content>
                    </Card>
                </View>
                <View style={{backgroundColor:colors.background,}}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: colors.background }}>
                        {/*@ts-ignore*/}
                        <List.Item
                            description={currencyFormatter(Number(share[0]))}
                            descriptionStyle={{ color: colors.primary, fontSize: 25, fontWeight: 'bold' }}
                            title={'Debt repayment total:(' + toggleVal + ')'}
                            titleStyle={{ fontSize: WIDTH>500?20: 13 }}
                            style={{ width: WIDTH>500?300:195 }} />
                        {/*@ts-ignore*/}
                        <List.Item
                            title={'Spending Cash'}
                            titleStyle={{ fontSize:WIDTH>500?20: 13,textAlign: 'right' }}
                            description={currencyFormatter(Number(share[1]))}
                            descriptionStyle={{ color: colors.primary, fontSize: 25, fontWeight: 'bold', textAlign: 'right' }}
                            style={{ width: WIDTH>400?160:150, }} />
                    </View>

                    <View>
                        <Card.Title
                            title={'Select a Repayment Method'}
                            titleStyle={{fontSize:WIDTH>500?30: 17,fontWeight:'bold',textAlign:'center'}}
                            style={{ backgroundColor: colors.background }} 
                        />
                        <View style={{ flexDirection: 'row', justifyContent:WIDTH>500?'space-evenly':'space-evenly', backgroundColor: colors.background, width:WIDTH }}>
                            {/* @ts-ignore */}
                            <List.Item 
                                title={'Snowball Method'}
                                titleStyle={{ fontSize:WIDTH>500?20: 13}}
                                style={{width:WIDTH>500?200:150}}
                            />
                            
                            <Switch 
                                ios_backgroundColor={colors.primary} 
                                disabled={loadingPlan} 
                                onValueChange={() => {setUseBlizzard(!useBlizzard)}}
                                trackColor={{true: colors.primary, false: colors.primary}}
                                value={useBlizzard} 
                            />
                            
                            {/* @ts-ignore */}
                            <List.Item 
                                title={'Blizzard Method'}
                                titleStyle={{ fontSize:WIDTH>500?20: 13}}
                                style={{width:WIDTH>500?200:150}}
                            />
                        </View>
                        
                    </View>

                    {!loadingPlan &&
                    <View style={{
                        alignItems:'center',
                        flexDirection:'column',
                        justifyContent:'center',
                        marginTop:20,
                        marginBottom: 0,
                        paddingHorizontal:WIDTH > 500 ? 0 :20
                    }}
                    >
                        <Text 
                            style={{
                                color:colors.primary,
                                fontSize: WIDTH > 500 ? 25 : 15,
                            }}
                        >
                            {`On average our users are savings ${currencyFormatter(totalSavings.totalSavings,true,false)}`}
                        </Text>
                        <Text 
                            style={{
                                color:colors.primary,
                                fontSize: WIDTH > 500 ? 25 : 15,
                            }}
                        >
                            {`and reducing their debt by ${calculatePaymentPeriod(totalSavings.totalMonths,true,)}...`}
                        </Text>
                    </View>}

                </View>
                <View style={{backgroundColor:colors.background,}}>
                <View
                        style={{
                            backgroundColor:colors.background,
                            flexDirection:'row',
                            justifyContent:'space-between',
                        }}
                    >
                        <Card.Title
                            title={payPlan.length + ' Payments in total'}
                            titleStyle={{fontSize:WIDTH>500?30: 17,fontWeight:'bold'}}
                            subtitle={<Text>{'Debt free in ' + calculatePaymentPeriod(payPlan.length)}</Text>}
                            subtitleStyle={{ color: colors.primary, fontSize:WIDTH>500?18: 12 }}
                            subtitleNumberOfLines={3}
                            style={{ backgroundColor: colors.background, width:WIDTH>1000?'65%':WIDTH>600?WIDTH/2:WIDTH/2.4 }} 
                        />
                        
                        <CreditUtilizationGraph getProgressColour={getProgressColour} totalCreditUtilization={totalCreditUtilization} />
                    </View>

                    <View
                        style={{
                            flexDirection:'row',
                            justifyContent:'space-between'
                        }}
                    >
                        <Button
                            mode='text'
                            onPress={()=>{
                                setSelectedLiab(newLib);
                                RBRef.current?.open();
                            }}
                            style={{
                                backgroundColor:colors.background,
                                height:40,
                                width:WIDTH/3
                            }}
                            contentStyle={{justifyContent:'flex-start'}}
                            uppercase={false}
                        >
                            {WIDTH<=500?'Add Account':'Add New Account'}
                        </Button>

                        <View style={{ alignItems: 'flex-end', backgroundColor: colors.background }}>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', width: WIDTH > 500 ? 440 : 250, marginBottom: 10 }}>
                                <View style={{ flex: 1, height: 20 }}>
                                    <View style={{ backgroundColor: "#F56565", height: 15 }} />
                                    <Text style={{ fontSize: 12, textAlign: 'center' }}>100 - 61%</Text>
                                </View>
                                <View style={{ flex: 1, height: 20 }}>
                                    <View style={{ backgroundColor: "#FFFF00", height: 15 }} />
                                    <Text style={{ fontSize: 12, textAlign: 'center' }}>60 - 41%</Text>
                                </View>
                                <View style={{ flex: 1, height: 20 }}>
                                    <View style={{ backgroundColor: "#92D050", height: 15 }} />
                                    <Text style={{ fontSize: 12, textAlign: 'center' }}>40 - 21%</Text>
                                </View>
                                <View style={{ flex: 1, height: 20 }}>
                                    <View style={{ backgroundColor: "#00B050", height: 15 }} />
                                    <Text style={{ fontSize: 12, textAlign: 'center' }}>20 - 0%</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <Divider style={styles.divider} />
                <View style={{ flex: 1 }}>
                    {
                        loadingPlan ?
                            <View style={styles.loading}>
                                <Loading visible={true} />
                                <Text style={{color:colors.primary,fontSize:HEIGHT*0.03,fontWeight:'bold',marginTop:20}}>{'Standby as We Build Your Legacy...'}</Text>
                            </View>
                        :
                        !errMessage && payPlan.length <= 0?
                                            <Row>
                                                <Col style={{...styles.col,width:WIDTH/1.1,height:200,borderColor:'transparent',marginTop:15}}>
                                                    <ListEmpty type={'bills'} />
                                                </Col>
                                            </Row>
                                        :
                            <ScrollView horizontal style={{ flex: 1 }}>
                                <Grid>
                                    <Row>
                                        <Col style={{ ...styles.colHeader, justifyContent: 'center' }}><Text style={styles.colHeaderText}>{'Month'}</Text></Col>
                                        {liabilities.map((item: LiabilitiesProp, index) => <TouchableNativeFeedback key={index} onPress={() => { setSelectedLiab(item); RBRef.current?.open(); }}>
                                            <Col style={styles.colHeader}>
                                                <Badge 
                                                    visible={
                                                        !item.interestRate ||
                                                         ( (item.manuallyAdded??false) && isBillPassedDue(item?.next_payment_due_date??'') ) ||
                                                         (item.account.type == 'credit' && !item.creditLimit)
                                                    } 
                                                    size={10} 
                                                />
                                                <Text numberOfLines={2} style={styles.colHeaderText}>{item.account.bankName}</Text>
                                            </Col>
                                        </TouchableNativeFeedback>
                                        )}
                                        <Col style={styles.colHeader}><Text style={styles.colHeaderText}>{'Debit Obligation Total'}</Text></Col>
                                        <Col style={{ ...styles.colHeader, justifyContent: 'center' }}><Text style={styles.colHeaderText}>{'Payment Total'}</Text></Col>
                                    </Row>
                                    <Divider />
                                    {
                                        errMessage ?
                                            <Row>
                                            <Col style={{...styles.col,width:WIDTH/1.1,height:200,borderColor:'transparent',marginTop:15}}>
                                                <ErrorScreen message={errMessage} />
                                            </Col>
                                            </Row>
                                        :
                                        <>
                                        {payPlan.map((item, index) => RenderPlan2({ item, index }))}
                                        <RenderTotals />
                                        </>
                                    }
                                </Grid>
                            </ScrollView>}
                </View>
            </ScrollView>
            {/*@ts-ignore*/}
            <RBSheet
                ref={ref => { RBRef.current = ref; }}
                closeOnDragDown={true}
                height={HEIGHT>1000?500:320}
                openDuration={250}
                onClose={() => {
                    setSelectedLiab(false);
                }}
                customStyles={{
                    container: {
                        borderRadius: 20,
                        padding: 20
                    }
                }}
            >
                {selectedLiab ?
                    <UpdateLiabilityBottomSheet liability={selectedLiab} closeModal={closeModal} />
                    :
                    <View />}
            </RBSheet>
        </>
    );
}

const Styles = (colors: any) => {
    return (
        StyleSheet.create({
            button: {
                alignSelf: 'center',
                borderRadius: 10,
                height: WIDTH > 1000 ? 85 : 55,
                justifyContent: 'space-around',
                marginTop: 25,
                //width: '50%'
            },
            container: {
                backgroundColor: colors.background,
                flex: 1,
                //padding: 10,
                marginTop: 10,
                paddingLeft: 10,
                paddingRight: 10
            },
            container2: {
                alignContent: 'center',
                alignSelf: 'center',
                backgroundColor: colors.background,
                flexGrow: 1,
                padding: WIDTH > 1000 ? 50 : 20,
                paddingLeft: WIDTH > 1000 ? 80 : 50,
                paddingRight: WIDTH > 1000 ? 80 : 50,
            },
            card: {
                marginBottom: 10,
                maxHeight: '50%',
            },
            col: {
                alignContent: 'center',
                alignItems: 'center',
                borderColor: colors.placeholder,
                borderWidth: 0.5,
                height: 60,
                justifyContent: 'center',
                width: 125,
            },
            colHeader: {
                borderColor: colors.placeholder,
                borderWidth: 0.5,
                height: 60,
                width: 125,
            },
            colHeaderText: {
                color: colors.primary,
                fontWeight: 'bold',
                paddingLeft: 5,
                paddingRight: 5,
                textAlign: 'center'
            },
            divider: {
                backgroundColor: colors.backdrop,
                marginLeft: -20,
                marginTop: 10,
                width: '110%'
            },
            image: {
                alignSelf: 'center',
                width: '100%',
                height: HEIGHT * 0.45,
            },
            loading:{
                alignItems:'center',
                height:WIDTH > 1000? (HEIGHT-(HEIGHT / 1.2)) : HEIGHT*0.20,
                justifyContent:'center',
            },
            row: {

            },
            text: {
                color: colors.placeholder,
                fontSize: WIDTH > 1000 ? 30 : 15,
                paddingTop: 20,
                textAlign: 'center'
            },
            toggleBtn: { display: 'flex', justifyContent: 'center' },
            toggleBtnBtn: { width: WIDTH / 3, height: 75 },
            toggleBtnText: {
                color: colors.primary,
                fontSize: 20,
                fontWeight: 'bold'
            }
        })
    );
}

function getProgressColour(percentage:number):string{
    let progressColor = '#F56565';
        if (isNaN(percentage) || percentage == 0) {
            percentage = 0;
            progressColor = 'transparent';
        }
        let fontColor = 'black';
        if (percentage > 40)
            progressColor = '#FFFF00';
        if (percentage > 60)
            progressColor = '#92D050';
        if (percentage > 80) {
            progressColor = '#00B050';
            //fontColor = colors.accent;
        }
        return progressColor;
}
