import React from 'react';
import { Dimensions, StyleSheet, Text, View, } from 'react-native';
import { Card, Divider, List, useTheme } from 'react-native-paper';
//@ts-ignore
import { AreaChart, BarChart, Grid, LineChart as LineChart2, XAxis, YAxis } from 'react-native-svg-charts';
import { useFundsSummary } from '../hooks/useFundsSummary';
import dayjs from 'dayjs';
import ErrorScreen from './ErrorScreen';
import { abbrNum, currencyFormatter } from '../constants/utils';

interface propsType {
    year?:string
}

function MonthlyLineGraph(props:propsType) {
    const Year = props.year??dayjs().year().toString()
    const { colors } = useTheme();
    const styles = Styles(colors);
    const {currentSpending,error,errorMessage,loading,summary} = useFundsSummary(Year);

    const CashflowChart = ()=>{
        return (
            <>
            <View style={{ flexDirection: 'row', height: 200 }}>
                <YAxis
                        data={ summary.values }
                        //yAccessor={({ index }:any) => index}
                        formatLabel={(value:any,index:number) => abbrNum(value/100)}
                        style={{  }}
                        contentInset={{ top: 10, bottom: 10 }}
                        svg={{ fontSize: 12, fill: 'grey' }}
                        spacing={0.2}
                />
                <BarChart
                    style={ { flex: 1, marginLeft: 8 } }
                    data={ [
                        {
                            data: summary.income,
                            svg: {
                                fill: colors.primary,
                            },
                        },
                        {
                            data: summary.expense,
                            svg: {
                                fill: colors.error,
                            },
                        },
                        
                    ] }
                    yAccessor={({ item }:{item:any}) => item.value}
                    svg={{
                        fill: 'green',
                    }}
                    contentInset={ { top: 10, bottom: 10 } }
                >
                    <Grid />
                    {/* <Labels/> */}
                </BarChart>
            </View>
            <XAxis
                style={{ marginTop:4 }}
                data={summary.expense}
                formatLabel={(value:any) => summary.expense[ value ].label}
                contentInset={{ left: 58, right: 18 }}
                svg={{ fontSize: 12, fill: colors.primary, }}
            />
            </>
        );
    }

    const Summary = ()=>{
        return(
            <Card style={{marginTop:20}}>
                {/*@ts-ignore*/}
                <List.Item
                    left={(props) => (<List.Icon {...props} style={{alignItems:'center',flexDirection:'row'}} icon="currency-usd" />)}
                    right={(props) => (
                        <View {...props} style={{alignItems:'center',flexDirection:'row'}}>
                            {/*@ts-ignore*/}
                            <Text>{currencyFormatter(summary.totalIncome)}</Text>
                            {/* <List.Icon icon="chevron-right" /> */}
                        </View> 
                    )}
                    title={'Earned so far in '+Year}
                />
                <Divider />
                {/*@ts-ignore*/}
                <List.Item
                    left={(props) => (<List.Icon {...props} style={{alignItems:'center',flexDirection:'row'}} color={colors.error} icon="currency-usd-off" />)}
                    right={(props) => (
                        <View {...props} style={{alignItems:'center',flexDirection:'row'}}>
                            {/*@ts-ignore*/}
                            <Text style={{color:colors.error}}>{currencyFormatter(summary.totalExpense)}</Text>
                            {/* <List.Icon icon="chevron-right" /> */}
                        </View> 
                    )}
                    title={'Spent so far in '+Year}
                />
                <Divider />
                {/*@ts-ignore*/}
                <List.Item
                    description={summary.spendDiff}
                    descriptionStyle={{fontSize:12}}
                    left={(props) => (<List.Icon {...props} color={'green'} style={{alignItems:'center',flexDirection:'row'}} icon="cash" />)}
                    right={(props) => (
                        <View {...props} style={{alignItems:'center',flexDirection:'row'}}>
                            {/*@ts-ignore*/}
                            <Text style={{}}>{currencyFormatter(currentSpending)}</Text>
                            {/* <List.Icon icon="chevron-right" /> */}
                        </View> 
                    )}
                    title={'Current Spending'}
                    titleStyle={{fontSize:13}}
                />
            </Card>
        );
    }

    // if(loading || totalLoad) {
    //     return (
    //         <Card style={styles.contentContainer}>
    //             <Card.Content>
    //                 <Loading visible={true} />
    //             </Card.Content>
    //         </Card>
    //     )
    // }

    if(error){
        return (
            <Card style={styles.contentContainer}>
                <ErrorScreen message={errorMessage} />
            </Card>
        )
    }

    return (
        <Card style={styles.contentContainer}>
            <Card.Title 
                right={(props) => <View {...props}>
                    <View style={{flexDirection:'row',}}>
                        <Text style={{color:colors.primary,fontSize:50,fontWeight:'bold',lineHeight:25}}> . </Text>
                        <Text style={{color:colors.primary}}> Income</Text>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <Text style={{color:colors.error,fontSize:50,fontWeight:'bold',lineHeight:25}}> . </Text>
                        <Text style={{color:colors.error}}> Expense</Text>
                    </View>
                </View>
                }
                title={Year+' Cashflow'} 
                titleStyle={styles.cardTitle} 
            />
            {<CashflowChart/>}
            <Summary />
        </Card>
    )
}

export default React.memo(MonthlyLineGraph);

const Styles = (colors: any) => {
    const Height = Dimensions.get('window').height*0.3;
    return (
        StyleSheet.create({
            cardTitle:{
                fontSize: 18,
                textAlign:'center',
                marginLeft:55
            },
            contentContainer:{
                borderColor: '#F8F0E3',
                borderWidth: 1,
                minHeight: Height,
                marginBottom: 10,
                padding:10
            },
        })
    );
}