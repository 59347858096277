import React from 'react';

export function PromotionalVideo() {
  return (
    <>{/*=================================
                =            Promo Video            =
                ==================================*/}
      <section className="section promo-video bg-3 overlay">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Promo Video */}
              <div className="video">
                <video width="900" height="400" controls>
                  <source src="https://firebasestorage.googleapis.com/v0/b/cenedex-legacy.appspot.com/o/video%2FLegacy_Financial__mwsmithjr__v8.mp4?alt=media&token=0bebd12d-71e6-4cd5-a6d1-15484c858896" type="video/mp4"/>
                  Your browser does not support the video tag.
                </video> 
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====  End of Promo Video  ====*/}</>
  );
}
