import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import {  ActivityIndicator, Modal, Portal, useTheme } from 'react-native-paper';
import Strings from '../constants/Strings';
import ProgressBar from './ProgressBar';

interface LoadingModalType {
  message?:string  
  visible: boolean
}

export function LoadingModal({message,visible}:LoadingModalType){ 
    const { colors } = useTheme();
    const containerStyle = {backgroundColor: message?'white':'transparent', width:'50%', padding: 20};
    return(
        <Portal>
        <Modal 
            visible={visible} 
            style={{flex:1,justifyContent:'center',alignItems:'center'}} 
            contentContainerStyle={containerStyle}
        >
          <ActivityIndicator animating={true} color={colors.primary} size={'large'} />
          <Text>{message}</Text>
        </Modal>
      </Portal>
    )
}

interface LoadingProps{
  size?: 'small' | 'large'
  style?: StyleProp<ViewStyle>
  visible: boolean
}
export function Loading({size='large',style,visible}:LoadingProps){ 
  const { colors } = useTheme();
  return(
    <ActivityIndicator style={style} animating={visible} color={colors.primary} size={size} />
  )
}

export function AnimatedProgressBar(){
  const { colors } = useTheme();
  
  return(
    <View
      style={{
        backgroundColor: colors.primary,
        bottom:0,
        height:25,
        position:'absolute',
        width:'100%'
      }}
    >
      <ProgressBar indeterminate backgroundColor={colors.primary} />
      <Text 
        style={{
          color:colors.accent,
          fontSize:15,
          //marginBottom:5,
          paddingLeft:20,
          paddingTop:5
        }}
      >
        {Strings.LOADING_TRANSACTION_MESSAGE}
      </Text>
      <ProgressBar indeterminate backgroundColor={colors.primary} />
    </View>
  );
}