import React from 'react';
import { useUserStats } from '../../hooks/useUserStats';
import { abbrNum, calculatePaymentPeriod, currencyFormatter } from '../../constants/utils';

export function Counter() {
  const {totalSavings,getTotalSavings} = useUserStats();

  React.useEffect(()=>{getTotalSavings()},[])
  
  return (
    <section className="section counter bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-md-4 mb-2 mb-lg-0">
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
              <div className="counter-item">
                <p>we have</p>
                {/* Counter Number */}
                <h3><span className="count" data-count={29}>{abbrNum(totalSavings.totalUsers)}</span></h3>
                {/* Counter Name */}
                <p>Users</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
              <div className="counter-item">
                <p>saving them</p>
                {/* Counter Number */}
                <h3><span className="count" data-count={200}>{currencyFormatter(totalSavings.totalSavings,true,false)}</span></h3>
                {/* Counter Name */}
                <p>on average</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
              <div className="counter-item">
                <p>while also saving them</p>
                {/* Counter Number */}
                <h3><span className="count" data-count={60}>{calculatePaymentPeriod(totalSavings.totalMonths,true)}</span></h3>
                {/* Counter Name */}
                <p>of debit</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
