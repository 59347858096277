import React from 'react';
import {Dimensions, FlatList, RefreshControl, StyleSheet,View,} from 'react-native';
import {Button,Card,Divider,List,Text,useTheme} from 'react-native-paper';
import { TransferType,useTransfers } from '../hooks/useTransfers';
import { Loading } from '../components/LoadingModal';
import ListEmpty from '../components/ListEmpty';
import TransactionListItem from '../components/TransactionListItem';
import { useAccounts } from '../hooks/useAccounts';
import ErrorScreen from '../components/ErrorScreen';

const WIDTH = Dimensions.get('window').width;

export default function ValidateTransfersScreen(props:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {error,errorMessage,loading,loadingUpdate,transfers,GetTransfers,UpdateTransfer} = useTransfers();
    const {UpdateCashTotals} = useAccounts(false);

    React.useEffect(() => {
        const unsubscribe = props.navigation.addListener('blur', () => {
            UpdateCashTotals();
        });
    
        return unsubscribe;
    }, [props.navigation]);

    function TransferComponent({item}:{item:TransferType}){
        return(
            <List.Section>
                {/* @ts-ignore */}
                <List.Subheader style={{fontSize: WIDTH > 1000 ? 25 : 15,}}>
                    Was this an internal transfer?
                </List.Subheader>
                <TransactionListItem item={item.trans1} navigation={props.navigation} />
                <TransactionListItem item={item.trans2} navigation={props.navigation} />

                <Card.Actions style={{justifyContent:'flex-end', marginBottom:20,marginTop:-20}}>
                    <Button
                        disabled={loadingUpdate} 
                        loading={loadingUpdate}
                        mode={'contained'}
                        onPress={()=>{UpdateTransfer(item.id,'yes')}}
                        style={styles.button}
                    >
                        Yes
                    </Button>
                    <Button 
                        color={colors.error}
                        disabled={loadingUpdate} 
                        loading={loadingUpdate}
                        mode={'outlined'}
                        onPress={()=>{UpdateTransfer(item.id,'no')}}
                        style={styles.button}
                    >
                        No
                    </Button>
                </Card.Actions>
                <Divider/>
            </List.Section>
        );
    }

    return(
        <View style={styles.container}>
            <Text style={{color:colors.primary,fontSize: WIDTH > 1000 ? 30 : 18,padding:15}}>
                Legacy suspects that the following transactions could be internal transfers
            </Text>

            <View style={{padding:10}}>
                <FlatList 
                    keyExtractor={(item)=>item.id}
                    data={transfers}
                    refreshControl={
                        <RefreshControl
                            colors={[colors.primary]}
                            refreshing={loading}
                            onRefresh={()=>{GetTransfers()}}
                        />
                    }
                    renderItem={({item})=><TransferComponent item={item} />}
                    showsVerticalScrollIndicator={false}
                    ListFooterComponent={<Loading size={'small'} visible={loading} />}
                    ListEmptyComponent={error? <ErrorScreen message={errorMessage}/> : <ListEmpty type={'transactions'} />}
                />
            </View>
        </View>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            button:{
                display:'flex',
                height:35,
                justifyContent:'center',
                marginRight:10,
                width:65,
            },
            container: {
                //alignItems: 'flex-start',
                backgroundColor: colors.background,
                flex: 1,
                flexGrow: 1,
                //flexWrap:'wrap',
                padding:10,
                width: WIDTH
            },
        })
    );
}