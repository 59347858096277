import React from 'react';
import {StyleSheet,Text,View,} from 'react-native';
import {ActivityIndicator, useTheme} from 'react-native-paper';
import Strings from '../constants/Strings';
import { usePlaid } from '../hooks/usePlaid';
import { useRefreshBank } from '../hooks/useRefreshBank';
import { AppContext } from '../context';
import WebView from 'react-native-webview';
import { useAuthentication } from '../hooks/useAuthentication';
import { Loading } from '../components/LoadingModal';
const {PLAID_URL} = Strings;

export default function PlaidRefreshBank({navigation,route}:any){ 
    const plaidAccount = route.params.account??false;
    const {colors} = useTheme();
    const styles = Styles(colors);

    const { dispatch, state:AppState } = React.useContext(AppContext);
    const {user} = AppState;
    const { error: plaidErr, errorMessage: plaidErrMsg, loading: plaidLoading, linkToken, currentBank, plaidItemId, accounts, _handleURL, startPlaid,reset } = usePlaid(false,()=>navigation.goBack());
    const {Logout} = useAuthentication({});
    const {loading,accounts:accRequireRefresh,getRequiredRefresh,updateAccount} = useRefreshBank();
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [selectedAcc, setSelectedAcc] = React.useState<object>();
    const [url, setUrl] = React.useState(PLAID_URL);
    let WebViewRef: any = null;

    React.useEffect(()=>{
        selectAcc(plaidAccount);
    },[plaidAccount,]);
    React.useEffect(()=>{
        if(linkToken){
            setUrl(PLAID_URL + linkToken);
        }
    },[linkToken]);

    function refreshSuccess(bankId:string,bankName:string){
        reset();
        if(selectedAcc) updateAccount(user,{...selectedAcc,bankId,bankName});
        navigation.pop(2);
    }
    function selectAcc(acc:any){
        //console.log('acc: ', acc);
        setSelectedAcc(acc);
        if(acc.accessToken) startPlaid(acc.accessToken);
        else if(acc.bankAccessToken) startPlaid(acc.bankAccessToken);
        else startPlaid('');
    }

    if (plaidLoading) {
        return (
          <View style={{ ...styles.container, justifyContent: 'center' }}>
            <Text style={{ color: colors.error, marginBottom: 20, textAlign:'center' }}>{plaidErr}</Text>
            <Loading size='large' visible={true} />
            <Text style={{ color: colors.primary, marginTop: 20, textAlign:'center' }}>{''}</Text>
          </View>
        )
    }

    if(linkToken){
        return (
            <WebView 
                ref={WEBVIEW_REF => (WebViewRef = WEBVIEW_REF)}
                source={{ uri: url }}
                userAgent={Strings.WEBVIEW_USERAGENT}
                originWhitelist={['https://*', 'http://*', 'plaidlink://*']}
                style={{backgroundColor:colors.background}}
                startInLoadingState={true}
                onShouldStartLoadWithRequest={(request) => {
                    if (!request || !request.url) {
                    return true;
                    }
                    if (request.url.startsWith('plaidlink://')) {
                    _handleURL(request.url,true,refreshSuccess)
                    return false;
                    }
                    if( request.url.startsWith(Strings.PLAID_REDIRECT_URL) ){
                        const newUrl = PLAID_URL+linkToken+'&receivedRedirectUri='+encodeURIComponent(request.url);
                        setUrl( newUrl );
                        //console.log('request.url: ', request.url);
                        return false;
                    }
                    return true;
                }}
                renderLoading={() =>
                    <View style={{...styles.container,position:'absolute',height:'100%',width:'100%',justifyContent: 'center' }}>
                    <Loading size='large' visible={true} />
                  </View>
                }
                renderError={(errorName) =>
                    <View style={styles.container}>
                    <Text style={{ color: 'red', marginBottom: 20 }}>{errorName}</Text>
                    <ActivityIndicator animating={true} size="large" color={colors.primary} />
                    </View>
                }
                onError={(error) => {
                    if (firstLoad) {
                    setFirstLoad(false);
                    WebViewRef && WebViewRef.reload();
                    }
                }}
            />
        );
    }

    

    // return(
    //     <View style={{ ...styles.container}}>
    //         <Text>The following bank accounts need to be refreshed (click on each to continue):</Text>

    //         <FlatList 
    //             style={{marginTop:30}}
    //             showsVerticalScrollIndicator={false}
    //             keyExtractor={(item, index) => 'key' + index}
    //             data={accRequireRefresh}
    //             renderItem={({item}:any)=>{
    //                 return(
    //                     <List.Item 
    //                         left={(props)=><List.Icon {...props} icon="bank"/>}
    //                         onPress={()=>{selectAcc(item)}}
    //                         title={item.name}
    //                         titleStyle={{color:colors.primary}}
    //                     />
    //                 )
    //             }}
    //         />

    //         <Button onPress={()=>{Logout()}}>Logout</Button>
    //     </View>
    // )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            container: {
                backgroundColor: colors.background,
                flex: 1,
                padding: 20,
              },
        })
    );
}