import React from 'react';
import { Firestore } from '../constants/Firebase';
const {useEffect,useState}=React;
 
interface CategoriesStateProps {
    categories: any[]
    error: boolean
    errorMessage: string
    loading: boolean
}
export const useCategories = ()=>{
    const [state,setState] = useState<CategoriesStateProps>({categories:[],error:false,errorMessage:'',loading:true});
    const Ref = Firestore.collection(Firestore.getFirestore(),'Categories');
 
    const GetCategories = ()=>{
        setState({...state,loading:true});
        Firestore.getDocs(Ref)
        .then((snap)=>{
            const categories: any[] = [];
            snap.docs.forEach((doc)=>{
                categories.push({ label:doc.data().name, value:doc.data().name, ...doc.data() });
            })
            setState({...state,loading:false,categories});
        })
        .catch((error)=>{
            setState({
                ...state,
                error:true,
                errorMessage:error.message
            });
        })
    }

    useEffect(()=>{
        GetCategories();
    },
    []);
    return {...state,GetCategories};
}