import { Formik } from 'formik';
import React from 'react';
import {Dimensions, Platform, StyleSheet,useWindowDimensions,View,} from 'react-native';
import CurrencyInput from 'react-native-currency-input';
import {Button, Divider, List, Text, useTheme} from 'react-native-paper';
import DateTimePicker from '@react-native-community/datetimepicker';
import { addBillSchema } from '../constants/Schemas';
import { dateFormatter } from '../constants/utils';
import { BillProps } from '../hooks/useBills';
import RBSheet from "react-native-raw-bottom-sheet";

interface BillRequiredFieldsProps{ 
    bill: BillProps; 
    navigation:any;
    RbRef: any;
    UpdateBill: (bill:BillProps)=>Promise<any>;
}

export default function BillRequiredFields({ bill,navigation, RbRef, UpdateBill }: BillRequiredFieldsProps) {
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {height} = useWindowDimensions();
    const InitialFormValues = { ...bill, owed: 0, paid: 0, complete: true, showDatePicker: false };

    return (
        <RBSheet 
            ref={ref2 => {RbRef.current = ref2}}
            closeOnDragDown={true}
            customStyles={{
                container: {
                    borderRadius:20,
                    height: Platform.OS == 'android'? height/2.2 : height/1.5,
                    padding:20
                }
            }}
            onClose={()=>navigation.goBack()}
        >
        <Formik
            initialValues={InitialFormValues}
            validationSchema={addBillSchema}
            onSubmit={(values, actions) => {
                values.updated = Date.now();
                UpdateBill(values)
                    .then(() => {
                        navigation.goBack();
                    })
                    .catch((error) => {
                        actions.setFieldError('amount', 'Unable to add bill at this time.');
                    })
                    .finally(() => {
                        actions.setSubmitting(false);
                    });
            } }
        >
            {({ handleChange, handleSubmit, setFieldValue, isSubmitting, values, errors }) => {
                return (
                    <View style={{ marginTop: 10, padding: 5, paddingBottom: 10, width: '100%' }}>
                        <List.Subheader style={{ color: colors.error }}>Please enter these required fields</List.Subheader>

                        <View style={{ alignItems: 'center' }}>
                            <Text>Please enter the estimated bill total </Text>
                            <Text style={styles.errorText}>{errors.amount}</Text>
                            <CurrencyInput
                                value={values.amount}
                                onChangeValue={(value) => { setFieldValue('amount', value); } }
                                prefix={"$"}
                                delimiter=","
                                separator="."
                                precision={2}
                                onChangeText={(formattedValue) => { } }
                                keyboardType={'decimal-pad'}
                                style={styles.currencyInput} 
                            />
                        </View>

                        <List.Item
                            description={'Please enter estimated date bill is due'}
                            left={props => <List.Icon {...props} icon="calendar" />}
                            onPress={() => setFieldValue('showDatePicker', true)}
                            title={values.due > 0 ? dateFormatter(values.due, 'fullString') : '-'} />
                        <Divider />
                        <Button
                            mode={'contained'}
                            color={colors.primary}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            uppercase={false}
                            onPress={() => handleSubmit()}
                            style={{}}
                        >
                            Save
                        </Button>
                        <Divider />
                        {values.showDatePicker && (
                            <View>
                                {Platform.OS === 'ios' && (
                                    <Button onPress={() => setFieldValue('showDatePicker', false)}>Close</Button>
                                )}
                                <DateTimePicker
                                    testID="dateTimePicker"
                                    value={values.due > 0 ? new Date(values.due) : new Date(Date.now())}
                                    minimumDate={new Date(Date.now())}
                                    themeVariant="light"
                                    mode={'date'}
                                    display={Platform.OS === 'ios' ? "spinner" : "default"}
                                    onChange={(event: any, selectedDate: Date | undefined) => {
                                        if (!selectedDate)
                                            return;
                                        const currentDate = selectedDate?.getTime() ?? Date.now();
                                        setFieldValue('showDatePicker', Platform.OS === 'ios');
                                        setFieldValue('due', currentDate);
                                    } } />
                            </View>
                        )}
                    </View>
                );
            } }
        </Formik>
        </RBSheet>
    );
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                //alignItems: 'center',
                borderColor: 'black',
                //borderBottomWidth: 1,
                height: Height/1.5,
                //justifyContent:'space-evenly',
                //marginBottom: 20,
                //width: Width
            },
            currencyInput:{
                fontSize: 20,
            },
            errorText: {
                color: colors.error,
            },
            textInput: {
                fontSize: 17,
                height: 50,
                marginBottom: 20,
                marginTop: 20,
                //width: '75%'
            },
        })
    );
}