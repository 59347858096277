import React from 'react';
import { Firestore, Functions } from '../constants/Firebase';
import { LiabilitiesProp } from '../constants/Types';
import { AppContext } from '../context';
const {useEffect,useState}=React;

interface StateProp {
    error:boolean,
    errorMessage:string,
    loading:boolean,
    liability:LiabilitiesProp|null,
    liabilities?:LiabilitiesProp[]
}
const InitialState:StateProp = {
    error:false,
    errorMessage: '',
    loading:false,
    liability:null,
    liabilities:[]
}
export const useLiabilities = (uid:string|null=null,owedOnly=false,get=true)=>{
    const [state,setState] = useState<StateProp>(InitialState);
    const { state: AppState } = React.useContext(AppContext);
    const UID = uid??AppState.user.id;
    const LiabilitiesRef = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/Liabilities');
    const UpdateLiabilityFunction = Functions.httpsCallable(Functions.getFunctions(),'UpdateLiabilityv2');

    async function GetLiability(id:string):Promise<LiabilitiesProp | undefined>{
        //Loading();
        const LiabilitiesRef = Firestore.doc(Firestore.getFirestore(),'Users/'+UID+'/Liabilities',id);
        //@ts-ignore
        return Firestore.getDoc(LiabilitiesRef).then((snapshot)=>{
            const data = snapshot.data();
            //Loading(false);
            return data;
        })
        .catch(()=>{
            //Loading(false);
            return;
        })
    }

    async function GetLiabilities(){
        Loading();
        let query;
        if(owedOnly){
            query = Firestore.query(LiabilitiesRef, Firestore.orderBy('amount_owed','asc'), Firestore.where('amount_owed','>',0) );
        }
        else{
            query = Firestore.query(LiabilitiesRef, Firestore.orderBy('amount_owed','asc') );
        }

        return Firestore.getDocs(query)
        .then((snapshot)=>{
            const liabilities: LiabilitiesProp[] = [];

            snapshot.forEach((doc)=>{
                if( !isNaN(doc.data().paymentAmount) )
                    liabilities.push( ( doc.data() as LiabilitiesProp) );
            });
            setState({...InitialState,liabilities});
        })
        .catch((error)=>{Error(error);});
    }

    async function UpdateLiability(liability: LiabilitiesProp){
        Loading();
        return UpdateLiabilityFunction({liability:JSON.stringify(liability)})
        .then(()=>{ Loading(false); })
        .catch((error)=>{ Error(error); })
    }

    
 
    useEffect(()=>{
        if(get)GetLiabilities();
    },
    []);

    function Error(error:Error){
        console.log('liabilitiesErr: ', error.message);
        setState({
            ...InitialState,
            error:true,
            errorMessage:error.message
        });
    }
    function Loading(start:boolean=true){ setState({...InitialState,loading:start}); }

    return {...state,GetLiability,GetLiabilities,UpdateLiability};
}