import React from 'react';
import { StyleSheet, View, useWindowDimensions, } from 'react-native';
import { Card, List, useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import { useSubscriptions } from '../hooks/useSubscriptions';
import { LoadingModal } from '../components/LoadingModal';
import RBSheet from 'react-native-raw-bottom-sheet';
import { usePayments } from '../hooks/usePayments';
import SelectSubscriptionSheet from '../components/SelectSubscriptionSheet';

export default function Subscriptions(props:any) {
    const {navigation} = props;
    const { colors } = useTheme();
    const {height} = useWindowDimensions();
    const styles = Styles(colors,height);
    const RBRef = React.useRef<RBSheet | null>();
    
    const { initialzed, loading: loadingPlans, subs, getAvailablePurchases, requestSubscription } = usePayments();
    const {error,errorMessage,loading,subscription} = useSubscriptions();

    function endingSoon(endDate:number){
        if(endDate <=1) return false;
        const now = dayjs();
        const dateDiff = dayjs(endDate).diff(now,'days');
        if(dateDiff <= 14 ) return true;
        return false;
    }

    function getColour(type:string,date:number){
        if(type == 'ended') return colors.error;
        if( endingSoon(date) ) return colors.warning;
        return colors.primary;
    }

    return (
        <View style={styles.container}>
            <LoadingModal visible={loading} />
            <Card style={styles.contentContainer}>
                <Card.Title 
                    title={'My Subscription'} 
                    titleStyle={styles.cardTitle} 
                />
                <Card.Content>
                    {/*@ts-ignore */}
                    <List.Item 
                        description={subscription?.name??'-'}
                        descriptionStyle={{color:colors.primary}}
                        left={props => 
                            <List.Icon 
                                {...props} 
                                color={colors.primary} 
                                icon={subscription?.type=='ended'?"bookmark-remove-outline":"bookmark-check-outline"}
                            />
                        }
                        title={'Subscription Type'}
                        titleStyle={{color:colors.primary}}
                    />
                    {/*@ts-ignore */}
                    <List.Item 
                        description={subscription?.type??'-'}
                        descriptionStyle={{color:getColour(subscription?.type??'',subscription?.endDate??0)}}
                        left={props => 
                            <List.Icon 
                                {...props} 
                                color={getColour(subscription?.type??'',subscription?.endDate??0)} 
                                icon={subscription?.type=='ended'?"close-circle-outline":"check-circle-outline"}
                            />
                        }
                        title={'Subscription Status'}
                        titleStyle={{color:colors.primary}}
                    />

                    {
                        subscription?.id?
                            <>
                                {/*@ts-ignore */}
                                <List.Item 
                                    description={dayjs(subscription?.startDate).format('MMMM D, YYYY h:mma')}
                                    left={props => <List.Icon {...props} icon="calendar-range" />}
                                    title={'Date Started'}
                                />

                                {/*@ts-ignore */}
                                <List.Item 
                                    description={dayjs(subscription?.endDate).format('MMMM D, YYYY h:mma')}
                                    descriptionStyle={{color:getColour(subscription?.type??'',subscription?.endDate??0)}}
                                    left={props => 
                                        <List.Icon 
                                            {...props} 
                                            color={getColour(subscription?.type??'',subscription?.endDate??0)}
                                            icon="update"  
                                        />
                                    }
                                    title={subscription?.type=='ended'?'Date Ended':'End Date'}
                                    titleStyle={{color:colors.primary}}
                                />
                            </>
                        :
                            <View/>
                    }

                    {
                        ( subscription?.type=='ended' || endingSoon(subscription?.endDate??0) )&&
                        /*@ts-ignore */
                        <List.Item 
                        description={'press here to start your subscription'}
                        //left={props => <List.Icon {...props} icon="update" />}
                        onPress={async () => { RBRef.current?.open(); }}
                        title={'Subscribe Now'}
                        />
                    }
                </Card.Content>
            </Card>
            <SelectSubscriptionSheet loading={loadingPlans} RBRef={RBRef} requestSubscription={requestSubscription} subscriptions={subs}/>
        </View>
    )
}

const Styles = (colors:any,height:number)=>{
    const Height = height*0.3;
    return (
        StyleSheet.create({
            cardTitle:{
                fontSize: 18,
                textAlign:'center'
            },
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                borderColor: '#F8F0E3',
                borderWidth: 1,
                minHeight: Height,
                marginBottom: 20,
                padding:5
            },
            errorText:{
                color:colors.error,
                textAlign:'center',
            },
        })
    );
}