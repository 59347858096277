import { Formik } from 'formik';
import React from 'react';
import {Dimensions, ScrollView, StyleSheet,TextInput,View,} from 'react-native';
import {Button, Divider, List, Switch, Text, useTheme} from 'react-native-paper';
import { addBudgetSchema } from '../constants/Schemas';
import CategoriesPicker from '../components/CategoriesPicker';
import CurrencyInput from 'react-native-currency-input';
import { useBudgets } from '../hooks/useBudgets';

export default function AddBudgetScreen({navigation}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {AddBudget} = useBudgets({get:false});
    const inputRef = React.useRef<any>();
    const pickerRef = React.useRef<any>();

    const InitialFormValues = {
        amount: 0,
        balance: 0,
        category: 'Other',
        error: '',
        id: '',
        name: '',
        repeats:'Monthly',
        startWithPrevMonth:false,
    };
    
    return(
        <View style={styles.container}>
            <Formik
                initialValues={InitialFormValues}
                validationSchema={addBudgetSchema}
                onSubmit={(values,actions)=>{
                    actions.setSubmitting(true);
                    const budget = (values as any);
                    AddBudget(budget)
                    .then(()=>{
                        navigation.goBack();
                    })
                    .catch((error)=>{
                        actions.setFieldError('error',error.message);
                        actions.setSubmitting(false);
                    });
                }}
            >
                {({handleChange,handleSubmit,setFieldValue,isSubmitting,values,errors})=>{
                    return(
                        <>
                            <ScrollView contentContainerStyle={{flexGrow:1}} showsVerticalScrollIndicator={false}>
                                <List.Item 
                                    description={
                                        <CategoriesPicker 
                                            ref={pickerRef}
                                            value={values.category} 
                                            onChange={(value)=>{
                                                setFieldValue('category',value);
                                            }} 
                                        />
                                    }
                                    left={props => <List.Icon {...props} icon="shape" />}
                                    onPress={()=>pickerRef.current?.open()}
                                    title={'Category'}
                                />
                                <Divider />
                                <List.Item
                                    description={values.repeats}
                                    left={props => <List.Icon {...props} icon="sync" />}
                                    onPress={()=>{}}
                                    title={'Repeats'}
                                />
                                <Divider />
                                <List.Item
                                    description={
                                        (values.startWithPrevMonth?"Start":"Don't start") +
                                        " each new month with the previous month's remainder"
                                    }
                                    descriptionStyle={{fontSize:12}}
                                    left={props => <List.Icon {...props} icon="check" />}
                                    onPress={()=>{
                                        setFieldValue('startWithPrevMonth',!values.startWithPrevMonth);
                                    }}
                                    right={props => 
                                        <Switch 
                                            {...props} 
                                            color={colors.primary} 
                                            onValueChange={()=>{
                                                setFieldValue('startWithPrevMonth',!values.startWithPrevMonth);
                                            }} 
                                            value={values.startWithPrevMonth} 
                                        />
                                    }
                                    title={'Use Previous Remainder'}
                                />
                                <Divider />
                                <List.Item
                                    description={()=>
                                        <CurrencyInput
                                            value={values.amount}
                                            onChangeValue={(value)=>{setFieldValue('amount',value)}}
                                            prefix={"$"}
                                            delimiter=","
                                            separator="."
                                            precision={2}
                                            onChangeText={(formattedValue) => {
                                            }}
                                            keyboardType={'decimal-pad'}
                                            ref={inputRef}
                                            //style={styles.currencyInput}
                                        />
                                    }
                                    left={props => <List.Icon {...props} icon="currency-usd" />}
                                    onPress={()=>{inputRef.current?.focus()}}
                                    title={'Amount'}
                                />
                                <Divider />

                                <View style={{position:'absolute',bottom:1,width:'100%'}}>
                                <Text style={styles.errorText}>{errors.error}</Text>
                                    <Button 
                                        mode={'contained'}
                                        color={colors.primary}
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        uppercase={false}
                                        onPress={()=>handleSubmit()}
                                        style={{}}
                                    >
                                        Save Budget
                                    </Button>
                                </View>
                            </ScrollView>
                        </>
                    );
                }}
            </Formik>
        </View>
    );
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            errorText: {
                color: colors.error,
                marginBottom: 20,
            },
        })
    );
}