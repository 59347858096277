import React from 'react';
import { Platform } from 'react-native';
import { Firestore } from '../constants/Firebase';
import { AppContext } from '../context';
const {useEffect,useState}=React;
 //TODO: quick fix solution. Needs to be refactored
export const useNotifications = ()=>{
    const { state: AppState } = React.useContext(AppContext);
    const uid = AppState.user.id;
    const isloggedIn = AppState.isloggedIn;

    async function UploadToken(token:string){
        if(!isloggedIn || !token) return;
        const Ref = Firestore.doc(Firestore.getFirestore(),'Notifications',token);
        return Firestore.setDoc(Ref,{
            device: Platform.OS,
            id: uid,
            pushToken: token,
            uid: uid,
        },{merge:true})
        .catch((error)=>{
            console.log('saveTokenErr',error.message);
        })
    }
 
    useEffect(()=>{
    },
    []);

    return {UploadToken};
}