import React from 'react';
import { Functions } from '../constants/Firebase';
import { creditUtilizationType, totalCreditUtilizationType } from '../constants/Types';
const {useEffect,useState}=React;
 
export const useCreditUtilization = ()=>{
    const [totalCreditUtilization,setTotalCreditUtilization] = useState<totalCreditUtilizationType>();
    const [creditUtilization,setCreditUtilization] = useState<creditUtilizationType>();
    const GetCreditUtilization = Functions.httpsCallable(Functions.getFunctions(),'getCreditUtilizationv2');

    type resultsType = {success:boolean,creditUtilization:creditUtilizationType,totalCreditUtilization:totalCreditUtilizationType}
    async function getCreditUtilization(){
        try{
            if(totalCreditUtilization)setTotalCreditUtilization({...totalCreditUtilization,rate:0});
            const results:any = (await GetCreditUtilization()).data;
            if(!results.success) return;
            setCreditUtilization(results.creditUtilization);
            setTotalCreditUtilization(results.totalCreditUtilization);
        }
        catch(error){
            console.log('getCreditUtilizationErr: ', error);
        }
    }
 
    useEffect(()=>{
        getCreditUtilization();
    },
    []);
    
    return {creditUtilization,totalCreditUtilization,getCreditUtilization};
}