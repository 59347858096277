import { Formik } from 'formik';
import React from 'react';
import {Dimensions,Platform,ScrollView,StyleSheet,Text,TouchableOpacity,View,} from 'react-native';
import {ActivityIndicator, Button, Divider, List, TextInput, useTheme} from 'react-native-paper';
import DateTimePicker from '@react-native-community/datetimepicker';
import { addAccountSchema } from '../constants/Schemas';
import Picker from 'react-native-picker-select';
import { PickerSelectStylesAvil } from '../constants/Theme';
import CurrencyInput from 'react-native-currency-input';
import { useAccounts } from '../hooks/useAccounts';
import { AccountProps, LiabilitiesProp } from '../constants/Types';
import { dateFormatter, isLiability } from '../constants/utils';
import { useLiabilities } from '../hooks/useLiabilities';
import { LoadingModal } from '../components/LoadingModal';
import { AccountContext } from '../context/AccountContext';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const Width = Dimensions.get('window').width;

type Props = {
    balance?:number,
    connectBank?:string,
    id?:string,
    interestRate?:number,
    isEditing?:boolean,
    manuallyAdded?:boolean,
    name?:string,
    next_payment_due_date?:string,
    paymentAmount?:number,
    type?: 'Cash' | 'Credit Cards' | 'Loans' | 'Investments'
}
export default function AddAccountsScreen(Props:any){ 
    const {state:accountState,dispatch} = React.useContext(AccountContext);
    const {balance,connectBank,id,interestRate,isEditing=false,manuallyAdded,name,next_payment_due_date,paymentAmount,type} = (Props.route.params as Props)
    const {accounts,error,errorMessage,loading,message,GetAllAccounts,AddAccountMannually} = useAccounts(false);
    const {loading:liabLoading,liability,GetLiability} = useLiabilities(null,false,false);
    const [showDatePicker,setShowDatePicker] = React.useState(false);
    
    const {colors} = useTheme();
    const styles = Styles(colors);
    const pickerStyles = PickerSelectStylesAvil(colors,Width);
    const accountTypes = [
        {label:'Cash',value:'cash'},
        {label:'Credit Card',value:'credit'},
        {label:'Investment',value:'investment'},
        {label:'Loans',value:'loans'}
    ];
    const NoBankObj = {label:'No Bank',value:''};
    const [InitialFormValues,setInitialFormValues] = React.useState({
        loading:false,
        balance:balance??0.00,
        connectBank:connectBank??NoBankObj.value,
        id:id??null,
        interestRate:interestRate??0,
        manuallyAdded:manuallyAdded==undefined?true:manuallyAdded,
        name:name??'',
        next_payment_due_date:next_payment_due_date??dateFormatter(Date.now(),'dashFormattedString'),
        paymentAmount:paymentAmount??1.00,
        type:accountTypes.find(o => o.label === type || type?.toLowerCase() === o.value)?.value??''
    });

    React.useEffect(()=>{GetAllAccounts()},[]);
    React.useEffect(()=>{
        if( isEditing && isLiability(InitialFormValues.type) ){
            if(id){
                GetLiabilityData();
            }
        }
    },[isEditing])

    const GetLiabilityData = async ()=>{
        if(!id) return;
        setInitialFormValues({...InitialFormValues,loading:true})
        const liability = await GetLiability(id);
        setInitialFormValues((state)=>{
            return {
                ...state,
                loading:false,
                interestRate:liability?.interestRate??0,
                next_payment_due_date:liability?.next_payment_due_date??dateFormatter(Date.now(),'dashFormattedString'),
                paymentAmount:liability?.paymentAmount?(liability.paymentAmount)/100:1.00,
            }
        });
    }

    const getAccountsPickerData = (accounts:AccountProps[])=>{
        const bankObj = [NoBankObj];
        accounts.forEach((account) => {
            bankObj.push({label:account.name,value:account.id});
        });
        bankObj.sort((a,b)=>{
            if(a.label < b.label) return -1;
            else if(a.label > b.label) return 1;
            return 0;
        })
        return bankObj;
    }

    if(InitialFormValues.loading){
        return <LoadingModal visible={InitialFormValues.loading} />
    }
    
    return(
        <KeyboardAwareScrollView keyboardShouldPersistTaps={'always'}>
        <View style={styles.container}>
            <Formik
                initialValues={InitialFormValues}
                validationSchema={addAccountSchema}
                onSubmit={async(values,actions)=>{
                    const account = ({
                        balances:{available:values.balance,current:values.balance},
                        id:values.id,
                        item_id:values.connectBank,
                        manuallyAdded:true,
                        name:values.name,
                        title:values.name,
                        total:(values.balance*100).toString(),
                        //next_payment_due_date:dateDue,
                        type: values.type
                    } as AccountProps)

                    if( isLiability(values.type) ) account.total = '-'+account.total;

                    if(!isEditing) {
                        account.bankName = values.name;
                    }

                    const liability = ({
                        account: {...account,next_payment_due_date:values.next_payment_due_date,},
                        amount_owed:values.balance*100,
                        id:values.id,
                        interestRate:values.interestRate,
                        next_payment_due_date: values.next_payment_due_date,
                        paymentAmount:values.paymentAmount*100,
                    } as LiabilitiesProp)

                    const isLiab = isLiability(values.type);
                    const acc = isLiab? liability : account;

                    const res = await AddAccountMannually(acc,isLiab)
                    actions.setSubmitting(false);
                    if( res ) {
                        if(isEditing && values.id) {
                            const currAccState = accountState.account;
                            const oldAcc = accountState.account.accounts[values.id];
                            const newAcc = {...oldAcc,...account};
                            currAccState.accounts[values.id] = newAcc;
                            dispatch({type:'setAccount',payload:currAccState});
                        }
                        Props.navigation.goBack();
                    }
                }}
            >
                {
                    ({handleChange,handleSubmit,setFieldValue,isSubmitting,values,errors})=>{
                        return(
                            <>
                                <ScrollView contentContainerStyle={{flexGrow:1}} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>

                                {/* <View style={{
                                    ...styles.contentContainer,
                                    alignItems:'center',
                                    padding:isLiability(values.type)?-20:20
                                    }}
                                >
                                    
                                </View> */}

                                <View style={{flexDirection:'row'}}>
                                    <List.Icon icon={'card-account-details-outline'} />
                                    <View style={{width:'100%'}}>
                                        {/*@ts-ignore*/}
                                        <List.Subheader style={{marginBottom:-20}}>Account Type:</List.Subheader>
                                        <View style={{marginLeft:15}}>
                                            <Picker 
                                                onValueChange={(value)=>{setFieldValue('type',value)}}
                                                items={accountTypes}
                                                style={pickerStyles}
                                                value={values.type}
                                            />
                                            <Text style={styles.errorText}>{errors.type}</Text>
                                        </View>
                                    </View>
                                </View>
                                <Divider />

                                <View style={{flexDirection:'row',}}>
                                    <List.Icon icon={'card-text-outline'} />
                                    <View style={{width:'100%'}}>
                                        {/*@ts-ignore*/}
                                        <List.Subheader style={{marginBottom:-20}}>Account Name</List.Subheader>
                                        <TextInput
                                            mode={'flat'}
                                            style={styles.textInput}
                                            onChangeText={handleChange('name')}
                                            value={values.name}
                                            placeholder={'Account name'}
                                            error={errors.name ? true : false}
                                            underlineColor={'transparent'} 
                                            autoCompleteType={undefined}                                        
                                        />
                                        <Text style={styles.errorText}>{errors.name}</Text>
                                    </View>    
                                </View>
                                <Divider/>
                                
                                <View style={{flexDirection:'row',}}>
                                    <List.Icon icon={'bank-outline'} />
                                    <View style={{width:'100%'}}>
                                        {/*@ts-ignore*/}
                                        <List.Subheader style={{marginBottom:-20}}>Connect Existing Bank</List.Subheader>
                                        {
                                            loading?
                                            <ActivityIndicator />
                                            :
                                            <View style={{marginLeft:15}}>
                                                <Picker 
                                                    onValueChange={(value)=>{setFieldValue('connectBank',value)}}
                                                    items={accounts.length>0?
                                                        //@ts-ignore
                                                        getAccountsPickerData(accounts)
                                                        :
                                                        [NoBankObj]
                                                    }
                                                    style={pickerStyles}
                                                    value={values.connectBank}
                                                />
                                            </View>
                                        }
                                    </View>
                                </View>
                                <Divider/>

                                <View style={{flexDirection:'row',}}>
                                    <List.Icon icon={'currency-usd'} />
                                    <View style={{width:'100%'}}>
                                        {/*@ts-ignore*/}
                                        <List.Subheader style={{marginBottom:-20}}>Account Balance</List.Subheader>
                                        <View style={{marginLeft:15}}>
                                            <CurrencyInput
                                                style={{...styles.textInput,}}
                                                onChangeValue={(value)=>setFieldValue('balance',value)}
                                                value={values.balance}
                                                placeholder={'Account Balance'}
                                                prefix={"$"}
                                                delimiter=","
                                                separator="."
                                                precision={2}
                                                keyboardType={'decimal-pad'}
                                            />
                                            <Text style={styles.errorText}>{errors.balance}</Text>
                                        </View>
                                    </View>    
                                </View>
                                <Divider/>

                                {
                                    isLiability(values.type) &&
                                    <>
                                        <View style={{flexDirection:'row',}}>
                                            <List.Icon icon={'credit-card-sync-outline'} />
                                            <View style={{width:'100%'}}>
                                                {/*@ts-ignore*/}
                                                <List.Subheader style={{marginBottom:-20}}>Monthly Payment Amount</List.Subheader>
                                                <View style={{marginLeft:15}}>
                                                    <CurrencyInput
                                                        style={{...styles.textInput}}
                                                        onChangeValue={(value)=>setFieldValue('paymentAmount',value)}
                                                        value={values.paymentAmount}
                                                        placeholder={'Enter monthly payment amount for this account.'}
                                                        prefix={"$"}
                                                        delimiter=","
                                                        separator="."
                                                        precision={2}
                                                        keyboardType={'decimal-pad'}
                                                    />
                                                    <Text style={styles.errorText}>{errors.paymentAmount}</Text>
                                                </View>
                                            </View>    
                                        </View>
                                        <Divider/>

                                        <View style={{flexDirection:'row',}}>
                                            <List.Icon icon={'label-percent-outline'} />
                                            <View style={{width:'100%'}}>
                                                {/*@ts-ignore*/}
                                                <List.Subheader style={{marginBottom:-20}}>Annual Percentage Rate (APR)</List.Subheader>
                                                <TextInput
                                                    mode={'flat'}
                                                    style={styles.textInput}
                                                    onChangeText={handleChange('interestRate')}
                                                    value={values?.interestRate?.toString()}
                                                    placeholder={'Enter your annual percentage rate (APR)'}
                                                    right={<TextInput.Affix text="%" />}
                                                    error={errors.interestRate ? true : false}
                                                    underlineColor={'transparent'} 
                                                    autoCompleteType={undefined}
                                                    keyboardType={'numeric'}
                                                />
                                                <Text style={styles.errorText}>{errors.interestRate}</Text>
                                            </View>    
                                        </View>
                                        <Divider/>

                                        <View style={{flexDirection:'row',}}>
                                            <List.Icon icon={'calendar-month-outline'} />
                                            <View style={{width:'100%'}}>
                                                {/*@ts-ignore*/}
                                                <List.Subheader style={{marginBottom:-20}}>Payment Due Date</List.Subheader>
                                                <TouchableOpacity
                                                    onPress={()=>{setShowDatePicker(true)}}
                                                >
                                                    <TextInput
                                                        autoCompleteType={''}
                                                        editable={false}
                                                        pointerEvents={"none"}
                                                        mode={'flat'}
                                                        style={styles.textInput}
                                                        //onChangeText={(text)=>{ setDateDue( text ) }}
                                                        value={values.next_payment_due_date?dateFormatter(values.next_payment_due_date.toString(),'fullString' ):''}
                                                        //label={'Date Due'}
                                                        error={!values.next_payment_due_date}
                                                    />
                                                    <Text style={styles.errorText}>{errors.next_payment_due_date}</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                        <Divider/>
                                    </>
                                }

                                {showDatePicker && (
                                    <View
                                        style={{
                                            zIndex:100
                                        }}
                                    >
                                        {Platform.OS === 'ios' && (
                                            <Button style={{marginTop:20,}} onPress={() => setShowDatePicker(false)}>Close</Button>
                                        )}
                                        <DateTimePicker
                                            testID="dateTimePicker"
                                            value={values.next_payment_due_date?new Date(values.next_payment_due_date+'T20:25:42.475Z'):new Date()}
                                            mode={'date'}
                                            display={Platform.OS === 'ios'?"spinner":"default"}
                                            onChange={(event:any, selectedDate:Date | undefined)=>{
                                                const currentDate = selectedDate?.getTime() ?? Date.now();
                                                setShowDatePicker(Platform.OS === 'ios');
                                                setFieldValue('next_payment_due_date',dateFormatter(currentDate,'dashFormattedString'));
                                            }}
                                            themeVariant="light"
                                        />
                                    </View>
                                )
                                }

                                <Text style={{...styles.errorText,color:error?colors.error:colors.primary}}>
                                    {error?errorMessage:message}
                                </Text>
                                <TouchableOpacity>
                                    <View style={{flexDirection:'row',}}>
                                        <View style={{width:'100%'}}>
                                        <Button
                                            style={styles.button}
                                            labelStyle={{fontSize:Width>500?25:15}}
                                            onPress={()=>handleSubmit()}
                                            color={colors.primary}
                                            mode={'contained'}
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                            uppercase={true}
                                        >
                                            Submit
                                        </Button>
                                        </View>
                                    </View>
                                </TouchableOpacity>

                                </ScrollView>
                            </>
                        )
                    }
                }
            </Formik>
        </View>
        </KeyboardAwareScrollView>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            button: {
                alignSelf:'center',
                borderRadius: 10,
                height: Width>500?75:55,
                justifyContent: 'space-around',
                marginTop: '4%',
                width:'75%'
            },
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                justifyContent:'center',
                alignContent:'center',
                padding: 20,
                marginTop:20
            },
            contentContainer:{
                borderColor: 'black',
                height: Height/3,
            },
            currencyInput:{
                fontSize: Height*0.2,
                //height: '100%',
            },
            errorText: {
                color: colors.error,
                width:'65%'
            },
            textInput: {
                backgroundColor: 'transparent',
                borderBottomColor: 'transparent',
                borderBottomWidth: 0,
                fontSize: 17,
                height: 50,
                width: '75%'
            },
        })
    );
}