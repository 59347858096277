import React from 'react';
import {StyleSheet,View,} from 'react-native';
import {Avatar,useTheme} from 'react-native-paper';
import { getInitials} from '../constants/utils';

interface Props {
    logo?:string,
    compProps?:any,
    name:string,
    refresh:boolean,
    size?:number,
}
export default function AccountLogo(item:Props){ 
    const {colors} = useTheme();
    const styles = Styles(colors);

    if(item.logo){
        return(
            <Avatar.Image
                {...item.compProps}
                source={{uri:item.logo}} 
                size={item.size??35} 
                style={{
                    borderColor: item.refresh? colors.error : colors.primary,
                    borderWidth: 1,
                    justifyContent:'center',
                    width:38,
                    height:38,
                    borderRadius:38/2,
                }}
            />
        );
    }
    return(
        <Avatar.Text 
            {...item.compProps} 
            color={colors.accent} 
            label={getInitials(item.name)} 
            size={35} 
            style={{
                borderColor: item.refresh? colors.error : colors.primary,
                borderWidth: 1,
                justifyContent:'center',
                width:38,
                height:38,
                borderRadius:38/2,
            }}
            labelStyle={{
                fontSize: 15,
            }}
    />
    );
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({})
    );
}