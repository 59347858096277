import React from 'react';
import dayjs from "dayjs";
import { Firestore, Functions } from '../constants/Firebase';
import { getDateCodes } from '../constants/utils';
import { AppContext } from '../context';
import { useTransactions } from './useTransactions';
import { AccountProps, AccountsProps, LiabilitiesProp } from '../constants/Types';
const { useEffect, useState } = React;

interface useAccountsProps {
    loading: boolean,
    message: string,
    accounts: AccountsProps[],
    //summary: {total:number,monthlyIncome:number,monthlyExpense:number},
    error: boolean,
    errorMessage: string
}
const InitialState = {
    loading: false,
    message: '',
    accounts: [],
    //summary: {total:0.00,monthlyIncome:0.00,monthlyExpense:0.00},
    error: false,
    errorMessage: ''
}
export const useAccounts = (get = true, uid=null) => {
    const AddAccountsFunction = Functions.httpsCallable(Functions.getFunctions(),'addAccountv2');
    const AddAccountsManuallyFunction = Functions.httpsCallable(Functions.getFunctions(),'addAccountManuallyv2');
    const DeleteAccountFunction = Functions.httpsCallable(Functions.getFunctions(),'deleteAccountv2');
    const DeleteSubAccountFunction = Functions.httpsCallable(Functions.getFunctions(),'deleteSubAccountv2');
    const RefreshAccountBalancesFunction = Functions.httpsCallable(Functions.getFunctions(),'refreshPlaidAccountsv2');
    const UpdateCashTotals = Functions.httpsCallable(Functions.getFunctions(),'updateCashTotalsv2');

    const { state: AppState, dispatch } = React.useContext(AppContext);
    const [state, setState] = useState<useAccountsProps>(InitialState);
    const [summary, setSummary] = useState({ total: 0.00, monthlyIncome: 0.00, monthlyExpense: 0.00 });
    const { GetTransactions } = useTransactions({ get: false, limit: -1 });
    const [account,setAccount] = useState<AccountProps|null>(null);

    const UID = uid??AppState.user.id;
    const AccountsRef = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/Accounts');
    const Ref = Firestore.doc(Firestore.getFirestore(),'Accounts',UID);
    const UserRef = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/CashSummary');
    const CashSummaryLink = 'Users/'+UID+'/CashSummary';
    const accountRef = Firestore.collection(Firestore.getFirestore(),'PlaidAccountsTracker');

    async function AddAccount(account: AccountProps, type: string, Accounts: AccountsProps[]) {
        setState({ ...state, loading: true, message: 'Submitting your account ...' });
        //const Accounts = state.accounts;
        account.updated = Date.now();
        const AccountData: any = prepareAccountData(account, Accounts, {}, type).AccountData;

        const accountDoc = Firestore.doc(accountRef,account.item_id);
        Firestore.setDoc( accountDoc,{ ...account, uid: UID } ).catch(() => { });
        
        return Firestore.setDoc( Ref, AccountData, {merge:true} )
            .then(() => {
                setState({ ...state, loading: false, message: '' });
            })
            .catch((error) => {
                setState({ ...state, error: true, errorMessage: error.message, message: '' });
            })
    }

    async function AddAccountMannually(account:AccountProps|LiabilitiesProp,isLiability:boolean){
        return AddAccountsManuallyFunction({account,isLiability})
        .then((res)=>{
            const data:any = res.data;
            if(data.success){
                setState({ ...state, loading: false, message:data.message });
                return true;
            }
            setState({ ...state, loading: false, error: true, errorMessage: data.message, message: '' });
            return false;
        })
        .catch((error) => {
            console.log('error: ', error.message);
            setState({ ...state, loading: false, error: true, errorMessage: error.message, message: '' });
            return false;
        })
    }

    async function BulkAddAccount(accounts: AccountProps[], accessToken: string, bankName: string, bankId: string, item_id: string, AllAccounts: AccountsProps[]) {
        setState({ ...state, loading: true, message: 'Submitting your accounts ...' });
        let AccountData: any = {
            accessToken,
            accounts: [],
            bankId,
            id: item_id,
            name: bankName,
        };

        for (let i = 0; i < accounts.length; i++) {
            const account = accounts[i];
            //@ts-ignore
            account.id = account.account_id;
            //@ts-ignore
            account.title = account.name;
            //@ts-ignore
            account.total = setAccountTotal(account.balances);
            account.bankId = bankId;
            account.bankAccessToken = accessToken;
            account.bankName = bankName;
            account.item_id = item_id;
            account.updated = Date.now();

            let type = account.type;
            switch (type) {
                case 'depository':
                    type = 'Cash';
                    break;
                case 'investment':
                case 'brokerage':
                    type = 'Investment';
                    break;
                case 'loan':
                    type = 'Loans';
                    break;
                case 'credit':
                    type = 'Credit';
                    break;
                default:
                    type = 'Cash';
                    break;
            }
            account.type = type;
            AccountData.accounts.push( account );
            // const preparedAccs = prepareAccountData(account, AllAccounts, AccountData, type);
            // console.log('preparedAccs: ', preparedAccs);
            // AccountData = preparedAccs.AccountData;
            // AllAccounts = preparedAccs.Accounts;
        }
        const PlaidAccountsTracker = {
            bankAccessToken: accessToken,
            bankId,
            bankName,
            id: item_id,
            item_id,
            uid: UID
        };
        return AddAccountsFunction({AccountData,PlaidAccountsTracker})
        .then((res)=>{
            const data:any = res.data;
            if(data.success){
                setState({ ...state, loading: false, message:data.message });
                return;
            }
            setState({ ...state, loading: false, error: true, errorMessage: data.message, message: '' });
        })
        .catch((error) => {
            console.log('error: ', error.message);
            setState({ ...state, loading: false, error: true, errorMessage: error.message, message: '' });
        })
    }

    function prepareAccountData(account: AccountProps, Accounts: AccountsProps[], AccountData:any, type: string) {
        for (let i = 0; i < Accounts.length; i++) {
            const acc = Accounts[i];
            //console.log('acc: ', acc);
            if(!acc) continue;
            if (!acc.accounts) {
                acc.accounts = [];
            }

            if (acc.title !== type) {
                continue;
            }
            else {
                AccountData[acc.key] = acc;

                const exists = acc.accounts?.some((curAcc) => { return curAcc.id === account.id });
                if (exists) break;

                acc.accounts.push(account);

                let total: number = 0;

                for (let i = 0; i < acc.accounts.length; i++) {
                    total = total + Number(acc.accounts[i].total ?? 0);
                }
                acc.total = total;

                Accounts[i] = acc;
                AccountData[acc.key] = acc;
                break;
            }
        }
        return { AccountData, Accounts };
    }

    async function GetSingleSummary(date = getDateCodes('', 'yearMonth')) {
        const sumDocRef = Firestore.doc( Firestore.getFirestore(), CashSummaryLink, date );
        return Firestore.getDoc(sumDocRef)
            .then((snapshot) => {
                const sum: { expense: number, income: number } = (snapshot.data() as any);
                const newSummary = summary;
                newSummary.monthlyExpense = sum?.expense??0;
                newSummary.monthlyIncome = sum?.income??0;

                setSummary(newSummary);
                return newSummary;
            })
            .catch((error) => {
                console.log('GetSingleSummaryErr: ', error.message);
                const newSummary = { total: 0.00, monthlyIncome: 0.00, monthlyExpense: 0.00 };
                setSummary(newSummary);
                return newSummary;
            })
    }

    function GetSummary(date = getDateCodes('', 'yearMonth')) {
        const sumDocRef = Firestore.doc( Firestore.getFirestore(), CashSummaryLink, date );
        return Firestore.getDoc(sumDocRef)
            .then((snapshot) => {
                if (!snapshot.exists) {
                    setSummary({ total: 0.00, monthlyIncome: 0.00, monthlyExpense: 0.00 });
                    return;
                }
                if(AppState.user.getTrans) return;
    
                const sum: { expense: number, income: number } = (snapshot.data() as any);
                const newSummary = summary;
                newSummary.monthlyExpense = sum?.expense??0;
                newSummary.monthlyIncome = sum?.income??0;
    
                setSummary(newSummary);
            })
            .catch((error) => {
                console.log('GetSummaryErr: ', error.message);
                const newSummary = { total: 0.00, monthlyIncome: 0.00, monthlyExpense: 0.00 };
                setSummary(newSummary);
                return newSummary;
            })
        // return Firestore.onSnapshot( sumDocRef, (snapshot) => {
        //     if (!snapshot.exists) {
        //         setSummary({ total: 0.00, monthlyIncome: 0.00, monthlyExpense: 0.00 });
        //         return;
        //     }
        //     if(AppState.user.getTrans) return;

        //     const sum: { expense: number, income: number } = (snapshot.data() as any);
        //     const newSummary = summary;
        //     newSummary.monthlyExpense = sum?.expense??0;
        //     newSummary.monthlyIncome = sum?.income??0;

        //     setSummary(newSummary);
        // },
        //     (error) => {
        //         console.log('GetSummaryErr: ', error.message);
        //         const newSummary = { total: 0.00, monthlyIncome: 0.00, monthlyExpense: 0.00 };
        //         setSummary(newSummary);
        //         return newSummary;
        //     });
    }

    function GetAccount() {
        return Firestore.getDoc(Ref)
            .then((snapshot) => {
                if (!snapshot.exists) return;
                if(AppState.user.getTrans) return;
                setState({ ...state, loading: true, message: 'Loading accounts ...' });
    
                const Accounts: AccountsProps[] = [];
                const Data: any = snapshot.data();
                Accounts.push(Data?.cash ?? []);
                Accounts.push(Data?.credit ?? []);
                Accounts.push(Data?.loans ?? []);
                Accounts.push(Data?.investment ?? []);
    
                //update context to
                setState({ ...state, accounts: Accounts, loading: false, message: '' });
            })
            .catch((error) => {
                setState({ ...state, error: true, errorMessage: error.message,message: '' });
            })
        // return Firestore.onSnapshot(Ref,(snapshot) => {
        //     if (!snapshot.exists) return;
        //     if(AppState.user.getTrans) return;
        //     setState({ ...state, loading: true, message: 'Loading accounts ...' });

        //     const Accounts: AccountsProps[] = [];
        //     const Data: any = snapshot.data();
        //     Accounts.push(Data?.cash ?? state.accounts[0]);
        //     Accounts.push(Data?.credit ?? state.accounts[1]);
        //     Accounts.push(Data?.loans ?? state.accounts[2]);
        //     Accounts.push(Data?.investment ?? state.accounts[3]);

        //     //update context to
        //     setState({ ...state, accounts: Accounts, loading: false, message: '' });
        // },
        //     (error) => {
        //         setState({ ...state, error: true, errorMessage: error.message,message: '' });
        //     });
    }

    function GetSingleAccount(accId:string,uid:string){
        const Ref = Firestore.collection( Firestore.getFirestore(),'Users/'+uid+'/Accounts');
        const q = Firestore.query( Ref, Firestore.where(`accounts.${accId}.id`,'==',accId) );
        return Firestore.getDocs(q)
        .then((snapshot)=>{
            const account = snapshot.docs;
            if(account.length > 0){
                const bank = account[0].data();
                const acc = bank.accounts[accId];
                //console.log('acc: ', acc);
                setAccount(acc);
            }
        })
        .catch((error)=>{})
    }

    async function GetAllAccounts() {
        //console.log('starting...');
        const Accounts: AccountsProps[] = [];
        const accountsIds: string[] = [];
        return Firestore.getDocs(AccountsRef).then((snapshot) => {
            //console.log('connected');
            setState({ ...state, loading: true, message: 'Loading accounts ...' });
            snapshot.forEach((docs)=>{
                const Data: any = docs.data();
                accountsIds.push(Data.id);
                Accounts.push(Data);
            })
            if(AppState.accountIds < 0) dispatch({ type: 'setAccountIds', payload:accountsIds });
            //update context to
            setState({ ...state, accounts: Accounts, loading: false, message: '' });
        })
        .catch((error) => {
            console.log('error: ', error);
            setState({ ...state, error: true, errorMessage: error.message,message: '' });
        })
        .then(()=>{
            return Accounts;
        });
    }

    async function RefreshAccounts() {
        setState({...state,errorMessage:'',loading:true});
        return UpdateCashTotals({uid:UID}).then(()=>{
            return RefreshAccountBalancesFunction({uid:UID})
            .then((res)=>{
                //console.log('res: ', res);
                const data:any = res.data;
                if(data.success) {
                    setState({ ...state, loading: false, message:data.message });
                    return;
                }
                let message = data?.message??'';
                if( data.limit ) {
                    const time = dayjs(data.limit).format('h:mm a');
                    message += '\nPlease try again after '+time+'.';
                }
                setState({ ...state, loading: false, error: true, errorMessage: message, message: '' });
                return message;
            })
            .catch((error)=>{
                if(error.message === 'deadline-exceeded') {
                    error.message = '';
                }
                setState({ ...state, loading: false, error: true, errorMessage: error.message, message: '' });
                return error.message;
            })
        });
     }

    async function RemoveAccount(account: AccountProps) {
        setState({ ...state, error: false, loading: true, message: 'Deleting account ...' });
        // const bal = account.balances;
        // const total = (bal.available? bal.available * -1 : bal.current);

        return DeleteSubAccountFunction({account})
        .then((res)=>{
            const data:any = res.data;
            if(data.success){
                setState({ ...state, loading: false, message:data.message });
                return true;
            }
            setState({ ...state, loading: false, error: true, errorMessage: data.message, message: '' });
            return false;
        })
        .catch((error) => {
            setState({
                ...state,
                error: true,
                errorMessage: error.message,
                loading: false,
                message: ''
            });
            return false;
        })
    }

    async function DeleteAccount(account: AccountsProps) {
        setState({ ...state, error: false, loading: true, message: 'Deleting account ...' });
        return DeleteAccountFunction({account})
        .then((res)=>{
            const data:any = res.data;
            if(data.success){
                setState({ ...state, loading: false, message:data.message });
                return true;
            }
            setState({ ...state, loading: false, error: true, errorMessage: data.message, message: '' });
            return false;
        })
        .catch((error)=>{
            console.log('error: ', error);
            setState({
                ...state,
                error: true,
                errorMessage: error.message,
                loading: false,
                message: ''
            });
            return false;
        })
        
    }

    function setAccountTotal(balances:{available:number,current:number}):string{
        const available = balances.available;
        const current = balances.current;
        let total = '0.00';

        if( available && available != 0 ){
            total = Number(available).toFixed(2);
        }
        else if( current && current != 0) {
            total = '-'+Number(current).toFixed(2);
        }
        return total;
    }

    useEffect(() => {
        if (!get) return;
        GetAccount();
        GetSummary();
    },
        []);

    return { ...state, account, summary, AddAccount, AddAccountMannually, BulkAddAccount, DeleteAccount, GetAccount, GetAllAccounts, GetSingleAccount, GetSingleSummary, GetSummary, RefreshAccounts, RemoveAccount, UpdateCashTotals }
}

export const GetAccountIds = (UID:string)=>{
    const AccountsRef = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/Accounts');
    const accountsIds: string[] = [];
    return Firestore.getDocs(AccountsRef).then((snapshot) => {
        snapshot.forEach((docs)=>{
            const Data: any = docs.data();
            accountsIds.push(Data.id);
        })
    })
    .catch((error) => {
        console.log('GetAccountIdsErr: ', error);
    })
    .then(()=>{
        return accountsIds;
    });
}