import React from 'react';
import {Appearance, Dimensions, StyleSheet,View,} from 'react-native';
import {useTheme} from 'react-native-paper';
import RNPickerSelect from 'react-native-picker-select';
import { useCategories } from '../hooks/useCategories';
import { PickerSelectStylesAvil } from '../constants/Theme';

const Width = Dimensions.get('window').width;

interface Props{
    onChange: (cat:string)=>void
    value:string
}
export default React.forwardRef((props:Props,ref) => {
    const {onChange,value} = props;
    const {colors} = useTheme();
    const styles = PickerSelectStylesAvil(colors,Width);
    const {categories} = useCategories();
    const pickerRef = React.useRef<any>();

    React.useImperativeHandle(ref, () => ({
        open: () => { pickerRef.current.togglePicker(true) },
      }));

    return(
        <RNPickerSelect
            ref={pickerRef}
            onValueChange={(value) => onChange(value)}
            items={categories}
            style={styles}
            value={value}
            useNativeAndroidPickerStyle={false}
        />
    );
});

export function CategoriesPicker({onChange,value}:Props){ 
    
}