import React from 'react';
import { Dimensions, FlatList, Platform, ScrollView, StyleSheet,useWindowDimensions,View,} from 'react-native';
import {Button, Dialog, Divider, IconButton, List, Paragraph, Portal, Switch, Text, TextInput, useTheme} from 'react-native-paper';
import { TabBar, TabView, SceneMap } from 'react-native-tab-view';
import CurrencyInput from 'react-native-currency-input';
import {Formik} from 'formik';
import { TransactionProps, useTransactions } from '../hooks/useTransactions';
import { currencyFormatter, dateFormatter } from '../constants/utils';
import { BillProps, useBills } from '../hooks/useBills';
import TransactionListItem from '../components/TransactionListItem';
import Strings from '../constants/Strings';
import { addBillSchema } from '../constants/Schemas';
import BillRequiredFields from '../components/BillRequiredFields';
import RBSheet from 'react-native-raw-bottom-sheet';
import DeleteBillAlert from '../components/DeleteBillAlert';

export default function ViewBillScreen({navigation,route}:any){ 
    const {account,amount,due,id,name,owed,repeats} = route?.params?.bill ?? {};
    const layout = useWindowDimensions();
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {DeleteBill}  = useBills({get:false});
    const [index, setIndex] = React.useState(0);
    const [visible, setVisible] = React.useState(false);
    const hideDialog = () => setVisible(false);
    const showDialog = () => setVisible(true);

    const [routes] = React.useState([
        { key: 'first', title: 'Bill Summary', bill: route?.params?.bill ?? {},navigation,showDialog},
        { key: 'second', title: 'History', bill: route?.params?.bill ?? {}, navigation },
    ]);
    

    React.useLayoutEffect(() => {
        navigation.setOptions({
          headerRight: () => (
            <View style={{alignItems:'center',borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
                <IconButton 
                    color={colors.primary} 
                    icon={'delete'} 
                    onPress={()=>{
                        showDialog();
                    }} 
                    size={18} 
                />
            </View>
          ),
        });
    }, [navigation]);

    return(
        <>
        <View style={styles.container}>
            <View style={{...styles.contentContainer,alignItems:'center'}}>
                <Text style={{color:'black',fontSize:35,fontWeight:'bold'}}>{currencyFormatter(amount??0)}</Text>
                <Text style={{fontSize:20,fontWeight:'bold',marginTop:'5%'}}>{name}</Text>
                <Text style={{color:colors.backdrop}}>{due>=0?dateFormatter(due??'','fullString'):''}</Text>
                <Text style={{color:colors.backdrop}}>{account? (account.title+' ('+account.type+')') : 'Cash'}</Text>
            </View>
            <Divider/>
            <TabView
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
                initialLayout={{ width: layout.width }}
                renderTabBar={(props:any)=>{
                    return(
                      <TabBar
                        {...props}
                        indicatorStyle={{ backgroundColor:colors.primary }}
                        style={{backgroundColor:colors.background,color:colors.primary}}
                        labelStyle={{color:colors.primary}}
                        renderLabel={({ route, focused, color }) => (
                          <Text 
                            style={{ 
                              color: focused? colors.primary : colors.placeholder, 
                              fontWeight: 'bold',
                              margin: 8 
                            }}
                          >
                            {route.title}
                          </Text>
                        )}
                      />
                    );
                  }
                  }
            />
            <DeleteBillAlert 
                DeleteBill={()=>{
                    DeleteBill(id)
                        .then(()=>{
                            navigation.goBack();
                        })
                        .catch((error:Error)=>{
                            alert(error.message);
                        })
                        .then(()=>{hideDialog()})
                }}
                hideDialog={hideDialog}
                visible={visible} 
            />
        </View>
        </> 
    )
}

interface SummaryProps {
    navigation:any
    route:any,
    showDialog: ()=>void
}
const Summary = ({navigation,route}:SummaryProps) => {
    const {id} = route.bill;
    const {colors} = useTheme();
    const {bill,GetSingleBill,UpdateBill}  = useBills({get:false});
    const RBRef = React.useRef<RBSheet | null>();
    const {account,amount,autoPay,complete,due,name,owed,repeats} = bill;

    if(!complete) RBRef.current?.open();

    React.useEffect(()=>{
        GetSingleBill(id);
    },[id]);

    

    return(
        <ScrollView contentContainerStyle={{flexGrow:1}} showsVerticalScrollIndicator={false}>
            <View>
            
            <List.Item 
                title={currencyFormatter(owed??0)}
                titleStyle={{color:owed&&owed>0?colors.error:owed<0?'green':colors.primary}}
                description={'Amount Owed'}
                descriptionStyle={{color:owed&&owed>0?colors.error:owed<0?'green':colors.primary}}
                left={props => 
                    <List.Icon 
                        {...props} 
                        color={owed&&owed>0?colors.error:owed<0?'green':colors.primary} 
                        icon={owed&&owed<0?"text-box-remove-outline" : "text-box-check-outline" }
                    />
                }
            />
            <List.Item 
                title={currencyFormatter(amount??0)}
                titleStyle={{color:colors.primary}}
                description={'Estimated monthly amount'}
                descriptionStyle={{color:colors.primary}}
                left={props => 
                    <List.Icon 
                        {...props} 
                        color={colors.primary} 
                        icon={"text-box-check-outline" }
                    />
                }
            />
            <List.Item 
                title={name}
                description={'Bill Name'}
                left={props => <List.Icon {...props} icon="file-document-outline" />}
            />
            {due>=0&&<List.Item 
                title={dateFormatter(due??'','fullString')}
                description={'Date'}
                left={props => <List.Icon {...props} icon="calendar" />}
            />}
            <List.Item
                description={repeats}
                left={props => <List.Icon {...props} icon="sync" />}
                onPress={()=>{}}
                title={'Repeats'}
            />
            {/* <List.Item
                description={autoPay?'ON':'OFF'}
                left={props => <List.Icon {...props} icon="cogs" />}
                onPress={()=>{}}
                right={props => <Switch {...props} color={colors.primary} onValueChange={()=>{}} value={autoPay} />}
                title={'Auto pay on due date'}
            /> */}
            </View>
            <BillRequiredFields bill={bill} navigation={route.navigation} RbRef={RBRef} UpdateBill={UpdateBill} />
        </ScrollView>
    )
};
  
const TransHistory = ({route}:any) => {
    const {bill,navigation} = route;
    const {colors} = useTheme();
    const { transactions, GetBillTransactions } = useTransactions({get:false,limit:-1});

    React.useEffect(()=>{
        GetBillTransactions(bill.id);
    },[]);

    function EmptyList(){
        return(
            <List.Item 
                description={Strings.EMPTY_TRANSACTION_LIST}
                title={''}
            />
        );
    }

    return(
        <FlatList 
            keyExtractor={(item)=>item.id}
            data={transactions}
            ListEmptyComponent={EmptyList}
            renderItem={({item})=><TransactionListItem item={item} navigation={navigation} />}
            showsVerticalScrollIndicator={false}
        />
    );
};
  
const renderScene = SceneMap({
    first: Summary,
    second: TransHistory,
});

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                //alignItems: 'center',
                borderColor: 'black',
                //borderBottomWidth: 1,
                height: Height/1.5,
                //justifyContent:'space-evenly',
                //marginBottom: 20,
                //width: Width
            },
            currencyInput:{
                fontSize: 20,
            },
            errorText: {
                color: colors.error,
            },
            textInput: {
                fontSize: 17,
                height: 50,
                marginBottom: 20,
                marginTop: 20,
                //width: '75%'
            },
        })
    );
}