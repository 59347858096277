// @ts-nocheck 
import React from 'react';
import { Ionicons } from '@expo/vector-icons';

export function Footer({contactEmail,facebook,instagram,twitter}:any) {
  return (
    <div className="contact-us-section">
    <div className="container">
      <div className="contact-grid">
        <div className="contact-right">
          <a href="#">
            <img className="logo" alt="Legacy Logo" src="img/logo.png" />
          </a>
          <p className="text">© 2021 Legacy Financial</p>
          <br />
          <p className="text">
            Phone:
            <a className="footer-text" href="tel:571-263-7917"> 571-263-7917</a>
          </p>
          <p className="text">
            Email:
            <a className="footer-text" href={"mailto:"+contactEmail}>{" "+contactEmail}</a>
          </p>
          {/* <p class="text">Developed By : Cenedex Software Solutions</p> */}
        </div>
        <div className="contact-left">
          <div className="social-links">
            {facebook && <a href={facebook} target="_blank" rel="noopener noreferrer" className="icon"><ion-icon className="icon" name="logo-facebook" /></a>}
            {twitter && <a href={twitter} target="_blank" rel="noopener noreferrer" className="icon"><ion-icon className="icon" name="logo-twitter" /></a>}
            {instagram && <a href={instagram} target="_blank" rel="noopener noreferrer" className="icon"><ion-icon className="icon" name="logo-instagram" /></a>}
          </div>
          <div className="contact-links">
            <a href="#home">Home</a>
            <a href="#app-features">App Features</a>
            <a href="/d/privacypolicy">Privacy Policy</a>
            <a href="/d/termsandconditions">Terms &amp; Conditions</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
