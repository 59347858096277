import React from 'react';
import { StyleSheet, Text, View, } from 'react-native';
import { useTheme } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';

interface props {
    message: string
}
export default function ErrorScreen({ message }: props) {
    const { colors } = useTheme();
    const styles = Styles(colors);
    return (
        <View style={styles.container}>
            <MaterialIcons name="error-outline" size={75} color={colors.error} />
            <Text style={styles.errorText}>{message}</Text>
        </View>
    )
}

const Styles = (colors: any) => {
    return (
        StyleSheet.create({
            container: {
                alignContent: 'center',
                alignItems: 'center',
                backgroundColor: colors.background,
                flex: 1,
                justifyContent: 'center',
                padding: 20,
            },
            errorText: { 
                color: 'red', 
                fontSize:18, 
                marginBottom: 20,
                marginTop: 20,
                textAlign: 'center' 
            },
        })
    );
}