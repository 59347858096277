import React from 'react';
import { Dimensions, ScrollView, StyleSheet, RefreshControl, View } from 'react-native';
import { Button, Divider, IconButton, List, useTheme } from 'react-native-paper';
import RBSheet from "react-native-raw-bottom-sheet";
import dayjs from 'dayjs';
import ListEmpty from '../components/ListEmpty';
import TransactionListItem, {TransactionList} from '../components/TransactionListItem';
import { AppContext } from '../context';
import { useAccounts } from '../hooks/useAccounts';
import { useTransactions } from '../hooks/useTransactions';
import { usePlaidLinks } from '../hooks/usePlaidLinks';
import { AnimatedProgressBar } from '../components/LoadingModal';
import { useDailyTotals } from '../hooks/useDailyTotals';
//@ts-ignore
import { HomeAccountsList } from '../components/HomeAccountsList';
import { HomeSummary } from '../components/HomeSummary';

export const Width = Dimensions.get('window').width;

export default function HomeScreen({ navigation }: any) {
  const { colors } = useTheme();
  const styles = Styles(colors);
  const { state } = React.useContext(AppContext);
  const { user } = state;

  const { loading: homeLoading, homeAccounts, netWorth } = usePlaidLinks('home');
  const { accounts, loading: accLoading, errorMessage: refreshErr, summary, RefreshAccounts } = useAccounts();
  const Year = dayjs().year().toString();
  //const {error,errorMessage,loading,summary: sum} = useFundsSummary(Year);
  const { error, errorMessage, loading, currentSpending, spendingDifference } = useDailyTotals(user.uid, true);
  const { transactions, } = useTransactions({ selection: 'yearly' });
  const RBRef = React.useRef<RBSheet | null>();
  const [spendFree, setSpendFree] = React.useState(0);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={{ alignItems: 'center', backgroundColor: colors.accent, borderRadius: 24 / 2, height: 24, justifyContent: 'center', marginRight: 20, width: 24, }}>
          {/*@ts-ignore*/}
          <IconButton color={colors.primary} icon={'plus'} onPress={() => { RBRef.current?.open() }} size={18} />
        </View>
      ),
    });
  }, [navigation]);

  return (
    <View style={styles.container}>
      <ScrollView
        bounces={true}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            colors={[colors.primary]}
            refreshing={false}
            onRefresh={() => { RefreshAccounts().then((res) => { if (res) alert(res) }) }}
          />
        }
      >

        {HomeSummary({ currentSpending, netWorth, spendFree, spendingDifference, setSpendFree })}

        {HomeAccountsList({ homeAccounts, navigation })}

        <TransactionList navigation={navigation} />

      </ScrollView>
      {/*@ts-ignore*/}
      <RBSheet
        ref={ref => { RBRef.current = ref }}
        closeOnDragDown={true}
        height={300}
        openDuration={250}
        customStyles={{
          container: {
            borderRadius: 20,
            padding: 20
          }
        }}
      >
        <ScrollView showsVerticalScrollIndicator={false}>
          {/*@ts-ignore*/}
          <List.Item
            left={props => <List.Icon {...props} icon="bank-outline" />}
            onPress={() => {
              RBRef.current?.close();
              navigation.navigate('Plaid', { type: '', accounts: homeAccounts });
            }}
            right={(props: any) => <List.Icon {...props} icon={'chevron-right'} />}
            title={'Add An Account'}
          //description={'(Bank,Credit Card,Loan,Investment)'}
          />

          {/*@ts-ignore*/}
          <List.Item
            left={props => <List.Icon {...props} icon="bank-outline" />}
            onPress={() => {
              RBRef.current?.close();
              navigation.navigate('Add Account',{type:''});
            }}
            right={(props: any) => <List.Icon {...props} icon={'chevron-right'} />}
            title={'Manually add account'}
          //description={'(Bank,Credit Card,Loan,Investment)'}
          />
          {/* <List.Item 
          left={props => <List.Icon {...props} icon="text-box-outline" />}
          onPress={()=>{
            RBRef.current?.close();
            navigation.navigate('Add Bill');
          }}
          right={(props:any)=><List.Icon {...props} icon={'chevron-right'} />}
          title={'Add A Bill'} 
          //description={'Bills are added automatically from connected accounts.'}
        /> */}
          <Divider />
          {/*@ts-ignore*/}
          {/* <List.Item
            left={props => <List.Icon {...props} icon="file-document-outline" />}
            onPress={() => {
              RBRef.current?.close();
              navigation.navigate('Add Transaction');
            }}
            right={(props: any) => <List.Icon {...props} icon={'chevron-right'} />}
            title={'Add A Transaction'}
          //description={'Transactions are added automatically from connected accounts.'}
          /> */}
        </ScrollView>
      </RBSheet>
      {(homeLoading || accLoading) && <AnimatedProgressBar />}
    </View>
  )
}

export const Styles = (colors: any) => {
  const Maxwidth = 1000;
  const windowHeight = Dimensions.get('window').height;
  return StyleSheet.create({
    container: {
      backgroundColor: colors.background,
      flex: 1,
      //height: '100%',
    },
    button: {
      borderColor: 'black',
      borderWidth: 1,
      height: 50,
      margin: '5%',
      width: '40%',
    },
    buttonsContainer: {
      alignSelf: 'center',
      maxWidth: Maxwidth,
      padding: 20,
      width: '100%',
    },
    fundsSummary: {
      backgroundColor: colors.headerBackground,
      height: windowHeight * 0.52,
      paddingTop: 20,
      padding: 20,
      width: Width
    },
    labelText: {
      color: colors.accent,
      fontSize: Width > 500 ? 16 : 12,
    },
    spendDiff: {
      color: colors.accent,
      fontSize: Width > 500 ? 18 : 12,
      fontWeight: 'bold',
      //marginBottom: 10,
      //marginRight: 20
    },
    monthlyContainer: {
      bottom: 10,
      flexDirection: 'row',
    },
    totalBalance: {
      color: colors.accent,
      fontSize: Width > 500 ? 30 : 18,
      fontWeight: 'bold',
    },
    totalContainer: {
      justifyContent: 'center',
    },
    totalMonthlyBalance: {
      color: colors.accent,
      fontSize: 18,
      fontWeight: 'bold',
    },
    transactionsContainer: {
      alignSelf: 'center',
      marginTop: -20,
      maxWidth: Maxwidth,
      width: '90%',
    },
  });
} 