import React from 'react';
import { StyleSheet, View, } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { useDocs } from '../../hooks/useDocs';
import { About } from './About';
import { Banner } from './Banner';
import { ContactUs } from './ContactUs';
import { Counter } from './Counter';
import { Download } from './Download';
import { Features } from './Features';
import { Footer } from './Footer';
import { Headings } from './Headings';
import { Pricing } from './Pricing';
import { PromotionalVideo } from './PromotionalVideo';
import { Team } from './Team';
import { Testimonials } from './Testimonials';
import Strings from '../../constants/Strings';

export default function LandingPage() {
  const { colors } = useTheme();
  const styles = Styles(colors);

  const {loading,landing} = useDocs();

  if(loading) return (
    <div style={{display:'flex',alignContent:'center',alignItems:'center',alignSelf:'center',flex:1,justifyContent:'center'}}>
      <img src={Strings.LOGO_NO_BACKGROUND} width={200} height={200}/>
    </div>
  )

  return (
    <div>
      <Headings />
      <Banner appstoreUrl={landing.appstoreUrl} playstoreUrl={landing.playstoreUrl} headingSubTitle={landing.headingSubTitle} headingTitle={landing.headingTitle} />
      <About
        about1Des={landing.about1Des}
        about2Des={landing.about2Des}
        about3Des={landing.about3Des}
        about1Title={landing.about1Title}
        about2Title={landing.about2Title}
        about3Title={landing.about3Title}
       />
      <Features 
        feat1Des={landing.feat1Des}
        feat2Des={landing.feat2Des}
        feat3Des={landing.feat3Des}
        feat4Des={landing.feat4Des}
        feat5Des={landing.feat5Des}
        feat6Des={landing.feat6Des}
        feat1Title={landing.feat1Title}
        feat2Title={landing.feat2Title}
        feat3Title={landing.feat3Title}
        feat4Title={landing.feat4Title}
        feat5Title={landing.feat5Title}
        feat6Title={landing.feat6Title}
        featSubtitle={landing.featSubtitle}
      />
      <PromotionalVideo />
      {/* <Pricing /> */}
      <Download 
        appstoreUrl={landing.appstoreUrl}
        downloadSubTitle={landing.downloadSubTitle}
        downloadTitle={landing.downloadTitle}
        playstoreUrl={landing.playstoreUrl}
      />
      <Counter />
      {/* <Team /> */}
      {/* <Testimonials /> */}
      {/* <ContactUs 
        contactEmail={landing.contactEmail}
      /> */}
      <Footer
        contactEmail={landing.contactEmail}
        facebook={landing.facebook}
        instagram={landing.instagram}
        twitter={landing.twitter}
      />
    </div>

  );
}

const Styles = (colors: any) => {
  return (
    StyleSheet.create({})
  );
}