import React from 'react';
import * as Updates from 'expo-updates';
import { AppContext } from '../context';
import { Firebase, FirebaseAuth, Firestore } from '../constants/Firebase';
import { useRefreshBank } from './useRefreshBank';
import { GoogleAuthProvider } from "firebase/auth";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getReactNativePersistence } from 'firebase/auth/react-native';
import { GoogleSignin, statusCodes } from '../constants/google-signin';
import Strings from '../constants/Strings';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Crypto from 'expo-crypto';
const {useEffect,useState}=React;
 
interface Props{
    checkLogin?:boolean
}
export const useAuthentication = ({checkLogin=false}:Props)=>{
    const { dispatch } = React.useContext(AppContext);
    const [authState,setAuthState] = React.useState({loading:true});
    const {checkForRefresh} = useRefreshBank();

    const Ref = Firestore.collection(Firestore.getFirestore(),'Users');
    
    const CheckLogin = async ()=>{
        return FirebaseAuth.onAuthStateChanged(FirebaseAuth.getAuth(), async (user) => {
            if(user) {
                if((user.phoneNumber && !user.email)) {
                    return;
                }
                let initialRoute = user.emailVerified ? 'Home' : 'UnverifiedScreen';
                if(!user.emailVerified){
                    pollVerification(user);
                }
                const userRef = Firestore.doc( Firestore.getFirestore(), 'Users', user.uid );
                Firestore.getDoc( userRef )
                .then(async(userData)=>{
                    const isAdmin = await user.getIdTokenResult().then((idTokenResult) => { return (!!idTokenResult.claims.admin)}).catch((error)=>{return false});
                    const data = {...userData.data(),isAdmin};
                    //@ts-ignore
                    if( data?.onBoardComplete )
                        dispatch({ type: 'setUserInitial', payload: {user:data,isloggedIn:true,initialRoute} });
                    else
                        dispatch({ type: 'setUserInitial', payload: {user:data,isloggedIn:false,initialRoute} });
                    return isAdmin;
                })
                .then((isAdmin)=>{
                    Firestore.onSnapshot(userRef,(userSnap)=>{
                        const data = userSnap.data();
                        if( data?.onBoardComplete ){ dispatch({type:'setLogin',payload:true}) }
                        else { dispatch({type:'setLogin',payload:false}) }
                        dispatch({ type: 'setUser', payload:{...data,isAdmin} });
                    },(error)=>{
                        dispatch({ type: 'setUserInitial', payload: {user:{},isloggedIn:false} });
                        setAuthState({loading:false});
                    })
                    const subscriptionRef = Firestore.doc(Firestore.getFirestore(),'Subscriptions',user.uid);
                    Firestore.onSnapshot(subscriptionRef,(snap)=>{
                        if(!snap.exists) return;
                        const data = snap.data();
                        dispatch({type:'setSubscription',payload:data});
                    },
                    (error)=>{
                        console.log('subscriptionSnapshot: ', error.message);
                    });
                })
                .then(()=>{
                    setAuthState({loading:false});
                })
                .catch((error)=>{
                    console.log('error: ', error.message);
                    Logout();
                    dispatch({ type: 'setUserInitial', payload: {user:{},isloggedIn:false,initialRoute:''} });
                    setAuthState({loading:false});
                })
                //dispatch({ type: 'setUserInitial', payload: {user,isloggedIn:true} });
            }
            else {
                Logout();
                setAuthState({loading:false});
            }
        });
    }

    const SetAuthPersistence = async (persist:boolean)=>{
        if(persist){
            return FirebaseAuth.setPersistence(FirebaseAuth.getAuth(),getReactNativePersistence(AsyncStorage))
        }
        else 
            return FirebaseAuth.setPersistence(FirebaseAuth.getAuth(),FirebaseAuth.inMemoryPersistence)
    }

    interface loginParams {email: string,password:string}
    const Login = async ({email,password}:loginParams)=>{
        return SetAuthPersistence(false)
        .then(()=>{
            return FirebaseAuth.signInWithEmailAndPassword(FirebaseAuth.getAuth(),email, password)
            .then((user)=>{
                
            })
        })
        .catch((error)=>{
            switch(error.code){
                case 'auth/network-request-failed':{
                    throw new Error("Unable to connect to network, please try again later.");
                    break;
                }
                default:{
                    throw new Error("Invalid login credentials.");
                    break;
                }
            }
        })
    }
    const Logout = async ()=>{
        AsyncStorage.clear();
        GoogleSignin.signOut();
        FirebaseAuth.signOut(FirebaseAuth.getAuth());
        dispatch({type:'reset',payload:true});
    }
    const pollVerification = async (user:any) => {
        let timeout = 0;
        const poll = new Promise((resolve, reject) => {
          const pollInterval = setInterval(async () => {
            await user.reload();
            user = await FirebaseAuth.getAuth().currentUser;
            const provider = user?.providerData[0]?.providerId;
            //console.log('provider: ', provider);
            const isVerified = (provider == 'password' || provider == 'phone') ? user?.emailVerified : true;
            //console.log('check Verifiy',isVerified);
            if (isVerified) {
              //clearInterval();
              //getUserData();
              resolve(pollInterval);
            }
            if (timeout >= 4000) {
              //timeout after 15minutes
              reject('time out');
            }
            timeout++;
          }, 1000);
        });
    
        poll.then(
          async function (value: any) {
            //console.log('resolved');
            clearInterval(value);
            await Updates.reloadAsync();
          },
          function (error) {
            console.log('error: ', error.message);
            //@ts-ignore
            clearInterval();
          }
        )
        .catch((error)=>{
            console.log('error: ', error.message);
            //@ts-ignore
            clearInterval();
          });
      };
    interface signupParams2 {name:string,phone:string,email: string,password:string}
    const SignupWithoutPhone = async ({name,phone,email,password}:signupParams2)=>{
        await SetAuthPersistence(false);
        return FirebaseAuth.createUserWithEmailAndPassword(FirebaseAuth.getAuth(),email, password)
        .then((userCredential) => {
            // Signed in 
            return userCredential.user;
          })
          .catch((error) => {
            //var errorCode = error.code;
            const errorMessage = error.message;
            throw new Error(errorMessage);
          })
          .then((user)=>{
            return afterSignup(user,email,'',name);
          })
          .catch(async (error:any)=>{
            Logout();
            throw new Error(error.message);
        });
        

    }  

    interface signupParams {name:string,phone:string,email: string,password:string,phoneCredentials:any}
    const Signup = async ({name,phone,email,password,phoneCredentials}:signupParams)=>{
            
    }

    const SignInWithGoogle = async()=>{
        try{
            await SetAuthPersistence(false);
            GoogleSignin.configure({
                webClientId:Strings.WEB_CLIENT_ID,
            });
            await GoogleSignin.hasPlayServices();
            await GoogleSignin.signOut();
            const userInfo = await GoogleSignin.signIn();
            //console.log('userInfo: ', userInfo);
            const credentials = GoogleAuthProvider.credential(userInfo.idToken);

            const signInResponse = await FirebaseAuth.signInWithCredential(FirebaseAuth.getAuth(), credentials);
            const user = signInResponse.user;
            const userRef = Firestore.doc( Firestore.getFirestore(), 'Users', user.uid );
            const userData = await Firestore.getDoc( userRef );
            if ( userData.exists() && userData.data().onBoardComplete){
                return true;
            }
            return afterSignup(user,userInfo.user.email,'',userInfo
                .user.name??''
            );
        }
        catch(error:any){
            //console.log('signInWithGoogleErr: ', error.code);
            throw new Error('Unable to login at this time. Please try again later');
            switch (error.code) {
                case statusCodes.SIGN_IN_CANCELLED:
                  // user cancelled the login flow
                  throw new Error(error.message);
                  break;
                case statusCodes.IN_PROGRESS:
                  // operation (eg. sign in) already in progress
                  throw new Error(error.message);
                  break;
                case statusCodes.PLAY_SERVICES_NOT_AVAILABLE:
                  // play services not available or outdated
                  throw new Error(error.message);
                  break;
                default:
                // some other error happened
                Logout();
                throw new Error('Unable to login at this time. Please try again later');
                break;
            }
        }
    }
    
    const SignInWithApple = async()=>{
        try {
            await SetAuthPersistence(false);
            const nonce = Math.random().toString(36).substring(2, 10);
            const hashedNonce = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce);
            const credential = await AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    AppleAuthentication.AppleAuthenticationScope.EMAIL,
                ],
                nonce: hashedNonce
            });
            const { identityToken } = credential;
            //console.log('credential: ', credential);
            if(!identityToken) throw new Error('Unable to sign you in at this time');

            const provider = new FirebaseAuth.OAuthProvider('apple.com');
            const userCredential = provider.credential({
                idToken: identityToken,
                rawNonce: nonce
            });
            const signInResponse = await FirebaseAuth.signInWithCredential(FirebaseAuth.getAuth(),userCredential);
            //console.log('signInResponse: ', signInResponse);
            const user = signInResponse.user;
            //console.log('user: ', user);

            const emailText = user.email? user.email.substring(0, user.email.indexOf("@")) : '';
            const name = !credential.fullName?.givenName? emailText : credential.fullName.givenName+' '+credential.fullName.familyName;

            const userRef = Firestore.doc( Firestore.getFirestore(), 'Users', user.uid );
            const userData = await Firestore.getDoc( userRef );
            if ( userData.exists() && userData.data().onBoardComplete){
                return true;
            }
            
            return afterSignup(user,user.email??'','',name??'');
            return true;

        } catch (error:any) {
            if (error.code === 'ERR_REQUEST_CANCELED') {
            // handle that the user canceled the sign-in flow
            } else {
            // handle other errors
            }
            throw new Error(error.message);
        }
    }

    const afterSignup = async(user:any,email:string,phone:string,name:string)=>{
        const userRef = Firestore.doc( Firestore.getFirestore(), 'Users', user.uid );
        Firestore.setDoc(userRef,{
            email,
            id:user.uid,
            name,
            phone,
            totalFunds: 0,
            uid:user.uid,
        },{merge:true})
        .catch(()=>{})
        .then(()=>{
            // return AccountsRef.doc(user.uid).set({
            //     cash:{key:'cash',title:'Cash',total:'0.00',accounts: []},
            //     credit:{key:'credit',title:'Credit',total:'0.00',accounts: []},
            //     loans:{key:'loans',title:'Loans',total:'0.00',accounts: []},
            //     investment:{key:'investment',title:'Investment',total:'0.00',accounts: []},
            // });
        })
        .then(()=>{
            const currentUser = FirebaseAuth.getAuth().currentUser;
            if(!currentUser) return;
            FirebaseAuth.sendEmailVerification(currentUser);
        })
    }
 
    useEffect(()=>{
        let unSubscribe: any = false;
        if(checkLogin) unSubscribe = CheckLogin();

        return ()=>{
            //if(unSubscribe) return unSubscribe();
        }
    },
    []);
    return {...authState,CheckLogin,Login,Logout,SetAuthPersistence,SignInWithApple,SignInWithGoogle,Signup,SignupWithoutPhone};
}