import React from 'react';
import {Image, Platform, ScrollView, StyleSheet,View} from 'react-native';

export default function SignUpScreen({navigation}:any){ 
    
    
    return(
        <View></View>
    )
}

const Styles = (colors:any)=>{
    const isDevice = (Platform.OS == 'ios' || Platform.OS == 'android')
    return (
        StyleSheet.create({
        })
    );
}