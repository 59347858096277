import React from 'react';
import { Dimensions, Platform, ScrollView, Text, StyleSheet,View, Alert,} from 'react-native';
import {Avatar, Button, Divider, IconButton, List, useTheme} from 'react-native-paper';
import RBSheet from "react-native-raw-bottom-sheet"
import DateTimePicker from '@react-native-community/datetimepicker';
import { TransactionProps, useTransactions } from '../hooks/useTransactions';
import { currencyFormatter, dateFormatter } from '../constants/utils';
import { LoadingModal } from '../components/LoadingModal';
import SelectBillModal from '../components/SelectBillModal';
import CategoriesPicker from '../components/CategoriesPicker';

export default function ViewTransactionsScreen({navigation,route}:any){ 
    const {id,account} = route?.params?.transaction ?? {};
    const cat = account?.type??'';
    
    const InitialFormValues = {
        amount: 0,
        date: Date.now(),
        error: '',
        name: '',
        type: 'expense'
    }

    const {colors} = useTheme();
    const styles = Styles(colors);
    const {loading,transaction,AddBillToTransaction,DeleteTransaction,GetSingleTransaction,RemoveBillFromTransaction,UpdateTransaction,UpdateTransactionCategory}  = useTransactions({get:false});

    const RBRef = React.useRef<RBSheet | null>();
    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [trans,setTrans] = React.useState<TransactionProps>();
    const [updated,setUpdated] = React.useState(false);
    
    React.useLayoutEffect(() => {
        navigation.setOptions({
          headerRight: () => (
            <View style={{alignItems:'center',borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
                {/*@ts-ignore*/}
                <IconButton 
                    color={colors.primary} 
                    icon={'delete'} 
                    onPress={()=>{
                        DeleteTransaction(transaction)
                        .then((res:any)=>{
                            if(!res.success) alert('Unable to delete your transaction at this time.');
                            navigation.goBack();
                        })
                        .catch((error:Error)=>{
                            console.log(error.message);
                            alert('Unable to delete your transaction at this time.');
                        })
                    }} 
                    size={18} 
                />
            </View>
          ),
        });
    }, [navigation,transaction]);

    React.useEffect(()=>{GetSingleTransaction(id,cat);},[]);
    React.useEffect(()=>{setTrans(transaction);},[transaction]);
    React.useEffect(()=>{setUpdated(JSON.stringify(trans)!=JSON.stringify(transaction));},[trans]);
    
    return(
        <View style={styles.container}>
            <LoadingModal visible={loading} />
            <ScrollView contentContainerStyle={{flexGrow:1}}>
                <View style={{...styles.contentContainer,alignItems:'center'}}>
                    <Text style={{color:'black',fontSize:35,fontWeight:'bold'}}>{currencyFormatter(Number(trans?.amount))}</Text>
                    <Text style={{fontSize:20,fontWeight:'bold',marginTop:'5%'}}>{trans?.name}</Text>
                    <Text style={{color:colors.backdrop}}>{dateFormatter(trans?.date,'fullString')}</Text>
                    <Text style={{color:colors.backdrop}}>{trans?.account? (trans?.account.title+' ('+trans?.account.type+')') : 'Cash'}</Text>
                </View>
                <Divider/>
                <View>
                    {/*@ts-ignore*/}
                    <List.Item 
                        title={trans?.name}
                        description={'Merchant'}
                        left={(props)=>
                            trans?.logo_url?
                            <View {...props}>
                                <Avatar.Image size={30} style={{marginTop:8}} source={{uri:trans.logo_url}} />
                            </View>
                            :
                            <List.Icon {...props} icon={trans?.icon??"file-document-outline"} />
                        }
                    />
                    <Divider />

                    {/*@ts-ignore*/}
                    <List.Item 
                        title={dateFormatter(trans?.date,'fullString')}
                        description={'Date'}
                        left={props => <List.Icon {...props} icon="calendar" />}
                        //onPress={()=>setShowDatePicker(true)}
                    />
                    <Divider />

                    {/*@ts-ignore*/}
                    <List.Item 
                        description={//trans?.category?trans?.category[0]:''
                            <CategoriesPicker 
                                value={trans?.category?trans?.category[0]:''} 
                                onChange={(value)=>{
                                    if(trans){
                                        setTrans({
                                            ...trans,
                                            category:[value]
                                        })
                                    }
                                }} 
                            />
                        }
                        left={props => <List.Icon {...props} icon="shape" />}
                        right={props => <List.Icon {...props} icon="chevron-down" />}
                        title={'Category'}
                    />
                    <Divider />
                    {/*@ts-ignore*/}
                    {/* <List.Item 
                        title={
                            trans?.billId?
                            trans?.bill?.name
                            :
                                'Mark as bill?'    
                         }
                        description={trans?.billId&&'Bill Name'}
                        left={props => <List.Icon {...props} icon="text-box-outline" />}
                        right={props => trans?.billId?<List.Icon {...props} icon="delete" />:<View/>}
                        onPress={()=>{!trans?.billId?RBRef.current?.open():RemoveBillFromTransaction(id)}}
                    />
                    <Divider /> */}
                </View>
                {true &&
                    <Button 
                        mode={'contained'}
                        color={colors.primary}
                        disabled={loading}
                        loading={loading}
                        uppercase={false}
                        onPress={()=>{
                            if(trans) {
                                UpdateTransactionCategory(trans)
                                .then((success)=>{
                                    if(success){
                                        //navigation.goBack();
                                        navigation.setParams({reset:true});
                                        navigation.pop(2);
                                    }
                                });
                            }
                        }}
                        style={styles.button}
                    >
                        Update Transaction
                    </Button>
                }

                {/* <Button
                    color={colors.error}
                    onPress={()=>{
                        Alert.alert("Delete This Account","Are you sure you want to delete this transaction?",
                        [
                            {text: 'Cancel',style:'cancel'},
                            {
                                text:'delete',
                                onPress: ()=>{
                                    console.log('transaction: ', transaction.id); return;
                                    DeleteTransaction(transaction)
                                    .then((res:any)=>{
                                        if(!res.success) alert('Unable to delete your transaction at this time.');
                                        navigation.goBack();
                                    })
                                    .catch((error:Error)=>{
                                        console.log(error.message);
                                        alert('Unable to delete your transaction at this time.');
                                    })
                                }
                            }
                        ]);
                    }}
                    style={styles.button}
                    uppercase={false}
                >
                    Delete Account
                </Button> */}

            </ScrollView>
            {showDatePicker && (
                <View>
                    {Platform.OS === 'ios' && (
                        <Button onPress={() => setShowDatePicker(false)}>Close</Button>
                    )}
                    <DateTimePicker
                        testID="dateTimePicker"
                        value={new Date(trans?.date??'')}
                        mode={'date'}
                        display={Platform.OS === 'ios'?"spinner":"default"}
                        onChange={(event:any, selectedDate:Date | undefined)=>{
                            const currentDate = selectedDate?.getTime() ?? Date.now();
                            setShowDatePicker(Platform.OS === 'ios');
                            if(trans)setTrans({...trans,date: currentDate});
                        }}
                    />
                </View>
            )}
            <SelectBillModal 
                AddBillToTransaction={AddBillToTransaction}
                navigation={navigation}
                RBRef={RBRef}
                TransactionId={id}
            />
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            button: {
                alignSelf:'center',
                borderRadius: 10,
                height: Width>500?75:55,
                justifyContent: 'space-around',
                marginTop: '4%',
                width:'75%'
            },
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                //alignItems: 'center',
                borderColor: 'black',
                //borderBottomWidth: 1,
                minHeight: Height/1.5,
                paddingBottom: 10,
                //justifyContent:'space-evenly',
                //marginBottom: 20,
                //width: Width
            },
            currencyInput:{
                fontSize: Height*0.2,
                height: '100%',
            },
            errorText: {
                color: colors.error,
            },
            textInput: {
                fontSize: 17,
                height: 50,
                marginBottom: 20,
                marginTop: 20,
                //width: '75%'
            },
        })
    );
}