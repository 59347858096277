import React, { RefObject } from 'react';
import {FlatList, StyleSheet, Text, View,} from 'react-native';
import {Card, Divider, IconButton, useTheme} from 'react-native-paper';
import RBSheet from "react-native-raw-bottom-sheet";
import Strings from '../constants/Strings';
import { BillProps, useBills } from '../hooks/useBills';
import { BillsList } from './BillList';

interface SelectBillProps{
    AddBillToTransaction?: (id:string,item:BillProps)=>void
    navigation: any
    onPress?: (bill:BillProps)=>void
    RBRef: any
    TransactionId?: string
}
export default function SelectBillModal({AddBillToTransaction,navigation,onPress,RBRef,TransactionId}:SelectBillProps){ 
    const {colors} = useTheme();
    const styles = Styles(colors);

    const {bills} = useBills({limit:-1});
    
    return(
        <RBSheet
                ref={ref => {RBRef.current = ref}}
                closeOnDragDown={true}
                height={400}
                openDuration={250}
                customStyles={{
                    container: {
                        borderRadius:20,
                        padding:20
                    }
                }}
                >
                <View>
                    <Card.Title 
                        right={(props)=>
                            <View style={{alignItems:'center',backgroundColor:colors.primary,borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
                            <IconButton 
                                {...props} 
                                color={colors.accent}
                                icon={'plus'} 
                                onPress={()=>{
                                    RBRef.current?.close();
                                    navigation.navigate('Add Bill')
                                }} 
                            />
                            </View>
                        }
                        title={'List of Bills'}
                    />
                    <Divider style={{marginBottom:20}} />
                    <FlatList 
                        data={bills}
                        keyExtractor={(item)=>item.id}
                        ListEmptyComponent={<Text style={{textAlign:'center'}}>{Strings.EMPTY_BILLS_LIST}</Text>}
                        renderItem={({item})=>
                            <BillsList 
                                bill={item} 
                                onPress={()=>{
                                    if(onPress){
                                        onPress(item);
                                        return;
                                    }
                                    RBRef.current?.close();
                                    if(AddBillToTransaction)
                                        AddBillToTransaction(TransactionId??'',item);
                                }} 
                            />
                        }
                        showsVerticalScrollIndicator={false}
                    />
                </View>
            </RBSheet>
    )
}

const Styles = (colors:any)=>{
return (
StyleSheet.create({})
);
}