import React from 'react';
import { AppContext } from '../context';
import Strings from '../constants/Strings';
import { useUser } from './useUser';
import { useAccounts } from './useAccounts';

const {useEffect,useState}=React;
const {
    APP_MODE,
    PLAID_ACCESS_TOKEN_URL,
    PLAID_ACCOUNT_URL,
    PLAID_INITIAL_PULL,
    PLAID_TOKEN_URL,
  } = Strings;

const mode = APP_MODE === 'production' ?
  'prod'
  :
  APP_MODE === 'staging' ?
    'staging'
    :
    'dev';

interface startPladBody{
    accessToken?:string
    mode: 'dev'|'prod'|'staging'
    uid:string
}
 
export const usePlaid = (start=true,goBack=(s=false)=>{})=>{
    const { UpdateUserData } = useUser();
    const { accounts: allAccounts, BulkAddAccount } = useAccounts();
    const [state, setState] = useState({ loading: false, linkToken: null, error: false, errorMessage: '' });
    const [currentBank, setCurrentBank] = React.useState({ id: '', name: '', accessToken: '', item_id: '' });
    const [plaidItemId, setPlaidItemId] = React.useState('');
    const [accounts, setAccounts] = React.useState<[] | null>();

    const { state: AppState } = React.useContext(AppContext);
    const { user } = AppState;

    async function _handleURL(url: string,isRefresh=false,refreshSuccess:(bankId:string,bankName:string)=>void=()=>{}){
        const URL = decodeURIComponent(url);
        //console.log('URL: ', URL);
        if (URL.includes("EXIT")) {
            goBack();
            return;
        }
        if (URL.includes("connected")){
            let URLstring = URL.replace("plaidlink://connected?account_id&account_mask&account_name&account_subtype&account_type&accounts=", "");
            const URLstringArr = URLstring.split("&");

            const reg = new RegExp('plaidlink*');
            const token = URLstringArr.filter((item) => {
                return typeof item == 'string' && item.match(reg);
            })
            const public_token = (token[0].split("="))[1];
            await fetchAccessToken(public_token);
        }
        else if (URL.includes("event")) {
            let urlParamsArr: any = URL.split('?')[1].toString();
            urlParamsArr = urlParamsArr.split('&');
            let params: any = {};
            urlParamsArr.forEach(function (property: any) {
                var tup = property.split('=');
                params[tup[0]] = tup[1];
            });
            
            switch (params['event_name']) {
                case 'SUBMIT_CREDENTIALS':
                case 'SELECT_INSTITUTION': { 
                    if(isRefresh){
                        break;
                    }
                    const id = params['institution_id'];
                    const name = params['institution_name'];
                    setCurrentBank({ id, name, accessToken: '', item_id: '' });
                    break;
                }
                case 'TRANSITION_VIEW':{ 
                    if(!isRefresh)break;
                    
                    const id = params['institution_id'];
                    const name = params['institution_name'];
                    setCurrentBank({ id, name, accessToken: '', item_id: '' });
                    break;
                }
                case 'HANDOFF': {
                    if(isRefresh){
                        const institution_id = params['institution_id'];
                        const institution_name = params['institution_name'];
                        
                        refreshSuccess(institution_id,institution_name);
                        break;
                    }
                }
                default: { }
            }
        }
        else if (URL.includes("plaidlink://exit?")) {
            let urlParamsArr:any = URL.split('?')[1].toString();
            urlParamsArr = urlParamsArr.split('&');
            let params:any = {};
            urlParamsArr.forEach(function (property:any) {
                var tup = property.split('=');
                params[tup[0]] = tup[1];
            });
            console.log('errorCode',params['error_code']);
            console.log('errorMsg',decodeURI(params['error_message']));
            catchErrors('An error occurred while connecting to plaid.\n Please try again later or contact support if problem persists.','_handleURL');
        }
    }

    function catchErrors(error:any,funcName:string){
        console.log('funcName: ',funcName,'error: ', error);
        setState({
            ...state,
            error:true,
            errorMessage:error.message,
            loading:false
        });
    }

    async function fetchAccessToken(public_token:string){
        return fetch(PLAID_ACCESS_TOKEN_URL, {
            "method": "POST",
            "headers": {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              uid: user.id,
              mode: mode,
              accessToken: public_token
            })
        })
        .then(response => response.json())
        .then((json)=>{
            if (json.error) {throw new Error(json.message);}
            if (json.access_token != null) {getBankAccounts(json.access_token,currentBank);}
            else{throw new Error(json.error_message);}
        })
        .catch((error:any)=>catchErrors(error,'fetchAccessToken'));
    }

    async function getBankAccounts(access_token: string, exist:false|typeof currentBank = false){
        // if (!exist) {
        //     //@ts-ignore
        //     setCurrentBank({ ...exist });
        // }
        const bank = exist? exist : currentBank;
        setState({...state,loading:true});
        fetch(PLAID_ACCOUNT_URL, {
            "method": "POST",
            "headers": {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              uid: user.id,
              mode: mode,
              accessToken: access_token,
              bankId: bank.id,
              itemId: bank.item_id,
              
            })
        })
        .then(response => response.json())
        .then(async (json)=>{
            //console.log('json: ', json,'json.accounts: ',json.accounts);
            if (json.error) {throw new Error(json.message);}
            setPlaidItemId(json.item.item_id);
            setCurrentBank({ ...bank, item_id: json.item.item_id, accessToken:access_token });
            setAccounts(json.accounts);
            //console.log('allAccounts: ', allAccounts);
            await BulkAddAccount(json.accounts,access_token, bank.name, bank.id, json.item.item_id, allAccounts);
            
            // if(user.plaidLinks) {
            //     user.plaidLinks[bank.id] = { ...bank, item_id: json.item.item_id, accessToken:access_token };
            // }
            // else {
            //     user.plaidLinks = { [bank.id]: { ...bank, item_id: json.item.item_id, accessToken:access_token }};
            // }
            UpdateUserData(user);
            pullTransactions(json.item.item_id);



            setState({...state,loading:false});
            goBack(true);
        })
        .catch((error:any)=>catchErrors(error,'getBankAccounts'));
    }

    async function pullTransactions(item_id:string){
        return fetch(PLAID_INITIAL_PULL,{
            "method": "POST",
            "headers": {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              uid: user.id,
              item_id,
            })
        })
        // const InitialPull = Firebase.functions().httpsCallable('InitialPull');
        // return InitialPull({item_id})
        .catch((error)=>{
            console.log('InitialPullError: ', error.message);
        })
    }

    function reset() {
        setAccounts(null);
        setCurrentBank({ id: '', name: '', accessToken: '', item_id: '' });
        setState({ loading: false, linkToken: null, error: false, errorMessage: '' });
    }

    async function startPlaid(accessToken:string|null=null) {
        setState({...state,loading:true});
        const body:startPladBody = {
            uid: user.id,
            mode: mode,
        };
        if(accessToken) body.accessToken = accessToken;
        
        fetch(PLAID_TOKEN_URL,{
            method: 'post',
            headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then((json)=>{
            if (json.error) {throw new Error(json.message);}
            if (json.error_code) {throw new Error(json.message);}
            setState({
                ...state,
                loading:false,
                linkToken: json.link_token
            });
        })
        .catch((error:any)=>catchErrors(error,'startPlaid'));
    }
 
    useEffect(()=>{
        if(start) startPlaid();
    },
    []);

    return {...state,accounts,currentBank,plaidItemId,_handleURL,reset,startPlaid};
}
