import React from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { ActivityIndicator, Button, Card, useTheme } from 'react-native-paper';
import { WebView } from 'react-native-webview';
import Strings from '../constants/Strings';
import { currencyFormatter } from '../constants/utils';
import { AppContext } from '../context';
import { useAccounts } from '../hooks/useAccounts';
import { useUser } from '../hooks/useUser';
import ErrorScreen from '../components/ErrorScreen';
import { usePlaid } from '../hooks/usePlaid';
import { Loading } from '../components/LoadingModal';

const {PLAID_URL} = Strings;

//TODO:Refactor this into seperate components
export default function PlaidScreen({ navigation, route }: any) {
  let {  type } = route.params;
  const { state } = React.useContext(AppContext);
  const { user } = state;
  const { UpdateUserData } = useUser();
  const { colors } = useTheme();
  const styles = Styles(colors);
  const { error, errorMessage, loading: accLoading, message } = useAccounts();

  const goBack = (success=false)=>{
    if(success){
      alert('Account has been added successfully.\n\nYou can continue using the app while Legacy loads your data in the background.');
    }
    navigation.goBack();
  }
  const { error: plaidErr, errorMessage: plaidErrMsg, loading: plaidLoading, linkToken, _handleURL } = usePlaid(true,goBack);
  
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [url, setUrl] = React.useState(PLAID_URL);
  const [loading,setLoading] = React.useState(false);
  let WebViewRef: any = null;

  React.useEffect(()=>{setUrl(PLAID_URL+ linkToken)},[linkToken]);

  React.useLayoutEffect(() => {
    //console.log('type: ', type);
    type = type==='Credit'?'Credit Card':type;
    const title = type?'Connect '+type+' Account':'Connect An Account' ;
    navigation.setOptions({
      title: title,
    });
}, [navigation]);

  if (error || plaidErr) {
    //console.log('plaidErrMsg: ', plaidErrMsg,'errorMessage: ',errorMessage);
    return (
      <ErrorScreen message={'We are unable to access your account at this time.  Please try again later.'} />
    )
  }

  if (loading || plaidLoading || accLoading) {
    return (
      <View style={{ ...styles.container, justifyContent: 'center' }}>
        <Text style={{ color: colors.error, marginBottom: 20, textAlign:'center' }}>{plaidErr}</Text>
        <Loading size='large' visible={true} />
        <Text style={{ color: colors.primary, marginTop: 20, textAlign:'center' }}>{message}</Text>
      </View>
    )
  }

  return (
    <WebView
      ref={WEBVIEW_REF => (WebViewRef = WEBVIEW_REF)}
      source={{ uri: url }}
      userAgent={Strings.WEBVIEW_USERAGENT}
      originWhitelist={['https://*', 'http://*', 'plaidlink://*']}
      style={{backgroundColor:colors.background}}
      startInLoadingState={true}
      onShouldStartLoadWithRequest={(request) => {
        if (!request || !request.url) {
          return true;
        }
        if (request.url.startsWith('plaidlink://')) {
          _handleURL(request.url)
          return false;
        }
        //TODO set redirect url in backend and check for it here
        if( request.url.startsWith(Strings.PLAID_REDIRECT_URL) ){
          const newUrl = PLAID_URL+linkToken+'&receivedRedirectUri='+encodeURIComponent(request.url);
          setUrl( newUrl );
          //console.log('request.url: ', request.url);
          return false;
      }
        return true;
      }}
      renderLoading={() =>
        <View style={{...styles.container,position:'absolute',height:'100%',width:'100%',justifyContent: 'center' }}>
          <Loading size='large' visible={true} />
        </View>
      }
      renderError={(errorName) =>
        <View style={styles.container}>
          <Text style={{ color: 'red', marginBottom: 20 }}>{errorName}</Text>
          <ActivityIndicator animating={true} size="large" color={colors.primary} />
        </View>
      }
      onError={(error) => {
        if (firstLoad) {
          setFirstLoad(false);
          WebViewRef && WebViewRef.reload();
        }
      }}
    />
  );
}

const Styles = (colors: any) => {
  return StyleSheet.create({
    button: {
      borderRadius: 10,
      height: 55,
      justifyContent: 'space-around',
      marginTop: '4%',
    },
    button2: {
      borderRadius: 10,
      height: 55,
      justifyContent: 'space-around',
      marginTop: '4%',
    },
    container: {
      backgroundColor: colors.background,
      flex: 1,
      padding: 20,
    },
    flatList: {
      marginLeft: 20,
      marginRight: 20
    },
    View: {

    },
    Info: {
      backgroundColor: colors.background,
      borderColor: '#F0F0F0',
      borderRadius: 15,
      borderWidth: 1,
      marginBottom: 20,
      height: 60,
      flexDirection: "row",
      justifyContent: 'space-between',
      paddingLeft: '15%',
      paddingRight: '10%',
      alignItems: "center",
      width: "100%"
    },
    Info2: {
      flexDirection: "column",

    },
    bankImage: {
      marginRight: 30
    },
    bottomText: {
      color: "grey",
      marginTop: 10,
    },
    fundsSummary: {
      backgroundColor: colors.background,
      marginBottom: 40,
      height: '65%',
      paddingTop: '10%',
    },
    labelText: {
      color: colors.primary,
      fontSize: 14,
      marginBottom: '2%',
      marginRight: 20
    },
    labelText2: {
      color: colors.primary,
      fontSize: 14,
    },
    totalBalance: {
      color: colors.primary,
      fontSize: 32,
      fontWeight: 'bold',
    },
    monthlyContainer: {
      justifyContent: 'space-between'
    },
    totalContainer: {
      justifyContent: 'center',
    },
    totalMonthlyBalance: {
      color: colors.primary,
      fontSize: 15,
      fontWeight: 'bold',
    },
  })
}