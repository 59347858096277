import React from 'react';
import {Dimensions, Linking, Platform, ScrollView, StyleSheet,Text,View,} from 'react-native';
import {Avatar, List, useTheme, Divider, Badge} from 'react-native-paper';
import { AppContext } from '../context';
import { useAuthentication } from '../hooks/useAuthentication';
import { calculatePaymentPeriod, currencyFormatter, getInitials } from '../constants/utils';
import Strings from '../constants/Strings';
import { useUserStats } from '../hooks/useUserStats';

const WIDTH = Dimensions.get('window').width;

export default function ProfileScreen({navigation}:any){ 
    const {state} = React.useContext(AppContext);
    const {user} = state;
    const {colors} = useTheme();
    const styles = Styles(colors);
    const profileImage = getInitials(user?.name??'',1);

    const {Logout} = useAuthentication({});
    const {totalSavings,getTotalSavings} = useUserStats();

    //React.useEffect(()=>{getTotalSavings()},[]);

    // React.useEffect(()=>{
    //     navigation.setOptions({
    //         headerRight: () => {
    //             return(
    //                 <View style={{ alignItems: 'flex-end', marginRight: 15, width:WIDTH > 500 ?400 : 220 }}>
    //                     <Text style={{ color: colors.primary, textAlign: 'right', fontSize: WIDTH > 500 ? 18 : 14, fontWeight: 'bold' }}>
    //                         {`${totalSavings.totalUsers} users are savings`}
    //                     </Text>
    //                     <Text style={{ color: colors.primary, textAlign: 'right', fontSize: WIDTH > 500 ? 18 : 14, fontWeight: 'bold' }}>
    //                         {`${currencyFormatter(totalSavings.totalSavings)} on average`}
    //                     </Text>
    //                     <Text style={{ color: colors.primary, textAlign: 'right', fontSize: WIDTH > 500 ? 18 : 14, fontWeight: 'bold' }}>
    //                         {`and saving ${calculatePaymentPeriod(totalSavings.totalMonths,true)} of debt.`}
    //                     </Text>
    //                 </View>)
    //         }
    //     });
    // },[totalSavings]);

    const ListItems = [
        {key:'accounts',title:'Accounts', onPress:()=>{navigation.navigate('Accounts')},left:(props:any)=><List.Icon {...props} icon={'bank-outline'} />,noShow:!user.onBoardComplete},
        {
            key:'support',
            title:'Contact Customer Support', 
            onPress:()=>{Linking.openURL('mailto:'+Strings.SUPPORT_EMAIL)},
            left:(props:any)=><List.Icon {...props} icon={'email-outline'} />,
        },
        {key:'privacy',title:'Privacy Policy', onPress:()=>{navigation.navigate('Docs',{doc:'privacypolicy',title:'Privacy Policy'})},left:(props:any)=><List.Icon {...props} icon={'file-document-outline'} />},
        {key:'guide',title:'Quick Start Guide', onPress:()=>{navigation.navigate('HowToGuideScreen')},left:(props:any)=><List.Icon {...props} icon={'book-open-page-variant-outline'} />},
        {key:'settings',title:'Settings', onPress:()=>{navigation.navigate('Settings')},left:(props:any)=><List.Icon {...props} icon={'cog-outline'} />},
        {
            key:'subscriptions',
            title:'Subscription', 
            onPress:()=>{navigation.navigate('Subscriptions')},
            left:(props:any)=><List.Icon {...props} icon={'bookmark-check-outline'} />,
            noShow:!user.onBoardComplete,
            //showBadge:state.user?.badges?.subscription??false //TODO: undo this after subscriptions go live
        },
        // {key:'banks',title:'Connected Banks', onPress:()=>{navigation.navigate('Banks')},left:(props:any)=><></>},
        {key:'terms',title:'Terms and Conditions', onPress:()=>{navigation.navigate('Docs',{doc:'termsandconditions',title:'Terms and Conditions'})},left:(props:any)=><List.Icon {...props} icon={'text-box-outline'} />},
        {
            key:'valtrans',
            title:'Validate Internal Transfers',
            onPress:()=>{navigation.navigate('ValidateTransfersScreen')},
            left:(props:any)=><List.Icon {...props} icon={'bank-transfer'} />,
            noShow:!user.onBoardComplete,
            showBadge:state.user?.badges?.internalTransfer??false
        },
        {
            key:'logout',
            title:'Logout', 
            onPress:()=>{Logout().then(()=>{if(!user.onBoardComplete)navigation.goBack()})},
            left:(props:any)=><List.Icon {...props} icon={'logout'} />,
        },
    ];

    return(
        <View style={styles.container}>
            <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
                <View style={styles.profileSummary}>
                    <Avatar.Text size={50} color={colors.accent} label={profileImage} />
                    <View style={{alignSelf:'center',marginLeft:20}}>
                        <Text style={{color:colors.primary}}>{user.name}</Text>
                        <Text style={{color:colors.primary}}>{user.email}</Text>
                    </View>
                </View>
                <Divider />
                {
                    ListItems.map((item)=>{
                        return(!item.noShow &&
                            <React.Fragment key={item.key}>
                            {/*@ts-ignore*/}
                            <List.Item 
                                left={item.left}
                                onPress={item.onPress}
                                right={(props:any)=>
                                    <View {...props} style={{flexDirection:'row'}}>
                                        <List.Icon {...props} icon={'chevron-right'} />
                                        {item.showBadge&&
                                            <Badge {...props} size={10} style={{alignSelf:'center',marginLeft:-10}}/>
                                        }
                                    </View>
                                }
                                title={item.title}
                                titleStyle={{color:colors.primary}}
                            />
                            <Divider />
                            </React.Fragment>
                        );
                    })
                }
                <Text style={{ textAlign: 'center', marginTop: 10, fontSize: 10 }}>
                    {(Platform.OS === 'android'
                        ? Strings.VERSION_ANDROID
                        : Platform.OS === 'ios'
                        ? Strings.VERSION_IOS
                        : '0')
                    }
                </Text>
            </ScrollView>
        </View>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            container: {
                backgroundColor: colors.background,
                flex: 1,
                padding:10
            },
            profileSummary: {
                flexDirection: 'row',
                height: 100,
                marginBottom: 5,
                padding: 20,
            },
        })
    );
}