import React from 'react';
import {FlatList, StyleSheet,View,} from 'react-native';
import {Avatar, Card, Text, Title, useTheme} from 'react-native-paper';
import { Loading } from '../components/LoadingModal';
import TransactionListItem from '../components/TransactionListItem';
import { currencyFormatter, dateFormatter } from '../constants/utils';
import { useTransactions } from '../hooks/useTransactions';

export default function SpendingCategory({navigation,route}:any){ 
    const {category,color,date,icon} = route.params;
    const {colors} = useTheme();
    const styles = Styles(colors);
    const { loading,transactions, GetTransactions } = useTransactions({get:false});
    const [totalIncome,setTotalIncome] = React.useState(0);
    const [totalExpense,setTotalExpense] = React.useState(0);

    React.useEffect(()=>{
        GetTransactions(-1,date,category);
    },[date,category]);

    React.useEffect(()=>{
        const income = transactions.reduce((a,{amount}:any)=>amount>-1?a+Number(amount):a+0,0);
        const expense = transactions.reduce((a,{amount}:any)=>amount<0?a+Number(amount):a+0,0);
        setTotalIncome(income);
        setTotalExpense(expense);
    },[transactions]);
    
    return(
        <View style={styles.container}>
            <Card style={styles.card}>
                <Card.Title
                    title={category}
                    subtitle={dateFormatter(date,'monthYear')}
                    subtitleStyle={{color:color,fontSize:14,fontWeight:'bold'}}
                    left={(props) => <Avatar.Icon {...props} icon={icon} style={{backgroundColor:color}} />}
                />
                <Card.Content>
                    {totalIncome>0?<Text>{'Total Income: '+currencyFormatter(totalIncome)}</Text>:<View/>}
                    {totalExpense!=0?<Text style={{color:colors.error}}>{'Total Spent: '+currencyFormatter(totalExpense)}</Text>:<View/>}
                </Card.Content>
            </Card>
            <FlatList 
                keyExtractor={(item)=>item.id}
                data={transactions}
                renderItem={({item})=><TransactionListItem item={item} navigation={navigation} onPress={()=>{}} />}
                ListHeaderComponent={
                    <Title>Transactions</Title>
                }
                ListFooterComponent={<Loading size={'small'} visible={loading} />}
                showsVerticalScrollIndicator={false}
            />
        </View>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            card:{
                marginBottom: 20
            }
        })
    );
}