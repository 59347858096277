import React from 'react';
import {Dimensions, FlatList, StyleSheet,View,} from 'react-native';
import { List, ProgressBar, Text, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import Strings from '../constants/Strings';
import { currencyFormatter } from '../constants/utils';
import { BudgetType, useBudgets } from '../hooks/useBudgets';
import ListEmpty from './ListEmpty';
import { Loading } from './LoadingModal';

interface PropsType {
    getMonthly?:boolean,
    yearMonth?:string,
}

const Width = Dimensions.get('window').width;
const progressWidth = Width/1.2;

export default function BudgetList({getMonthly=false,yearMonth=''}:PropsType){ 
    const navigation = useNavigation();
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {budgets,GetMonthlyBudgets,loading,LoadMore} = useBudgets({get:!!!yearMonth,limit:Strings.LIST_LIMITS});

    React.useEffect(()=>{
        if(getMonthly) GetMonthlyBudgets(Strings.LIST_LIMITS,yearMonth);
    },[getMonthly,yearMonth]);
    
    const BudgetItem = ({budget}:{budget:BudgetType})=> {
        const progress = ((budget.balance/budget.amount)*100)/100;
        const color = progress > 0.8? colors.error : colors.primary;
        return(
            <List.Item
                description={
                    <View style={styles.progressBarContainer}>
                        <ProgressBar progress={progress} color={color} style={styles.progressBar} />
                    </View>
                }
                descriptionStyle={styles.description}
                // @ts-ignore
                onPress={()=>navigation.navigate('View Budget',{budget})}
                right={(props) => <Text {...props} style={{ fontWeight: 'bold', }}>
                    {
                        currencyFormatter(budget.balance) +
                        ' of ' +
                        currencyFormatter(budget.amount)
                    }
                </Text>}
                style={styles.container}
                title={budget.name}
                titleStyle={{ color: colors.onSurface }} />
        )
    }

    return (
        <FlatList 
            data={budgets}
            keyExtractor={(item)=>item.id}
            renderItem={({item})=><BudgetItem budget={item} />}
            showsVerticalScrollIndicator={false}
            onEndReached={()=>LoadMore(budgets[budgets.length-1].name)}
            ListFooterComponent={<Loading size={'small'} visible={loading} />}
            ListEmptyComponent={<ListEmpty type={'budgets'} />}
        />
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            container: { 
                backgroundColor: colors.background, 
                borderColor: '#F0F0F0', 
                borderRadius: 15, 
                borderWidth: 1, 
                marginBottom: 20 
            },
            description: { 
                color: colors.onSurface,
                marginTop: 10,
                width: Width
            },
            progressBar: {
                height: 15,
                width: progressWidth,
            },
            progressBarContainer: {
                flex: 1,
                alignItems: 'center',
            }
        })
    );
}