import Constants from 'expo-constants';
import * as Application from 'expo-application';
import { Platform } from 'react-native';

//@ts-ignore
const {strings} = Constants.expoConfig.extra;
const {manifest2} = Constants;

const idAddress = manifest2?.extra?.expoClient?.hostUri?.split(':')[0] ?? 'localhost';
const useEmulator = Constants?.expoConfig?.extra?.useEmulator ?? false;
const mode = Constants?.expoConfig?.extra?.mode ?? 'production';

export default {
    //apis
    DELETE_USER:strings.DELETE_USER,
    MAKE_ADMIN:strings.MAKE_ADMIN,
    PLAID_URL: strings.PLAID_URL,
    PLAID_ACCESS_TOKEN_URL : strings.PLAID_ACCESS_TOKEN_URL,
    PLAID_ACCOUNT_URL : strings.PLAID_ACCOUNT_URL,
    PLAID_TOKEN_URL : strings.PLAID_TOKEN_URL,
    PLAID_REDIRECT_URL : strings.PLAID_REDIRECT_URL,
    PLAID_WEBHOOK_LISTENER : strings.PLAID_WEBHOOK_LISTENER,
    PLAID_INITIAL_PULL: useEmulator?"http://"+idAddress+':5002/cenedex-legacy/us-central1/InitialPullv2'
                        : 'https://us-central1-cenedex-legacy.cloudfunctions.net/InitialPullv2',

    //Empty messages
    EMPTY_BILLS_LIST: "There are currently no bills to display.\n Please add a bill to continue.",
    EMPTY_TRANSACTION_LIST: "There are currently no transactions to display.",
    NO_CATEGORIES: "Seems like there was no spending this month.",
    NO_BANKS: "Looks like you haven't conencted any bank accounts.\n\n Touch the plus button in the top corner to add your bank.",
    NO_BILLS: "Congratulations !!\n You paid all your bills for this month.",
    NO_BUDGETS: "You don't have any budgets for this month.",
    NO_SPENDING: "You have no expenses for the current month.",
    TRANSACTION_NOT_FOUND: 'The transaction could not be found.',

    //Error messages
    INVALID_EMAIL: 'You entered an invalid email address.',
    INVALID_PASSWORD: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character.',
    INVALID_PHONE_NUMBER: 'You entered an invalid phone number.',
    
    //Images
    BANK_ICON: require('../../assets/bank.png'),
    GOOGLE_SIGNIN: require('../../assets/googleSign.png'),
    LOGO: require('../../assets/logo.png'),
    LOGO_COLORED: require('../../assets/iconNoBackground.png'),
    LOGO_NO_BACKGROUND: require('../../assets/iconNoBackground.png'),
    SHIELD: require('../../assets/shield.png'),
    SPLASH_IMAGE: require('../../assets/splash.png'),

    //Msc
    ADD_BANK_MESSAGE: '\n One moment please.\n \n Legacy is currently updating your financial data.\n',
    APP_MODE: mode,
    APP_TIMEOUT_MINUTES: 5,
    HOME_TITLE: 'Legacy | Keep track of your money the simple way',
    LIST_LIMITS: 20,
    LOADING_TRANSACTION_MESSAGE: 'Updating your financial data ....',
    MAX_SCREEN_WIDTH: 1500,
    OPTIMIZER_PRICE:'$9.99',
    OPTIMIZER_PRICE_YEAR:'$99.99',
    SPENDFREEDAYSLIMIT: 0,
    SUPPORT_EMAIL:'support@legacyapp.org',
    TOTAL_ACCOUNTS_TO_SHOW: 5,
    VERSION_NUMBER: Application.nativeBuildVersion,
    VERSION_ANDROID: strings.VERSION_ANDROID??'',
    VERSION_IOS: strings.VERSION_IOS??'',
    WEB_CLIENT_ID:strings.WEB_CLIENT_ID,
    WEBVIEW_USERAGENT: Platform.OS=='ios'?
                        'Mozilla/5.0 (iPhone; CPU iPhone OS 16_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/113.0 Mobile/15E148 Safari/605.1.15'
                        :
                        'Mozilla/5.0 (Android 14; Mobile; rv:109.0) Gecko/119.0 Firefox/119.0',

    LIST_OF_STATES: [
        {label: 'Alabama', value: 'Alabama'},
        {label: 'Alaska', value: 'Alaska'},
        {label: 'American Samoa', value: 'American Samoa'},
        {label: 'Arizona', value: 'Arizona'},
        {label: 'Arkansas', value: 'Arkansas'},
        {label: 'California', value: 'California'},
        {label: 'Colorado', value: 'Colorado'},
        {label: 'Connecticut', value: 'Connecticut'},
        {label: 'Delaware', value: 'Delaware'},
        {label: 'District of Columbia', value: 'District of Columbia'},
        {label: 'Federated States of Micronesia', value: 'Federated States of Micronesia'},
        {label: 'Florida', value: 'Florida'},
        {label: 'Georgia', value: 'Georgia'},
        {label: 'Guam', value: 'Guam'},
        {label: 'Hawaii', value: 'Hawaii'},
        {label: 'Idaho', value: 'Idaho'},
        {label: 'Illinois', value: 'Illinois'},
        {label: 'Indiana', value: 'Indiana'},
        {label: 'Iowa', value: 'Iowa'},
        {label: 'Kansas', value: 'Kansas'},
        {label: 'Kentucky', value: 'Kentucky'},
        {label: 'Louisiana', value: 'Louisiana'},
        {label: 'Maine', value: 'Maine'},
        {label: 'Marshall Islands', value: 'Marshall Islands'},
        {label: 'Maryland', value: 'Maryland'},
        {label: 'Massachusetts', value: 'Massachusetts'},
        {label: 'Michigan', value: 'Michigan'},
        {label: 'Minnesota', value: 'Minnesota'},
        {label: 'Mississippi', value: 'Mississippi'},
        {label: 'Missouri', value: 'Missouri'},
        {label: 'Montana', value: 'Montana'},
        {label: 'Nebraska', value: 'Nebraska'},
        {label: 'Nevada', value: 'Nevada'},
        {label: 'New Hampshire', value: 'New Hampshire'},
        {label: 'New Jersey', value: 'New Jersey'},
        {label: 'New Mexico', value: 'New Mexico'},
        {label: 'New York', value: 'New York'},
        {label: 'North Carolina', value: 'North Carolina'},
        {label: 'North Dakota', value: 'North Dakota'},
        {label: 'Northern Mariana Islands', value: 'Northern Mariana Islands'},
        {label: 'Ohio', value: 'Ohio'},
        {label: 'Oklahoma', value: 'Oklahoma'},
        {label: 'Oregon', value: 'Oregon'},
        {label: 'Palau', value: 'Palau'},
        {label: 'Pennsylvania', value: 'Pennsylvania'},
        {label: 'Puerto Rico', value: 'Puerto Rico'},
        {label: 'Rhode Island', value: 'Rhode Island'},
        {label: 'South Carolina', value: 'South Carolina'},
        {label: 'South Dakota', value: 'South Dakota'},
        {label: 'Tennessee', value: 'Tennessee'},
        {label: 'Texas', value: 'Texas'},
        {label: 'Utah', value: 'Utah'},
        {label: 'Vermont', value: 'Vermont'},
        {label: 'Virgin Island', value: 'Virgin Island'},
        {label: 'Virginia', value: 'Virginia'},
        {label: 'Washington', value: 'Washington'},
        {label: 'West Virginia', value: 'West Virginia'},
        {label: 'Wisconsin', value: 'Wisconsin'},
        {label: 'Wyoming', value: 'Wyoming'}
      ]
}