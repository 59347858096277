import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React from 'react';

import {AppContextProvider} from "./src/context";
import Navigation from './src/navigation';
import { AccountContextProvider } from './src/context/AccountContext';

export default function App() {
  
  return (
    <AppContextProvider>
      <AccountContextProvider>
      <Navigation />
      <StatusBar style={'dark'} />
      </AccountContextProvider>
    </AppContextProvider>  
  );
}
