import React from 'react';
import {StyleSheet,View,} from 'react-native';
import {useTheme} from 'react-native-paper';

export default function PlaidRedirect(){ 
const {colors} = useTheme();
const styles = Styles(colors);
    return(<div>Please wait ...</div>)
}

const Styles = (colors:any)=>{
return (
StyleSheet.create({})
);
}