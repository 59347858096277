import React from 'react';
import {StyleSheet,View,} from 'react-native';
import {Text,useTheme} from 'react-native-paper';

export default function NotFound(){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    return(
        <View>
            <Text>ERROR 404</Text>
            <Text>Page not found.</Text>
        </View>
    )
}

const Styles = (colors:any)=>{
    return (
    StyleSheet.create({})
    );
}