import React from 'react';
import {FlatList, StyleSheet,Text,useWindowDimensions,View,} from 'react-native';
import {Avatar, Button, Divider, IconButton, List,useTheme} from 'react-native-paper';
import dayjs from 'dayjs';
import HomeGraph from '../components/HomeGraph';
import Strings from '../constants/Strings';
import { currencyFormatter, getInitials, sortAccounts, timeFromNow } from '../constants/utils';
import { AppContext } from '../context';
import { useFundsSummary } from '../hooks/useFundsSummary';
import { usePlaidLinks } from '../hooks/usePlaidLinks';
import { useAllUsers } from '../hooks/useUsers';
import WebDashboard from '../layouts/WebDashboard';
import Error403Screen from './Error403Screen';
import AccountLogo from '../components/AccountLogo';
import { Loading, LoadingModal } from '../components/LoadingModal';
import { useAccounts } from '../hooks/useAccounts';

export default function UserScreen({navigation,route}:any){ 
    const {id} = route.params;
    const { state } = React.useContext(AppContext);
    const { isAdmin } = state.user;
    if (!isAdmin) {
        return <Error403Screen />
    }

    const { colors } = useTheme();
    const { width } = useWindowDimensions();
    const styles = Styles(colors, width);
    const Year = dayjs().year().toString();
    const {currentSpending,error,errorMessage,loading,summary: sum} = useFundsSummary(Year,id);
    const {errorMessage: errMsg, loading:load,DeleteUser,user} = useAllUsers({getUser:id});
    const {homeAccounts} = usePlaidLinks('home',id);
    const { loading: accLoading, errorMessage: refreshErr, RefreshAccounts, UpdateCashTotals } = useAccounts(false,id);

    React.useEffect(()=>{
      navigation.setOptions({
        title: user?.name??'User',
      })
    },[user]);

    const [expandState,setExpandState] = React.useState<any>({status:'Expand',cash:true,credit:true,loans:true,investment:true});
    const [showAllAccs,setShowAllAccs] = React.useState<any>({cash:false,credit:false,loans:false,investment:false});

    const accountLabel = (label:string)=>{
        switch(label) {
          case 'Credit': return 'Credit Cards';
          default: return label;
        }
      }
    
      const handleExpand = (type:string)=>{
        switch(type){
          case 'cash':{
            setExpandState({...expandState,cash:!expandState.cash});
            break;
          }
          case 'credit':{
            setExpandState({...expandState,credit:!expandState.credit});
            break;
          }
          case 'loans':{
            setExpandState({...expandState,loans:!expandState.loans});
            break;
          }
          case 'investment':{
            setExpandState({...expandState,investment:!expandState.investment});
            break;
          }
          default:{
            if('Expand' == expandState.status){
              setExpandState({status:'Collapse',cash:true,credit:true,loans:true,investment:true});
              setShowAllAccs({cash:false,credit:false,loans:false,investment:false});
            }
            else{
              setExpandState({status:'Expand',cash:false,credit:false,loans:false,investment:false});
              setShowAllAccs({cash:false,credit:false,loans:false,investment:false});
            }
            break;
          }
        }
      }
    
      const onAccountPress = (account:any,bank:any)=>{
        if(account.refresh){
            navigation.navigate('plaidrefreshbank',{account:bank});
            return;
        }
        navigation.navigate('Account Transactions',{account})
    }
    
      const shouldShowAllAccounts = (index:number,accountType:string,totalAccounts:number)=>{
        if(showAllAccs[accountType]){
          return true;
        }
        if(totalAccounts <= Strings.TOTAL_ACCOUNTS_TO_SHOW){
          setShowAllAccs({
            ...showAllAccs,
            [accountType]: true
          });
          return true;
        }
        if(index < Strings.TOTAL_ACCOUNTS_TO_SHOW){
          return true;
        }
        return false;
      }

      const deleteFn = async(option:'CLEAR'|'DELETE'|'REFRESH')=>{
        return DeleteUser(id,option)
                .then(()=>{
                  if(errMsg) alert(errMsg);
                })
                .catch((error)=>{
                  alert(error.message);
                })
      }

    return(
        <WebDashboard>
          <LoadingModal visible={loading || load || accLoading} />
            <View style={styles.buttonsContainer}>
              <section style={{height:150,paddingLeft:10,paddingRight:10}}>
                <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginBottom:20}}>
                <Button
                  color={user?.emailVerified?colors.primary:colors.error}
                  mode={'contained'} 
                  style={{height:35,width:200}} 
                  icon={
                    load? 
                      ''
                    :
                      user?.emailVerified?'check':'close'
                  } 
                >
                  {
                    load?
                      ''
                    :
                      user?.emailVerified?'Verified':'Not Verified'
                  }
                </Button>
                <Button mode={'contained'} style={{height:35,width:200}} onPress={()=>{deleteFn('CLEAR')}}>Clear User Data</Button>
                  <Button mode={'contained'} style={{height:35,width:200}} onPress={()=>{deleteFn('REFRESH')}}>Refresh User</Button>
                  <Button mode={'contained'} style={{height:35,width:200}} onPress={()=>{deleteFn('DELETE')}}>Delete User</Button>
                </View>
                <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginBottom:20}}>
                <Button mode={'contained'} style={{height:35,width:200}} onPress={()=>{navigation.navigate('Payment Optimizer',{uid:id})}}>Payment Optimizer</Button>
                <Button mode={'contained'} style={{height:35,width:200}} onPress={()=>{RefreshAccounts()}}>Refresh Account</Button>
                <Button mode={'contained'} style={{height:35,width:200}} onPress={()=>{UpdateCashTotals({uid:id})}}>Update Cash Total</Button>
                </View>
              </section>
              <section style={{backgroundColor:colors.primary,height:450,paddingLeft:10,paddingRight:10,overflowY:'scroll'}}>
                <View style={styles.fundsSummary}>
                  <View>
                    <Text style={styles.labelText}>Spent this month:</Text>
                    <Text style={styles.totalBalance}>{currencyFormatter(Math.abs(currentSpending))}</Text>
                    <Text style={styles.spendDiff}>{sum.spendDiff}</Text>
                  </View>
                </View>
                <HomeGraph uid={id}/>
              </section>
                <FlatList 
                    data={homeAccounts}
                    numColumns={2}
                    renderItem={({ item }) => {
                        return(
                    <View key={item.key} style={{backgroundColor:colors.background,borderColor:colors.borderColor,borderRadius: 15,borderWidth: 1,marginBottom: 20,marginRight:20,width:'45%'}}>
                    <List.Accordion 
                        style={{backgroundColor: colors.background}}
                        title={item.title}
                        titleStyle={{color:colors.primary,fontSize:18,fontWeight:'bold'}}
                        right={(props)=><Text style={{}}>{currencyFormatter(Number(item.total))}</Text>}
                        expanded={expandState[item.title.toLowerCase()]}
                        onPress={()=>handleExpand(item.title.toLowerCase())}
                    >
                        <Divider />
                        <>
                        {
                        item.accounts?.sort(sortAccounts).map((account,index)=>{
                            if( Number(account.balances?.current) !== 0 ) {
                            if( shouldShowAllAccounts(index, item.title.toLowerCase(),item.accounts?.length??0) ){
                                return(
                                  //@ts-ignore
                                <List.Item 
                                    key={account.id} 
                                    description={account.bankName}
                                    title={account.title} 
                                    titleStyle={{color:colors.onSurface}} 
                                    left={(props)=>
                                      <AccountLogo 
                                        compProps={props}
                                        logo={account.logo}
                                        name={account.bankName}
                                        refresh={account.refresh}
                                      />
                                    }
                                    right={
                                    (props)=>
                                        <View>
                                        <Text {...props} style={{alignSelf: 'flex-end',color:colors.onSurface}}>
                                            {currencyFormatter(Number(account.total))}
                                        </Text>
                                        <Text style={{
                                            alignSelf: 'flex-end',
                                            color:account.refresh?colors.error:colors.primary,
                                            marginTop:10,
                                            opacity:0.7
                                        }}>
                                            {account.refresh?'verify':timeFromNow(account.updated)}
                                        </Text>
                                        </View>
                                    }
                                    onPress={()=>{
                                    navigation.navigate('Account Transactions',{uid:id,id:account.id})
                                    }}
                                />
                                )
                            }
                            }
                        })
                        }
                        <Divider />
                        {
                        item.accounts.length!=0 && !showAllAccs[item.title.toLowerCase()] &&
                        <Text 
                            onPress={()=>{
                            setShowAllAccs({
                                ...showAllAccs,
                                [item.title.toLowerCase()]: true
                            });
                            }}
                            style={{
                            padding:20,
                            textAlign:'center'
                            }}
                        >
                            Show All Accounts
                        </Text>
                        }
                        </>
                    </List.Accordion>
                    </View>
                    )
                }
            }
            />
      </View>
        </WebDashboard>
    )
}

const Styles = (colors:any,width:number)=>{
    const maxWidth = width > Strings.MAX_SCREEN_WIDTH ? Strings.MAX_SCREEN_WIDTH : width;
    const Maxwidth = 1000;
    return (
        StyleSheet.create({
          fundsSummary: {
            backgroundColor: colors.primary,
            //height: 450,
            //paddingTop: '10%',
            padding: 20,
          },
          labelText: {
            color: colors.accent,
            fontSize: 12,
            //marginBottom: 10,
            marginRight: 20
          },
          spendDiff: {
            color: colors.accent,
            fontSize: 14,
            //marginBottom: 10,
            marginRight: 20
          },
          totalBalance: {
            color: colors.accent,
            fontSize: 32,
            fontWeight: 'bold',
          },
          buttonsContainer: {
                alignSelf: 'center',
                maxWidth: Maxwidth,
                padding: 20,
                width: '100%',
          },
        })
    );
}