import React from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import TransactionListItem from '../components/TransactionListItem';
import Strings from '../constants/Strings';
import { useTransactions } from '../hooks/useTransactions';
import { Loading } from '../components/LoadingModal';
import ListEmpty from '../components/ListEmpty';

export default function TransactionsScreen({navigation}:any) {
  const {colors} = useTheme();
  const styles = Styles(colors);
  const { dateDisplay,loading,selection,transactions, GetTransactions, LoadMoreTransactions } = useTransactions({limit:Strings.LIST_LIMITS});

  // React.useLayoutEffect(() => {
  //   navigation.setOptions({
  //     headerRight: () => (
  //       <View style={{alignItems:'center',backgroundColor:colors.primary,borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
  //         <IconButton color={colors.accent} icon={'plus'} onPress={()=>{navigation.navigate('Add Transaction')}} size={18} />
  //       </View>
  //     ),
  //   });
  // }, [navigation]);

  const renderHeader = ()=>{
    return(
      <>
      <View style={{flexDirection:'row',alignSelf:'center',marginBottom:50}}>
        <Text onPress={()=>{GetTransactions(Strings.LIST_LIMITS,'weekly')}} style={{color:selection==='weekly'?colors.primary:colors.placeholder,fontSize:14,marginRight:10}}>Weekly</Text>
        <Text style={{color:colors.placeholder}}>|</Text>
        <Text onPress={()=>{GetTransactions(Strings.LIST_LIMITS,'monthly')}} style={{color:selection==='monthly'?colors.primary:colors.placeholder,fontSize:14,marginRight:10,marginLeft:10}}>Monthly</Text>
        <Text style={{color:colors.placeholder}}>|</Text>
        <Text onPress={()=>{GetTransactions(Strings.LIST_LIMITS,'yearly')}} style={{color:selection==='yearly'?colors.primary:colors.placeholder,fontSize:14,marginLeft:10}}>Yearly</Text>
      </View>
      <Text style={{marginBottom:20}}>{dateDisplay}</Text>
      </>
    )
  }

  return (
    <View style={styles.container}>
      <FlatList 
        keyExtractor={(item)=>item.id}
        data={transactions}
        ListHeaderComponent={renderHeader}
        ListHeaderComponentStyle={{backgroundColor:colors.background,marginBottom:20,zIndex:2}}
        stickyHeaderIndices={[0]}
        renderItem={({item})=><TransactionListItem item={item} navigation={navigation} />}
        showsVerticalScrollIndicator={false}
        onEndReached={()=>{if(transactions.length>1)LoadMoreTransactions(transactions[transactions.length-1].date,selection)}}
        //onEndReachedThreshold ={0.1}
        ListFooterComponent={<Loading size={'small'} visible={loading} />}
        ListEmptyComponent={<ListEmpty type={'transactions'} />}
      />
    </View>
  )}

const Styles = (colors:any)=>{
  return StyleSheet.create({
    container: {
      backgroundColor: colors.background,
      flex: 1,
      padding: 20,
    },
  });
} 