import React from 'react';
import { Dimensions, FlatList, StyleSheet,View,} from 'react-native';
import {Avatar, Divider, IconButton, List, useTheme} from 'react-native-paper';
import AccountLogo from '../components/AccountLogo';
import ListEmpty from '../components/ListEmpty';
import { getInitials } from '../constants/utils';
import { usePlaidLinks, UserAccountProps } from '../hooks/usePlaidLinks';
import AccountsList from '../components/AccountsList';

export default function AccountsScreen({navigation}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const { loading, accounts, getAccounts } = usePlaidLinks('default');

    React.useLayoutEffect(() => {
        navigation.setOptions({
          headerRight: () => (
            <View style={{
                alignItems:'center',
                backgroundColor:colors.primary,
                borderRadius:24/2,
                height:24,
                justifyContent:'center',
                marginRight:20,
                width:24,
                }}
            >
                {/*@ts-ignore*/}
              <IconButton color={colors.accent} icon={'plus'} onPress={()=>{navigation.navigate('Plaid',{type:'',accounts}); }} size={18} />
            </View>
          ),
        });
      }, [navigation]);

    return(
        <View style={styles.container}>
            <FlatList 
                data={accounts}
                ItemSeparatorComponent={()=><Divider />}
                ListEmptyComponent={()=><ListEmpty type={'account'} />}
                renderItem={({item})=><AccountsList item={item} navigation={navigation} colors={colors} />}
                onRefresh={()=>getAccounts()}
                refreshing={loading}
                showsVerticalScrollIndicator={false}
            />
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
        })
    );
}