import { DefaultTheme } from 'react-native-paper';
import {StyleSheet} from 'react-native';

declare global {
  namespace ReactNativePaper {
    interface ThemeColors {
      border:string;
      borderColor: string;
      card:string;
      headerBackground:string;
      primary2:string;
      warning:string;
    }
  }
}

export const DarkTheme: ReactNativePaper.Theme = {
    ...DefaultTheme,
    dark: true,
    roundness: 4,
    colors: {
      ...DefaultTheme.colors,
      primary: '#2c5d40',
      accent: '#FFFFFF',
      background: '#F0F2EF',
      surface: '#747474',
      error: '#B00020',
      text: '#2c5d40',
      onSurface: '#000000',
      disabled: '#DEDEDE',
      placeholder: '#A5A5A5',
      notification: '#B00020',
      //backdrop: color('black').alpha(0.5).rgb().string(),
      //custom properties
      border: '#bababa',
      borderColor: '#bababa',
      card: 'red',
      headerBackground: '#73B62A',//'#60a884',
      primary2: '#2c5d40',
      warning: '#c4b22c'
    },
};

export const LightTheme: ReactNativePaper.Theme = {
    ...DefaultTheme,
    dark: false,
    roundness: 15,
    colors: {
      ...DefaultTheme.colors,
      primary: '#2c5d40',
      accent: '#FFFFFF',
      background: '#dddee1',
      surface: '#dddee1',
      error: '#B00020',
      text: '#2c5d40',
      onSurface: '#000000',
      disabled: '#DEDEDE',
      placeholder: '#A5A5A5',
      notification: '#B00020',
      //backdrop: color('black').alpha(0.5).rgb().string(),
      //custom properties
      border: '#cfcfcf',
      borderColor: '#cfcfcf',
      card: '#dddee1',
      headerBackground: '#00AD50',//'#01B844',//'#479a71',
      primary2: '#397E55',
      warning: '#c4b22c'
    },
};

export const currentTheme = ( mode: ('light' | 'dark' | undefined) )=>{
  switch(mode){
    case 'light':
      return LightTheme;
    case 'dark':
      return DarkTheme;
    default:
      return LightTheme    
  }
}

export const PickerSelectStylesAvil = (colors:any,Width:number)=>{
  const pickerSelectStylesAvil = StyleSheet.create({
      inputIOS: {
      fontSize: 16,
      //paddingVertical: 18,
      //paddingHorizontal: 5,
      //borderWidth: 0.5,
      borderColor: 'gray',
      borderRadius: 4,
      color: colors.primary,
      //paddingRight: 30, // to ensure the text is never behind the icon
      width: Width*0.65,
      height: 40,
      },
      inputAndroid: {
      fontSize: 16,
      //paddingLeft: 5,
      //paddingVertical: 18,
      //borderWidth: 0.5,
      //borderColor: 'gray',
      //borderRadius: 8,
      color: colors.primary,
      //paddingRight: 30, // to ensure the text is never behind the icon
      width: Width*0.5,
      height: 40,
      },
  });
  return pickerSelectStylesAvil;
}

export default {
  currentTheme,
  DarkTheme,
  LightTheme,
}  