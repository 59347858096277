import React from 'react';
import {Dimensions, Platform, ScrollView,StyleSheet,TouchableOpacity,View,} from 'react-native';
import {Button, Caption, Divider, Text, TextInput, Title, useTheme} from 'react-native-paper';
import { LiabilitiesProp } from '../constants/Types';
import { dateFormatter, isBillPassedDue } from '../constants/utils';
import { useLiabilities } from '../hooks/useLiabilities';
import DateTimePicker from '@react-native-community/datetimepicker';
import Picker from 'react-native-picker-select';
import { PickerSelectStylesAvil } from '../constants/Theme';

const Width = Dimensions.get('window').width;

interface props {
    closeModal: ()=>void
    liability: LiabilitiesProp
}
export default function UpdateLiabilityBottomSheet(props:props){ 
    const {closeModal,liability} = props;
    const {colors} = useTheme();
    const styles = Styles(colors);
    const pickerStyles = PickerSelectStylesAvil(colors,Width);
    const { loading,UpdateLiability } = useLiabilities(null,false,false);

    const isNew = (liability.account?.bankName === 'new');
    const [showDatePicker,setShowDatePicker] = React.useState(false);
    const [amountOwed,setAmountOwed] = React.useState<any>(liability.amount_owed/100??0);
    const [bankName,setBankName] = React.useState<string>(isNew?'':liability.account.bankName);
    const [dateDue,setDateDue] = React.useState<string>(liability.next_payment_due_date??'');
    const [name,setName] = React.useState<string>(isNew?'':liability.account.name);
    const [interestRate,setInterestRate] = React.useState<any>(liability.interestRate);
    const [paymentAmount,setPaymentAmount] = React.useState<any>(liability.paymentAmount?liability.paymentAmount/100:0);
    const [accountType,setAccountType] = React.useState<string>(liability.account.type??'loans');
    const [creditLimit,setCreditLimit] = React.useState<number>(liability.creditLimit?liability.creditLimit/100:0.00);

    function submit(){
        const account = {
            ...liability.account,
            bankName,
            balances:{available:0,current:amountOwed},
            manuallyAdded:true,
            name,
            next_payment_due_date:dateDue,
            type: accountType
        }
        liability.account = account;
        liability.amount_owed = amountOwed*100;
        liability.interestRate = interestRate;
        liability.next_payment_due_date = dateDue;
        liability.paymentAmount = paymentAmount*100;
        liability.creditLimit = creditLimit*100;
        return UpdateLiability(liability)
        .then(()=>{closeModal()});
    }

    return(
        <View style={{flex:1,height:500}}>
            {showDatePicker && (
                    <View
                        style={{
                            height:500,
                            zIndex:100
                        }}
                    >
                        {Platform.OS === 'ios' && (
                            <Button style={{marginTop:20,}} onPress={() => setShowDatePicker(false)}>Close</Button>
                        )}
                        <DateTimePicker
                            testID="dateTimePicker"
                            value={dateDue?new Date(dateDue+'T20:25:42.475Z'):new Date()}
                            mode={'date'}
                            display={Platform.OS === 'ios'?"spinner":"default"}
                            onChange={(event:any, selectedDate:Date | undefined)=>{
                                const currentDate = selectedDate?.getTime() ?? Date.now();
                                setShowDatePicker(Platform.OS === 'ios');
                                setDateDue(dateFormatter(currentDate,'dashFormattedString'));
                            }}
                            themeVariant="light"
                        />
                    </View>
                )
                }
            {
                !isNew &&
                    <Title style={{fontSize:Width>500?25:18}} numberOfLines={1}>{liability.account?.bankName??''}</Title>
            }
            
            {
                !isNew &&
                    <Caption style={{fontSize:Width>500?18:14}}>{liability.account?.name}</Caption>    
            }

            <Divider />
            
            <ScrollView style={styles.container} keyboardShouldPersistTaps={'always'} showsVerticalScrollIndicator={false}>
                {
                    liability.account?.bankName == 'new' &&
                    <>
                        {/* <TextInput
                            autoCompleteType={''}
                            mode={'flat'}
                            style={styles.textInput}
                            onChangeText={(text)=>{ setBankName( text ) }}
                            value={bankName}
                            label={'Bank Name'}
                            placeholder={'Enter the name of the bank'}
                            error={!bankName}
                            returnKeyType={'next'}
                        /> */}

                        <TextInput
                            autoCompleteType={''}
                            mode={'flat'}
                            style={styles.textInput}
                            onChangeText={(text)=>{ setName( text ); setBankName( text ) }}
                            value={name}
                            label={'Account Name'}
                            placeholder={'Enter the name of the account'}
                            error={!name}
                            returnKeyType={'next'}
                        />
                    </>
                }
                {
                    liability.manuallyAdded &&
                    <Picker 
                        onValueChange={(value)=>{setAccountType(value)}}
                        items={[
                            {label:'Credit Card',value:'credit'},
                            {label:'Loans',value:'loans'}
                        ]}
                        style={pickerStyles}
                        value={accountType}
                    />
                }
                <TextInput
                    autoCompleteType={''}
                    mode={'flat'}
                    style={styles.textInput}
                    onChangeText={(text)=>{ setInterestRate( text ) }}
                    value={interestRate?interestRate.toString():''}
                    label={'Annual Percentage Rate (APR)'}
                    placeholder={'Enter your annual percentage rate (APR)'}
                    right={<TextInput.Affix text="%" />}
                    error={!interestRate}
                    keyboardType = 'numeric'
                    returnKeyType={'next'}
                />
                {!interestRate && 
                    //@ts-ignore
                    <Text style={styles.errorText}>{'Please enter the interest rate for this account.'}</Text>
                }

                <TextInput
                    autoCompleteType={''}
                    mode={'flat'}
                    style={styles.textInput}
                    onChangeText={(text)=>{ setPaymentAmount( text ) }}
                    value={paymentAmount.toString()}
                    label={'Minimum Payment Amount'}
                    placeholder={'Enter minimum payment amount for this account.'}
                    left={<TextInput.Affix text="$" />}
                    error={!paymentAmount}
                    keyboardType = 'numeric'
                    returnKeyType={'next'}
                />

                <TextInput
                    autoCompleteType={''}
                    mode={'flat'}
                    style={styles.textInput}
                    onChangeText={(text)=>{ setAmountOwed( text ) }}
                    value={amountOwed.toString()}
                    label={'Amount Owed'}
                    placeholder={'Enter the amount owed on this account.'}
                    left={<TextInput.Affix text="$" />}
                    error={!amountOwed || isBillPassedDue(dateDue)}
                    keyboardType = 'numeric'
                    returnKeyType={'next'}
                    //onSubmitEditing={()=>{submit()}}
                />
                {
                    (liability.account.type === 'credit' || accountType === 'credit') &&
                    <>
                    <TextInput
                        autoCompleteType={''}
                        mode={'flat'}
                        style={styles.textInput}
                        onChangeText={(text)=>{ setCreditLimit( Number(text) ) }}
                        value={creditLimit?.toString()??''}
                        label={'Credit Limit'}
                        placeholder={'Enter your credit card limit.'}
                        left={<TextInput.Affix text="$" />}
                        error={!creditLimit}
                        keyboardType = 'numeric'
                        returnKeyType={'next'}
                        //onSubmitEditing={()=>{submit()}}
                    />
                    {/*@ts-ignore*/}
                    <Text style={styles.errorText}>{'Enter your credit card limit.'}</Text>
                    </>
                }
                {isBillPassedDue(dateDue) && 
                    //@ts-ignore
                    <Text style={styles.errorText}>{'Bill is currently passed due. Please update amount owed.'}</Text>
                }

                {liability.manuallyAdded &&
                    <>
                    <TouchableOpacity
                        onPress={()=>{setShowDatePicker(true)}}
                    >
                        <TextInput
                            autoCompleteType={''}
                            editable={false}
                            pointerEvents={"none"}
                            mode={'flat'}
                            style={styles.textInput}
                            onChangeText={(text)=>{ setDateDue( text ) }}
                            value={dateDue?dateFormatter(dateDue.toString(),'fullString' ):''}
                            label={'Date Due'}
                            error={!dateDue}
                        />
                    </TouchableOpacity>
                    {isBillPassedDue(dateDue) && 
                        //@ts-ignore
                        <Text style={styles.errorText}>{'Bill is currently passed due. Please update due date.'}</Text>
                    }
                    </>
                }

                <Button
                    style={styles.button}
                    onPress={()=>{ submit()}}
                    color={colors.primary}
                    mode={'contained'}
                    disabled={loading}
                    loading={loading}
                    uppercase={true}
                >
                    Save
                </Button>
            </ScrollView>
        </View>
    );
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            button: {
                alignSelf:'center',
                borderRadius: 10,
                height: 40,
                justifyContent: 'center',
                marginTop: '4%',
                maxWidth:700,
                width:'100%'
            },
            container:{
                flex:1,
                flexGrow:1,
                maxWidth:800,
                width:'100%'
            },
            errorText: {
                color: colors.error,
            },
            textInput: {
                backgroundColor: 'transparent',
                fontSize:Width>500?20:18,
                marginBottom: 2,
            },
        })
    );
}