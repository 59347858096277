import React from 'react';
const {useEffect,useState}=React;
import * as LocalAuthentication from 'expo-local-authentication';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AppContext } from '../context';
import { AppState, AppStateStatus } from 'react-native';
import Strings from '../constants/Strings';
import { useAuthentication } from './useAuthentication';
 
export const useBiometrics = ()=>{
    const { dispatch, state: Appstate } = React.useContext(AppContext);
    const {SetAuthPersistence} = useAuthentication({checkLogin:false});
    const [state,setState] = useState({enabled:false,loading:false});

    async function authenticate(){
        setState({...state,loading:true});
        const res = await LocalAuthentication.authenticateAsync();
        if( res.success ) dispatch({type:'setIsLocked',payload:false});
        else dispatch({type:'setIsLocked',payload:true});
        setState({...state,loading:false});
    }

    async function getBioMetricStatus(){
        setState({...state,loading:true});
        let status:string = await AsyncStorage.getItem('biometricStatus')??'';
        let newState = state;
        if(!status){
            if( !await LocalAuthentication.hasHardwareAsync() ){ 
                status = 'disabled'; 
                newState.enabled = false; 
            }
            else { 
                if(status == 'enabled')
                    newState.enabled = true;
                else{
                    status = 'disabled';
                    newState.enabled = false;
                }
            }
        }
        
        setState(newState);
        if( status == 'enabled' ) dispatch({type:'setIsLocked',payload:true});
        else dispatch({type:'setIsLocked',payload:false});
        setState({...state,loading:false});
        return await setStatus(status);
    }

    async function setStatus(status:string){
        AsyncStorage.setItem('biometricStatus',status);
        dispatch({type:'setBiometric',payload:status})
        return status;
    }

    async function toggleBiometricUse(){
        setState({...state,loading:true});
        const res = await LocalAuthentication.authenticateAsync();
        
        if( res.success){
            if( Appstate.biometric != 'enabled' ){
                setState({...state,enabled:true});
                await SetAuthPersistence(true).catch((error:any)=>{console.log('toggleBiometricUseErr',error);});
                return await setStatus('enabled');
            }
            else{
                setState({...state,enabled:false});
                SetAuthPersistence(false).catch((error:any)=>{console.log('toggleBiometricUseErr',error);});
                return await setStatus('disabled');
            }
        }
    }

    async function handleAppStateListener(nextAppState:AppStateStatus){
        if(nextAppState == 'background'){
            AsyncStorage.setItem('lastTimestamp',(Date.now()).toString() );
        }
        else if (nextAppState === 'active') {
            const lastTimestamp = await AsyncStorage.getItem('lastTimestamp');
            if( !lastTimestamp ) return;
            const timeDiff = Date.now() - Number(lastTimestamp);
            if (timeDiff > Strings.APP_TIMEOUT_MINUTES * 60 * 1000) {
                await AsyncStorage.setItem('lastTimestamp',(Date.now()).toString() );
                dispatch({type:'setIsLocked',payload:true});
            }
        }
    }

    useEffect(()=>{
        const subscription = AppState.addEventListener('change',handleAppStateListener);
        //return subscription.remove();
    },
    []);

    return {...state,authenticate,getBioMetricStatus,toggleBiometricUse};
}