import React from 'react';
import {StyleSheet,Text,View,} from 'react-native';
import {Avatar, Card, useTheme} from 'react-native-paper';
import Strings from '../constants/Strings';
import { currencyFormatter } from '../constants/utils';

export default function SpendingCategoriesList({categories}:any){ 
    const {colors} = useTheme();
    if(categories.length == 0){
        return <View><Text style={{'textAlign':'center'}}>{Strings.NO_SPENDING}</Text></View>   
       }
       return(
           <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
               {
                   categories.map((item:any,index:number)=>{
                       return(
                           <Card 
                                key={index}
                                style={{alignItems:'center',margin:"5%",height:100,width:'40%',}}
                                onPress={item.onPress}
                            >
                                <Card.Content style={{alignItems:'center'}}>
                                    <Avatar.Icon 
                                        icon={item.icon} 
                                        size={30} 
                                        style={{backgroundColor:item?.color,marginBottom:10}} 
                                    />
                                    <Text
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {currencyFormatter(item.value)}
                                    </Text>
                                    <Text>{item.label??item.name}</Text>
                                </Card.Content>
                           </Card>
                        //    <List.Item
                        //        key={index}
                        //        title={item.name}
                        //        description={"Item description"}
                        //        style={{width:'40%',margin:"5%"}}
                        //        onPress={item.onPress}
                        //        right={(props) => (
                        //            <View {...props} style={{alignItems:'center',flexDirection:'row'}}>
                        //                <Text>{currencyFormatter(item.value)}</Text>
                        //                <List.Icon icon="chevron-right" />
                        //            </View> 
                        //        )}
                        //    />
                       )
                   })
               }
           </View>
       );
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({})
    );
}