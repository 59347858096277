import { initializeApp } from "firebase/app";
import * as firestore from 'firebase/firestore';
import * as Auth from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import * as functions from 'firebase/functions';
import { getReactNativePersistence } from "firebase/auth/react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"

import { LogBox, Platform } from 'react-native';
import Constants from 'expo-constants';

const {manifest2} = Constants;
const idAddress = manifest2?.extra?.expoClient?.hostUri?.split(':')[0] ?? 'localhost';
const useEmulator = Constants?.expoConfig?.extra?.useEmulator ?? false;

const webConfig = {
  apiKey: "AIzaSyAgL1hwYPEuCtWGgjQtj8Btk4Reb4kWNgM",
  authDomain: "cenedex-legacy.firebaseapp.com",
  projectId: "cenedex-legacy",
  storageBucket: "cenedex-legacy.appspot.com",
  messagingSenderId: "356860454045",
  appId: "1:356860454045:web:7f42229ffc39f1d1ca6074"
};
const androidConfig = {
  apiKey: "AIzaSyBx64qee-FwSWOP0gx9Ur5Fgr0KDF3jEu4",
  authDomain: "cenedex-legacy.firebaseapp.com",
  projectId: "cenedex-legacy",
  storageBucket: "cenedex-legacy.appspot.com",
  messagingSenderId: "564139298221",
  appId: "1:356860454045:android:540b602e7bfff0c6ca6074"

};
const iosConfig = {
  apiKey: "AIzaSyAc9XE0xwDJmkdEBqBHss8sIv8IbCh2oRA",
  authDomain: "cenedex-legacy.firebaseapp.com",
  projectId: "cenedex-legacy",
  storageBucket: "cenedex-legacy.appspot.com",
  messagingSenderId: "564139298221",
  appId: "1:356860454045:ios:2cf65117befaee57ca6074"

};

//made em separate to experiment with logging and analytics
let firebaseConfig = webConfig;
switch(Platform.OS) {
  case 'android':{
    firebaseConfig = androidConfig;
    break;
  }
  case 'ios':{
    firebaseConfig = iosConfig;
    break;
  }
  default:{break;}
}

const firebase = initializeApp(firebaseConfig);
Auth.initializeAuth(firebase, {
  persistence: getReactNativePersistence(AsyncStorage)
});

firestore.initializeFirestore(firebase, {
  experimentalAutoDetectLongPolling: true
})

// firebase.analytics();

if( Platform.OS != 'web'  ) {
  LogBox.ignoreAllLogs(); //TODO: remember to resolve this
  const _console = { ...console };
  console.warn = message => {
    if (message.indexOf('Setting a timer') <= -1 && message.indexOf('VirtualizedLists should never be nested')<= -1) {
       _console.warn(message);
       }
    };
}

export const Firebase = firebase;
export const FirebaseAuth = {...Auth,getAuth:()=>{return Auth.getAuth(firebase)}};
//if(useEmulator)connectAuthEmulator(FirebaseAuth,"http://"+idAddress+":9099");
export const Firestore = {...firestore,getFirestore:()=>{return firestore.getFirestore(firebase)}}
//if(useEmulator)connectFirestoreEmulator(Firestore, idAddress, 8080);
export const FirebaseStorage = getStorage(firebase);
//if(useEmulator)connectStorageEmulator(FirebaseStorage,"localhost", 9199);
export const Functions = {...functions,getFunctions:()=>{return functions.getFunctions(firebase)}};
if(useEmulator)functions.connectFunctionsEmulator(Functions.getFunctions(), idAddress, 5002);
//export const FieldValue = firebase.firestore.FieldValue;
