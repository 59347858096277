import React from 'react';
import {Platform, StyleSheet,View,} from 'react-native';
import {useTheme} from 'react-native-paper';
import { WebView } from 'react-native-webview';
import { useDocs } from '../hooks/useDocs';

export default function DocsScreen({navigation,route}:any){ 
    const {doc: type,title} = route.params;
    const {colors} = useTheme();
    const styles = Styles(colors);

    const {doc} = useDocs(type);

    React.useLayoutEffect(() => {
        let t = title?title:(type=='privacypolicy')?'Privacy Policy':(type=='termsandconditions')?'Terms & Conditions':'';
        navigation.setOptions({
          headerTitle: t,
          headerStyle: {
            backgroundColor: Platform.OS === 'web'?colors.primary:colors.background,
          },
          headerTintColor: Platform.OS === 'web'?colors.accent:colors.text,
        });
      }, [navigation]);

    return(
        <View style={styles.container}>
            {
                Platform.OS === 'web'?
                <div style={{alignContent:'center',alignSelf:'center',justifyContent:'center',maxWidth:1000,whiteSpace:'pre-wrap'}}>
                    <div dangerouslySetInnerHTML={{__html: doc}} />
                </div>
                :
                <WebView 
                    source={{ html: '<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head><body style="background-color:'+colors.background+';">'+doc+'</body></html>' }} 
                    originWhitelist={['*']} 
                    style={{backgroundColor:colors.background}}
                />
            }
        </View>
    );
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
        })
    );
}