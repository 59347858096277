import React from 'react';
import { Functions } from '../constants/Firebase';
const {useEffect,useState}=React;
 
export const useUserStats = ()=>{
    const[totalSavings,setTotalSavings] = React.useState({totalMonths:0,totalSavings:0,totalUsers:0});
    const getTotalSavingsFunction = Functions.httpsCallable(Functions.getFunctions(),'getTotalSavings');

    const getTotalSavings = async()=>{
        const totals: typeof totalSavings = await getTotalSavingsFunction()
                .then((res)=>{
                    const totals:any = res.data;
                    return totals;
                })
                .catch(()=>{return {totalMonths:0,totalSavings:0,totalUsers:0}});
        setTotalSavings({...totals});
    }
 
    useEffect(()=>{
    },
    []);

    return {totalSavings,getTotalSavings};
}