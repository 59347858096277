import React from 'react';
import {StyleSheet,View,} from 'react-native';
import {Button, Dialog, Paragraph, Portal, useTheme} from 'react-native-paper';

interface DeleteBillAlertProps {
    DeleteBill: ()=>void
    hideDialog: ()=>void
    visible: boolean
}
export default function DeleteBillAlert({DeleteBill,hideDialog,visible}:DeleteBillAlertProps){
    return (
        <Portal>
                <Dialog visible={visible} onDismiss={hideDialog}>
                <Dialog.Title>Deleting Bill</Dialog.Title>
                <Dialog.Content>
                    <Paragraph>{"Continuing will remove the bill from future monthly summaries."}</Paragraph>
                </Dialog.Content>
                    <Dialog.Actions>
                    <Button onPress={() => hideDialog()}>Cancel</Button>
                    <Button onPress={() => DeleteBill()}>
                        Continue
                    </Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
    );
}

const Styles = (colors:any)=>{
return (
StyleSheet.create({})
);
}