import { useContext, useEffect, useState } from "react";
import { Firestore, Functions } from '../constants/Firebase';
import { SubProps } from "../constants/Types";
import { AppContext } from "../context";
import { Platform } from "react-native";

interface stateProps {
    error:boolean,
    errorMessage:string,
    loading:boolean,
    subscription:SubProps
}

const InitialState:stateProps = {
    error:false,
    errorMessage: '',
    loading:false,
    // @ts-ignore
    subscription:{id:'',endDate:0,name:'-',startDate:0,type:'-'}
}

export const useSubscriptions = (start=true) => {
    const [state,setState] = useState<stateProps>(InitialState);
    const { state: AppState } = useContext(AppContext);
    const UID = AppState.user.id;
    const subscriptionRef = Firestore.doc(Firestore.getFirestore(),'Subscriptions/'+UID);
    const setStoredData = Functions.httpsCallable(Functions.getFunctions(),'startSubscriptionv2');

    async function startTrial(next=()=>{}){
        Loading();
        return setStoredData({os:Platform.OS,'subscription':null})
                .then(()=>{next();})
                .catch((error)=>{Error(error);});
                
    }

    async function getSubscription(){
        Loading();
        Firestore.onSnapshot(subscriptionRef,(snapshot)=>{
            if( !snapshot.exists() ) return Loading(false);
            const subscription = snapshot.data() as SubProps;
            if( !subscription ) return Loading(false);
            subscription.name = getSubscriptionName(subscription?.storeData?.productId??'');
            setState({...InitialState,subscription})
        },
        (error)=>{Error(error);})
    }

    const subs = {optimizer_sub:'Payment Optimizer',optimizer_yearly_sub:'Payment Optimizer'};
    function getSubscriptionName(subId:keyof (typeof subs)):string{        
        return subId? subs[subId] : subs['optimizer_sub'];
    }

    function Error(error:Error){
        console.log('liabilitiesErr: ', error.message);
        setState({
            ...InitialState,
            error:true,
            errorMessage:error.message
        });
    }
    function Loading(start:boolean=true){ setState({...InitialState,loading:start}); }

    useEffect(() => {
        if(start)getSubscription();
    },
    []);

    return{...state,startTrial};
}