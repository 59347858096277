import React from 'react';
import {Dimensions, Image, StyleSheet,View,} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {Avatar, IconButton, List, Text,useTheme} from 'react-native-paper';
import { Firestore } from '../../constants/Firebase';
import { AppContext } from '../../context';

const Width = Dimensions.get('window').width;

interface props {
    navigation: any
    next: ()=>void
    prev: ()=>void
}

const ListItems = [
    {key:4,title:'App Store',value:'appstore',icon:'cellphone-arrow-down'},
    {key:6,title:'Deadly Dill Gaming',value:'deadlydill',image: require('../../../assets/onboarding/deadlyDillLogo.jpg')},
    {key:2,title:'Friend',value:'friend',icon:'account'},
    {key:1,title:'Online Ad',value:'onlinead',icon:'web'},
    {key:3,title:'Online Article',value:'onlinearticle',icon:'newspaper'},
    {key:0,title:'TV Commercial',value:'tvcommercial',icon:'television'},
    {key:5,title:'Other',value:'other',icon:''},
];

export default function HeardAboutUs(props:props){ 
    const {next,prev}=props;
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {state} = React.useContext(AppContext);
    const {user} = state;

    const itemPress = (item:any)=>{
        const userId = user.id;
        const UserRef = Firestore.doc(Firestore.getFirestore(),'Users/'+userId);
        const HeardAboutUsRef = Firestore.doc(Firestore.getFirestore(),'UsersStats/heardAboutUs');

        Firestore.updateDoc(UserRef,{heardAboutUs:item.title}).catch(()=>{});
        Firestore.getDoc(HeardAboutUsRef)
        .then((doc)=>{
            let data = doc.data();
            const heard = item.title;
            if(!data) data = {[heard]:1};
            else if(heard in data) data[heard]++;
            else data[heard] = 1;
            return data;
        })
        .then((data)=>{
            return Firestore.setDoc(HeardAboutUsRef,data,{merge:true});
        })
        .catch((error)=>{
            console.log('error: ', error);
        });

        next();
    }
    
    return(
        <>
            <View style={{ alignItems: 'center',flexDirection: 'row',justifyContent: 'space-between',marginTop: 60,marginBottom:-20}}>
                {/*@ts-ignore*/}
                <IconButton
                    color={colors.primary}
                    icon={'arrow-left'}
                    onPress={()=>{prev();}}
                    size={35}
                    style={{backgroundColor: 'transparent'}}
                />
            </View>
        <KeyboardAwareScrollView
            contentContainerStyle={styles.container}
            //extraScrollHeight={160} 
            keyboardShouldPersistTaps={'always'}
            showsVerticalScrollIndicator={true}
        >
            <View style={styles.welcomeTextContainer}>
                {/*@ts-ignore*/}
                <Text style={styles.welcomeTextHeader}>How did you first hear about us?</Text>
            </View>
            <View>
                {
                    ListItems.map((item)=>(
                        //@ts-ignore
                        <List.Item 
                            key={item.key}
                            left={(props)=>
                                item.image?
                                    <Avatar.Image size={46} {...props} source={item.image} />
                                :
                                item.icon?
                                    <Avatar.Icon size={46} {...props} color={colors.accent} icon={item.icon} />
                                :
                                    null
                            }
                            onPress={()=>{itemPress(item)}}
                            right={props => <List.Icon 
                                    {...props} 
                                    icon="chevron-right" 
                                />
                            }
                            title={item.title}
                            titleStyle={{fontSize:Width>1000?40:20,}}
                        />
                    ))
                }
            </View>
        </KeyboardAwareScrollView>
        <Image 
            resizeMode={'contain'}
            source={require('../../../assets/onboarding/heardAboutUs.png')}
            style={styles.image}
        />
        </>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            container: {
                alignContent: 'center',
                alignSelf: 'center',
                flexGrow: 1,
                maxWidth: 700,
                paddingLeft: '10%',
                paddingRight: '10%',
                width: '100%',
            },
            image: {
                alignSelf: 'center',
                height: '26%',
                width: '100%',
            },
            welcomeTextContainer: {
                marginBottom: '5%'
            },
            welcomeTextHeader: {
                color: colors.primary,
                fontSize: Width>1000?70:30,
                fontWeight: 'bold',
                marginTop: 20,
                textAlign: 'center'
            },
        })
    );
}