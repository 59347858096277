import React from 'react';
import { Dimensions, StyleSheet, Text, View, } from 'react-native';
import { List, Title, useTheme } from 'react-native-paper';
import RBSheet from "react-native-raw-bottom-sheet";
import Strings from '../constants/Strings';
import { Loading } from './LoadingModal';
import { SubData } from '../constants/Types';

const HEIGHT = Dimensions.get('window').height;
const WIDTH = Dimensions.get('window').width;

const DefaultSubs = [
    {description:'',price:Strings.OPTIMIZER_PRICE,title:'Monthly Subscription',type:'monthly'},
    {description:'',price:Strings.OPTIMIZER_PRICE_YEAR,title:'Yearly Subscription',subscriptionPeriodUnitIOS:'YEAR',type:'yearly'},
];

interface PropsType {
    loading: boolean
    RBRef: any
    requestSubscription: (type: 'monthly' | 'yearly') => void
    subscriptions?: SubData[]
}

export default function SelectSubscriptionSheet(props: PropsType) {
    const { loading, RBRef, subscriptions, requestSubscription } = props;

    const { colors } = useTheme();
    const styles = Styles(colors);
    const subs = subscriptions && subscriptions[0].platform=='ios'? subscriptions : DefaultSubs;

    async function handleSubmit(type: 'monthly' | 'yearly') {
        await requestSubscription(type);
    }

    return (
        <>
            {/**@ts-ignore */}
            <RBSheet
                ref={ref => { RBRef.current = ref; }}
                closeOnDragDown={true}
                height={HEIGHT > 1000 ? 400 : 320}
                openDuration={250}
                onClose={() => {

                }}
                customStyles={{
                    container: {
                        backgroundColor: colors.background,
                        borderRadius: 20,
                        padding: 20
                    }
                }}
            >
                <Title>Payment Optimizer Subscription</Title>
                {/**@ts-ignore */}
                <List.Subheader>Subscribe now for access to our payment optimizer.</List.Subheader>
                <View>
                {
                    subs.map((sub,index)=>{
                        return(
                            /**@ts-ignore */
                            <List.Item
                                key={index}
                                title={sub.title}
                                titleStyle={{ color: colors.primary }}
                                description={
                                    <Text style={{ color: colors.primary }}>
                                        {sub.price}
                                        <Text style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                                            {
                                                sub.subscriptionPeriodUnitIOS && sub.subscriptionPeriodUnitIOS == 'YEAR' ?
                                                    WIDTH > 1000 ?
                                                        ' (Best Value ~ 17% Discount)'
                                                        :
                                                        ' (Best Value ~ \n               17% Discount)'
                                                :
                                                null
                                            }
                                        </Text>
                                    </Text>
                                }
                                descriptionStyle={{ color: colors.primary }}
                                //@ts-ignore
                                onPress={() => { handleSubmit(sub.type); }}
                                disabled={loading}
                                left={(props: any) => <List.Icon {...props} icon={'calendar-month-outline'} color={colors.primary} />}
                                right={(props: any) =>
                                    loading ?
                                        <Loading size='small' visible={true} />
                                        :
                                        <List.Icon {...props} icon={'chevron-right'} />
                                }
                            />
                        );
                    })
                }
                </View>
            </RBSheet>
        </>
    );
}

const Styles = (colors: any) => {
    return (
        StyleSheet.create({})
    );
}
