import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import { AuthRoutes, UnverifiedRoutes } from './Routes';
import { AppContext } from '../context';

const Stack = createStackNavigator();

export default function AuthStack({}:any){ 
    const {state} = React.useContext(AppContext);
    const {initialRoute} = state;

    if(initialRoute === 'UnverifiedScreen') {
        return(
            <Stack.Navigator initialRouteName={initialRoute}>
            {
                UnverifiedRoutes.map(({key,component,name,options})=>{
                    return(
                        <Stack.Screen
                            key={key}
                            name={name}
                            component={component}
                            options={{...options,headerShown:false}}
                        />
                    )
                })
            }
            </Stack.Navigator>
        );
    }

    return(
        <Stack.Navigator>
        {
            AuthRoutes.map(({key,component,isSecured,name,options})=>{
                return(
                    <Stack.Screen
                        key={key}
                        name={name}
                        component={component}
                        options={{...options}}
                    />
                )
            })
        }
        </Stack.Navigator>
    )
}