import React from 'react';
import { Dimensions, FlatList, StyleProp, StyleSheet, TextStyle, View, } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

const WIDTH = Dimensions.get('window').width;

export default function HowToGuide() {
    const { colors } = useTheme();
    const styles = Styles(colors);

    return (
        <View style={styles.container}>
            <FlatList
                data={GuidePoints(styles.text,styles.textReddot)}
                renderItem={({ item }) => {
                    return (
                        <View style={{flexDirection:'row',marginBottom:15,maxWidth: '92%',}}>
                            <Text style={{ fontWeight:'bold',marginRight:10 }}>{item.key}.</Text>
                            {item.text()}
                        </View>
                    )
                }}
                showsVerticalScrollIndicator={false}
            />
        </View>
    );
}

const Styles = (colors: any) => {
    return (
        StyleSheet.create({
            container: {
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            text: {
                fontSize: WIDTH > 1000 ? 30 : 16,
            },
            textReddot: {
                color: colors.error,
                //fontSize: WIDTH > 1000 ? 30 : 14,
            }
        })
    );
}

const GuidePoints = (style:StyleProp<TextStyle>,redDot:StyleProp<TextStyle>)=>[
    {
        key: 1,
        text: () => (
            <Text style={style}>After downloading the <Text style={{ fontWeight: 'bold' }}>Legacy Financial App</Text>, connect as many accounts as possible through Plaid in each section of the Home Screen, such as <Text style={{ fontWeight: 'bold' }}>Cash, Credit Cards, Loans, and Investments</Text>. You can do this by either clicking the <Text style={{ fontWeight: 'bold' }}>"+"</Text> icon in the top-right corner or selecting <Text style={{ fontWeight: 'bold' }}>"Add another account"</Text> at the bottom of each section.</Text>)
    },
    {
        key: 2,
        text: () => (
            <Text style={style}>If there are accounts that cannot be connected via Plaid, manually add them by clicking <Text style={{ fontWeight: 'bold' }}>"Manually add account"</Text> at the bottom of each section. This will require you to enter details like the outstanding balance, interest rate, minimum payment, and due date.</Text>
        )
    },
    {
        key: 3,
        text: () => (
            <Text style={style}>If you're a homeowner, click on <Text style={{ fontWeight: 'bold' }}>Real Estate</Text> and input the current value of your home. This data will be used to calculate your net worth accurately.</Text>
        )
    },
    {
        key: 4,
        text: () => (
            <Text style={style}>Once all accounts have been connected or manually entered, click the <Text style={{ fontWeight: 'bold' }}>Payment</Text> icon at the bottom of the Home Screen. For any accounts marked with a red dot, click on them and enter the interest rate, minimum payment, and due date, then save the information.</Text>
        )
    },
    {
        key: 5,
        text: () => (
            <Text style={style}>For manually entered accounts, you will receive an <Text style={{ fontWeight: 'bold' }}>"update required"</Text> prompt every 30 days on the Home Screen, with a red <Text style={redDot}>"{'\u2B24'}"</Text> dot appearing on the Payment screen. You’ll need to update the outstanding balance, minimum payment (if it has changed), and due date to ensure accurate debt-free date calculations.</Text>
        )
    },
    {
        key: 6,
        text: () => (
            <Text style={style}>For credit card accounts, be sure to enter your credit card limit to enable the <Text style={{ fontWeight: 'bold' }}>Credit Utilization Calculator</Text>.</Text>
        )
    },
    {
        key: 7,
        text: () => (
            <Text style={style}>Adjust the slider at the top left to enter your monthly income. For married users sharing bills, adjust the slider to account for joint income.</Text>
        )
    },
    {
        key: 8,
        text: () => (
            <Text style={style}>Decide what percentage of your monthly income (50%, 65%, or 75%) will be allocated toward automated <Text style={{ fontWeight: 'bold' }}>Debt Snowball</Text> or <Text style={{ fontWeight: 'bold' }}>Debt Blizzard</Text> (Debt Snowball combined with Debt Avalanche) calculations. On average, individuals use 50% of their income for debt elimination.</Text>
        )
    },
    {
        key: 9,
        text: () => (
            <Text style={style}>Ensure that you enter <Text style={{ fontWeight: 'bold' }}>ONLY the minimum payments</Text>, not the amount you're currently paying if you're contributing extra. The Debt Snowball and Debt Blizzard functions rely solely on the minimum payment amounts to calculate accurately.</Text>
        )
    },
    {
        key: 10,
        text: () => (
            <Text style={style}>Following these steps will allow the <Text style={{ fontWeight: 'bold' }}>Legacy Financial App</Text> to provide your debt-free date (month/year), significantly reducing your time in debt compared to traditional models. You’ll also see substantial savings in interest as you move toward becoming debt-free and financially stable.</Text>
        )
    },
];
