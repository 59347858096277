import * as React from 'react';
import { Dimensions, Image, Platform, StyleSheet, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Button, TextInput, useTheme } from 'react-native-paper';
import { FirebaseAuth } from '../constants/Firebase';
import Strings from '../constants/Strings';
import { Formik } from 'formik';

const Width = Dimensions.get('window').width;


export default function ForgotPasswordScreens({navigation}:any) {
  const {colors} = useTheme();
  const styles = Styles(colors);
  
  const [loading,setLoading] = React.useState(false);
  const [error,setError] = React.useState('');

  const handlePasswordReset = async (email:string,setSubmitting:(l:boolean)=>void,setFieldError:(field:string,error:string)=>void) => {
    setSubmitting(true);
    FirebaseAuth.sendPasswordResetEmail(FirebaseAuth.getAuth(),email)
    .then(()=>{
      alert('Password reset email sent successfully');
      navigation.goBack();
    })
    .catch((error)=>{
      setFieldError('email', 'The entered email address is invalid.');
    })
    .finally(()=>{
      setSubmitting(false);
    });
  }
 
  return (
    <KeyboardAwareScrollView contentContainerStyle={styles.container}>
      <View style={{alignItems: 'center', }}>
        <Image source={Strings.LOGO_COLORED} style={styles.logo} />
      </View>
      <View style={{}}>
        <Text style={{ ...styles.text, color: colors.placeholder }}>Forgot Password?</Text>
        
        <Formik
          initialValues={{email:''}}
          onSubmit={(values, actions) => {
            handlePasswordReset(values.email, actions.setSubmitting,actions.setFieldError);
          }}
        >
          {
            ({handleChange,values,handleSubmit,errors,isValid,isSubmitting,}) => (
              <>
              <TextInput
              autoCompleteType={'email'}
              value={values.email}
              onChangeText={handleChange('email')}
              placeholder='Enter your email'
              label={'email'}
              style={{...styles.textInput,fontSize: Width>1000?37:17,}}
              autoCapitalize='none'
              error={errors.email ? true : false}
              onSubmitEditing={()=>handleSubmit()}
            />
            <Text style={styles.errorText}>{errors.email}</Text>
            <Button
              style={styles.button}
              labelStyle={{fontSize:Width>1000?30:15}}
              onPress={() => handleSubmit()}
              mode='contained'
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
            >
              Send Email
            </Button>
              </>
            )
          }
        </Formik>

        
        <Button
          style={{ borderRadius: 10 }}
          labelStyle={{fontSize:Width>1000?25:15}}
          onPress={()=>{navigation.goBack()}}
          disabled={loading}
        >
          Go Back
        </Button>
      </View>
    </KeyboardAwareScrollView>
  );
}

const Styles = (colors:any)=>{
  const isDevice = (Platform.OS == 'ios' || Platform.OS == 'android');
  return (
    StyleSheet.create({
      buttonContainer: {
        margin: 25,
      },
      button: {
        borderRadius: 10,
        height: Width>1000?75:55,
        justifyContent: 'space-around',
        marginTop: '15%',
      },
      container: {
        alignContent: 'center',
        alignSelf: 'center',
        flex: 1,
        marginTop: isDevice?'20%': '5%',
        maxWidth: 700,
        paddingLeft: '10%',
        paddingRight: '10%',
        width: '100%',
      },
        errorText: {
          color: colors.error,
      },
      logo: {
        alignSelf:'center',
        height: Width>1000?350:200,
        marginBottom: '10%',
        resizeMode: 'contain',
        width: Width>1000?350:200,
      },
      signupContainer: {
        marginTop: '10%',
      },
      signupButton: {
        marginTop: '2%',
        height:45,
        justifyContent: 'space-around',
      },
      text: {
        fontSize: Width>1000?38:18,
        marginBottom: 15,
      },
      textInput: {
        backgroundColor: 'transparent',
        fontSize: 17,
        //marginBottom: 10,
      },
    })
  );
}