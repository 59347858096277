import React from 'react';
import {Provider,useTheme} from 'react-native-paper';
import { NavigationContainer } from '@react-navigation/native';

import theme from '../constants/Theme';
import SplashScreen from '../components/SplashScreen';
import { linking } from './Routes';
import MainStack from './MainStack';
import { useAppUpdater } from '../hooks/useAppUpdater';
import useCachedResources from '../hooks/useCachedResources';

export default function Navigation(){ 
    const {colors} = useTheme();
    const {isUpdateAvailable,UpdateApp} = useAppUpdater({autoUpdate:true});
    const isLoadingComplete = useCachedResources();

    if(!isLoadingComplete) return null;
    if(isUpdateAvailable) return <SplashScreen update={UpdateApp}/>;

    return(
        <Provider theme={theme.LightTheme}>
            <NavigationContainer
                fallback={<SplashScreen/>}
                linking={linking}
                theme={theme.LightTheme}
            >
                <MainStack/>
            </NavigationContainer>
        </Provider>
    )
}