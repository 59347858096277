import React from 'react';
import {StyleSheet,View,} from 'react-native';
//@ts-ignore
import { BarChart, XAxis } from 'react-native-svg-charts';
import { G, Rect, Text as SvgText } from 'react-native-svg';
import { currencyFormatter } from '../constants/utils';

export default function IncomeExpenseChart({colors,data}:any){ 
    const styles = Styles(colors);
    
    const fill = colors.primary;
    
    return (
        <>
            <XAxis
                style={{ }}
                data={data}
                formatLabel={(value:any) => currencyFormatter(data[ value ].value)}
                contentInset={{ left: 90, right: 90 }}
                svg={{ fontSize: 15, fill: colors.primary }}
            />
            <BarChart 
                style={{ flex: 1 }} 
                data={data} 
                svg={{ fill }} 
                contentInset={{ top: 10, bottom: 30 }}
                yAccessor={({ item }:any) => item.value}
                yMin={0}
                //xAccessor={ ({ item }:any) => item.value }
            >
            </BarChart>
            <XAxis
                style={{ marginTop:10 }}
                data={data}
                formatLabel={(value:any) => data[ value ].label}
                contentInset={{ left: 90, right: 90 }}
                svg={{ fontSize: 18, fill: colors.primary }}
            />
        </>
    );
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({})
    );
}