// @ts-nocheck 
import React from 'react';
import { AppContext } from '../../context';

export function Headings() {
  const {state} = React.useContext(AppContext);
  const {isloggedIn} = state;
  
  return (
    <header className="sticky">
    <div className="header-grid">
      <div className="wrapper">
        <div className="nav" id="nav">
          <a href="javascript:void(0)" className="close" onclick="closeNav()">×</a>
          <a className="text" href="#about-the-app">About Us</a>
          <a className="text" href="#app-features">App Features</a>
          <a className="text" href="#download">Download</a>
          <a className="text" href="#">Contact Us</a>
        </div>
        <span onclick="openNav()" className="open" id="open">☰</span>
      </div>
      <a href="#">
        <img className="logo" alt="Legacy Logo" src="img/logo.png" />
      </a>
      <div className="header">
        <div className="main-nav">
          <ul className="main-nav-list">
            <li>
              <a className="main-nav-link" href="#about-the-app">About Us</a>
            </li>
            <li>
              <a className="main-nav-link" href="#app-features">App Features</a>
            </li>
            <li><a className="main-nav-link" href="#download">Download</a></li>
            <li><a className="main-nav-link" href="#">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div className="remove" />
    </div>
  </header>
  );
}
