import React from 'react';
import {StyleSheet,View,} from 'react-native';
import {Avatar,Button,List,Text,useTheme} from 'react-native-paper';
import { currencyFormatter, dateFormatter, getInitials } from '../constants/utils';
import { useTransactions } from '../hooks/useTransactions';
import ListEmpty from './ListEmpty';
import { Loading } from './LoadingModal';

export const TransactionList = ({navigation}:any)=>{
  const {colors} = useTheme();
  const styles = Styles(colors);
  const { loading,transactions, } = useTransactions({ selection: 'yearly' });
  
  return(
    <View style={styles.transactionsContainer}>
          <List.Section>
            {/*@ts-ignore*/}
            <List.Item
              title={'Recent Transactions'}
              titleStyle={{ color: colors.onSurface }}
              right={(props) => <Button mode={'text'} onPress={() => { navigation.navigate('Transactions') }} uppercase={false} >View All</Button>}
            />
            {
              loading?
              <Loading visible={true} />
              :
              transactions.length < 1 ?
                <ListEmpty type={'transactions'} />
                :
                transactions.map((item) => {
                  return (
                    <TransactionListItem key={item.id} item={item} navigation={navigation} />
                  );
                })
            }
          </List.Section>
        </View>
  );
}

export default function TransactionListItem({item,navigation,onPress=null}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);

    const initial = getInitials(item.name);
    
    return(
      <>
      {/**@ts-ignore */}
        <List.Item
            //key={item.id} 
            title={item.name} 
            description={ 
              <>
                {/**@ts-ignore */}
                <Text style={{color:colors.onSurface}}>{dateFormatter(item.date,'shortString')}</Text>
                {/**@ts-ignore */}
                {item.account&&<Text>{' - '+item.account.title}</Text>}
              </>
            }
            left={(props)=>
              item.logo_url?
              <Avatar.Image size={46} {...props} source={{uri:item.logo_url}} />
              :
              item.icon?
              <Avatar.Icon size={46} {...props} color={colors.accent} icon={item.icon} />
              :
              <Avatar.Text size={46} {...props} color={colors.accent} label={initial} />
          }
            //@ts-ignore
            right={(props)=> <Text 
                {...props} 
                style={{fontWeight:'bold',color:item.type==='expense'?colors.error:colors.primary}}
              >
                {currencyFormatter(Number(item.amount))}
              </Text>
            }
            titleStyle={{color:colors.onSurface}}
            descriptionStyle={{color:colors.onSurface}}
            style={{backgroundColor:colors.background,borderColor:colors.borderColor,borderRadius: 15,borderWidth: 1,marginBottom: 20}}
            onPress={()=>{
              navigation.navigate('View Transactions',{transaction:item});
              if(onPress) onPress();
            }}
          />
      </>
    )
}

const Styles = (colors:any)=>{
  const Maxwidth = 1000;
    return (
        StyleSheet.create({
          transactionsContainer: {
            alignSelf: 'center',
            marginTop: -20,
            maxWidth: Maxwidth,
            width: '90%',
          },
        })
    );
}