import React from 'react';
import {StyleSheet,useWindowDimensions,View,} from 'react-native';
import {Button, useTheme} from 'react-native-paper';
import { Editor } from '@tinymce/tinymce-react';
import Strings from '../constants/Strings';
import { AppContext } from '../context';
import Error403Screen from './Error403Screen';
import { useDocs } from '../hooks/useDocs';
import { LoadingModal } from '../components/LoadingModal';
import WebDashboard from '../layouts/WebDashboard';

export default function EditDocs({navigation}:any){ 
    const {state} = React.useContext(AppContext);
    const {isAdmin} = state.user; 
    if(!isAdmin){
        return <Error403Screen />
    }
    
    const {colors} = useTheme();
    const {width} = useWindowDimensions();
    const styles = Styles(colors,width);
    const {loading,doc,Getdoc,UpdateDoc} = useDocs('privacypolicy');
    const editorRef = React.useRef<any>(null);
    const [btnState, setBtnState] = React.useState<'Privacy' | 'Terms'>('Privacy');

    const onPostToggle = ()=>{
        switch(btnState) {
          case 'Privacy': {
            setBtnState('Terms');
            Getdoc('termsandconditions',true);
            break;
          }
          case 'Terms': {
            setBtnState('Privacy');
            Getdoc('privacypolicy',true);
            break;
          }
        }
    }
    
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef?.current.getContent());
        }
    };

    React.useEffect(()=>{},[]);

    return(
        <WebDashboard>
            <section className="grid">
            <View style={styles.postToggleContainer}>
                <Button
                    style={styles.postToggleBtns}
                    labelStyle={{...styles.postToggleBtnsText,color:btnState == 'Terms'? colors.primary : 'white'}}
                    uppercase={false}
                    mode={ 'contained' }
                    color={ btnState == 'Privacy'? colors.primary : 'white' }
                    onPress={()=>onPostToggle()}
                >
                    Privacy Policy
                </Button>
                <Button
                    style={{...styles.postToggleBtns,}}
                    labelStyle={{...styles.postToggleBtnsText,color:btnState == 'Privacy'? colors.primary : 'white'}}
                    uppercase={false}
                    mode={ 'contained' }
                    color={ btnState == 'Terms'? colors.primary : 'white' }
                    onPress={()=>onPostToggle()}
                >
                    Terms and Conditions
                </Button>
            </View>
            <View />
            <View style={styles.editorContainer}>
                <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={doc}
                    init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                /> 
                <Button
                    style={{...styles.postToggleBtns,width:100,marginTop:10}}
                    labelStyle={{...styles.postToggleBtnsText}}
                    uppercase={false}
                    mode={ 'contained' }
                    disabled={loading}
                    loading={loading}
                    onPress={()=>{
                        const type = btnState=='Terms'?'termsandconditions':'privacypolicy';
                        UpdateDoc(type,editorRef?.current.getContent())
                        .then(()=>{
                            const msg = btnState=='Terms'?'Terms and conditions updated successfully.' : 'Privacy policy updated successfully.';
                            alert(msg);
                        })
                        .catch(()=>{
                            const msg = btnState=='Terms'?'Failed to update Terms and conditions.' : 'Failed to update Privacy policy.';
                            alert(msg);
                        })
                    }}
                >
                    Save
                </Button>
            </View>
            <LoadingModal visible={loading} />
            </section>
        </WebDashboard>
    )
}

const Styles = (colors:any,width:number)=>{
    const maxWidth = width>Strings.MAX_SCREEN_WIDTH?Strings.MAX_SCREEN_WIDTH:width;
    return (
        StyleSheet.create({
            container:{
                flex:1,
                padding:20,
            },
            editorContainer:{
                alignSelf:'center',
                marginTop: 10,
                maxWidth,
                width: maxWidth*0.75
            },
            postToggleContainer: {
                marginTop: 20,
                flexDirection: 'row',
                height: 50,
                width: 300,
                padding: 10,
                borderRadius: 1,
                backgroundColor:'transparent',
              },
              postToggleBtns: {
                width: '50%',
                justifyContent:'center',
                borderRadius: 10,
              },
              postToggleBtnsText: {
                fontSize: 11
              },
        })
    );
}