import React, { createContext, useReducer } from 'react';

interface UserType {
    
}
interface SubscriptionType {
    id:string,
    startDate:number,
    endDate:number,
    type: 'free'|'trial'|'paid'|'ended'|null
}
interface Props {
    state: state | any;
    dispatch: any;
}

interface BadgesProp {
    internalTransfer?:string,
    subscription?:string
}
interface state {
    accountIds: string[];
    badges:BadgesProp,
    biometric: 'enabled'|'disabled'|'unavailable'|'unknown';
    cookie: string;
    initialRoute: string;
    isLocked:boolean;
    isloggedIn: boolean;
    refreshBank: boolean;
    user: UserType;
    subscription: SubscriptionType;
}
interface action {
    type: 'reset' | 'setLogin' | 'setUser' | 'setUserInitial' | 'setCookie' | 'storeUserInfo' | 'logoutUser' | 'setInitialRoute' | 'setRefreshBank' | 'setSubscription' | 'setBiometric' | 'setIsLocked' | 'setAccountIds'
    payload: any
}

const initialState:state = {
    accountIds: [],
    badges:{},
    biometric: 'unknown',
    cookie: '',
    initialRoute: 'Home',
    isLocked:false,
    isloggedIn: false,
    refreshBank: false,
    user: {
        
    },
    subscription: {
        id:'',
        startDate:0,
        endDate:0,
        type:null

    }
}

const reducer = (state: state, action: action) => {
    switch (action.type) {
        case 'reset':{
            return {...initialState,biometric:'disabled'};
        }
        case 'setLogin': {
            const isloggedIn = action.payload;
            return {...state, isloggedIn};
        }
        case 'setUser': {
            const user = {...state.user, ...action.payload};
            return {...state, user};
        }
        case 'setUserInitial': {
            const {initialRoute,isloggedIn,user} = action.payload;
            return {...state, initialRoute,isloggedIn,user};
        }
        case 'setCookie': {
            const cookie = action.payload;
            return {...state, cookie};
        }
        case 'storeUserInfo': {
            const user = action.payload;
            return {...state,user};
        }
        case 'logoutUser': {
            const isloggedIn = action.payload;
            return {...state, isloggedIn};
        }
        case 'setInitialRoute': {
            const initialRoute = action.payload;
            return { ...state, initialRoute };
        }
        case 'setRefreshBank':{
            const refreshBank = action.payload;
            return { ...state, refreshBank };
        }
        case 'setSubscription':{
            const subscription = {...state.subscription, ...action.payload};
            return { ...state, subscription }
        }
        case 'setBiometric':{
            const biometric = action.payload;
            return { ...state, biometric};
        }
        case 'setIsLocked':{
            const isLocked = action.payload;
            return {...state,isLocked};
        }
        case 'setAccountIds':{
            const accountIds = action.payload;
            return{...state,accountIds};
        }
    }
}

const AppContext = createContext<Props>({ state: {}, dispatch: null });

const AppContextProvider = (props: any) => {
    const [state, dispatch] = useReducer<any>(reducer, initialState);
    return (
      <AppContext.Provider value={{state,dispatch}}>
        {props.children}
      </AppContext.Provider>
    );
};

const AppConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppConsumer };  