import React from 'react';
import {Dimensions, Image, Platform, ScrollView, StyleSheet,Text,TouchableNativeFeedback,TouchableOpacity,View} from 'react-native';
import {Button,TextInput,useTheme} from 'react-native-paper';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {Formik} from 'formik';
import * as AppleAuthentication from 'expo-apple-authentication';
import { useAuthentication } from '../hooks/useAuthentication';
import Strings from '../constants/Strings';
import { signInSchema } from '../constants/Schemas';
import { AppContext } from '../context';
//import { GoogleSigninButton } from '@react-native-google-signin/google-signin';

const Width = Dimensions.get('window').width;

const InitialFormValues = {
    email: '',
    password: '',
    error: ''
}

export default function SignInScreen({navigation}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {state} = React.useContext(AppContext);
    const {user} = state;
    const {Login,SignInWithGoogle,SignInWithApple} = useAuthentication({});

    const os = Platform.OS;
    const passwordRef = React.useRef<any>();
    const [showPass,setShowPass] = React.useState<boolean>(false);

    React.useEffect(()=>{
        if(user.email && !user.onBoardComplete)navigation.navigate('Onboarding')
    },[user]);
    
    return(
        <KeyboardAwareScrollView keyboardShouldPersistTaps={'always'}>
            <ScrollView contentContainerStyle={styles.container} keyboardShouldPersistTaps={'always'}>
                <Formik
                    initialValues={InitialFormValues}
                    validationSchema={signInSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values,actions)=>{
                        Login({email:values.email,password:values.password})
                        .then(()=>{
                            //actions.setSubmitting(false);
                            //if(user && !user.onBoardComplete)navigation.goBack()
                        })
                        .catch((error)=>{
                            actions.setFieldError('email',error.message);
                            actions.setSubmitting(false);
                        })
                    }}
                >
                    {({handleChange,handleSubmit,setFieldError,setSubmitting,isSubmitting,values,errors})=>{
                        return(
                            <View>
                                <View>
                                    <Image source={Strings.LOGO_COLORED} style={styles.logo} />
                                </View>
                                <View style={styles.welcomeTextContainer}>
                                    <Text style={styles.welcomeTextHeader}>Login</Text>
                                    <Text style={styles.welcomeText}>Let's start creating your Legacy today!</Text>
                                </View>

                                <TextInput
                                    mode={'flat'}
                                    style={styles.textInput}
                                    theme={{colors:{placeholder:colors.primary}}}
                                    onChangeText={handleChange('email')}
                                    value={values.email}
                                    label={'Email'}
                                    placeholder={'Enter your email'}
                                    placeholderTextColor={colors.primary}
                                    error={errors.email?true:false}
                                    //@ts-ignore
                                    autoCompleteType="email"
                                    returnKeyType={'next'}
                                    onSubmitEditing={()=>passwordRef?.current?.focus()}
                                />
                                <Text style={styles.errorText}>{errors.email}</Text>

                                <TextInput
                                    //autoCompleteType={''}
                                    ref={(ref:any)=>passwordRef.current=ref}
                                    mode={'flat'}
                                    style={styles.textInput}
                                    theme={{colors:{placeholder:colors.primary}}}
                                    onChangeText={handleChange('password')}
                                    value={values.password}
                                    label={'Password'}
                                    placeholder={'Enter your password'}
                                    placeholderTextColor={colors.primary}
                                    error={errors.password?true:false}
                                    secureTextEntry={!showPass}
                                    returnKeyType={'done'}
                                    onSubmitEditing={()=>handleSubmit()}
                                    //@ts-ignore
                                    right={<TextInput.Icon icon={showPass?"eye-off":"eye"} onPress={()=>setShowPass(!showPass)} />}
                                />
                                <Text style={styles.errorText}>{errors.password}</Text>

                                <Button
                                    style={styles.forgotPassword}
                                    labelStyle={{fontSize:Width>500?20:12}}
                                    onPress={()=>navigation.navigate('Forgot Password')}
                                    color={colors.primary}
                                    mode={'text'}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    uppercase={false}
                                >
                                    Forgot Password?
                                </Button>

                                <Text style={styles.errorText}>{errors.error}</Text>

                                <Button
                                    style={styles.button}
                                    labelStyle={{fontSize:Width>500?25:15}}
                                    onPress={()=>handleSubmit()}
                                    color={colors.primary}
                                    mode={'contained'}
                                    disabled={isSubmitting}
                                    uppercase={true}
                                >
                                    Login
                                </Button>

                                <TouchableOpacity
                                    disabled={isSubmitting}
                                    onPress={()=>{
                                        setSubmitting(true);
                                        SignInWithGoogle()
                                        .catch((error)=>{
                                            console.log('error: ', error);
                                            setFieldError('error',error.message);
                                        })
                                        .finally(()=>{setSubmitting(false);})
                                    }}
                                    style={{...styles.button,marginBottom:10,backgroundColor:colors.accent}}
                                >
                                    <Image 
                                        resizeMode={'contain'}
                                        source={Strings.GOOGLE_SIGNIN}
                                        style={styles.buttonImg}
                                    />
                                </TouchableOpacity>

                                {/* <GoogleSigninButton
                                    size={GoogleSigninButton.Size.Wide}
                                    style={styles.button}
                                    color={GoogleSigninButton.Color.Light}
                                    onPress={()=>{
                                        setSubmitting(true);
                                        SignInWithGoogle()
                                        .catch((error)=>{
                                            console.log('error: ', error);
                                            setFieldError('error',error.message);
                                        })
                                        .finally(()=>{setSubmitting(false);})
                                    }}
                                    disabled={isSubmitting}
                                /> */}
                                
                                {os === 'ios' &&
                                    <AppleAuthentication.AppleAuthenticationButton
                                        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                                        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
                                        cornerRadius={5}
                                        style={styles.button}
                                        onPress={async () => { 
                                            setSubmitting(true);
                                            SignInWithApple() 
                                            .catch((error:any)=>{setFieldError('error',error.message)})
                                            .finally(()=>{
                                                setSubmitting(false);
                                            })
                                        }}
                                    />
                                }


                                {(os === 'android'||os==='ios')&&
                                    <Button
                                    style={styles.button2}
                                    labelStyle={{fontSize:Width>500?25:14}}
                                    onPress={()=>navigation.goBack()}
                                    color={colors.primary}
                                    mode={'text'}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    uppercase={false}
                                >
                                    Create New Account
                                </Button>}
                            </View>
                        )
                    }}
                </Formik>
                <Text style={{ textAlign: 'center', marginTop: 10, fontSize: Width>500?20:10 }}>
                    {(Platform.OS === 'android'
                        ? Strings.VERSION_ANDROID
                        : Platform.OS === 'ios'
                        ? Strings.VERSION_IOS
                        : '0')
                    }
                </Text>
            </ScrollView>
        </KeyboardAwareScrollView>
    )
}

const Styles = (colors:any)=>{
    const isDevice = (Platform.OS == 'ios' || Platform.OS == 'android')
    return (
        StyleSheet.create({
            button: {
                borderRadius: 10,
                height: Width>500?75:55,
                justifyContent: 'space-around',
                marginTop: '4%',
            },
            button2: {
                borderRadius: 10,
                height: Width>1000?75:55,
                justifyContent: 'space-around',
                //marginTop: '4%',
            },
            buttonImg: {
                borderRadius: 10,
                height: Width>500?75:55,
                //marginTop: '4%',
                width:Width*0.8,
            },
            container: {
                alignContent: 'center',
                alignSelf:'center',
                flex: 1,
                marginTop: isDevice?'20%': '5%',
                maxWidth: 700,
                paddingLeft: Width>1000?20:'10%',
                paddingRight: Width>1000?20:'10%',
                width: '100%',
            },
            errorText: {
                color: colors.error,
            },
            forgotPassword: {
                alignSelf: 'flex-end',
                marginTop: -20
            },
            logo: {
                alignSelf:'center',
                height: Width>500?350:200,
                marginBottom: '10%',
                resizeMode: 'contain',
                width: Width>500?350:200,
            },
            textInput: {
                backgroundColor: colors.background,
                color: colors.primary,
                fontSize: Width>500?25:17,
                marginBottom: 20,
            },
            welcomeText: {
                color: colors.primary,
                fontSize: Width>500?30:15,
            },
            welcomeTextContainer: {
                marginBottom: '20%'
            },
            welcomeTextHeader: {
                color: 'black',
                fontSize: Width>500?40:20,
                fontWeight: 'bold',
            },
        })
    );
}