import React from 'react';
import { Dimensions, FlatList, StyleSheet,View,} from 'react-native';
import {IconButton, useTheme} from 'react-native-paper';
import { useBills } from '../hooks/useBills';
import { BillsList } from '../components/BillList';
import Strings from '../constants/Strings';
import { Loading } from '../components/LoadingModal';
import ListEmpty from '../components/ListEmpty';

export default function BillsScreen({navigation}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {bills,loading,DeleteBill,LoadMoreBills} = useBills({limit:Strings.LIST_LIMITS});

    // React.useLayoutEffect(() => {
    //     navigation.setOptions({
    //       headerRight: () => (
    //         <View style={{alignItems:'center',backgroundColor:colors.primary,borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
    //           <IconButton color={colors.accent} icon={'plus'} onPress={()=>navigation.navigate('Add Bill')} size={18} />
    //         </View>
    //       ),
    //     });
    // }, [navigation]);

    
    return(
        <View style={styles.container}>
            <FlatList 
                data={bills}
                keyExtractor={(item)=>item.id}
                renderItem={({item})=><BillsList bill={item} DeleteBill={DeleteBill} onPress={()=>{navigation.navigate('View Bill',{bill:{...item}})}} />}
                showsVerticalScrollIndicator={false}
                onEndReached={()=>LoadMoreBills(bills[bills.length-1].name)}
                ListFooterComponent={<Loading size={'small'} visible={loading} />}
                ListEmptyComponent={<ListEmpty type={'bills'} />}
            />
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                borderColor: 'black',
                borderWidth: 1,
                height: Height,
                marginBottom: 20,
            },
            bills:{
                borderColor: 'black',
                borderWidth: 1,
                height: '30%',
                width: '100%',
            },
            expenses:{
                borderColor: 'black',
                borderWidth: 1,
                height: '30%',
                width: '100%',
            },
            income:{
                borderColor: 'black',
                borderWidth: 1,
                height: '30%',
                width: '100%',
            },
            transactions:{
                borderColor: 'black',
                borderWidth: 1,
                height: '30%',
                width: '100%',
            },
        })
    );
}