import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import { useAuthentication } from './useAuthentication';
import { useGetPushTokens } from './useGetPushToken';
import { useNotifications } from './useNotifications';
import { useBiometrics } from './useBiometrics';
import { GetAccountIds } from './useAccounts';
import { AppContext } from '../context';

export default function useCachedResources() {
  const {state,dispatch} = React.useContext(AppContext);
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [resLoadComplete,setResLoadComplete] = React.useState(false);
  const {loading:authLoading} = useAuthentication({checkLogin:true});
  const {UploadToken} = useNotifications();
  const {expoPushToken} = useGetPushTokens({});
  const {getBioMetricStatus} = useBiometrics();

  async function loadResourcesAndDataAsync() {
    try {
      if(state.user.uid){
        const accountsIds = await GetAccountIds(state.user.uid);
        dispatch({ type: 'setAccountIds', payload:accountsIds });
      }
    } 
    catch (e) {
      console.warn(e);
    } 
    finally {
      await getBioMetricStatus();
      setResLoadComplete(true);
      SplashScreen.hideAsync();
    }
  }

  React.useEffect(() => {
    SplashScreen.preventAutoHideAsync();
  }, []);

  React.useEffect(()=>{
    if(!authLoading){
      setLoadingComplete(true);
      UploadToken(expoPushToken??'');
      //SplashScreen.hideAsync();
      loadResourcesAndDataAsync();
    }
  },[authLoading]);

  return isLoadingComplete;
}
