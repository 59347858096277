import React from 'react';
import { Firestore } from '../constants/Firebase';
import { getDateCodes } from '../constants/utils';
import { AppContext } from '../context';
import { AccountProps } from '../constants/Types';
const {useEffect,useState}=React;

export interface BillProps{
    account?:AccountProps
    amount:number
    autoPay:boolean
    created:number
    complete:boolean
    due:number
    id:string
    name:string
    overdue:boolean
    owed:number
    paid:number
    repeats:string//'Monthly'
    uid:string
    updated:number
}

interface UseBillsProps {
    bill: BillProps
    bills: BillProps[]
    dueBills: BillProps[]
    error:boolean
    errorMessage:string
    loading:boolean
}
 
const InitialBill = {
    amount:0,
    autoPay:false,
    created:Date.now(),
    complete:true,
    due:Date.now(),
    id:'string',
    name:'',
    overdue:false,
    owed:0,
    paid:0,
    repeats:'Monthly',
    uid:'',
    updated:Date.now()
}
const InitialBillsState = {
    bill:InitialBill,
    bills:[],
    dueBills:[],
    error:false,
    errorMessage:'',
    loading: false,
}
export const useBills = ({get=true,limit=3})=>{
    const [billsState,setBillsState] = React.useState<UseBillsProps>(InitialBillsState);
    const {state} = React.useContext(AppContext);
    const {uid} = state.user;
    const Ref = Firestore.collection(Firestore.getFirestore(),'Bills');
    const UserBillRef = Firestore.collection(Firestore.getFirestore(),'Users/'+uid+'/Bills');
 
    async function AddBill(bill:BillProps){
        try{
            bill.uid = uid;
            const ref = await Firestore.addDoc(Ref,bill);
            bill.id = ref.id;
            const docRef = Firestore.doc(Ref,ref.id)
            return Firestore.updateDoc(docRef,{...bill});
        }
        catch(error){

        }
    }
    async function DeleteBill(id:string){
        const docRef = Firestore.doc(Ref,id)
        return Firestore.deleteDoc(docRef);
    }
    async function GetDueBills(limit:number,month:string){
        let ref = Firestore.doc(Firestore.getFirestore(),'Users/'+uid+'/Bills/'+month);

        return Firestore.onSnapshot(ref,(snap)=>{
            if(state.user.getTrans) return;
            if(!snap.exists()){
                setBillsState({...billsState,dueBills:[]});
                return []; 
            }
            const due = Object.values( (snap.data() as BillProps) );
            const dueBills: BillProps[] = [];
            for(let i=0; i<due.length; i++){
                const bill = due[i];
                //if(!bill.overdue) continue;
                dueBills.push( bill );
            }
            setBillsState({
                ...billsState,
                dueBills
            });
            return dueBills;
        },
        (error)=>{
            console.log('getBillsrror: ', error.message);
            setBillsState({
                ...billsState,
                error:true,
                errorMessage:error.message
            })
        });
    }

    async function GetBills(limit:number){
        let queryParams:any[] = [Firestore.orderBy('name','asc'), Firestore.where('uid','==',uid)];
        if(limit > 0){ 
            queryParams.push( Firestore.limit(limit) ) ;
        }

        const query = Firestore.query(Ref,...queryParams);


        return Firestore.onSnapshot(query,(snapshot)=>{
            if(state.user.getTrans) return;
            if(snapshot.empty) {
                setBillsState({...billsState,bills:[]});
                return;
            }

            const bills: BillProps[] = [];
            snapshot.forEach((snap)=>{
                const data = (snap.data() as BillProps);
                bills.push(data);
            });
            setBillsState({
                ...billsState,
                bills
            })
        },(error)=>{
            console.log('getBillsrror: ', error.message);
            setBillsState({
                ...billsState,
                error:true,
                errorMessage:error.message
            })
        });
    }
    async function GetSingleBill(billId:string){
        const docRef = Firestore.doc(Ref,billId);
        Firestore.onSnapshot(docRef,(snapshot)=>{
            if(state.user.getTrans) return;
            if(!snapshot.exists){
                setBillsState({...billsState,bill:InitialBill});
                return;
            }
            setBillsState({...billsState,loading:true});
            const bill = (snapshot.data() as BillProps);
            setBillsState({...billsState,loading:false,bill});
            return bill;
        },(error)=>{
            console.log('getBillsrror: ', error.message);
            setBillsState({
                ...billsState,
                error:true,
                errorMessage:error.message
            })
        });
    }
    async function LoadMoreBills(after:string){
        let queryParams:any[] = [Firestore.orderBy('name','asc'),Firestore.where('uid','==',uid),Firestore.startAfter(after)];
        if( limit > 0 ) queryParams.push( Firestore.limit(limit) );
        
        const query = Firestore.query(Ref,...queryParams);

        setBillsState({...billsState,loading:true});
        const bills: BillProps[] = billsState.bills;
        Firestore.getDocs(query)
        .then((snapshot)=>{
            snapshot.forEach((snap)=>{
                const data = (snap.data() as BillProps);
                bills.push(data);
            });
            setBillsState({
                ...billsState,
                loading:false,
                bills
            });
            return bills;
        })
        .catch((error)=>{
            setBillsState({
                ...billsState,
                error:true,
                errorMessage:error.message
            });
            return billsState.bills;
        });
    }
    async function UpdateBill(bill:BillProps){
        const yearMonth = getDateCodes('','yearMonth');
        bill.uid = uid;
        const docRef = Firestore.doc(Ref,bill.id);
        
        return Firestore.setDoc(docRef,bill,{merge:true})
            .then(()=>{
                const billRef = Firestore.doc(UserBillRef,yearMonth);
                return Firestore.setDoc(billRef,{[bill.id]:bill},{merge:true});
            });
    }

    useEffect(()=>{
        if(get){
            GetBills(limit);
        }
    },
    []);

    return {...billsState,AddBill,DeleteBill,GetDueBills,GetBills,GetSingleBill,LoadMoreBills,UpdateBill}
}