import React from 'react';
import { TransactionProps } from './useTransactions';
import { Firestore, Functions } from '../constants/Firebase';
import { AppContext } from '../context';
const {useEffect,useState}=React;

export type TransferType = {
    id:string,
    trans1:TransactionProps,
    trans2:TransactionProps
}
type StateProp = {
    error:boolean,
    errorMessage:string,
    loading:boolean,
    loadingUpdate:boolean,
    transfers:TransferType[]
}

const InitialState = {
    loading:false,
    loadingUpdate:false,
    error:false,
    errorMessage:'',
    transfers:[]
}
export const useTransfers = ()=>{
    const [state,setState] = useState<StateProp>(InitialState);
    const { state: AppState } = React.useContext(AppContext);
    const UID = AppState.user.id;
    const Ref = Firestore.collection(Firestore.getFirestore(),'Users/'+UID+'/TransferTransactions');
    const handleTransfer = Functions.httpsCallable(Functions.getFunctions(),'handleTransferv2')

    async function GetTransfers(){
        setState({...state,loading:true});
        const query = Firestore.query(Ref,Firestore.limit(10));
        Firestore.getDocs(query)
        .then((snapshot)=>{
            const trans:TransferType[] = [];
            snapshot.forEach((snap)=>{
                const data = (snap.data() as TransferType);
                trans.push(data);
            });
            setState({
                ...state,
                loading:false,
                transfers: trans
            })
            return trans;
        })
        .catch((error:any)=>{
            setState({
                ...state,
                loading:false,
                error:true,
                errorMessage:error.message
            })
            return [];
        })
    }

    async function UpdateTransfer(transferId:string,isInternal:'yes'|'no'){
        setState({...state,loadingUpdate:true});
        return handleTransfer({transferId,isInternal})
        .then((res:any)=>{
            setState({...state,loadingUpdate:false});
            if(res.data.success){
                GetTransfers();
            }
        })
        .catch((error)=>{
            console.log('error: ', error);
            setState({
                ...state,
                loading:false,
                error:true,
                errorMessage:error.message
            })
        })
    }
 
    useEffect(()=>{
        GetTransfers();
    },[]);

    return {...state,GetTransfers,UpdateTransfer};
}