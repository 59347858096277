import React from 'react';
import {StyleSheet,View,} from 'react-native';
import {Text, useTheme} from 'react-native-paper';

export default function Error403Screen(){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    return(
        <View>
            <Text>ERROR 403</Text>
            <Text>No unauthorized access.</Text>
        </View>
    )
}

const Styles = (colors:any)=>{
return (
StyleSheet.create({})
);
}