import React from 'react';
import dayjs from 'dayjs';
import { Firestore } from '../constants/Firebase';
import { AppContext } from '../context';
import { currencyFormatter } from '../constants/utils';
import { useDebouncedCallback } from 'use-debounce';

const {useEffect,useState}=React;
 
const InitialState = {
    currentSpending:0.00,
    error:false,
    errorMessage:'',
    loading:false,
    summary:{
        expense:[{label:'',value:0}],
        eValues:[0],
        income:[{label:'',value:0}],
        iValues:[0],
        spendDiff:'',
        totalExpense:0,
        totalIncome:0,
        values:[0]
    },
}
export const useFundsSummary = (year:string,uid=null)=>{
    const [state,setState] = useState(InitialState);
    const { state: AppState } = React.useContext(AppContext);
    const UID = uid??AppState.user.id;
    const SummaryRef = 'Users/'+UID+'/CashSummary';//Firestore.collection('Users').doc(UID).collection('CashSummary');

    const debounced = useDebouncedCallback((val,func)=>{func(val)},3000);

    const GetSummary= React.useCallback(()=>{
        const collection = Firestore.collection(Firestore.getFirestore(), SummaryRef);
        const query = Firestore.query( collection, Firestore.where('year','==',year) );
        // return Firestore.getDocs(query)
        //     .then((snapshot) => {
        //         if(AppState.user.getTrans) return;
        //         setState({...state,loading:true});
        //         if( snapshot.empty ){ setState({...state,loading:false}); return; }
    
        //         const prev = dayjs().subtract(1,'month').format('YYYYMM');
        //         const cur = dayjs().format('YYYYMM');
        //         let curMonth = {label:dayjs(cur.toString(),"YYYYMM").format('MMM'),value:0.00};
        //         let prevMonth = {label:dayjs(prev.toString(),"YYYYMM").format('MMM'),value:0.00};
        //         let currentSpending = 0.00;
        //         const summary:any = {expense:[],income:[],spendDiff:'',totalExpense:0,totalIncome:0,values:[],iValues:[],eValues:[]};
        //         snapshot.forEach((doc)=>{
        //             const data = doc.data();
        //             data.label = dayjs(doc.id.toString(),"YYYYMM").format('MMM');
        //             const expense = {...data,value: Number(Math.abs(data.expense??0).toFixed(2))};
    
        //             //@ts-ignore
        //             if(prev == doc.id) prevMonth = expense;
        //             if(cur == doc.id) {
        //                 //@ts-ignore
        //                 curMonth = expense;
        //                 currentSpending = expense.value??0;
        //             }
                    
        //             summary.expense.push(expense);
        //             summary.values.push(Number(Math.abs(data.expense??0).toFixed(2)));
        //             summary.eValues.push(Number(Math.abs(data.expense??0).toFixed(2)));
        //             summary.totalExpense += data.expense??0;
        //             summary.income.push({
        //                 ...data,
        //                 value: Number(Math.abs(data.income??0).toFixed(2))
        //             });
        //             summary.values.push(Number(Math.abs(data.income??0).toFixed(2)));
        //             summary.iValues.push(Number(Math.abs(data.income??0).toFixed(2)));
        //             summary.totalIncome += data.income??0;
        //         });
        //         //curMonth = summary.expense[ summary.expense.length-1 ];
        //         //prevMonth = summary.expense.length > 1?summary.expense[ summary.expense.length-2 ] : false;
        //         if(prevMonth && prevMonth.value > curMonth.value) {
        //             summary.spendDiff = currencyFormatter(prevMonth.value-curMonth.value) + ' less than '+prevMonth.label;
        //         }
        //         else if(prevMonth && prevMonth.value < curMonth.value) {
        //             summary.spendDiff = currencyFormatter(curMonth.value-prevMonth.value) + ' more than '+prevMonth.label;
        //         }
        //         else if(prevMonth && prevMonth.value == curMonth.value) {
        //             summary.spendDiff = ' same as '+prevMonth.label;
        //         }
        //         setState({...state,loading:false,summary,currentSpending});
        //     })
        //     .catch((error)=>{
        //         console.log('error: ', error);
        //         setState({
        //             ...state,
        //             loading:false,
        //             error:true,
        //             errorMessage:error.message
        //         });
        //     })
        return Firestore.onSnapshot(query,(snapshot) => {
            if(AppState.user.getTrans) return;
            setState({...state,loading:true});
            if( snapshot.empty ){ setState({...state,loading:false}); return; }

            const prev = dayjs().subtract(1,'month').format('YYYYMM');
            const cur = dayjs().format('YYYYMM');
            let curMonth = {label:dayjs(cur.toString(),"YYYYMM").format('MMM'),value:0.00};
            let prevMonth = {label:dayjs(prev.toString(),"YYYYMM").format('MMM'),value:0.00};
            let currentSpending = 0.00;
            const summary:any = {expense:[],income:[],spendDiff:'',totalExpense:0,totalIncome:0,values:[],iValues:[],eValues:[]};
            snapshot.forEach((doc)=>{
                const data = doc.data();
                data.label = dayjs(doc.id.toString(),"YYYYMM").format('MMM');
                const expense = {...data,value: Number(Math.abs(data.expense??0).toFixed(2))};

                //@ts-ignore
                if(prev == doc.id) prevMonth = expense;
                if(cur == doc.id) {
                    //@ts-ignore
                    curMonth = expense;
                    currentSpending = expense.value??0;
                }
                
                summary.expense.push(expense);
                summary.values.push(Number(Math.abs(data.expense??0).toFixed(2)));
                summary.eValues.push(Number(Math.abs(data.expense??0).toFixed(2)));
                summary.totalExpense += data.expense??0;
                summary.income.push({
                    ...data,
                    value: Number(Math.abs(data.income??0).toFixed(2))
                });
                summary.values.push(Number(Math.abs(data.income??0).toFixed(2)));
                summary.iValues.push(Number(Math.abs(data.income??0).toFixed(2)));
                summary.totalIncome += data.income??0;
            });
            //curMonth = summary.expense[ summary.expense.length-1 ];
            //prevMonth = summary.expense.length > 1?summary.expense[ summary.expense.length-2 ] : false;
            if(prevMonth && prevMonth.value > curMonth.value) {
                summary.spendDiff = currencyFormatter(prevMonth.value-curMonth.value) + ' less than '+prevMonth.label;
            }
            else if(prevMonth && prevMonth.value < curMonth.value) {
                summary.spendDiff = currencyFormatter(curMonth.value-prevMonth.value) + ' more than '+prevMonth.label;
            }
            else if(prevMonth && prevMonth.value == curMonth.value) {
                summary.spendDiff = ' same as '+prevMonth.label;
            }
            debounced({...state,loading:false,summary,currentSpending},setState);
        },
        (error)=>{
            console.log('error: ', error);
            setState({
                ...state,
                loading:false,
                error:true,
                errorMessage:error.message
            });
        });
    },[])
 
    useEffect(()=>{
        const unsubscribe = GetSummary();
        return ()=>{
            //unsubscribe();
        }
    },
    []);

    return {...state};
}