import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'react-native-paper';
import Header from './Header';

export default function WebDashboard({children}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);

    return(
        <View style={styles.container}>
            <Header />
            <section className="page-content">
                {children}
            </section>
        </View>
    );
}

const Styles = (colors:any)=>{
    return {
        container:{
            flex:1,
            padding:20,
        },
    }
}