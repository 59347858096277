import React from 'react';
import {Dimensions, ScrollView, StyleSheet,View,} from 'react-native';
import {Divider, IconButton, List, Text, useTheme} from 'react-native-paper';
import { LoadingModal } from '../components/LoadingModal';
import { currencyFormatter } from '../constants/utils';
import { BudgetType,useBudgets } from '../hooks/useBudgets';

interface propsType {
    route: any,
    navigation: any
}

export default function ViewBudgetScreen(props:propsType){ 
    const {route,navigation} = props;
    const { budget } = route.params;
    const { amount, balance, id, name, repeats, startWithPrevMonth } = budget;
    const {colors} = useTheme();
    const progress = ((budget.balance/budget.amount)*100)/100;
    const color = progress > 0.8? colors.error : colors.primary;
    const styles = Styles(colors);
    const {exists,loading,DeleteBudget,DoesBudgetExists} = useBudgets({});

    React.useEffect(()=>{DoesBudgetExists(id)},[id]);

    React.useLayoutEffect(() => {
        navigation.setOptions({
          headerRight: () => (
            <View style={{alignItems:'center',borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
                <IconButton 
                    color={colors.primary} 
                    icon={'delete'} 
                    onPress={()=>{
                        DeleteBudget(id)
                        .then(()=>{
                            navigation.goBack();
                        })
                        .catch((error:Error)=>{
                            alert(error.message);
                        })
                    }} 
                    size={18} 
                />
            </View>
          ),
          title: (exists=='no'?'(Deleted) ':'')+name,
        });
    }, [exists,navigation]);

    return(
        <View style={styles.container}>
            <LoadingModal visible={!exists || loading} />
            {exists=='no'&&<Text style={{color:colors.error,textAlign:'center'}}>DELETED</Text>}
            <ScrollView>
                <List.Item 
                    description={'Name'}
                    left={props => <List.Icon {...props} icon="shape" />}
                    title={name}
                />
                <Divider />
                <List.Item 
                    description={repeats}
                    left={props => <List.Icon {...props} icon="sync" />}
                    title={'Repeats'}
                />
                <Divider />
                <List.Item 
                    description={
                        (startWithPrevMonth?"Starts":"Doesn't start") +
                        " each new month with the previous month's remainder"
                    }
                    descriptionStyle={{fontSize:12}}
                    left={props => <List.Icon {...props} icon="check" />}
                    title={(startWithPrevMonth?"Uses":"Doesn't Use") +' Previous Remainder'}
                />
                <Divider />
                <List.Item 
                    description={currencyFormatter(amount)}
                    left={props => <List.Icon {...props} icon="currency-usd" />}
                    title={'Amount'}
                />
                <Divider />
                <List.Item 
                    description={currencyFormatter(balance)}
                    descriptionStyle={{color:color}}
                    left={props => <List.Icon {...props} icon="currency-usd" />}
                    title={'Balance'}
                />
                <Divider />
            </ScrollView>
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                //alignItems: 'center',
                borderColor: 'black',
                //borderBottomWidth: 1,
                height: Height/1.5,
                //justifyContent:'space-evenly',
                //marginBottom: 20,
                //width: Width
            },
            currencyInput:{
                fontSize: Height*0.2,
                height: '100%',
            },
            errorText: {
                color: colors.error,
            },
            textInput: {
                fontSize: 17,
                height: 50,
                marginBottom: 20,
                marginTop: 20,
                //width: '75%'
            },
        })
    );
}