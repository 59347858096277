import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { Button, Divider, IconButton, List, useTheme } from 'react-native-paper';
import { currencyFormatter, displayDate, isBillPassedDue, sortAccounts, timeFromNow } from '../constants/utils';
import Strings from '../constants/Strings';
import AccountLogo from './AccountLogo';
import { AccountProps, AccountsProps } from '../constants/Types';

const Width = Dimensions.get('window').width;

export function HomeAccountsList({ homeAccounts, navigation }: any) {
  const { colors } = useTheme();
  const styles = Styles(colors);
  const [expandState, setExpandState] = React.useState<any>({ status: 'Expand', cash: false, credit: false, loans: false, investment: false,properties:false });
  const [showAllAccs, setShowAllAccs] = React.useState<any>({ cash: false, credit: false, loans: false, investment: false,properties:false });

  const accountLabel = (label: string) => {
    switch (label) {
      case 'Credit': return 'Credit Cards';
      case 'Investment': return 'Investments';
      case 'Properties': return 'Real Estate';
      default: return label;
    }
  };

  const handleExpand = (type: string) => {
    switch (type) {
      case 'cash': {
        setExpandState({ ...expandState, cash: !expandState.cash });
        break;
      }
      case 'credit': {
        setExpandState({ ...expandState, credit: !expandState.credit });
        break;
      }
      case 'loans': {
        setExpandState({ ...expandState, loans: !expandState.loans });
        break;
      }
      case 'investment': {
        setExpandState({ ...expandState, investment: !expandState.investment });
        break;
      }
      case 'real estate':
      case 'properties':{
        setExpandState({ ...expandState, properties: !expandState.properties });
        break;
      }
      default: {
        if ('Expand' == expandState.status) {
          setExpandState({ status: 'Collapse', cash: true, credit: true, loans: true, investment: true , properties:true});
          setShowAllAccs({ cash: false, credit: false, loans: false, investment: false,properties:false });
        }
        else {
          setExpandState({ status: 'Expand', cash: false, credit: false, loans: false, investment: false,properties:false });
          setShowAllAccs({ cash: false, credit: false, loans: false, investment: false,properties:false });
        }
        break;
      }
    }
  };

  const shouldShowAllAccounts = (index: number, accountType: string, totalAccounts: number) => {
    if (showAllAccs[accountType]) {
      return true;
    }
    if (totalAccounts <= Strings.TOTAL_ACCOUNTS_TO_SHOW) {
      setShowAllAccs({
        ...showAllAccs,
        [accountType]: true
      });
      return true;
    }
    if (index < Strings.TOTAL_ACCOUNTS_TO_SHOW) {
      return true;
    }
    return false;
  };

  
  return (
    <>
      <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button
          mode="text"
          onPress={() => handleExpand('status')}
          icon={expandState.status == 'Expand' ? "chevron-down" : "chevron-up"}
          contentStyle={{ flexDirection: 'row-reverse' }}
          compact={true}
          uppercase={false}
          style={{ width: '40%' }}
        >
          {expandState.status}
        </Button>
      </View>
      <View style={styles.buttonsContainer}>
        {homeAccounts.map((item: AccountsProps) => {
          return (
            <View key={item.key} style={{ backgroundColor: colors.background, borderColor: colors.borderColor, borderRadius: 15, borderWidth: 1, marginBottom: 20 }}>
              <List.Accordion
                style={{ backgroundColor: colors.background }}
                title={accountLabel(item.title ?? '-')}
                titleStyle={{ color: colors.primary, fontSize: 18, fontWeight: 'bold' }}
                right={(props) => <Text {...props} style={{textAlign:'right'}}>{currencyFormatter(Number(item.total))}</Text>}
                expanded={expandState[item.title?.toLowerCase() ?? item.key]}
                onPress={() => handleExpand(item.title?.toLowerCase() ?? item.key)}
              >
                <Divider />
                <>
                  {item.accounts?.sort(sortAccounts).map((account: AccountProps, index: number) => {
                    if (Number(account.total) !== 0) {
                      if (shouldShowAllAccounts(index, item.title?.toLowerCase() ?? item.key, item.accounts?.length ?? 0)) {
                        return (
                          //@ts-ignore
                          <List.Item
                            key={account.id}
                            title={account.type == 'properties'?account.name:account.bankName}
                            description={account.type == 'properties'?account.bankName:(account.name ?? account.title ?? account.name ?? item.key)}
                            titleStyle={{ color: colors.onSurface }}
                            left={(props) => <AccountLogo
                              compProps={props}
                              logo={account.logo}
                              name={account.type == 'properties'?account.name:account.bankName}
                              refresh={account.refresh ?? false} />}
                            right={(props) => <View>
                              <Text {...props} style={{ textAlign:'right', color: colors.onSurface }}>
                                {currencyFormatter(Number(account.total))}
                              </Text>
                              <Text style={{
                                alignSelf: 'flex-end',
                                //@ts-ignore
                                color: (account.refresh || isBillPassedDue(account?.next_payment_due_date ?? Date.now)) ? colors.error : colors.primary,
                                marginTop: 10,
                                opacity: 0.7
                              }}>
                                {account.type != 'properties'?displayDate(account):''}
                              </Text>
                            </View>}
                            onPress={() => {
                              if(account.type == 'properties') {
                                navigation.navigate('Add Properties', { property:account });
                                return;
                              }

                              if (account.refresh) {
                                navigation.navigate('plaidrefreshbank', { account });
                                return;
                              }

                              //@ts-ignore
                              else if (isBillPassedDue(account?.next_payment_due_date ?? Date.now)) {
                                navigation.navigate('Payment Optimizer');
                                return;
                              }
                              
                              else navigation.navigate('Account Transactions', { account });
                            }}
                            onLongPress={() => { 
                              if(account.type == 'properties') return;
                              navigation.navigate('plaidrefreshbank', { account }); 
                            }} 
                          />
                        );
                      }
                    }
                  })}
                  <Divider />
                  {item.accounts.length != 0 && !showAllAccs[item.title.toLowerCase()] &&
                    <Text
                      onPress={() => {
                        setShowAllAccs({
                          ...showAllAccs,
                          [item.title.toLowerCase()]: true
                        });
                      }}
                      style={{
                        padding: 20,
                        textAlign: 'center'
                      }}
                    >
                      Show All Accounts
                    </Text>}

                  {
                    item.key == 'properties'?
                      /*@ts-ignore*/
                      <List.Item left={(props) => <IconButton icon={'plus'} />} onPress={() => navigation.navigate('Add Properties')} title={'Add Real Estate'} />
                    :
                      <>
                        {/*@ts-ignore*/}
                        <List.Item left={(props) => <IconButton icon={'plus'} />} onPress={() => navigation.navigate('Plaid', { type: item.title, accounts: homeAccounts })} title={'Add another account'} />

                        {/*@ts-ignore*/}
                        <List.Item left={(props) => <IconButton icon={'plus'} />} onPress={() => navigation.navigate('Add Account', { type: item.title })} title={'Manually add account'} />
                      </>
        }
                </>
              </List.Accordion>
            </View>
          );
        })}
      </View>
    </>
  );
}

const Styles = (colors:any)=>{
  const Maxwidth = 1000;
  const windowHeight = Dimensions.get('window').height;
  return StyleSheet.create({
    container: {
      backgroundColor: colors.background,
      flex: 1,
      //height: '100%',
    },
    button: {
      borderColor: 'black',
      borderWidth: 1,
      height: 50,
      margin: '5%',
      width: '40%',
    },
    buttonsContainer: {
      alignSelf: 'center',
      maxWidth: Maxwidth,
      padding: 20,
      width: '100%',
    },
    fundsSummary: {
      backgroundColor: colors.headerBackground,
      height: windowHeight*0.52,
      paddingTop: 20,
      padding: 20,
      width:Width
    },
    labelText: {
      color: colors.accent,
      fontSize: Width>500?16:12,
    },
    spendDiff: {
      color: colors.accent,
      fontSize: Width>500?18:12,
      fontWeight:'bold',
      //marginBottom: 10,
      //marginRight: 20
    },
    monthlyContainer: {
      bottom: 10,
      flexDirection: 'row',
    },
    totalBalance: {
      color: colors.accent,
      fontSize: Width>500?30:18,
      fontWeight: 'bold',
    },
    totalContainer: {
      justifyContent: 'center',
    },
    totalMonthlyBalance: {
      color: colors.accent,
      fontSize: 18,
      fontWeight: 'bold',
    },
    transactionsContainer: {
      alignSelf: 'center',
      marginTop: -20,
      maxWidth: Maxwidth,
      width: '90%',
    },
  });
} 