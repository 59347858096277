import React from 'react';
import {Dimensions,StyleSheet,View,} from 'react-native';
import {useTheme} from 'react-native-paper';
//@ts-ignore
import { totalCreditUtilizationType } from '../constants/Types';
import { VictoryLabel, VictoryPie } from 'victory-native';
import Svg from 'react-native-svg';

const WIDTH = Number(Dimensions.get('window').width.toFixed(0));

type props = {
    getProgressColour:(p:number)=>string,
    totalCreditUtilization?:totalCreditUtilizationType
}
export default function CreditUtilizationGraph({getProgressColour,totalCreditUtilization}:props){ 
    const {colors} = useTheme();
    //const styles = Styles(colors);

    const rate = totalCreditUtilization?.rate??0;
    let remainder = 100-rate;
    remainder = remainder > 0?remainder : 0;
    const color = rate>0?getProgressColour(101-(rate)):getProgressColour(100);
    let pieWidth = (240);
    let pieHeight = (240);
    let pieRadius = 50;

    let svgWidth   = 250;
    let svgHeight  = 200;
    let svgViewBox = "0 0 "+svgWidth+" "+svgHeight;

    let rateFontSize  = 25;
    let labelFontSize = 15;
    let marginRight   = 0;

    if(WIDTH>1200){
        pieWidth  = 300;
        pieHeight = 300;
        pieRadius = 75;
        svgHeight = 300;
        svgViewBox = "0 0 "+svgWidth+" "+svgHeight;
        rateFontSize  = 35;
        labelFontSize = 25;
    }
    else if(WIDTH>1000){
        pieWidth  = 325;
        pieHeight = 325;
        pieRadius = 85;
        svgWidth  = 290;
        svgHeight = WIDTH>=1100?400:300;
        svgViewBox = "0 0 "+svgWidth+" "+svgHeight;
        rateFontSize  = 40;
        labelFontSize = 25;
        marginRight = WIDTH>=1100?50:0;
    }
    else if(WIDTH>500){
        pieWidth  = (300);
        pieHeight = (300);
        pieRadius = 75;
        svgHeight = pieHeight;
        svgViewBox = "0 0 "+svgWidth+" "+svgHeight;
        rateFontSize  = 30;
        labelFontSize = 22;
        marginRight   = -30;
    }
    
    
    if(!rate) return <View/>

    return(
        <Svg 
                width={svgWidth}
                height={svgHeight}
                viewBox={svgViewBox}
                style={{ width: '100%', height: 'auto'}}
            >
            <VictoryPie
                data={[
                    {y:rate,x:0,label:' '},
                    {y:remainder,x:0,label:' '}
                ]}
                colorScale={[color,colors.background]}
                width={pieWidth}
                height={pieHeight}
                innerRadius={pieRadius}
                style={{
                    data: {stroke: colors.border, strokeWidth: 2},  
                }}
            /> 
            <VictoryLabel
                textAnchor="middle"
                verticalAnchor="middle"
                style={[
                    {fill:colors.primary,fontSize: rateFontSize,fontWeight:'bold'},
                    {fill:colors.primary,fontSize: labelFontSize},
                    {fill:colors.primary,fontSize: labelFontSize},
                    {fill:colors.primary,fontSize: labelFontSize},
                ]}
                x={(pieWidth/2)} 
                y={(pieHeight/2)+13}
                text={[
                    ('  '+(rate?rate+'%':'')),
                    "credit", 
                    "utilization",
                    "rate"
                ]}
            />
            </Svg>
    );
}

const Styles = (colors:any)=>{
return (
StyleSheet.create({})
);
}