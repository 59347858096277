import React from 'react';
import { FlatList, StyleSheet, View, } from 'react-native';
import { Card, Text, useTheme } from 'react-native-paper';
import ListEmpty from '../components/ListEmpty';
import { Loading } from '../components/LoadingModal';
import TransactionListItem from '../components/TransactionListItem';
import Strings from '../constants/Strings';
import { currencyFormatter, timeFromNow } from '../constants/utils';
import { useAccounts } from '../hooks/useAccounts';
import { useTransactions } from '../hooks/useTransactions';

export default function AccountTransactionsScreen({navigation,route}:any) {
    const { uid, id } = route.params;
    const { colors } = useTheme();
    const styles = Styles(colors);
    const {account,GetSingleAccount} = useAccounts(false);
    const { loading,selection,transactions, LoadMoreTransactions } = useTransactions({account:true,limit:Strings.LIST_LIMITS,selection:id,uid});

    React.useEffect(()=>{
        GetSingleAccount(id,uid);
    },[]);

    const renderHeader = ()=>{
        return(
          <>
          
          </>
        )
      }
    
    return (
        <View style={styles.container}>
            <Card style={styles.card}>
                <Card.Title
                    title={account?.title??''}
                    subtitle={'last account activity was '+(account?.updated?timeFromNow(account.updated):'-')}
                    subtitleStyle={{color:colors.primary,fontSize:14,fontWeight:'bold'}}
                    //left={(props) => <Avatar.Icon {...props} icon={icon} style={{backgroundColor:color}} />}
                />
                <Card.Content>
                    <Text>{'Available Balance: '+currencyFormatter(account?.balances?.available??0,false)}</Text>
                    <Text>{'Current Balance: '+currencyFormatter(account?.balances?.current??0,false)}</Text>
                </Card.Content>
            </Card>
            <FlatList 
                keyExtractor={(item)=>item.id}
                data={transactions}
                ListHeaderComponent={renderHeader}
                ListHeaderComponentStyle={{backgroundColor:colors.background,marginBottom:20,zIndex:2}}
                stickyHeaderIndices={[0]}
                renderItem={({item})=><TransactionListItem item={item} navigation={navigation} />}
                showsVerticalScrollIndicator={false}
                //onEndReached={()=>LoadMoreTransactions(transactions[transactions.length-1].date,selection)}
                onEndReachedThreshold ={0.1}
                ListFooterComponent={<Loading size={'small'} visible={loading} />}
                ListEmptyComponent={<ListEmpty type={'transactions'} />}
            />
        </View>
    )
}

const Styles = (colors: any) => {
    return (
        StyleSheet.create({
            container: {
                backgroundColor: colors.background,
                flex: 1,
                height: '50%',
                marginLeft: '10%',
                marginRight: '10%',
                padding: 20,
            },
            card:{
                marginBottom: 20
            }
        })
    );
}