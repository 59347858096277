import React from 'react';
import { Firestore } from '../constants/Firebase';
import dayjs from 'dayjs';
const {useEffect,useState}=React;

const InitialLanding = {
    about1Des:'',
    about2Des:'',
    about3Des:'',
    about1Title:'',
    about2Title:'',
    about3Title:'',
    appstoreUrl: '',
    contactEmail:'',
    downloadSubTitle:'',
    downloadTitle:'',
    facebook: '',
    feat1Des:'',
    feat2Des:'',
    feat3Des:'',
    feat4Des:'',
    feat5Des:'',
    feat6Des:'',
    feat7Des:'',
    featSubtitle:'',
    feat1Title:'',
    feat2Title:'',
    feat3Title:'',
    feat4Title:'',
    feat5Title:'',
    feat6Title:'',
    feat7Title:'',
    instagram:'',
    playstoreUrl: '',
    headingTitle: '',
    headingSubTitle: '',
    twitter:''
}
const InitialState = {loading:false,date:'',doc:'',landing:InitialLanding,error:false,errorMessage:''};
export const useDocs = (type?:string)=>{
    const [state,setState] = useState(InitialState);
    const Ref = Firestore.collection(Firestore.getFirestore(),'docs');

    const Getdoc = async(type:string,edit=false)=>{
        setState({...InitialState,loading:true});
        const docRef = Firestore.doc(Firestore.getFirestore(),'docs',type);
        return Firestore.getDoc(docRef).then((snapshot)=>{
            if(!snapshot.exists){
                throw new Error('Failed to get document');
            }
            let doc = snapshot.data()?.text ?? '';
            if(!edit){
                let updated = snapshot.data()?.updated ?? '';
                updated = dayjs(updated).format('MMMM D, YYYY');
                const date = 'Updated: '+updated+'.';
                doc = doc+'<br/><br/>'+date;
            }
            setState({...InitialState,doc});
        })
        .catch((error)=>{
            setState({...InitialState,error:true,errorMessage:error.message});
        })
    }

    const GetLanding = async()=>{
        setState({...InitialState,loading:true});
        const docRef = Firestore.doc(Firestore.getFirestore(),'docs','landing');
        return Firestore.getDoc(docRef).then((snapshot)=>{
            if(!snapshot.exists){
                throw new Error('Failed to get document');
            }
            const landing = (snapshot.data() as any) ?? InitialLanding;
            setState({...InitialState,landing});
        })
        .catch((error)=>{
            setState({...InitialState,error:true,errorMessage:error.message});
        })
    }

    const UpdateDoc = async(type:string,text:string)=>{
        setState({...state,doc:text,loading:true});
        const docRef = Firestore.doc(Firestore.getFirestore(),'docs',type);
        return Firestore.setDoc(docRef,{
            text,
            updated: new Date().getTime()
        },{merge:true})
        .then(()=>{
            setState({...state,doc:text,loading:false});
            return text;
        });
    }

    const UpdateLanding = async(data:any)=>{
        setState({...state,landing:data,loading:true});
        const docRef = Firestore.doc(Firestore.getFirestore(),'docs','landing');
        return Firestore.setDoc(docRef,{...data},{merge:true})
        .then(()=>{
            setState({...state,landing:data,loading:false});
            return data;
        });
    }
 
    useEffect(()=>{
        if(type)Getdoc(type);
        else GetLanding();
    },[]);
    return {...state,Getdoc,GetLanding,UpdateDoc,UpdateLanding};
}