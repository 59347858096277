import React from 'react';
import { AppContext } from '../context';
import { Firestore } from '../constants/Firebase';
const {useEffect,useState}=React;

export interface BudgetType {
    amount: number,
    balance: number,
    category: string,
    id: string,
    name: string,
    repeats:'Monthly',
    startWithPrevMonth:boolean,
    uid:string,
}

interface InitialBudgetsType {
    budgets: BudgetType[],
    error: boolean,
    errorMessage: string,
    loading: boolean,
}

const InitialBudgetsState = {
    budgets: [],
    error: false,
    errorMessage: '',
    loading: false,
}
 
export const useBudgets = ({get=true,limit=-1})=>{
    const [state,setState] = useState<InitialBudgetsType>(InitialBudgetsState);
    const [exists,setExist] = useState<null | 'yes' | 'no'>(null);
    const {state: AppState} = React.useContext(AppContext);
    const {uid} = AppState.user;
    const BudgetsRef = 'Users/'+uid+'/Budgets';
    const Ref = Firestore.collection(Firestore.getFirestore(),'Budgets');

    async function AddBudget(budget:BudgetType){
        budget.uid = uid;
        budget.name = budget.category;
        return Firestore.addDoc(Ref,budget)
        .then((docRef)=>{
            budget.id = docRef.id;
            return Firestore.setDoc(docRef,budget,{merge:true});
        })
    }

    async function DeleteBudget(id:string){
        setState({...state,loading:true});
        const doc = Firestore.doc(Ref,id);
        return Firestore.deleteDoc(doc)
        .catch(()=>{})
        .then(()=>{setState({...state,loading:false});});
    }

    async function DoesBudgetExists(id:string){
        const docRef = Firestore.doc(Ref,id);
        Firestore.getDoc(docRef)
        .then((snap)=>{
            if(snap.exists()){
                setExist('yes');
                return 'yes';
            }
            else {
                setExist('no');
                return 'no';
            }
        })
        .catch((error)=>{
            setExist('no');
            return 'no';
        });
    }

    async function GetBudgets(limit:number){
        let q;
        if( limit ){
            q = Firestore.query( Ref, Firestore.orderBy('name','asc'), Firestore.where('uid','==',uid ), Firestore.limit(limit));
        }
        else{
            q = Firestore.query( Ref, Firestore.orderBy('name','asc'), Firestore.where('uid','==',uid ));
        }
        
        return Firestore.onSnapshot(q,(snapshot)=>{
            if(AppState.user.getTrans) return;
            if(snapshot.empty) {
                setState({...state,budgets:[]});
                return;
            }

                const budgets: BudgetType[] = [];
                snapshot.forEach((snap)=>{
                    const data = (snap.data() as BudgetType);
                    budgets.push(data);
                });
                setState({
                    ...state,
                    budgets,
                    loading:false,
                });

        },(error)=>{
            setState({
                ...state,
                loading:false,
                error:true,
                errorMessage: error.message
            });
        });
    }

    async function GetBudgetSingle(id:string){

    }

    async function GetMonthlyBudgets(limit:number,yearMonth:string){
        setState({...state,loading:true});
        const doc = Firestore.doc(Firestore.getFirestore(),BudgetsRef,yearMonth);
        
        return Firestore.onSnapshot(doc,(snapshot)=>{
            if(AppState.user.getTrans) return;
            if(!snapshot.exists){
                setState({...state,loading:false,budgets:[]});
                return []; 
            }
            const data = Object.values( (snapshot.data() as BudgetType) );
            const budgets: BudgetType[] = [];
            for(let i=0; i<data.length; i++){
                const budget = data[i];
                budgets.push( budget );
            }
            setState({
                ...state,
                loading:false,
                budgets
            });
        },
        (error)=>{
            setState({
                ...state,
                loading:false,
                error:true,
                errorMessage:error.message
            })
        });
    }

    async function LoadMore(after:string,type:'all'|'monthly'='all'){}
 
    useEffect(()=>{
        if(get){
            GetBudgets(limit);
        }
    },
    []);

    return {...state,exists,AddBudget,DeleteBudget,DoesBudgetExists,GetBudgets,GetBudgetSingle,GetMonthlyBudgets,LoadMore}
}