import React, { createContext, useReducer } from 'react';
import { AccountsProps } from '../constants/Types';

interface Props {
    state: state | any;
    dispatch: any;
}

interface state {
    account: AccountsProps | false
}

interface action {
    payload: any,
    type: 'setAccount'
}

const initialState:state = {
    account: false
}

const reducer = (state:state,action:action) => {
    switch (action.type) {
        case 'setAccount':{
            return {...state,account:action.payload}
        }
    }
}

const AccountContext = createContext<Props>({ state: {}, dispatch: null });

const AccountContextProvider = (props: any) => {
    const [state, dispatch] = useReducer<any>(reducer, initialState);
    return (
      <AccountContext.Provider value={{state,dispatch}}>
        {props.children}
      </AccountContext.Provider>
    );
};

const AccountConsumer = AccountContext.Consumer;

export { AccountContext, AccountContextProvider, AccountConsumer };  