import dayjs from 'dayjs';
import { AccountProps } from './Types';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);


export const formatPhoneNumber = (str:string) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
  
    return str;
};

  export const currencyFormatter = (number:number,convert=true,showDecimal=true) => {
    // const decPlaces = 2;
    // const decSep = ".";
    const sign = Number(number) < 0 ? "-" : "";
    number = convert? Math.abs(number)/100 : Math.abs(number); 

    return sign+'$' + number.toFixed(showDecimal? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    
    // return sign + number.toLocaleString('en-US', {
    //   style: 'currency',
    //   currency: 'USD',
    //   minimumFractionDigits: showDecimal? 2 : 0,
    //   maximumFractionDigits: showDecimal? 2 : 0,
    // });

    // const sign = number < 0 ? "-$" : "$";
    // number = convert? Math.abs(number)/100 : Math.abs(number); 
    // number = Number(number.toFixed(0));
    // // const i:any = parseInt( Math.abs(Number(number) ).toFixed(decPlaces));
    // // var j:any = (j = i.length) > 3 ? j % 3 : 0;
    // const decimal = showDecimal? 2 : 0;
    // return sign +(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // return sign + new Intl.NumberFormat('en',{minimumFractionDigits: 2,}).format(number);
    // return sign +
    //     //(j ? i.substr(0, j) + thouSep : "") +
    //     i.toLocaleString() +
    //     (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

export function abbrNum(number:number) {
  // 2 decimal places => 100, 3 => 1000, etc
  let decPlaces = countDecemals(number);
  decPlaces = Math.pow(10,decPlaces);

  // Enumerate number abbreviations
  var abbrev = [ "k", "m", "b", "t" ];

  let numberString = number+'';

  // Go through the array backwards, so we do the largest first
  for (var i=abbrev.length-1; i>=0; i--) {

      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10,(i+1)*3);

      // If the number is bigger or equal do the abbreviation
      if(size <= number) {
           // Here, we multiply by decPlaces, round, and then divide by decPlaces.
           // This gives us nice rounding to a particular decimal place.
           number = Math.round(number*decPlaces/size)/decPlaces;

           // Add the letter for the abbreviation
           numberString = number + abbrev[i];

           // We are done... stop
           break;
      }
  }

  return numberString;
}

export function countDecemals (value:number) {
  if(Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0; 
}

export const dateFormatter = (str:string|number,type='localeString') => {
  const date = new Date(str);
  switch(type) {
    case 'fullString':{
      let string = dayjs(str).format('dddd, MMMM D, YYYY');
      return string;
    }
    case 'localeString':{
      return dayjs(str).format('ddd, MMM D, YY');
    }
    case 'monthYear':{
      return dayjs(str).format('MMM, YYYY');
    }
    case 'shortString':{
      let string = dayjs(str).format('MMM D, YYYY');
      return string;
    }
    case 'dashFormattedString':{
      let string = dayjs(str).format('YYYY-MM-D');
      return string;
    }
    default: {
      return date.toLocaleDateString();
    }
  }
}

export const getDateCodes = (str='',type:'yearMonth'|'milliseconds') =>{
  const dateObj = str? new Date(str) : new Date();
  let newdate = '';

  switch(type) {
    case 'yearMonth':{
      const month = dateObj.getUTCMonth() + 1; //months from 1-12
      const day = dateObj.getUTCDate();
      const year = dateObj.getUTCFullYear();
      newdate = ''+year + (month<10?'0'+month:month);
      break;
    }
    case 'milliseconds':{
      newdate = (dateObj.getTime()).toString();
    }
  }
    
    return newdate;
}

export const getInitials = (str:string,n=2):string=>{return str?.split(" ").slice(0, n).map( (n:any)=>n[0] ).join("")??str;}

export const timeFromNow = (date:any):string=>{
  // let string = dayjs(date).format('YYYY-MM-DD');
  // console.log('string: ', string);
  //@ts-ignore
  return dayjs(date).fromNow();
}

export function hexToRGB(h:string,array:boolean=false) {
  let r = '0', g = '0', b = '0';

  // 3 digits
  if (h.length == 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  // 6 digits
  } else if (h.length == 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  
  if(array) return {r,g,b};
  return "rgb("+ +r + "," + +g + "," + +b + ")";
}

export function rgb2hsv (r:any, g:any, b:any) {
  let rabs:any, gabs, babs, rr, gg, bb, h:any, s, v:any, diff:any, diffc, percentRoundFn:any;
  rabs = r / 255;
  gabs = g / 255;
  babs = b / 255;
  v = Math.max(rabs, gabs, babs),
  diff = v - Math.min(rabs, gabs, babs);
  diffc = (c:any) => (v - c) / 6 / diff + 1 / 2;
  percentRoundFn = (num:any) => Math.round(num * 100) / 100;
  if (diff == 0) {
      h = s = 0;
  } else {
      s = diff / v;
      rr = diffc(rabs);
      gg = diffc(gabs);
      bb = diffc(babs);

      if (rabs === v) {
          h = bb - gg;
      } else if (gabs === v) {
          h = (1 / 3) + rr - bb;
      } else if (babs === v) {
          h = (2 / 3) + gg - rr;
      }
      if (h < 0) {
          h += 1;
      }else if (h > 1) {
          h -= 1;
      }
  }
  return {
      h: Math.round(h * 360),
      s: percentRoundFn(s * 100),
      v: percentRoundFn(v * 100)
  };
}

export const sortAccounts = (a:any, b:any)=>{
  const res = flipOrder(a.type?.toLowerCase()??null);
  if( !res ){
    if ( Number(a.total) < Number(b.total) ){
      return 1;
    }
    if ( Number(a.total) > Number(b.total) ){
      return -1;
    }
    return 0;
  }
  else {
    if ( Number(a.total) > Number(b.total) ){
      return 1;
    }
    if ( Number(a.total) < Number(b.total) ){
      return -1;
    }
    return 0;
  }
}

const flipOrder = (accountType:string)=>{
  switch(accountType){
    case 'credit':
    case 'loans':  
      return true;
    default:
      return false;
  }
}

// The NPER function is a financial function that returns the number of periods for loan or investment
// rate - The interest rate per period.
// payment - The payment made each period.
// present - The present value, or total value of all payments now.
// future - [optional] The future value, or a cash balance you want after the last payment is made. Defaults to 0.
// type - [optional] When payments are due. 0 = end of period. 1 = beginning of period. Default is 0.
export function NPER(rate:number, payment:number, present:number, future?:number, type?:number) {
  if(!present) return 0;
  // Initialize type
  type = (typeof type === 'undefined') ? 0 : type;

  // Initialize future value
  future = (typeof future === 'undefined') ? 0 : future;

  // Return number of periods
  const num = payment * (1 + rate * type) - future * rate;
  const den = (present * rate + payment * (1 + rate * type));
  const results = Math.log(num / den) / Math.log(1 + rate);
  return isNaN(results)? 0 : results;
}

export function calculatePaymentPeriod(pay:number,yearOnly=false):string{
  if(pay == 1) return pay+' month';
  if(pay < 12) return pay+' months';
  if(pay == 12) return '1 year';
  const yrs = Math.floor(pay/12);
  let result = '';
  if(yrs < 2) result = '1 year';
  else result = yrs+' years';
  if(yearOnly && yrs>0) return result;
  let remainder = pay%12;
  remainder = Math.round(remainder);
  if( remainder < 1 ) return result;
  result = result+' & '+remainder+(remainder<2?' month':' months');
  return result;
}

export function isBillPassedDue(dateDue:string){
  const diff = dayjs(dateDue).diff(dayjs(),'day');
  return (diff <= 0);
}

export function displayDate(account: AccountProps): string {
  if (account.refresh) return 'verify';
  if (account.manuallyAdded) {
    if (account.next_payment_due_date && isBillPassedDue(account.next_payment_due_date)) {
      return 'update required';
    }
    else {
      return 'manually entered';
    }
  }
  return timeFromNow(account.updated);
};

export const isLiability = (type:string):boolean => {return (type=='loans' || type=='credit')}