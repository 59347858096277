import React, { useState } from 'react';
import { Dimensions, FlatList, Platform, ScrollView, StyleSheet,View,} from 'react-native';
import {Button, Dialog, Divider, IconButton, List, Paragraph, Portal, Text, useTheme} from 'react-native-paper';
import RBSheet from "react-native-raw-bottom-sheet"
import DateTimePicker from '@react-native-community/datetimepicker';
import { TransactionProps, useTransactions } from '../hooks/useTransactions';
import { currencyFormatter, dateFormatter } from '../constants/utils';
import { useBills } from '../hooks/useBills';
import { LoadingModal } from '../components/LoadingModal';
import SelectBillModal from '../components/SelectBillModal';
import CategoriesPicker from '../components/CategoriesPicker';
import { bankType, useBanks } from '../hooks/useBanks';
import ListEmpty from '../components/ListEmpty';

export default function BanksScreen({navigation,route}:any){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {banks,DeleteBank,loading} = useBanks();
    const [visible, setVisible] = React.useState(false);
    const [seletedBank,setSelectedBank] = useState<bankType>();
    const hideDialog = () => setVisible(false);

    React.useLayoutEffect(() => {
        navigation.setOptions({
          headerRight: () => (
            <View style={{alignItems:'center',backgroundColor:colors.primary,borderRadius:24/2,height:24,justifyContent:'center',marginRight:20,width:24,}}>
              <IconButton color={colors.accent} icon={'plus'} onPress={()=>{navigation.navigate('Plaid',{type:''});/*RBRef.current?.open()*/}} size={18} />
            </View>
          ),
        });
      }, [navigation]);


    const deleteBank = async (bank:bankType)=>{
        setVisible(true);
        setSelectedBank(bank);
    }

    function BankList({bank}:any) {
        return(
            <>
                <List.Item 
                    title={bank.name}
                    description={'Merchant'}
                    left={props => <List.Icon {...props} icon="bank-outline" />}
                    onPress={()=>{deleteBank(bank)}} 
                    right={props => <List.Icon {...props} icon="delete-outline" />}
                />
                <Divider />
            </>
        );
    }
    

    return(
        <View style={styles.container}>
            <ScrollView contentContainerStyle={{flexGrow:1}}>
                <View>
                <FlatList 
                    data={Object.values(banks)}
                    keyExtractor={(item)=>item.id}
                    renderItem={({item})=><BankList bank={item} />}
                    showsVerticalScrollIndicator={false}
                    ListEmptyComponent={<ListEmpty type={'bank'} />}
                />
                </View>    
            </ScrollView>
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog}>
                <Dialog.Title>Deleting Bank</Dialog.Title>
                <Dialog.Content>
                    <Paragraph>{"Are you sure you want to remove "+ (seletedBank?.name??'this bank') +" and all of it's related data?"}</Paragraph>
                </Dialog.Content>
                    <Dialog.Actions>
                    <Button onPress={() => hideDialog()}>Cancel</Button>
                    <Button onPress={() => DeleteBank(seletedBank).then(()=>{hideDialog()})}>Ok</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
            <LoadingModal visible={loading} />
        </View>
    )
}

const Styles = (colors:any)=>{
    const Height = Dimensions.get('window').height*0.3;
    const Width = Dimensions.get('window').width*0.9;
    
    return (
        StyleSheet.create({
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                //alignItems: 'center',
                borderColor: 'black',
                //borderBottomWidth: 1,
                height: Height/1.5,
                //justifyContent:'space-evenly',
                //marginBottom: 20,
                //width: Width
            },
            currencyInput:{
                fontSize: Height*0.2,
                height: '100%',
            },
            errorText: {
                color: colors.error,
            },
            textInput: {
                fontSize: 17,
                height: 50,
                marginBottom: 20,
                marginTop: 20,
                //width: '75%'
            },
        })
    );
}