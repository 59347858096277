import React from 'react';
import {FlatList, StyleSheet,useWindowDimensions,Text,View, Alert, Dimensions,} from 'react-native';
import {Button, Card, Divider, List, Title, useTheme} from 'react-native-paper';
import ListEmpty from '../components/ListEmpty';
import { LoadingModal } from '../components/LoadingModal';
import { currencyFormatter, displayDate, isBillPassedDue, sortAccounts, timeFromNow } from '../constants/utils';
import { AccountProps } from '../constants/Types';
import { useAccounts } from '../hooks/useAccounts'
import { AccountContext } from '../context/AccountContext';

const Width = Dimensions.get('window').width;;

export default function ViewAccountScreen({navigation,route}:any){ 
    const {state: accountState} = React.useContext(AccountContext);
    const account:any = accountState.account;
    const {colors} = useTheme();
    const {height} = useWindowDimensions();
    const styles = Styles(colors,height);
    const { error,errorMessage,loading, DeleteAccount } = useAccounts(false);

    React.useLayoutEffect(() => {
        navigation.setOptions({ headerTitle: account.name, });
    }, [navigation]);

    const accountsToArray = (accounts:any)=>{
        const accArray: AccountProps[]  = Object.values(accounts);
        return [...accArray.sort(sortAccounts)];
    }

    const RenderList = ({item}:{item:AccountProps})=>{
        return(
            //@ts-ignore
        <List.Item
            key={item.id} 
            description={item.bankName}
            title={item.title} 
            titleStyle={{color:colors.onSurface}} 
            right={
                (props)=>
                    <View>
                        <Text {...props} style={{alignSelf: 'flex-end',color:colors.onSurface}}>
                        {currencyFormatter(Number(item.total))}
                        </Text>
                        {
                            item.type !== 'properties' && 
                            <Text style={{
                                alignSelf: 'flex-end',
                                color: (account.refresh || isBillPassedDue(item?.next_payment_due_date ?? (Date.now).toString())) ? colors.error : colors.primary,
                                marginTop:10,
                                opacity:0.7
                            }}>
                                {displayDate(item)}
                            </Text>
                        }
                    </View>
            }
            onPress={()=>{
                if(item.type == 'properties') navigation.navigate('Add Properties',{property:item})
                else navigation.navigate('View Account Data',{account:item})
            }}
        />
        );
    }

    return(
        <View style={styles.container}>
            <LoadingModal visible={loading} />
            {/* <Card style={styles.contentContainer}>
                <Card.Title 
                    title={title} 
                    titleStyle={styles.cardTitle} 
                />
                <Card.Content>
                    <Text style={{alignSelf:'center'}}>PLACEHOLDER TEXT</Text>
                </Card.Content>
            </Card> */}
            <FlatList 
                data={accountsToArray(account.accounts)}
                ItemSeparatorComponent={()=><Divider />}
                ListEmptyComponent={()=><ListEmpty type={'account'} />}
                ListHeaderComponent={()=><Title>Connected Accounts:</Title>}
                showsVerticalScrollIndicator={false}
                renderItem={RenderList}
                style={{height:'75%'}}
            />

            <Text style={styles.errorText}>{errorMessage}</Text>
            <View style={{flex:1,flexDirection:'row',justifyContent:'center'}}>
                {account.refresh &&
                    <Button
                        color={colors.primary}
                        mode='contained'
                        onPress={()=>{navigation.navigate('plaidrefreshbank',{account:account});}}
                        style={styles.button}
                    >
                        Refresh Account
                    </Button>
                }
            
                <Button
                    color={colors.error}
                    onPress={()=>{
                        Alert.alert("Delete This Account","Are you sure you want to delete "+account.name+"?",
                        [
                            {text: 'Cancel',style:'cancel'},
                            {
                                text:'delete',
                                onPress: ()=>{
                                    DeleteAccount(account)
                                    .then((res)=>{if(res)navigation.goBack()})
                                }
                            }
                        ]);
                    }}
                    style={styles.button}
                    uppercase={false}
                >
                    Delete Account
                </Button>
            </View>
        </View>
    )
}

const Styles = (colors:any,height:number)=>{
    const Height = height*0.3;
    return (
        StyleSheet.create({
            button: {
                borderRadius: 10,
                height: Width>500?75:45,
                justifyContent: 'space-around',
                marginTop: '4%',
            },
            cardTitle:{
                fontSize: 18,
                textAlign:'center'
            },
            container:{
                backgroundColor: colors.background,
                flex: 1,
                height: '100%',
                padding: 20,
            },
            contentContainer:{
                borderColor: '#F8F0E3',
                borderWidth: 1,
                minHeight: Height,
                marginBottom: 20,
                padding:5
            },
            errorText:{
                color:colors.error,
                textAlign:'center',
            },
        })
    );
}