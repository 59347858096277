import { Formik } from 'formik';
import React from 'react';
import {Dimensions, Image,StyleSheet,Text,TouchableOpacity,View,} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {Button,TextInput,useTheme} from 'react-native-paper';
import {GoogleSigninButton} from '@react-native-google-signin/google-signin';
import { signUpWithoutPhoneSchema } from '../../constants/Schemas';
import { useAuthentication } from '../../hooks/useAuthentication';
import { AppContext } from '../../context';
import ProfileScreen from '../../screens/ProfileScreen';
import Strings from '../../constants/Strings';
import * as AppleAuthentication from 'expo-apple-authentication';

const Width = Dimensions.get('window').width;

interface props {
    navigation: any
    next: ()=>void
    prev: ()=>void
}

const InitialFormValues = {
    name: '',
    phone: '',
    email: '',
    password: '',
    error:''
}

export default function SignupComponent(props:props){ 
    const {navigation,next} = props;
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {state} = React.useContext(AppContext);
    const {user} = state;
    const {SignInWithGoogle,SignInWithApple,SignupWithoutPhone} = useAuthentication({});

    const [showPass,setShowPass] = React.useState<boolean>(false);
    const emailRef = React.useRef<any>();
    const passwordRef = React.useRef<any>();

    const signUpComponent = ()=>(
        <View style={{paddingLeft: '10%',paddingRight: '10%',marginBottom: '30%',}}>
        <Formik
                initialValues={InitialFormValues}
                validationSchema={signUpWithoutPhoneSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values,actions)=>{
                    SignupWithoutPhone({...values})
                    .then(()=>{
                        next();
                    })
                    .catch((error)=>{
                        console.log('onSubmitErr: ', error);
                        actions.setFieldError('email',error.message);
                    })
                    .finally(()=>{
                        actions.setSubmitting(false);
                    })
                }}
            >
                {({handleChange,handleSubmit,setFieldError,setFieldValue,setSubmitting,isSubmitting,values,errors})=>{
                    return(
                        <View style={{marginTop:'15%'}}>
                            <View>
                                <Image source={Strings.LOGO_COLORED} style={styles.logo} />
                            </View>
                            <View style={styles.welcomeTextContainer}>
                                {/* <Text style={styles.welcomeTextHeader}>Login</Text> */}
                                <Text style={styles.welcomeText}>Let's start creating your Legacy today!</Text>
                            </View>
                            
                            <TextInput
                                autoComplete={'name'}
                                //autoFocus={true}
                                mode={'flat'}
                                style={styles.textInput}
                                theme={{colors:{placeholder:colors.primary}}}
                                onChangeText={handleChange('name')}
                                value={values.name}
                                label={'Full Name'}
                                placeholder={'Enter your full name'}
                                error={errors.email?true:false}
                                returnKeyType={'next'}
                                onSubmitEditing={()=>emailRef?.current?.focus()}
                            />
                            <Text style={styles.errorText}>{errors.name}</Text>

                            <TextInput
                                ref={(ref:any)=>emailRef.current=ref}
                                mode={'flat'}
                                style={styles.textInput}
                                theme={{colors:{placeholder:colors.primary}}}
                                onChangeText={(email)=>setFieldValue('email',email.trim())}
                                value={values.email}
                                label={'Email Address'}
                                placeholder={'Enter your email'}
                                error={errors.email?true:false}
                                autoComplete="email"
                                returnKeyType={'next'}
                                onSubmitEditing={()=>passwordRef?.current?.focus()}
                            />
                            <Text style={styles.errorText}>{errors.email}</Text>

                            <TextInput
                                autoComplete={'password'}
                                ref={(ref:any)=>passwordRef.current=ref}
                                mode={'flat'}
                                style={styles.textInput}
                                theme={{colors:{placeholder:colors.primary}}}
                                onChangeText={handleChange('password')}
                                value={values.password}
                                label={'Password'}
                                placeholder={'Enter your password'}
                                error={errors.password?true:false}
                                secureTextEntry={!showPass}
                                returnKeyType={'done'}
                                onSubmitEditing={()=>handleSubmit()}
                                //@ts-ignore
                                right={<TextInput.Icon icon={showPass?"eye-off":"eye"} onPress={()=>setShowPass(!showPass)} />}
                            />
                            <Text style={styles.errorText}>{errors.password}</Text>
                            <Text style={styles.errorText}>{errors.error}</Text>

                            <Text style={{color:colors.primary}} onPress={()=>{navigation.navigate('Docs',{doc:'termsandconditions',title:'Terms and Conditions'})}}>By signing up you are agreeing to our terms and conditions</Text>

                            <Button
                                style={styles.button}
                                onPress={()=>{
                                    handleSubmit();
                                }}
                                color={colors.primary}
                                mode={'contained'}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                uppercase={true}
                            >
                                Create Account
                            </Button>
                            
                             {/* <GoogleSigninButton
                                size={GoogleSigninButton.Size.Wide}
                                style={styles.button}
                                color={GoogleSigninButton.Color.Light}
                                onPress={()=>{
                                    setSubmitting(true);
                                    SignInWithGoogle()
                                    .catch((error)=>{
                                        console.log('error: ', error);
                                        setFieldError('error',error.message);
                                    })
                                    .finally(()=>{setSubmitting(false);})
                                }}
                                disabled={isSubmitting}
                            /> */}

                            <TouchableOpacity
                                disabled={isSubmitting}
                                onPress={()=>{
                                    setSubmitting(true);
                                    SignInWithGoogle()
                                    .catch((error)=>{
                                        console.log('error: ', error);
                                        setFieldError('error',error.message);
                                    })
                                    .finally(()=>{setSubmitting(false);})
                                }}
                                style={{...styles.button,marginBottom:10,backgroundColor:colors.accent}}
                            >
                                <Image 
                                    resizeMode={'contain'}
                                    source={Strings.GOOGLE_SIGNIN}
                                    style={styles.buttonImg}
                                />
                            </TouchableOpacity>

                            <AppleAuthentication.AppleAuthenticationButton
                                buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                                buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
                                cornerRadius={5}
                                style={styles.button}
                                onPress={async () => { 
                                    setSubmitting(true);
                                    SignInWithApple() 
                                    .catch((error)=>{setFieldError('error',error.message)})
                                    .finally(()=>{
                                        setSubmitting(false);
                                    })
                                }}
                            />

                            <Text style={{fontSize:Width>500?20:13,marginTop:10,textAlign:'center'}}>OR</Text>

                            <Button
                                style={styles.button2}
                                labelStyle={{fontSize:Width>500?25:14}}
                                onPress={()=>navigation.navigate('Sign In')}
                                color={colors.primary}
                                mode={'text'}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                uppercase={false}
                            >
                                Login
                            </Button>

                        </View>
                    )
                }}
            </Formik>
            </View>
    )
    
    return(
        <View style={{flex:1}}>
        <KeyboardAwareScrollView
            contentContainerStyle={styles.container}
            keyboardShouldPersistTaps={'always'}
        >
            {
                user.id?
                    <>
                        <ProfileScreen navigation={navigation} />
                        <Button
                            style={{...styles.button,marginBottom:'10%',marginLeft: '10%',marginRight: '10%'}}
                            onPress={async ()=>{ next(); }}
                            color={colors.primary}
                            mode={'contained'}
                            uppercase={true}
                        >
                            Continue
                        </Button>
                    </>
                :
                    signUpComponent()
            }
        </KeyboardAwareScrollView>
        </View>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({
            button: {
                borderRadius: 10,
                height: Width>500?75:55,
                justifyContent: 'space-around',
                marginTop: '4%',
            },
            button2: {
                borderRadius: 10,
                justifyContent: 'space-around',
            },
            buttonImg: {
                borderRadius: 10,
                height: Width>500?75:55,
                //marginTop: '4%',
                width:Width*0.8,
            },
            container: {
                alignContent: 'center',
                alignSelf: 'center',
                backgroundColor: colors.background,
                marginTop: '5%',
                maxWidth: 700,
                width: '100%',
            },
            errorText: {
                color: colors.error,
            },
            logo: {
                alignSelf:'center',
                height: Width>500?350:200,
                marginBottom: 10,
                resizeMode: 'contain',
                width: Width>500?350:200,
            },
            textInput: {
                backgroundColor: 'transparent',
                fontSize: 17,
                //marginBottom: 10,
            },
            welcomeText: {
                color: colors.primary,
                fontSize: Width>500?30:15,
            },
            welcomeTextContainer: {
                marginBottom: 10
            },
            welcomeTextHeader: {
                color: 'black',
                fontSize: Width>1000?60:20,
                fontWeight: 'bold',
            },
        })
    );
}