import React from 'react';
import { Firestore, Functions } from '../constants/Firebase';
import { AppContext } from '../context';
import { useUser } from './useUser';
const {useEffect,useState}=React;

interface PlaidLinkType {
    accessToken: string
    id: string
    item_id: string
    name: string
    refresh: false
}

const InitialState = {loading:false,accounts:[],error:false,errorMessage:''};
 
export const useRefreshBank = ()=>{
    const { dispatch, state:AppState } = React.useContext(AppContext);
    const {user} = AppState;
    const [state,setState] = useState(InitialState);
    const {UpdateUserData} = useUser();
    
    const UpdateAccountAsRefreshedFunction = Functions.httpsCallable(Functions.getFunctions(),'updateAccountAsRefreshedv2');

    async function checkForRefresh(plaidLink:any){
        if(!plaidLink) {
            dispatch({ type: 'setRefreshBank', payload: false });
            return;
        }
        for (const key in plaidLink) {
            const val: PlaidLinkType = plaidLink[key];
            if(!val.refresh) continue;
            dispatch({ type: 'setRefreshBank', payload: true });
            return;
        }
        dispatch({ type: 'setRefreshBank', payload: false });
    }

    async function getRequiredRefresh(plaidLink:any){
        if(!plaidLink) {
            if(!user.plaidLink)return;
            plaidLink = user.plaidLink;
        }
        const accounts:any = [];
        setState({...InitialState,loading:true});
        for (const key in plaidLink) {
            const val: PlaidLinkType = plaidLink[key];
            if(!val.refresh) continue;
            accounts.push(val);
            break;
        }
        setState({...InitialState,accounts});
    }

    async function updateAccount(user:any,account:any){
        account.refresh = false;
        const itemId = account.item_id??account.id;
        //await UpdateUserData(user);
        const params = {
            accountId:account.account_id,
            itemId:itemId,
            refresh:false,
            bankId:account.bankId,
            bankName:account.bankName
        };
        return UpdateAccountAsRefreshedFunction(params)
        .catch((error)=>{
            console.log('updateAccRefresh',error.message);
        });
    }
 
    useEffect(()=>{
    },
    []);

    return {...state,checkForRefresh,getRequiredRefresh,updateAccount};
}