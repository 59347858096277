import dayjs from 'dayjs';
import React from 'react';
import {FlatList,StyleSheet,useWindowDimensions,View,} from 'react-native';
import {Card, Divider, useTheme} from 'react-native-paper';
import RBSheet from "react-native-raw-bottom-sheet";
import { useTransactions } from '../hooks/useTransactions';
import { Loading } from './LoadingModal';
import TransactionListItem from './TransactionListItem';

interface Props {
    category: string
    date: number
    navigation: any
    RbRef: any//React.RefObject<RBSheet>
}

export default function TransactionCategoryModal({
    category,date,navigation,RbRef
}:Props){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {height} = useWindowDimensions();

    const { loading,transactions, GetTransactions } = useTransactions({get:false});

    React.useEffect(()=>{
        GetTransactions(-1,date,category);
    },[date,category]);
    
    return(
        <RBSheet 
            ref={ref2 => {RbRef.current = ref2}}
            closeOnDragDown={true}
            customStyles={{
                container: {
                    borderRadius:20,
                    height: height/2,
                    padding:20
                }
            }}
        >
            <View>
                <Card.Title 
                    title={category}
                    subtitle={dayjs(date).format('MMMM, YYYY')}
                />
                <Divider />
                <FlatList 
                    keyExtractor={(item)=>item.id}
                    data={transactions}
                    renderItem={({item})=><TransactionListItem item={item} navigation={navigation} onPress={()=>{RbRef.current.close()}} />}
                    ListFooterComponent={<Loading size={'small'} visible={loading} />}
                />
            </View>
        </RBSheet>
    )
}

const Styles = (colors:any)=>{
    return (
        StyleSheet.create({})
    );
}