import React from 'react';
import {Image, StyleSheet,Text,View} from 'react-native';
import {Button, useTheme} from 'react-native-paper';
import Strings from '../constants/Strings';
import { useBiometrics } from '../hooks/useBiometrics';

interface Props{
    biometric?:boolean
    update?:()=>void
}

export default function SplashScreen({biometric=false,update}:Props){ 
    const {colors} = useTheme();
    const styles = Styles(colors);
    const {loading,authenticate} = useBiometrics();

    React.useEffect(()=>{
        if(biometric) authenticate();
    },[biometric]);

    return(
        <View style={styles.root}>
            <Image source={Strings.SPLASH_IMAGE} resizeMode={'contain'} style={styles.image} />
            {
                biometric?
                    <View style={{position:'absolute',bottom:'10%',}}>
                        <Button loading={loading} mode="contained" color={colors.primary} onPress={()=>{if(!loading)authenticate()}}>Login</Button>
                    </View>
                :
                update?
                    <View style={{position:'absolute',bottom:'10%',}}>
                        <Text style={{fontSize:15, color:'white',marginBottom:10}}>A new update is available.</Text>
                        <Button mode="contained" color='white' onPress={update}>Continue</Button>
                    </View>
                :
                    <></>    
            }
        </View>
    );
}

const Styles = (colors:any)=>{
return (
    StyleSheet.create({
        root: {
            alignItems: 'center',
            backgroundColor: colors.primary,
            flex: 1,
            justifyContent: 'center',
        },
        image: {
            backgroundColor: '#000',
            width: '100%'
        }
    })
);
}